import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Component } from "react";
import toast from "react-hot-toast";
import { API_DOMAIN, callAPI } from "../../../utils/API";
import { generateModalStyle } from "../../../utils/Misc";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

interface EditUserModalProps {
  user: any;
  onClose: () => void;
  onSave: () => void;
}

interface EditUserModalState { }

class EditUserModal extends Component<EditUserModalProps, EditUserModalState> {
  constructor(props: EditUserModalProps) {
    super(props);
    this.state = {
      user: {},
    };
  }

  deleteUser() {
    if (window.confirm("Are you sure you want to delete this user?")) {
      callAPI("actions/users", "POST", {
        action: "delete",
        id: this.props.user.id,
      })
        .then((response) => {
          toast.success("User deleted.");
          this.props.onClose();
          this.props.onSave();
        })
        .catch((error) => {
          toast.error("Error deleting user.");
        });
    }
  }

  updateUser(prop: string, val: any) {
    callAPI("actions/users", "POST", {
      user_id: this.props.user.id,
      action: "update",
      property: prop,
      value: val,
    })
      .then((response) => {
        toast.success("User updated successfully.");
        this.props.onSave();
      })
      .catch((error) => {
        toast.error("Error updating user.");
      });
  }

  render() {
    if (this.props.user === null) {
      return <></>;
    }
    return (
      <Modal
        open={this.props.user !== null}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <div className="card">
            <div className="card-body">
              <h1>
                <img
                  id="avatar-open"
                  alt="avatar"
                  className="avatar avatar-user me-3"
                  src={
                    API_DOMAIN + this.props.user.profile_pic
                  }
                  onError={(e) => {
                    (e.target as HTMLImageElement).src =
                      "/assets/img/profile-picture.jfif";
                  }}
                />
                {this.props.user.name}
              </h1>
              <div style={{ paddingLeft: "80px" }}>
                <div className="row">
                  <div className="col-6">
                    <p>
                      Username: <b>{this.props.user.username}</b>
                    </p>
                    <p>
                      Last logon: <b>{this.props.user.last_login}</b>
                    </p>
                  </div>
                  <div className="col-6">
                    <FormGroup>
                      <FormControlLabel control={
                        <Switch
                          checked={this.props.user.priceAccess === "1"}
                          onChange={(e) => {
                            this.updateUser("priceAccess", e.target.checked ? "1" : "0");
                          }}
                        />
                      } label="Price access" />
                      <FormControlLabel control={
                        <Switch
                          checked={this.props.user.financeAccess === "1"}
                          onChange={(e) => {
                            this.updateUser("financeAccess", e.target.checked ? "1" : "0");
                          }}
                        />
                      } label="Finance access" />
                      <FormControlLabel control={
                        <Switch
                          checked={this.props.user.isEditor === "1"}
                          onChange={(e) => {
                            this.updateUser("isEditor", e.target.checked ? "1" : "0");
                            if (e.target.checked) {
                              this.updateUser("isReadOnly", "0");
                            }
                          }}
                        />
                      } label="Editor" />
                      <FormControlLabel control={
                        <Switch
                          checked={this.props.user.isReadOnly === "1"}
                          onChange={(e) => {
                            this.updateUser("isReadOnly", e.target.checked ? "1" : "0");
                          }}
                        />
                      } label="Read only" />
                      <FormControlLabel control={
                        <Switch
                          checked={this.props.user.isComputerAdmin === "1"}
                          onChange={(e) => {
                            this.updateUser("isComputerAdmin", e.target.checked ? "1" : "0");
                          }}
                        />
                      } label="Computer Admin" />
                      <FormControlLabel control={
                        <Switch
                          checked={this.props.user.isAdmin === "1"}
                          onChange={(e) => {
                            this.updateUser("isAdmin", e.target.checked ? "1" : "0");
                          }}
                        />
                      } label="Admin" />
                    </FormGroup>
                  </div>
                </div>
                <button
                  className="btn btn-sm btn-outline-secondary me-2"
                  onClick={() => this.updateUser("enabled", this.props.user.enabled === "1" ? "0" : "1")}
                >
                  {this.props.user.enabled === "1" ? "Disable" : "Enable"} User
                </button>
                <button
                  className="btn btn-sm btn-outline-secondary me-2"
                  onClick={() => this.deleteUser()}
                >
                  Delete User
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              onClick={() => {
                this.props.onClose();
              }}
              className="btn btn-primary"
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default EditUserModal;
