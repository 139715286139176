import { Component } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { callAPI } from "../../utils/API";
import { formatter } from "../../utils/Misc";
import AddContactModal from "./AddContactModal";
import AddEndUserModal from "./AddEndUserModal";
import EditContactModal from "./EditContactModal";
import EditCustomerModal from "./EditCustomerModal";
import EditEndUserModal from "./EditEndUserModal";
import UserContext from "../ui/UserContext";
import { Box, Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';


interface CustomerPageProps {
  id: number;
  endUserID?: number;
}

interface CustomerPageState {
  customer: any;
  contacts: any[];
  end_users: any[];
  orders: any[];
  editCustomerModalOpen: boolean;
  addContactModalOpen: boolean;
  addEndUserModalOpen: boolean;
  editingContactID: number;
  editingEndUserID: number;
}

const styles = {
  contactCard: {
    flex: '0 0 auto',
    width: 200,
    marginRight: 8,
  },
  contactContainer: {
    display: 'flex',
    marginTop: 8,
    paddingBottom: 8,
  },
  addButtonCard: {
    flex: '0 0 auto',
    width: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    cursor: 'pointer',
  },
  addButtonIcon: {
    fontSize: '3rem',
  },
  editButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
};

class CustomerPageWithID extends Component<
  CustomerPageProps,
  CustomerPageState
> {
  constructor(props: CustomerPageProps) {
    super(props);
    this.state = {
      customer: {},
      contacts: [],
      end_users: [],
      orders: [],
      editCustomerModalOpen: false,
      addContactModalOpen: false,
      addEndUserModalOpen: false,
      editingContactID: -1,
      editingEndUserID: this.props.endUserID || -1,
    };
  }

  loadCustomer() {
    callAPI("tables/customers_view", "POST", {
      where: {
        id: this.props.id,
      },
    }).then((response) => {
      this.setState({
        customer: response[0],
      });
    });
  }

  loadEndUsers() {
    callAPI("tables/end_users", "POST", {
      where: {
        customer: this.props.id,
      },
    }).then((response) => {
      this.setState({
        end_users: response,
      });
    });
  }

  loadContacts() {
    callAPI("tables/contacts", "POST", {
      where: {
        customer_id: this.props.id,
      },
    }).then((response) => {
      this.setState({
        contacts: response,
      });
    });
  }

  loadOrders() {
    callAPI("tables/orders_view", "POST", {
      where: {
        customer_id: this.props.id,
      },
    }).then((response) => {
      this.setState({
        orders: response,
      });
    });
  }

  componentDidMount() {
    this.loadCustomer();
    this.loadEndUsers();
    this.loadContacts();
    this.loadOrders();
  }

  componentDidUpdate(prevProps: Readonly<CustomerPageProps>): void {
    if (prevProps.id !== this.props.id) {
      this.loadCustomer();
      this.loadEndUsers();
      this.loadContacts();
      this.loadOrders();
    }
  }

  deleteCustomer() {
    if (!window.confirm("Are you sure you want to delete this customer?")) {
      return;
    }
    callAPI("actions/customers", "POST", {
      action: "delete",
      customer_id: this.props.id,
    })
      .then((response) => {
        toast.success("Customer deleted");
        window.history.back();
      })
      .catch((data) => {
        toast.error("Error deleting customer");
        console.log(data);
      });
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Customer Details</h1>
              {!!user?.isEditor && (
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.setState({
                          editCustomerModalOpen: true,
                        });
                      }}
                    >
                      Edit Customer
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.deleteCustomer();
                      }}
                    >
                      Delete Customer
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <h5>Quote History</h5>
                <div>
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th>Quote #</th>
                        <th>Type</th>
                        <th>Ship Date</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.map((order) => (
                        <tr key={order.id}>
                          <td>
                            <Link to={"/orders/" + order.id}>
                              {order.order_num}
                            </Link>
                          </td>
                          <td>{order.type}</td>
                          <td>{order.ship_date}</td>
                          <td>{formatter.format(order.total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {this.state.customer.name}
                    </Typography>
                    {/* <Typography variant="body1">{"description"}</Typography> */}
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      Address: {formatAddress(this.state.customer)}
                    </Typography>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="body2" sx={{ mt: 2 }}>
                      Contacts:
                    </Typography>
                    <Box style={styles.contactContainer} className="scroll-horizontal">
                      {this.state.contacts.map((contact, index) => (
                        <Card key={index} style={styles.contactCard}>
                          <CardContent>
                            <Typography variant="body1">{contact.name}</Typography>
                            <Typography variant="body2">
                              Phone: {contact.phone}
                            </Typography>
                            <Typography variant="body2">
                              Email: {contact.email}
                            </Typography>
                          </CardContent>
                          {!!user?.isEditor && (
                            <CardActions>
                              <Button size="small" onClick={() => {
                                this.setState({
                                  editingContactID: contact.id,
                                });
                              }}>Edit</Button>
                            </CardActions>
                          )}
                        </Card>
                      ))}
                      {!user?.isReadOnly && (
                        <Card style={styles.addButtonCard} onClick={() => {
                          this.setState({
                            addContactModalOpen: true,
                          });
                        }}>
                          <AddIcon style={styles.addButtonIcon} />
                        </Card>
                      )}
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="body2" sx={{ mt: 2 }}>
                      End Users:
                    </Typography>
                    <Box style={styles.contactContainer} className="scroll-horizontal">
                      {this.state.end_users.map((end_user, index) => (
                        <Card key={index} style={styles.contactCard}>
                          <CardContent>
                            <Typography variant="body1">{end_user.name}</Typography>
                            <Typography variant="body2">
                              Defense: {parseInt(end_user.defense) ? "Yes" : "No"}
                            </Typography>
                          </CardContent>
                          {!!user?.isEditor && (
                            <CardActions>
                              <Button size="small" onClick={() => {
                                this.setState({
                                  editingEndUserID: end_user.id,
                                });
                              }}>Edit</Button>                            </CardActions>
                          )}
                        </Card>
                      ))}
                      {!user?.isReadOnly && (
                        <Card style={styles.addButtonCard} onClick={() => {
                          this.setState({
                            addEndUserModalOpen: true,
                          });
                        }}>
                          <AddIcon style={styles.addButtonIcon} />
                        </Card>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </div>
            </div>
            <AddEndUserModal
              isOpen={this.state.addEndUserModalOpen}
              onClose={() => {
                this.setState({ addEndUserModalOpen: false });
              }}
              onSave={() => {
                this.loadEndUsers();
              }}
              customer_id={this.props.id}
              defense={this.state.customer.defense}
            />
            <AddContactModal
              customer_id={this.props.id}
              isOpen={this.state.addContactModalOpen}
              onClose={() => {
                this.setState({ addContactModalOpen: false });
              }}
              onSave={() => {
                this.loadContacts();
              }}
            />
            <EditCustomerModal
              isOpen={this.state.editCustomerModalOpen}
              onClose={() => {
                this.setState({ editCustomerModalOpen: false });
              }}
              onSave={() => {
                this.loadCustomer();
              }}
              customer={this.state.customer}
            />
            <EditContactModal
              id={this.state.editingContactID}
              onClose={() => {
                this.setState({ editingContactID: -1 });
              }}
              onSave={() => {
                this.loadContacts();
              }}
            />
            <EditEndUserModal
              id={this.state.editingEndUserID}
              onClose={() => {
                this.setState({ editingEndUserID: -1 });
              }}
              onSave={() => {
                this.loadEndUsers();
              }}
            />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

function CustomerPage() {
  const { id, endUserID } = useParams();
  return (
    <CustomerPageWithID
      id={parseInt(id || "")}
      endUserID={parseInt(endUserID || "")}
    />
  );
}

function formatAddress(customer: any): JSX.Element {
  const address = [];
  if (customer.addr_line1) {
    address.push(customer.addr_line1);
  }
  if (customer.addr_line2) {
    address.push(customer.addr_line2);
  }
  if (customer.city) {
    address.push(customer.city);
  }
  if (customer.state) {
    address.push(customer.state);
  }
  if (customer.country) {
    address.push(customer.country);
  }
  if (customer.zip) {
    address.push(customer.zip);
  }
  return <>{address.join(", ").replaceAll("<br>", "\n")}</>;
}

export default CustomerPage;
