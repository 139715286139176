import * as React from 'react';
import toast from 'react-hot-toast';
import { callAPI } from '../../utils/API';
import { DataTable, GridColumn, GridElement } from '../../utils/DataTable';
import GSATravelRatesModal from './GSATravelRatesModal';


interface TravelTableProps {
  item_id?: number;
  onTotalsChange: (price: number, cost: number) => void;
  onChange: (data: TravelData) => void;
}

interface TravelTableState {
  data: TravelData;
  totalPrice: number;
  totalCost: number;
  gsaRatesModalOpen: boolean;
  loaded: boolean;
}

export interface TravelData {
  num_people: number;
  days_on_site: number;
  travel_days: number;
  travel_location: string;
  airfare: number;
  daily_car_rental: number;
  daily_parking: number;
  daily_conus_lodging: number;
  per_diem_on_site: number;
}

const columns: GridColumn[] = [
  { label: 'Travel Calculator', width: '50%', colSpan: 2 },
  { label: 'Qty', width: '25%', colSpan: 1 },
  { label: 'Cost', width: '25%', colSpan: 1 },
];

export class TravelTable extends React.Component<TravelTableProps, TravelTableState> {
  constructor(props: TravelTableProps) {
    super(props)
    this.state = {
      data: {
        num_people: 0,
        days_on_site: 0,
        travel_days: 0,
        travel_location: '',
        airfare: 0,
        daily_car_rental: 0,
        daily_parking: 0,
        daily_conus_lodging: 0,
        per_diem_on_site: 0,
      },
      totalPrice: 0,
      totalCost: 0,
      gsaRatesModalOpen: false,
      loaded: false,
    }
  }

  generateGrid(): GridElement[][] | null {
    if (!this.state.loaded) return null;
    return [
      [
        { value: "# people", bold: true },
        { value: this.state.data.num_people, editable: true },
        {},
        {}
      ],
      [
        { value: "Days on site" },
        { value: this.state.data.days_on_site, type: "number", editable: true },
        {},
        {}
      ],
      [
        { value: "Travel days" },
        { value: this.state.data.travel_days, type: "select", selectOptions: ["0", "1", "2"], editable: true },
        {},
        {}
      ],
      [
        { value: "Travel location" },
        { value: this.state.data.travel_location, editable: true },
        {},
        {}
      ],
      [
        { value: "Airfare" },
        { value: this.state.data.airfare, type: "money", editable: true },
        { value: "=B1", type: "number", isFormula: true },
        { value: "=B5*C5", type: "money", isFormula: true }
      ],
      [
        { value: "Daily Car Rental" },
        { value: this.state.data.daily_car_rental, type: "money", editable: true },
        { value: "=B2+B3", type: "number", isFormula: true },
        { value: "=B6*C6", type: "money", isFormula: true }
      ],
      [
        { value: "Daily Parking" },
        { value: this.state.data.daily_parking, type: "money", editable: true },
        { value: "=B2+B3", type: "number", isFormula: true },
        { value: "=B7*C7", type: "money", isFormula: true }
      ],
      [
        { value: "Daily CONUS Lodging" },
        { value: this.state.data.daily_conus_lodging, type: "money", editable: true },
        { value: "=B1*(B2+B3-1)", type: "number", isFormula: true },
        { value: "=B8*C8", type: "money", isFormula: true }
      ],
      [
        { value: "Per diem on site" },
        { value: this.state.data.per_diem_on_site, type: "money", editable: true },
        { value: "=B1*B2", type: "number", isFormula: true },
        { value: "=B9*C9", type: "money", isFormula: true }
      ],
      [
        { value: "Per diem 1st/last days" },
        { value: "=B9*0.75", type: "money", isFormula: true },
        { value: "=B1*B3", type: "number", isFormula: true },
        { value: "=B10*C10", type: "money", isFormula: true }
      ],
      [
        {},
        {},
        { value: "Subtotal travel" },
        { value: "=SUM(D5:D10)", type: "money", isFormula: true, watched: true }
      ],
      [
        {},
        { value: "0.1", type: "percent" },
        { value: "Travel OH" },
        { value: "=D11*B12", type: "money", isFormula: true }
      ],
      [
        {},
        {},
        { value: "Total travel", bold: true },
        { value: "=D11+D12", type: "money", isFormula: true, watched: true }
      ],
    ];
  }

  reverseData(grid: GridElement[][]) {
    if (!this.state.loaded) return;
    this.setState({
      data: {
        num_people: grid[0][1].value as number,
        days_on_site: grid[1][1].value as number,
        travel_days: grid[2][1].value as number,
        travel_location: grid[3][1].value as string,
        airfare: grid[4][1].value as number,
        daily_car_rental: grid[5][1].value as number,
        daily_parking: grid[6][1].value as number,
        daily_conus_lodging: grid[7][1].value as number,
        per_diem_on_site: grid[8][1].value as number,
      }
    }, () => {
      this.props.onChange(this.state.data);
    });
  }

  loadData() {
    if (!this.props.item_id) {
      this.setState({
        loaded: true,
      });
      return;
    }
    callAPI("tables/custom_product_travel", "POST", {
      where: {
        item_id: this.props.item_id,
      }
    })
      .then((res) => {
        if (res.length === 0) {
          res = [{
            num_people: 1,
            days_on_site: 1,
            travel_days: 0,
            travel_location: "",
            airfare: 0,
            daily_car_rental: 0,
            daily_parking: 0,
            daily_conus_lodging: 0,
            per_diem_on_site: 0,
          }];
        }
        this.setState({
          data: res[0],
          loaded: true,
        });
      })
      .catch((err) => {
        toast.error("Error getting materials data.");
      });
  }

  componentDidMount() {
    this.loadData();
  }

  updateTotals(watchedValues: string[]) {
    const totalPrice = parseFloat(watchedValues[1]);
    const totalCost = parseFloat(watchedValues[0]);
    const previousPrice = this.state.totalPrice;
    const previousCost = this.state.totalCost;
    if (totalPrice !== previousPrice || totalCost !== previousCost) {
      this.setState({ totalPrice, totalCost }, () => {
        this.props.onTotalsChange(this.state.totalPrice, this.state.totalCost);
      });
    }
  }

  render() {
    return (
      <div className="w-100">
        <DataTable
          columns={columns}
          defaultValue={this.generateGrid() || undefined}
          onChange={(value, watchedCells) => {
            this.reverseData(value);
            this.updateTotals(watchedCells);
          }}
        />
        <div className="d-flex justify-content-center my-3">
          <button className="btn btn-outline-secondary" onClick={() => {
            this.setState({ gsaRatesModalOpen: true });
          }}>GSA Travel Rates</button>
        </div>
        <GSATravelRatesModal
          open={this.state.gsaRatesModalOpen}
          onClose={() => {
            this.setState({ gsaRatesModalOpen: false });
          }}
        />
      </div>
    )
  }
}