import { Component } from "react";
import { callAPI } from "../../utils/API";
import { formatterNoCents } from "../../utils/Misc";
import DataGrid from "../../utils/DataGrid";
import toast from "react-hot-toast";
import { ORDER_TYPES } from "../dashboard/RequirementsChart";
import { Link } from "react-router-dom";

interface ProjectionsSummaryTableProps {
  orders: any[];
  onUpdate: () => void;
  domestic: boolean;
  defense: boolean;
}

interface ProjectionsSummaryTableState {
  editingRows: any;
  total: number;
}

const usdPrice: any = {
  valueFormatter: ({ value }: any) => formatterNoCents.format(Number(value)),
  cellClass: "font-tabular-nums",
};

class ProjectionsSummaryTable extends Component<
  ProjectionsSummaryTableProps,
  ProjectionsSummaryTableState
> {
  constructor(props: ProjectionsSummaryTableProps) {
    super(props);
    this.state = {
      editingRows: {},
      total: 0,
    };
  }

  async processRowUpdate(newRow: any) {
    const form = {
      ship_date: newRow.ship_date,
      award_date: newRow.award_date,
      type: newRow.type,
      action_required: newRow.action_required,
    };
    const order_id = newRow.id;
    const promise = callAPI("actions/order", "POST", {
      action: "update",
      order_id,
      data: form,
    });
    toast.promise(promise, {
      loading: "Saving...",
      success: "Saved!",
      error: "Error saving",
    });
    promise.then(() => {
      this.props.onUpdate();
    });
  }

  render() {
    const data = this.props.orders.filter((row) =>
      row.is_domestic === this.props.domestic && row.is_defense === this.props.defense
    );
    return (
      <div>
        <div style={{ height: "200px" }}>
          <DataGrid
            rows={data}
            columns={[
              {
                field: "action_required",
                headerName: "AR",
                width: 60,
                editable: false,
                cellRenderer: (params: any) => {
                  return (
                    <input
                      type="checkbox"
                      checked={parseInt(params.value) === 1}
                      onChange={(e) => {
                        const row = params.data;
                        this.processRowUpdate({ ...row, action_required: e.target.checked ? "1" : "0" });
                      }}
                    />
                  );
                },
              },
              {
                field: "customer",
                headerName: "Customer",
                editable: false,
              },
              { field: "end_user", headerName: "End User", editable: false },
              { field: "order_num", headerName: "Quote/CE", width: 100, editable: false, cellRenderer: (params: any)=>{
                return <Link to={`/bizdev/${params.data.id}`}>{params.value}</Link>
              } },
              {
                field: "description",
                headerName: "Application",
                editable: false,
              },
              {
                field: "award_date",
                headerName: "Est. Award",
                editable: true, width: 100
              },
              {
                field: "ship_date",
                headerName: "Est. Ship Date",
                editable: true, width: 100
              },
              {
                field: "type",
                headerName: "Probability",
                editable: true, width: 100,
                cellEditor: (params: any) => {
                  const row = params.data;
                  return (
                    <select
                      value={row.type}
                      onChange={(e) => {
                        this.processRowUpdate({ ...row, type: e.target.value });
                      }}
                    >
                      {ORDER_TYPES.concat(["Shipped"]).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  );
                },
              },
              {
                field: "total_price",
                headerName: "Total",
                editable: false,
                ...usdPrice,
                width: 100
              },
            ]}
            className="compact"
            defaultColumnProps={{
              sortable: true,
              resizable: true,
              suppressMovable: true,
            }}
            processRowUpdate={(e) => this.processRowUpdate(e)}
          />
        </div>
        <p className="bold text-right">
          Total: {formatterNoCents.format(
            data.reduce((acc, row) => acc + Number(row.total), 0)
          )}
        </p>
      </div>
    );
  }
}

export default ProjectionsSummaryTable;
