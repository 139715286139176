import { Component } from "react";
import "./menu.css";
import { Link } from "react-router-dom";
import { Account } from "../../utils/Auth";
import { API_DOMAIN } from "../../utils/API";

interface HeaderProps {
  user: Account | null;
  searchTerm: string;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hideSearch: boolean;
}

class Header extends Component<HeaderProps> {
  private searchInput: HTMLInputElement | null = null;

  render() {
    if (!this.props.user) {
      return (
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
          <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/">
            <img
              className="shadow-sm"
              height="20px"
              title="LTC Logo"
              alt="LTC Logo"
              src="/assets/img/favicon256.png"
            />
            <span>Planning</span>
          </Link>
        </header>
      );
    }
    return (
      <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" to="/">
          <img
            className="shadow-sm"
            height="20px"
            title="LTC Logo"
            alt="LTC Logo"
            src="/assets/img/favicon256.png"
          />
          <span>Planning</span>
        </Link>
        <button
          className="navbar-toggler position-absolute d-md-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {!this.props.hideSearch && (
          <input
            className="form-control form-control-dark w-100"
            type="text"
            ref={(ref) => (this.searchInput = ref)}
            placeholder="Search"
            aria-label="Search"
            value={this.props.searchTerm}
            onChange={this.props.onSearch}
          />
        )}
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <div className="nav-item drop-down-menu user-menu enabled">
              <span className="nav-link selection">
                <div className="avatar avatar-athlete">
                  <img
                    alt={this.props.user ? this.props.user.name : ""}
                    className="avatar-img size-32"
                    src={API_DOMAIN + this.props.user?.avatar}
                    onError={(e) => {
                      (e.target as HTMLImageElement).src =
                        "/assets/img/profile-picture.jfif";
                    }}
                  />
                </div>
                <span className="user-name">
                  {this.props.user ? this.props.user.name : ""}
                </span>
              </span>
              <ul className="options">
                <li>
                  <Link to="/account">Account</Link>
                </li>
                <li>
                  <Link to="/settings">Settings</Link>
                </li>
                {this.props.user?.isAdmin && (
                  <li>
                    <Link to="/admin">Admin</Link>
                  </li>
                )}
                <li>
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      localStorage.removeItem("jwt");
                      window.location.href = "/";
                    }}
                  >
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </header>
    );
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === '/' && this.searchInput) {
      if (document.activeElement?.tagName !== 'INPUT' && !document.activeElement?.closest('.ck')) {
        event.preventDefault(); // Prevent the "/" character from being typed in the input
        this.searchInput.focus();
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }
}

export default Header;
