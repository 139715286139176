import Alert from "@mui/material/Alert";
import { Component } from "react";
import { generateModalStyle } from "../../../utils/Misc";
import { Box, Modal, Typography } from "@mui/material";
import ComputerList from "./ComputerAudit";

export interface Computer {
  id: number;
  machine_name: string;
  pc_model: string;
  processor: string;
  memory: string;
  disk_size_mb: number;
  disk_free_mb: number;
  os: string;
  gpus: string;
  software: string;
  local_admins: string;
  username: string;
  timestamp: string;
}

interface ComputerInspectionModalProps {
  computer?: Computer;
  onClose: () => void;
}

interface ComputerInspectionModalState {
}

const IGNORE_SOFTWARE = [
  "Disk Cleanup",
  "Event Viewer",
  "File Explorer",
  "Administrative Tools",
  "Character Map",
  "Command Prompt",
  "Component Services",
  "Computer Management",
  "Control Panel",
  "LiveCaptions",
  "Magnify",
  "Memory Diagnostics Tool",
  "Microsoft Edge",
  "Narrator",
  "On-Screen Keyboard",
  "Performance Monitor",
  "Print Management",
  "RecoveryDrive",
  "Registry Editor",
  "Remote Desktop Connection",
  "Resource Monitor",
  "Run",
  "Security Configuration Management",
  "services",
  "Speech Recognition",
  "Steps Recorder",
  "System Configuration",
  "System Information",
  "Task Manager",
  "Task Scheduler",
  "VoiceAccess",
  "Windows Defender Firewall with Advanced Security",
  "Windows Fax and Scan",
  "Windows Media Player Legacy",
  "Windows PowerShell",
  "Windows PowerShell (x86)",
  "Windows PowerShell ISE",
  "Windows PowerShell ISE (x86)",
  "Wordpad",
  "XPS Viewer",
  "dfrgui",
  "About Java",
  "7-Zip Help",
  "Install Additional Tools for Node.js",
  "Node.js command prompt",
  "PC Health Check",
  "Windows Media Player",
  "Administration",
  "Administration (S drive) Shortcut",
  "Common (N drive) Shortcut",
  "iSCSI Initiator",
  "ODBC Data Sources (32-bit)",
  "ODBC Data Sources (64-bit)",
  "Office Language Preferences",
  "Telemetry Log for Office",
  "Open",
  "PDF",
  "Math Input Panel",
  "SolidNetWork License Manager Client 2019",
  "Send to OneNote",
  "Snipping Tool",
  "Notepad",
  "Paint",
  "Intuit Data Protect",
  "CutePDF Professional Help",
  "Check For Updates",
  "novaPDF Help",
  "Buy novaPDF Professional Desktop",
  "OneDrive",
  "Calculator",
  "Windows Firewall with Advanced Security",
  "Server Manager",
];

const IGNORE_SUBSTRINGS = [
  "uninstall",
  "readme",
  "help"
];

const MICROSOFT_OFFICE_SW = [
  "Access",
  "Excel",
  "OneNote",
  "Outlook",
  "PowerPoint",
  "Publisher",
  "Skype for Business",
  "Skype for Business Recording Manager",
  "Word",
];

class ComputerInspectionModal extends Component<
  ComputerInspectionModalProps,
  ComputerInspectionModalState
> {
  constructor(props: ComputerInspectionModalProps) {
    super(props);
    this.state = {
    };
  }

  render() {
    if (!this.props.computer) {
      return null;
    }
    const row = this.props.computer;
    // clean up local_admins
    if (row.local_admins) {
      try {
        const arr = JSON.parse(row.local_admins);
        if (arr) {
          row.local_admins = arr.filter((item: string) => item !== "LT\\Domain Admins" && !item.includes("Administrator")).join(", ");
        }
      } catch (e) { }
    }
    // clean up installed software
    if (row.software) {
      try {
        const arr = JSON.parse(row.software);
        if (arr) {
          // remove standard software and any software that includes a string from IGNORE_SUBSTRINGS
          row.software = arr.filter((item: string) => !IGNORE_SOFTWARE.includes(item) && !IGNORE_SUBSTRINGS.some((substring: string) => item.toLowerCase().includes(substring))).join(", ");
          // if contains any microsoft office product, show "Microsoft Office" instead
          if (arr.some((item: string) => MICROSOFT_OFFICE_SW.includes(item))) {
            const arr2 = row.software.split(", ");
            row.software = arr2.filter((item: string) => !MICROSOFT_OFFICE_SW.includes(item)).join(", ");
            row.software += ", Microsoft Office";
          }
        }
      } catch (e) { }
    }
    const warnings = ComputerList.getWarnings(row);
    const disk_percent_used = ((row.disk_size_mb - row.disk_free_mb) / row.disk_size_mb * 100);
    return (
      <Modal
        open={true}
        onClose={this.props.onClose}
      >
        <Box sx={generateModalStyle("78%")}>
          <Typography variant="h6" component="h2">
            {row.machine_name}
          </Typography>
          <Typography variant="subtitle1" component="h2">
            {row.pc_model}
          </Typography>
          <div className="d-flex flex-column gap-2 mb-2">
            {warnings.map((warning, index: number) => (
              <Alert severity={warning.severity} key={index}>{warning.message}</Alert>
            ))}
          </div>
          <Typography variant="body1" component="p">
            <b>Processor:</b> {row.processor}<br />
            <b>Memory:</b> {row.memory}<br />
            <b>Disk:</b> {(row.disk_size_mb / 1024).toFixed(1)} GB ({disk_percent_used.toFixed(0)}% used)<br />
            <b>OS:</b> {row.os?.replace("Microsoft ", "")}<br />
            <b>GPUs:</b> {row.gpus}<br />
            <b>Software:</b> {row.software}<br />
            <b>Local Admins:</b> {row.local_admins}<br />
            <b>Last used by:</b> {row.username}<br />
            <b>Reported at:</b> {new Date(row.timestamp + " UTC").toLocaleString()}<br />
          </Typography>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              onClick={() => {
                this.props.onClose();
              }}
              className="btn btn-primary"
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default ComputerInspectionModal;
