import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OrderGroup from "../orders/OrderGroup";
import NewOrderModal from "../orders/NewOrderModal";
import UserContext from "../ui/UserContext";
import ImportSmartsheetOrderModal from "./ImportSmartsheetOrderModal";
import RecentsSidebar from "./RecentsSidebar";

interface BizDevProps {
  searchTerm?: string;
}

const Orders: React.FC<BizDevProps> = (props: BizDevProps) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const navigate = useNavigate();
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <div className="d-flex">
          <div className="w-100">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                Orders
              </h1>

              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  {!user?.isReadOnly && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        setAddModalOpen(true);
                      }}
                    >
                      Add New
                    </button>
                  )}
                  {!user?.isReadOnly && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        setImportModalOpen(true);
                      }}
                    >
                      Import
                    </button>
                  )}
                </div>
              </div>
            </div>
            <OrderGroup
              name="In Process Orders"
              types={["Order", "Temp Job"]}
              isJob
              searchTerm={props.searchTerm}
            />
            <OrderGroup
              name="Projected Orders"
              types={["Projection 80%", "Projection 90%"]}
              searchTerm={props.searchTerm}
            />
            <OrderGroup
              name="Prospects"
              types={["Prospect"]}
              searchTerm={props.searchTerm}
            />
            <OrderGroup
              name="Shipped"
              types={["Shipped"]}
              isJob
              searchTerm={props.searchTerm}
            />
            <OrderGroup
              name="Archived"
              types={["Archived"]}
              isJob
              searchTerm={props.searchTerm}
            />
            <NewOrderModal
              isOpen={addModalOpen}
              onClose={() => {
                setAddModalOpen(false);
              }}
              onSave={(id) => {
                navigate("/bizdev/" + id);
              }}
            />
            <ImportSmartsheetOrderModal
              isOpen={importModalOpen}
              onClose={() => {
                setImportModalOpen(false);
              }}
              onSave={(id) => {
                // TODO: handle a better way than reloading the page
                window.location.href = "/orders/" + id;
              }}
            />
          </div>
          {showSidebar && (
            <RecentsSidebar onClose={() => setShowSidebar(false)} />
          )}
        </div>
      )
      }
    </UserContext.Consumer >
  );
}

export default Orders;
