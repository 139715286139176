import { Component } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { callAPI } from "../../../utils/API";
import DataGrid from "../../../utils/DataGrid";
import ComputerInspectionModal, { Computer } from "./ComputerInspectionModal";
import { Alert, styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface ComputerListProps {
  searchTerm?: string;
}

interface ComputerListState {
  computers: Computer[];
  inspectingComputer?: Computer;
  loaded: boolean;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    padding: "0.5rem",
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

class ComputerList extends Component<
  ComputerListProps,
  ComputerListState
> {
  constructor(props: ComputerListProps) {
    super(props);
    this.state = {
      computers: [],
      loaded: false,
    };
  }

  loadData() {
    callAPI("tables/computers_list_view", "POST", {
      order: "machine_name"
    })
      .then((res) => {
        this.setState({
          computers: res,
          loaded: true,
        });
      })
      .catch((err) => {
        toast.error("Error loading data");
        console.log(err);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  static getWarnings(row: any) {
    const disk_percent_used = ((row.disk_size_mb - row.disk_free_mb) / row.disk_size_mb * 100);
    const local_admins = row.local_admins.split(",").filter((item: string) => item !== "LT\\Domain Admins" && item !== "{}" && item !== "null");
    const warnings: { severity: "warning" | "error", message: string }[] = [];
    if (disk_percent_used > 80 && disk_percent_used !== Infinity) {
      warnings.push({
        severity: "warning",
        message: "Disk space is limited. This may impact performance."
      });
    }
    if (!row.software.includes("Webroot SecureAnywhere")) {
      warnings.push({
        severity: "warning",
        message: "Webroot is not installed"
      });
    }
    if (local_admins.some((user: string) => {
      return user.replace(row.machine_name + "\\", "").trim().toLowerCase() !== "ltcadmin";
    })) {
      warnings.push({
        severity: "error",
        message: "One or more of the user accounts on this machine have local admin rights, but should not."
      });
    }
    if (!local_admins.some((user: string) => {
      return user.replace(row.machine_name + "\\", "").trim().toLowerCase() === "ltcadmin";
    })) {
      warnings.push({
        severity: "warning",
        message: "Machine should have a local account with local admin rights."
      });
    }
    return warnings;
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Computer Audit</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div
              className="btn-group me-2"
              role="group"
              aria-label="Audit Type"
            >
              <Link
                className="btn btn-sm btn-outline-secondary active"
                to="/computers"
              >
                Computer Audit
              </Link>

              <Link
                className="btn btn-sm btn-outline-secondary"
                to="/computers/domain-audit"
              >
                Domain Audit
              </Link>
            </div>
          </div>
        </div>
        <div style={{ height: "80vh" }}>
          <DataGrid
            rows={this.state.computers}
            columns={[
              {
                field: "machine_name", headerName: "Name", width: 150,
                cellRenderer: (params: any) =>
                  <button className="btn btn-sm btn-link" onClick={() => {
                    this.setState({
                      inspectingComputer: params.data
                    });
                  }}>
                    {params.value}
                  </button>
              },
              { field: "pc_model", headerName: "Model", width: 200 },
              { field: "username", headerName: "User", width: 100 },
              {
                field: "os", headerName: "OS", width: 200,
                valueFormatter: (params: any) => params.value?.replace("Microsoft ", "")
              },
              {
                field: "software", headerName: "Software", width: 200,
                // replace [, ], " with nothing
                // replace "," with ", "
                valueFormatter: (params: any) => params.value?.replace(/[[\]"]/g, "").replace(/,/g, ", ")
              },
              { field: "processor", headerName: "Processor", width: 200, hide: true },
              {
                field: "memory", headerName: "Memory", width: 200, valueFormatter: (params: any) => {
                  // "15.9189567565918 GB, 1867 MHz" -> "16 GB, 1867 MHz"
                  const memory = params.value.split(",")[0].replace(/[^0-9.]/g, "");
                  if (!parseFloat(memory)) {
                    return "Unknown";
                  }
                  return `${Math.round(parseFloat(memory))} GB, ${params.value.split(",")[1]}`;
                }, hide: true
              },
              {
                field: "disk_size_mb", headerName: "Disk Usage", width: 200, cellRenderer: (params: any) => {
                  const disk_percent_used = ((params.data.disk_size_mb - params.data.disk_free_mb) / params.data.disk_size_mb * 100);
                  if (!parseInt(params.data.disk_size_mb) || !parseInt(params.data.disk_free_mb)) {
                    return "Unknown";
                  }
                  return `${params.data.disk_free_mb / 1000} / ${params.data.disk_size_mb / 1000} GB (${disk_percent_used.toFixed(1)}%)`;
                }, hide: true
              },
              { field: "gpus", headerName: "GPUs", width: 200, hide: true },
              { field: "local_admins", headerName: "Local Admins", width: 200, hide: true },
              {
                field: "warnings", headerName: "Warnings", width: 200, cellRenderer: (params: any) => {
                  const warnings = ComputerList.getWarnings(params.data);
                  if (warnings.length) {
                    // ex: "2 warnings, 1 error"
                    const msgs: { [key: string]: number } = {};
                    warnings.forEach((warning: any) => {
                      if (!msgs[warning.severity]) {
                        msgs[warning.severity] = 0;
                      }
                      msgs[warning.severity]++;
                    });
                    const msg = Object.keys(msgs).map((key: string) => `${msgs[key]} ${key}${msgs[key] > 1 ? "s" : ""}`).join(", ");
                    return <LightTooltip arrow title={
                      <div className="d-flex flex-column gap-2">
                        {warnings.map((warning: any, index: number) => {
                          return <Alert key={index} severity={warning.severity}>{warning.message}</Alert>;
                        })}
                      </div>
                    }>
                      <span className={!msgs.error ? "badge bg-warning" : "badge bg-danger"}>{msg}</span>
                    </LightTooltip>;
                  }
                  return null;
                }
              },
              { field: "timestamp", headerName: "Last Updated", width: 200, valueFormatter: (params: any) => new Date(params.value).toLocaleString() },
            ]}
            defaultColumnProps={{
              sortable: true,
              resizable: true,
              suppressMovable: true,
            }}
            searchTerm={this.props.searchTerm}
          />
        </div>
        <ComputerInspectionModal
          computer={this.state.inspectingComputer}
          onClose={() => {
            this.setState({
              inspectingComputer: undefined
            });
          }}
        />
      </div>
    );
  }
}

export default ComputerList;
