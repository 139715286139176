import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { formatter, generateModalStyle } from "../../utils/Misc";
import { callAPI } from "../../utils/API";
import DataGrid from "../../utils/DataGrid";

interface RevenueTableModalProps {
  onClose: () => void;
  month: number;
}

interface RevenueTableModalState {
  orders: any[];
  isLoading: boolean;
}

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class RevenueTableModal extends Component<
  RevenueTableModalProps,
  RevenueTableModalState
> {
  constructor(props: RevenueTableModalProps) {
    super(props);
    this.state = {
      isLoading: true,
      orders: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: RevenueTableModalProps) {
    if (prevProps.month !== this.props.month) {
      this.loadData();
    }
  }

  loadData() {
    if (this.props.month > 0) {
      callAPI("data/orders-by-month", "POST", {
        month: this.props.month,
        year: new Date().getFullYear(),
      })
        .then((res) => {
          this.setState({
            orders: res,
            isLoading: false
          });
        });
    }
  }

  render() {
    const columns: any[] = [
      { field: "id", headerName: "ID", width: 90, hide: true },
      { field: "job_num", headerName: "Order #", width: 150 },
      { field: "customer", headerName: "Customer", width: 150 },
      { field: "end_user", headerName: "End User", width: 150 },
      { field: "ship_date", headerName: "Ship Date", width: 150 },
      { field: "type", headerName: "Type", width: 150 },
      { field: "total", headerName: "Total", width: 150, valueFormatter: (params: any) => formatter.format(params.value as number) },
    ];
    return (
      <div>
        <Modal
          open={!!this.props.month}
          onClose={this.props.onClose}
          aria-labelledby="selectProductModalLabel"
        >
          <Box sx={generateModalStyle("98%")}>
            <Typography variant="h6" component="h2">
              {new Date().getFullYear()} Revenue - {MONTHS[this.props.month - 1]}
            </Typography>
            <div className="pb-3 mt-3" style={{ height: "400px" }}>
              <DataGrid
                className="mt-0"
                rows={this.state.orders}
                columns={columns}
                loading={this.state.isLoading}
                defaultColumnProps={{
                  sortable: true,
                  resizable: true,
                  suppressMovable: true,
                }}
              />
            </div>
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <button
                onClick={() => {
                  this.props.onClose();
                }}
                className="btn btn-outline-primary"
              >
                Close
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default RevenueTableModal;
