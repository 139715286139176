import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import EditProductDetails from "./EditProductDetails";

interface EditProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  product: any;
  prices?: boolean;
}

interface EditProductModalState {
  form: FormInstance;
  formValid: boolean;
}

export interface FormInstance {
}

class EditProductModal extends Component<
  EditProductModalProps,
  EditProductModalState
> {
  constructor(props: EditProductModalProps) {
    super(props);
    this.state = {
      form: this.props.product,
      formValid: false,
    };
  }

  componentDidUpdate(prevProps: EditProductModalProps) {
    if (prevProps.product !== this.props.product) {
      this.setState({
        form: this.props.product,
      });
    }
  }

  saveChanges() {
    callAPI("actions/product", "POST", {
      action: "update",
      id: this.props.product.id,
      data: this.state.form,
    })
      .then((data) => {
        this.props.onSave();
        this.props.onClose();
      })
      .catch((data) => {
        toast.error("Error saving product");
        console.log(data);
      });
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("80%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Product
          </Typography>
          <EditProductDetails
            form={this.state.form}
            onChange={(form: any) => {
              this.setState({ form });
            }}
            prices={this.props.prices}
            onFormValidation={(valid) => this.setState({ formValid: valid })}
          />
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={!this.state.formValid}
              onClick={() => {
                this.saveChanges();
              }}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </Box>
      </Modal >
    );
  }
}

export default EditProductModal;
