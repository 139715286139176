import { Component } from "react";
import { Link } from "react-router-dom";
import HistoricalRevenueChart from "./HistoricalRevenueChart";
import ProjectedBookingsTable from "../projected-bookings/ProjectedBookingsTable";
import RevenueEditManuallyModal from "./RevenueEditManuallyModal";
import RevenueTableModal from "./RevenueTableModal";

interface RevenueProps { }

interface RevenueState {
  cumulative: boolean;
  manualEditModalOpen: boolean;
  revenueTableMonthSelected: number;
}

class Revenue extends Component<RevenueProps, RevenueState> {
  constructor(props: RevenueProps) {
    super(props);
    this.state = {
      cumulative: false,
      manualEditModalOpen: false,
      revenueTableMonthSelected: 0,
    };
  }
  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Historical Revenue</h1>

          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              <button
                type="button"
                className="only-visual2 btn btn-sm btn-outline-secondary"
                onClick={() => {
                  this.setState({ cumulative: !this.state.cumulative });
                }}
              >
                {this.state.cumulative ? "Disable " : "Enable "}
                Cumulative
              </button>
              <button
                type="button"
                className="only-visual2 btn btn-sm btn-outline-secondary"
                onClick={() => {
                  this.setState({ manualEditModalOpen: true });
                }}
              >
                Edit Manually
              </button>
              <Link
                to="/projected-bookings"
                className="btn btn-sm btn-outline-secondary"
              >
                Switch to Projected Bookings
              </Link>
            </div>
          </div>
        </div>
        <HistoricalRevenueChart cumulative={this.state.cumulative} />
        <ProjectedBookingsTable type="This Year" onMonthClick={(month) => {
          this.setState({ revenueTableMonthSelected: month });
        }} />
        <RevenueEditManuallyModal isOpen={this.state.manualEditModalOpen} onClose={() => {
          this.setState({ manualEditModalOpen: false });
        }} onSave={() => {
          this.setState({
            cumulative: !this.state.cumulative
          },
            () => {
              this.setState({ cumulative: !this.state.cumulative })
            }
          );
        }} />
        <RevenueTableModal
          month={this.state.revenueTableMonthSelected}
          onClose={() => {
            this.setState({ revenueTableMonthSelected: 0 });
          }}
        />
      </div>
    );
  }
}

export default Revenue;
