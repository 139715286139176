import { callAPI } from "./API";

const tenantID = "7f6fd095-e7aa-4116-989c-69c03a20871b";
const clientID = "4fe2333c-1cb4-4bbc-a108-b705e1ec15f1";
const scope = "User.Read";
const redirectURI = window.location.origin;
const state = "dev.laser-ndt.org";

enum ValidationState {
  Unknown,
  Valid,
  Invalid,
}

export interface Account {
  username: string;
  name: string;
  avatar: string;
  hasPriceAccess: boolean;
  hasFinanceAccess: boolean;
  isEditor: boolean;
  isReadOnly: boolean;
  isAdmin: boolean;
  isComputerAdmin: boolean;
  smartsheetKeySet: boolean;
  defaultPage: string;
}

interface JWTPayload {
  name: string;
  exp: number;
}

export default class PlanningAuth {
  private _jwt: string | null = null;
  private _user: Account | null = null;
  private _validationState: ValidationState = ValidationState.Unknown;
  private _isValidating: boolean = false;
  private _expiryRedirectTimeout: NodeJS.Timeout | null = null;
  private _redirectPath: string | null = null;

  constructor() {
    this._jwt = localStorage.getItem("jwt");
    const authExpires = parseInt(localStorage.getItem("authExpires") || "");
    if (authExpires) {
      const expires_in = authExpires - Math.floor(Date.now() / 1000);
      this._expiryRedirectTimeout = setTimeout(() => {
        PlanningAuth._loginRedirect();
      }, expires_in * 1000);
    }
  }

  get user(): Account | null {
    return this._user;
  }

  get jwt(): string | null {
    return this._jwt;
  }

  get redirectPath(): string | null {
    return this._redirectPath;
  }

  private static _loginRedirect(): void {
    localStorage.removeItem("jwt");
    localStorage.removeItem("authExpires");
    localStorage.setItem("redirectURL", window.location.pathname); // save current URL
    window.location.href = `https://login.microsoftonline.com/${tenantID}/oauth2/v2.0/authorize?client_id=${clientID}&scope=${scope}&state=${state}&response_type=code&response_mode=query&redirect_uri=${redirectURI}`;
  }

  async validate(code?: string, state?: string) {
    return new Promise<void>(async (resolve, reject) => {
      if (this._isValidating) {
        return; // prevent simultaneous validations
      }
      this._isValidating = true;
      if (code) {
        // remove url params
        window.history.replaceState({}, document.title, window.location.origin);
        await this._useCode(code, state);
      }
      if (this._validationState === ValidationState.Invalid) {
        PlanningAuth._loginRedirect();
        return;
      }
      this._validationState = ValidationState.Invalid;
      if (!this._jwt) {
        PlanningAuth._loginRedirect();
        return;
      }
      this._user = await callAPI("me");
      this._validationState = ValidationState.Valid;
      const authExpires = this._parseJWT()?.exp;
      if (authExpires) {
        if (this._expiryRedirectTimeout) {
          clearTimeout(this._expiryRedirectTimeout);
        }
        localStorage.setItem("authExpires", authExpires.toString());
        const expires_in = authExpires - Math.floor(Date.now() / 1000);
        this._expiryRedirectTimeout = setTimeout(() => {
          PlanningAuth._loginRedirect();
        }, expires_in * 1000);
      }
      if (
        localStorage.getItem("redirectURL") &&
        localStorage.getItem("redirectURL")?.replace("/", "")
      ) {
        this._redirectPath = localStorage.getItem("redirectURL");
        localStorage.removeItem("redirectURL");
      } else if (this._user?.defaultPage && window.location.pathname !== this._user.defaultPage && code) {
        this._redirectPath = this._user.defaultPage.replace(/\/$/, "");
      } else {
        this._redirectPath = null;
      }
      this._isValidating = false;
      resolve();
    });
  }

  private async _useCode(code: string, state?: string) {
    const body = {
      code: code,
      state: state,
      host: window.location.origin,
    };
    const res = await callAPI("login", "POST", body);
    if (res.success) {
      localStorage.setItem("jwt", res.jwt);
      this._jwt = res.jwt;
    }
  }

  private _parseJWT(): JWTPayload | null {
    if (!this._jwt) {
      return null;
    }
    const payload = this._jwt.split(".")[1];
    return JSON.parse(atob(payload));
  }

}