import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { Quote, QuoteGenerator } from "../../utils/QuoteGenerator";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import PDFQuoteGenerator from "../../utils/PDFQuoteGenerator";

interface QuoteViewerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  order: any | null;
  contents: any[];
  draft?: boolean;
}

interface QuoteViewerModalState {
  pdfBlob: Blob | null;
  docxBlob: Blob | null;
  quote: Quote | null;
}

class QuoteViewerModal extends Component<
  QuoteViewerModalProps,
  QuoteViewerModalState
> {
  constructor(props: QuoteViewerModalProps) {
    super(props);
    this.state = {
      pdfBlob: null,
      docxBlob: null,
      quote: null,
    };
  }

  createQuote([revRes, customerRes, contactRes, userRes]: any) {
    const contents = this.props.contents.filter((item: any) => item.is_option === "0");
    const options = this.props.contents.filter((item: any) => item.is_option === "1");
    const maxRev = parseInt(revRes[0].revision);
    const customer = customerRes[0];
    const contact = contactRes[0];
    const user = userRes[0];
    // Construct a draft quote without actually saving it
    const quote: Quote = {
      revision: maxRev + 1,
      job_number: this.props.order.job_num,
      quote_number: this.props.order.order_num,
      contents: JSON.stringify(contents || []),
      options: JSON.stringify(options || []),
      customer: JSON.stringify(customer || null),
      contact: JSON.stringify(contact || null),
      order_id: this.props.order.id,
      id: 0,
      comment: "",
      saved_by: 0,
      sent_to_customer: 0,
      type: this.props.order.quote_type,
      created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
      rfq: this.props.order.rfq,
      ltc_poc_name: user?.name,
      ltc_poc_email: user ? user.username + "@laser-ndt.com" : "",
      valid_days: this.props.order.quote_valid_days,
      description: this.props.order.description,
      payment_str: this.props.order.payment_str,
      shipping: this.props.order.shipping,
      shipping_option: this.props.order.shipping_option,
      delivery_str: this.props.order.delivery_str,
      credit_card_percent: this.props.order.credit_card_percent,
      delivery_timeline: this.props.order.delivery_timeline,
      fs3_requested_dir: "",
      price_tier: this.props.order.price_tier,
      price_tier_name: this.props.order.price_tier_name,
      fs3_docx_path: "",
      fs3_pdf_path: "",
      progress_payments_enabled: 0,
      progress_payment_data: ""
    };
    const quoteGenerator = new QuoteGenerator();
    const quoteDoc = quoteGenerator.create(quote);
    Packer.toBlob(quoteDoc).then(async (blob) => {
      // use ReactPDF to render the PDF
      const pdfBlob = await pdf(<PDFQuoteGenerator quote={quote} />).toBlob();
      this.setState({
        pdfBlob: pdfBlob,
        docxBlob: blob,
        quote: quote,
      });
    });
  }

  loadData() {
    if (!this.props.order) return;
    const rev = callAPI("tables/quotes", "POST", {
      select: "COALESCE(MAX(revision), -1) as revision",
      where: {
        order_id: this.props.order.id,
      }
    });
    const customers = callAPI("tables/customers_view", "POST", {
      where: {
        id: this.props.order.customer_id,
      }
    });
    const contacts = callAPI("tables/contacts", "POST", {
      where: {
        id: this.props.order.contact_id,
      }
    });
    const user = callAPI("tables/users", "POST", {
      where: {
        id: this.props.order.attn,
      }
    });

    Promise.all([rev, customers, contacts, user]).then((res) => {
      this.createQuote(res);
    }).catch((err) => {
      console.error(err);
      toast.error("Failed to load quote data");
    });
  }

  componentDidMount(): void {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<QuoteViewerModalProps>) {
    if (prevProps.order !== this.props.order || prevProps.contents !== this.props.contents) {
      this.loadData();
    }
  }

  openInNewTab() {
    if (!this.state.pdfBlob) return;
    const dataURL = URL.createObjectURL(this.state.pdfBlob);
    window.open(dataURL, "_blank");
  }

  getBaseFileName() {
    if (!this.state.quote) return;
    let name = (this.state.quote.quote_number || this.state.quote.job_number);
    if (this.state.quote.revision > 0) {
      name += " R" + (this.state.quote.revision);
    }
    if (this.props.draft) {
      name += " (draft)";
    }
    return name;
  }

  downloadWord() {
    if (!this.state.docxBlob) return;
    saveAs(this.state.docxBlob, `${this.getBaseFileName()}.docx`);
  }

  emailDraft() {
    // Share the word and PDF files using the system sharing dialog
    if (!this.state.docxBlob) return;
    if (!this.state.pdfBlob) return;
    if (!this.state.quote) return;
    const baseFileName = this.getBaseFileName();
    const files = [
      new File([this.state.docxBlob], `${baseFileName}.docx`, { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }),
      new File([this.state.pdfBlob], `${baseFileName}.pdf`, { type: "application/pdf" }),
    ];
    const shareData = {
      files: files,
    };
    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator.share(shareData)
        .then(() => console.log("Share was successful."))
        .catch((error) => {
          toast.error("Sharing failed");
          console.log("Sharing failed", error);
        });
    } else {
      toast.error("Your system doesn't support sharing files.");
    }
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Box sx={generateModalStyle("78%", "78%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Preview PDF
          </Typography>
          <Alert severity="info" className="my-2">
            The PDF below is a preview. Click the Save button to create a new revision.<br />
            New revisions can be deleted until marked as sent to customer.
          </Alert>
          <div className="w-100">
            {this.state.quote && (
              <PDFViewer width="100%" height="490px" showToolbar={false}>
                <PDFQuoteGenerator quote={this.state.quote} />
              </PDFViewer>
            )}
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              onClick={() => {
                this.props.onClose();
              }}
              className="btn btn-outline-secondary"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.downloadWord();
              }}
              className="btn btn-outline-secondary"
            >
              Download as DOCX
            </button>
            <button
              onClick={() => {
                this.openInNewTab();
              }}
              className="btn btn-outline-secondary"
            >
              Open PDF in New Tab
            </button>
            <button
              onClick={() => {
                this.emailDraft();
              }}
              className="btn btn-outline-secondary"
            >
              Email Draft
            </button>
            <button
              onClick={() => {
                this.props.onClose();
                this.props.onSave();
              }}
              className="btn btn-outline-primary"
            >
              Save Cost Estimate
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default QuoteViewerModal;
