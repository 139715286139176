import { Component } from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { callAPI } from "../../utils/API";
import { CHART_OPTIONS, COLORS } from "./ChartUtils";

interface RequirementsChartProps {
  products: number[];
  types: { [key: string]: boolean };
  onTypeChange: (type: string, value: boolean) => void;
}

interface RequirementsChartState {
  data: any;
  types: { [key: string]: boolean };
  hideParentBuilds: boolean;
  tooltipData: any;
  tooltipPosition: any;
}

export const ORDER_TYPES = [
  "Order",
  "Temp Job",
  "Prospect",
  "Projection 90%",
  "Projection 80%",
  "Builds"
];

Chart.register();

class RequirementsChart extends Component<
  RequirementsChartProps,
  RequirementsChartState
> {
  constructor(props: RequirementsChartProps) {
    super(props);
    this.state = {
      data: ORDER_TYPES.map((orderType) => {
        return [];
      }),
      types: { ...props.types },
      hideParentBuilds: true,
      tooltipData: null,
      tooltipPosition: null,
    };
  }

  loadData() {
    this.setState({
      data: {},
    }, () => {
      callAPI("chart/requirements/", "POST", {
        id: JSON.stringify(this.props.products),
      }).then((response) => {
        this.setState({
          data: response,
        });
      });
    });
    callAPI("tables/products_view", "POST", {
      where: {
        id: this.props.products[0]
      },
    }).then((response) => {
      this.setState({
        hideParentBuilds: response.some((product: any) => {
          return product.inv_code === "KT" ||
            (product.inv_code === "FG" && product.category === "SEN") ||
            product.inv_code === "SW";
        }),
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<RequirementsChartProps>): void {
    if (prevProps.types !== this.props.types) {
      this.setState({ types: { ...this.props.types } });
    }
    if (prevProps.products !== this.props.products) {
      this.loadData();
    }
  }

  render() {
    let labels: string[] = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() + i);
      labels.push(date.toLocaleString("default", { month: "long" }));
    }
    return (
      <div style={{ height: "30vh", position: "relative" }}>
        <Bar
          options={{
            ...CHART_OPTIONS,
            plugins: {
              legend: {
                ...CHART_OPTIONS?.plugins?.legend,
                onClick: function (this: any, e: any, legendItem: any) {
                  const clickedTitle = legendItem.text;
                  this.props.onTypeChange(
                    clickedTitle,
                    !this.state.types[clickedTitle]
                  );
                }.bind(this),
              },
              tooltip: {
                ...CHART_OPTIONS?.plugins?.tooltip,
                enabled: false,
                mode: "index",
                position: "nearest",
                external: ({ chart, tooltip }: { chart: any, tooltip: any }) => {
                  const canvas = chart.canvas;
                  if (canvas) {
                    const position = { top: tooltip.y, left: tooltip.x, opacity: tooltip.opacity };
                    if (
                      this.state.tooltipPosition?.top !== position.top ||
                      this.state.tooltipPosition?.left !== position.left ||
                      this.state.tooltipPosition?.opacity !== position.opacity
                    ) {
                      this.setState({ tooltipData: tooltip, tooltipPosition: position });
                    }
                  }
                },
              },
            },
            scales: {
              ...CHART_OPTIONS?.scales,
              y: {
                ...CHART_OPTIONS?.scales?.y,
                ticks: {
                  ...CHART_OPTIONS?.scales?.y?.ticks,
                  callback: (value: string | number) => {
                    if (typeof value === "string") {
                      return value;
                    }
                    if (Math.floor(value) === value) {
                      return value;
                    }
                  },
                },
              },
            }
          }}
          style={{ height: "30vh" }}
          data={{
            labels,
            datasets: ORDER_TYPES.filter((order) => {
              return this.state.hideParentBuilds ? order !== "Builds" : true;
            }).map((orderType) => {
              const dataset = this.state.data[orderType];
              let data = [];
              if (dataset) {
                data = dataset.data;
              }
              return {
                label: orderType,
                hidden: !this.state.types[orderType],
                data,
                backgroundColor: COLORS[orderType],
                borderColor: COLORS.border,
              };
            }),
          }}
        />
        <CustomTooltip data={this.state.data} tooltip={this.state.tooltipData} />
      </div>
    );
  }
}

function CustomTooltip({ tooltip, data }: { tooltip: any, data: any }) {
  if (!tooltip || !tooltip.dataPoints || !tooltip.dataPoints[0]) {
    return null;
  }
  const monthIndex = tooltip.dataPoints[0].dataIndex;
  return (
    <div
      style={{
        position: "absolute",
        top: tooltip.y,
        left: tooltip.x,
        font: tooltip.options.bodyFont.string,
        padding: tooltip.options.padding,
        opacity: tooltip.opacity,
        background: "rgba(0, 0, 0, 0.7)",
        borderRadius: "3px",
        color: "white",
        pointerEvents: "none",
        transform: "translate(-50%, 0)",
        transition: "all .1s ease",
        zIndex: 100,
      }}
    >
      <table style={{ margin: 0 }}>
        <thead>
          <tr>
            <th style={{ borderWidth: 0 }}>{tooltip.title[0]}: {
              tooltip.dataPoints.reduce((acc: number, curr: any) => {
                return acc + curr.raw;
              }, 0)
            }</th>
          </tr>
        </thead>
        <tbody>
          {tooltip.body.filter((body: any, index: number) => {
            return tooltip.dataPoints[index].raw !== 0;
          }).map((body: any) => {
            const index = tooltip.body.indexOf(body);
            const datasetLabel = tooltip.dataPoints[index].dataset.label;
            const colors = tooltip.labelColors[index];
            return (
              <tr key={index}>
                <td style={{ borderWidth: 0 }}>
                  <span
                    style={{
                      background: colors.backgroundColor,
                      borderColor: colors.borderColor,
                      borderWidth: "2px",
                      marginRight: "10px",
                      height: "10px",
                      width: "10px",
                      display: "inline-block",
                    }}
                  />
                  {body.lines[0]}
                  {data[datasetLabel] && data[datasetLabel].labels[monthIndex] && (
                    <div style={{
                      maxWidth: "200px",
                      padding: "0.25rem 0.5rem",
                      color: "#ffffff",
                      textAlign: "center",
                      backgroundColor: "#000000",
                      borderRadius: "0.25rem",
                    }}>
                      {data[datasetLabel].labels[monthIndex] && data[datasetLabel].labels[monthIndex].split('<br>').map((label: any, index: number) => {
                        return (
                          <div key={index}>
                            {label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default RequirementsChart;
