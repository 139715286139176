import React, { useState, useEffect } from 'react';
import { callAPI } from "../../../utils/API";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

interface ContactSelectProps {
  customer_id: number;
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  name?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
}

const ContactSelect: React.FC<ContactSelectProps> = (props) => {
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldReload, setShouldReload] = useState<boolean>(true);

  useEffect(() => {
    if (!shouldReload) return;
    if (!props.customer_id) return;
    setShouldReload(false);
    callAPI("tables/contacts", "POST", {
      where: {
        customer_id: props.customer_id,
      },
    })
      .then((res) => {
        const newOptions = res.map((contact: any) => ({
          value: contact.id,
          label: contact.name,
        }));
        setOptions(newOptions);
        setLoading(false);
        if (!newOptions.find((option: any) => option.value === props.value)) {
          props.onChange(0);
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }, [props, shouldReload]);

  useEffect(() => {
    setShouldReload(true);
  }, [props.customer_id]);

  return (
    <FormControl disabled={props.disabled} fullWidth error={props.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        label={props.label}
        value={props.value}
        onChange={(e) => {
          props.onChange(parseInt(e.target.value as string));
        }}
        name={props.name}
      >
        {loading ? (
          <MenuItem disabled>Loading...</MenuItem>
        ) : (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
      {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

export default ContactSelect;