import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, callAPIWithCache } from "../../utils/API";
import AddBuildModal from "./AddBuildModal";
import { Link } from "react-router-dom";
import UserContext from "../ui/UserContext";
import DataGrid from "../../utils/DataGrid";

interface BuildsProps {
  searchTerm?: string;
}

interface BuildsState {
  data: any;
  loading: boolean;
  addModalOpen: boolean;
  currentlyEditingBuildID: number;
  filterModel: any;
}

const columns: any[] = [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "ltc_pn",
    headerName: "LTC P/N",
    width: 150,
    cellRenderer: (params: any) => (
      <Link to={params.data.id}>
        {params.value}
      </Link>
    ),
  },
  { field: "name", headerName: "Name", width: 150 },
  { field: "comment", headerName: "Comment", width: 150 },
  { field: "qty", headerName: "Qty", width: 70 },
  { field: "status", headerName: "Status", width: 150, filter: true },
  { field: "wo", headerName: "W/O", width: 150 },
  { field: "start_date", headerName: "Start Date", width: 150, comparator: dateSort },
  { field: "finish_date", headerName: "Finish Date", width: 150, comparator: dateSort },
  { field: "launch_date", headerName: "Launch Date", width: 150, comparator: dateSort },
  { field: "item_from", headerName: "From", width: 90 },
];

function dateSort(v1: any, v2: any, cellParams1: any, cellParams2: any) {
  const d1 = new Date(v1);
  const d2 = new Date(v2);
  if (d1 < d2) {
    return -1;
  }
  if (d1 > d2) {
    return 1;
  }
  return 0;
}

class Builds extends Component<BuildsProps, BuildsState> {
  constructor(props: BuildsProps) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      addModalOpen: false,
      currentlyEditingBuildID: 0,
      filterModel: undefined,
    };
  }

  updateBuild(oldVals: any, newVals: any) {
    // find what property/properties changed
    let changedProps: any = {};
    for (let prop in newVals) {
      if (newVals[prop] !== oldVals[prop]) {
        if (newVals[prop] instanceof Date) {
          changedProps[prop] = newVals[prop].toISOString().slice(0, 19).replace('T', ' ');
        } else {
          changedProps[prop] = newVals[prop].trim();
        }
      }
    }
    callAPI("actions/builds", "POST", {
      action: "update",
      id: oldVals.id,
      data: changedProps,
    })
      .then((response) => {
        toast.success("Updated build #" + oldVals.id);
        this.loadData();
      })
      .catch((error) => {
        if (error.message !== "Unauthorized") {
          toast.error("Failed to update build #" + oldVals.id);
        }
      });
  }

  loadData() {
    let cacheResult = callAPIWithCache("tables/builds_view", "POST", {
      order: "start_date DESC"
    });
    if (cacheResult.cache && cacheResult.cache.data) {
      this.setState({
        data: cacheResult.cache.data,
      });
    }
    cacheResult.result.then((response) => {
      this.setState({
        data: response,
        loading: false,
        filterModel: {
          "status": {
            "filterType": "text",
            "operator": "OR",
            "condition1": {
              "filterType": "text",
              "type": "equals",
              "filter": "Actual"
            },
            "condition2": {
              "filterType": "text",
              "type": "contains",
              "filter": "Planned"
            },
            "conditions": [
              {
                "filterType": "text",
                "type": "equals",
                "filter": "Actual"
              },
              {
                "filterType": "text",
                "type": "contains",
                "filter": "Planned"
              }
            ]
          }
        }
      });
    });
  }

  refreshDatesFromSmartsheet() {
    toast.loading("Refreshing dates from Smartsheet...\nThis may take a while", { duration: Infinity, id: "refreshDates" });
    callAPI("actions/builds", "POST", {
      action: "refresh_dates",
    })
      .then((response) => {
        this.loadData();
        toast.success("Dates refreshed", { id: "refreshDates", duration: 5000 });
      })
      .catch((error) => {
        if (error.message !== "Unauthorized") {
          toast.error(error.message, { id: "refreshDates", duration: 5000 });
        }
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Builds</h1>
              {!user?.isReadOnly && (
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => this.setState({ addModalOpen: true })}
                    >
                      Add New
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => this.refreshDatesFromSmartsheet()}
                    >
                      Refresh Dates from Smartsheet
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div style={{ height: "80vh" }}>
              <DataGrid
                className="mt-0"
                rows={this.state.data}
                columns={columns}
                loading={this.state.loading}
                defaultColumnProps={{
                  sortable: true,
                  resizable: true,
                  suppressMovable: true,
                }}
                searchTerm={this.props.searchTerm}
                defaultFilter={this.state.filterModel}
              />
            </div>
            <AddBuildModal
              isOpen={this.state.addModalOpen}
              onClose={() => this.setState({ addModalOpen: false })}
              onSave={() => this.loadData()}
            />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Builds;