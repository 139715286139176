import { Component } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { callAPIWithCache } from "../../utils/API";
import { formatter } from "../../utils/Misc";
import { getAccordionHeight } from "../../utils/Misc";
import DataGrid from "../../utils/DataGrid";

interface OrderGroupProps {
  types: string[];
  use?: "type" | "quote_type";
  name: string;
  isJob?: boolean;
  searchTerm?: string;
  prices?: boolean;
}

interface OrderGroupState {
  orders: any[];
  isLoading: boolean;
  collapsed: boolean;
}

class OrderGroup extends Component<OrderGroupProps, OrderGroupState> {
  constructor(props: OrderGroupProps) {
    const pgPref = localStorage.getItem("paginationPreference") || "paginate"
    super(props);
    this.state = {
      orders: [],
      isLoading: true,
      collapsed: pgPref === "paginate",
    };
  }

  loadOrders() {
    const where = {
      [this.props.use || "type"]: this.props.types,
    };
    let where_not = undefined;
    if (this.props.use === "quote_type") {
      where_not = {
        type: ["Shipped", "Archived"],
      };
    }
    const cacheResult = callAPIWithCache("tables/orders_view", "POST", {
      where,
      where_not,
    });
    if (cacheResult.cache && cacheResult.cache.data) {
      this.setState({
        orders: cacheResult.cache.data,
        isLoading: false,
      });
    }
    cacheResult.result
      .then((res) => {
        this.setState({
          orders: res,
          isLoading: false,
        });
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading orders", { id: "loadOrders" });
          console.log(data);
        }
      });
  }

  componentDidMount() {
    this.loadOrders();
  }

  render() {
    let columns: any[] = [
      { field: "id", headerName: "ID", width: 90, hide: true },
      {
        field: "order_num",
        headerName: "Quote Number",
        width: 150,
        cellRenderer: (params: any) => (
          <Link to={params.data.id}>
            {params.value}
          </Link>
        ),
      },
      { field: "job_num", headerName: "Job Number", width: 150 },
      { field: "description", headerName: "Description", width: 300 },
      { field: "type", headerName: "Job Type", width: 150 },
      { field: "customer", headerName: "Customer", width: 150 },
      { field: "end_user", headerName: "End User", width: 150 },
      { field: "ship_date", headerName: "Expected Ship Date", width: 150 },
      { field: "num_products", headerName: "# Products", width: 150 },
    ];
    if (this.props.prices) {
      columns = [
        { field: "id", headerName: "ID", width: 90, hide: true },
        {
          field: "order_num",
          headerName: "Quote Number",
          width: 150,
          cellRenderer: (params: any) => (
            <Link to={params.data.id}>
              {params.value}
            </Link>
          ),
        },
        { field: "description", headerName: "Description", width: 300 },
        { field: "type", headerName: "Job Type", width: 150 },
        { field: "customer", headerName: "Customer", width: 150 },
        { field: "end_user", headerName: "End User", width: 150 },
        { field: "award_date", headerName: "Award Date", width: 150, valueFormatter: (params: any) => params.value ? new Date(params.value as string).toLocaleDateString() : "" },
        { field: "ship_date", headerName: "Expected Ship Date", width: 150, valueFormatter: (params: any) => params.value ? new Date(params.value as string).toLocaleDateString() : "" },
        { field: "total", headerName: "Total (USD)", width: 150, valueFormatter: (params: any) => isNaN(params.value) ? "" : formatter.format(params.value as number) },
      ];
    }

    const orders = this.state.orders.filter((order) => {
      if (this.props.searchTerm) {
        const s = this.props.searchTerm.toLowerCase();
        const params = [
          order.order_num,
          order.job_num,
          order.description,
          order.type,
          order.customer,
          order.end_user,
          order.ship_date,
        ];
        for (let i = 0; i < params.length; i++) {
          if (params[i] && params[i].toLowerCase().includes(s)) {
            return true;
          }
        }
        return false;
      }
      return true;
    });

    if (orders.length === 0) {
      return null;
    }

    return (
      <Accordion
        expanded={!this.state.collapsed}
        onChange={() => {
          this.setState({
            collapsed: !this.state.collapsed,
          });
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {this.props.name}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{orders.length} order{orders.length === 1 ? "" : "s"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="pb-3" style={{ height: getAccordionHeight(orders.length), width: "100%" }}>
            <DataGrid
              className="mt-0"
              rows={orders}
              columns={columns}
              loading={this.state.isLoading}
              defaultColumnProps={{
                sortable: true,
                resizable: true,
                suppressMovable: true,
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default OrderGroup;
