import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { callAPI } from "../../utils/API";
import { formatter, generateModalStyle } from "../../utils/Misc";

interface GSATravelRatesModalProps {
  open: boolean;
  defaultState?: string;
  onClose: () => void;
}

interface GSATravelRatesModalState {
  state: string;
  city: string;
  data: CityData[];
}

interface CityData {
  city: string;
  state: string;
  months: { month: string, year: number, maxLodging: number }[];
}

const US_STATES = [{ id: "AL", name: "Alabama" }, { id: "AK", name: "Alaska" }, { id: "AZ", name: "Arizona" }, { id: "AR", name: "Arkansas" }, { id: "CA", name: "California" }, { id: "CO", name: "Colorado" }, { id: "CT", name: "Connecticut" }, { id: "DE", name: "Delaware" }, { id: "DC", name: "District Of Columbia" }, { id: "FL", name: "Florida" }, { id: "GA", name: "Georgia" }, { id: "HI", name: "Hawaii" }, { id: "ID", name: "Idaho" }, { id: "IL", name: "Illinois" }, { id: "IN", name: "Indiana" }, { id: "IA", name: "Iowa" }, { id: "KS", name: "Kansas" }, { id: "KY", name: "Kentucky" }, { id: "LA", name: "Louisiana" }, { id: "ME", name: "Maine" }, { id: "MD", name: "Maryland" }, { id: "MA", name: "Massachusetts" }, { id: "MI", name: "Michigan" }, { id: "MN", name: "Minnesota" }, { id: "MS", name: "Mississippi" }, { id: "MO", name: "Missouri" }, { id: "MT", name: "Montana" }, { id: "NE", name: "Nebraska" }, { id: "NV", name: "Nevada" }, { id: "NH", name: "New Hampshire" }, { id: "NJ", name: "New Jersey" }, { id: "NM", name: "New Mexico" }, { id: "NY", name: "New York" }, { id: "NC", name: "North Carolina" }, { id: "ND", name: "North Dakota" }, { id: "OH", name: "Ohio" }, { id: "OK", name: "Oklahoma" }, { id: "OR", name: "Oregon" }, { id: "PA", name: "Pennsylvania" }, { id: "RI", name: "Rhode Island" }, { id: "SC", name: "South Carolina" }, { id: "SD", name: "South Dakota" }, { id: "TN", name: "Tennessee" }, { id: "TX", name: "Texas" }, { id: "UT", name: "Utah" }, { id: "VT", name: "Vermont" }, { id: "VA", name: "Virginia" }, { id: "WA", name: "Washington" }, { id: "WV", name: "West Virginia" }, { id: "WI", name: "Wisconsin" }, { id: "WY", name: "Wyoming" }];
const MONTHS: { [key: string]: string } = {
  "jan": "January",
  "feb": "February",
  "mar": "March",
  "apr": "April",
  "may": "May",
  "jun": "June",
  "jul": "July",
  "aug": "August",
  "sep": "September",
  "oct": "October",
  "nov": "November",
  "dec": "December"
};

class GSATravelRatesModal extends Component<
  GSATravelRatesModalProps,
  GSATravelRatesModalState
> {
  constructor(props: GSATravelRatesModalProps) {
    super(props);
    this.state = {
      state: this.props.defaultState || "",
      city: "",
      data: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    callAPI('data/gsa-rates', 'POST', {
      state: this.state.state,
      city: this.state.city
    })
      .then((res) => {
        this.setState({
          data: res.data
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          data: []
        });
      });
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="selectProductModalLabel"
        >
          <Box sx={generateModalStyle("78%")}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              GSA Travel Rates
            </Typography>
            <a href="https://www.gsa.gov/travel/plan-book/per-diem-rates" target="_blank" rel="noreferrer" className="btn btn-sm btn-outline-secondary">
              Open GSA Website
            </a>
            <div className="my-2">
              <FormControl fullWidth className="my-1">
                <InputLabel>State</InputLabel>
                <Select
                  value={this.state.state}
                  label="State"
                  onChange={(e) => {
                    this.setState({ state: e.target.value }, () => {
                      this.loadData();
                    });
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a state
                  </MenuItem>
                  {US_STATES.map((state) => {
                    return (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label="City (optional)"
                variant="outlined"
                value={this.state.city}
                onChange={(e) => {
                  this.setState({ city: e.target.value });
                }}
                className="my-1"
                fullWidth
              />
            </div>
            <div className="row row-cols-2">
              {this.state.data.filter((cityData) => {
                if (this.state.city === "") {
                  return true;
                }
                return cityData.city.toLowerCase().includes(this.state.city.toLowerCase());
              }).map((cityData) => {
                return (
                  <div key={cityData.city} className="col p-2">
                    <div className="card">
                      <div className="card-header">
                        <h4>{cityData.city}</h4>
                      </div>
                      <div className="card-body">
                        <table className="table table-sm">
                          <thead>
                            <tr>
                              <th scope="col">Month</th>
                              <th scope="col">Rate</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cityData.months.map((month) => {
                              return (
                                <tr key={month.month}>
                                  <td>{MONTHS[month.month]} {month.year}</td>
                                  <td>{formatter.format(month.maxLodging)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                );
              })}
              {this.state.data.length === 0 && (
                <div className="col p-2">
                  <div className="card">
                    <div className="card-body">
                      <p>No data found.</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default GSATravelRatesModal;
