import * as React from 'react';
import { formatter } from './Misc';
import * as math from 'mathjs';

export interface GridElement {
  id?: string;
  value?: string | number | null;
  editable?: boolean;
  bold?: boolean;
  type?: 'number' | 'money' | 'select' | 'text' | 'date' | 'checkbox' | 'percent';
  selectOptions?: string[];
  isFormula?: boolean;
  rowSpan?: number;
  colSpan?: number;
  doNotRender?: boolean;
  align?: 'left' | 'center' | 'right';
  watched?: boolean; // notify with calculated value on change
}

export interface GridColumn {
  label: string;
  width?: string;
  colSpan?: number;
}

interface DataTableProps {
  defaultValue?: GridElement[][]; // 2D array of cells
  onChange: (value: GridElement[][], watchedValues: string[]) => void;
  columns?: GridColumn[];
}

interface DataTableState {
  grid: GridElement[][];
  selectedCell: { row: number, col: number } | null; // { row: 0, col: 0 }
  editingCell: { row: number, col: number } | null; // { row: 0, col: 0
}

export class DataTable extends React.Component<DataTableProps, DataTableState> {
  constructor(props: DataTableProps) {
    super(props)
    this.state = {
      grid: this.props.defaultValue || [],
      selectedCell: null,
      editingCell: null,
    }
  }

  componentDidUpdate(prevProps: DataTableProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ grid: this.props.defaultValue || [] }, () => {
        if (!prevProps.defaultValue?.length) this.updateMatches();
      });
    }
  }

  private updateMatches() {
    // get values of watched cells
    const watchedCells = this.state.grid
      .flatMap((row) => row)
      .filter((cell) => cell.watched);
    const watchedValues = watchedCells.map((cell) => {
      return this.calculateValue(cell.value as string || "");
    });
    this.props.onChange(this.state.grid, watchedValues);
  }

  render() {
    return (
      <table className="user-select-none">
        <thead>
          <tr>
            {this.props.columns && this.props.columns.map((col, index) => (
              <th
                key={index}
                colSpan={col.colSpan}
                style={{ width: col.width, border: '1px solid lightgray', textAlign: 'center' }}
              >
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.state.grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, colIndex) => {
                if (cell.doNotRender) {
                  return null;
                }
                const border = this.state.selectedCell?.row === rowIndex &&
                  this.state.selectedCell?.col === colIndex ? '2px solid blue' : '1px solid lightgray';
                return (
                  <td
                    key={colIndex}
                    rowSpan={cell.rowSpan}
                    colSpan={cell.colSpan}
                    onMouseDown={() => this.setState({ selectedCell: { row: rowIndex, col: colIndex } })}
                    onClick={() => {
                      if (cell.editable) {
                        this.setState({ editingCell: { row: rowIndex, col: colIndex } });
                      }
                    }}
                    onDoubleClick={() => {
                      if (cell.editable) {
                        this.setState({
                          editingCell: { row: rowIndex, col: colIndex },
                          selectedCell: { row: rowIndex, col: colIndex }
                        });
                      }
                    }}
                    style={{
                      fontWeight: cell.bold ? 'bold' : 'normal',
                      border,
                      borderTop: border,
                      borderLeft: border,
                      backgroundColor: cell.editable ? 'lightyellow' : 'white',
                      textAlign: cell.align || 'left',
                    }}
                  >
                    {cell.type === 'select' &&
                      this.state.editingCell?.row === rowIndex &&
                      this.state.editingCell?.col === colIndex ? (
                      <select
                        value={this.state.editingCell?.row === rowIndex &&
                          this.state.editingCell?.col === colIndex ? (
                          cell.value || ""
                        ) : (
                          this.formatValue(cell.isFormula ? this.calculateValue(cell.value as string || "") : cell.value, cell.type)
                        )}
                        onChange={(e) => this.onCellChange(cell, rowIndex, colIndex, e)}
                        disabled={this.state.editingCell?.row !== rowIndex ||
                          this.state.editingCell?.col !== colIndex}
                        onBlur={() => this.setState({ editingCell: null })}
                        autoFocus
                        style={{
                          width: '100%',
                          outline: 'none',
                          border: 'none',
                          backgroundColor: 'transparent',
                          textAlign: cell.align || 'left',
                          fontWeight: cell.bold ? 'bold' : 'normal',
                          pointerEvents: (this.state.editingCell?.row === rowIndex &&
                            this.state.editingCell?.col === colIndex) ? 'auto' : 'none',
                        }}
                      >
                        {cell.selectOptions && cell.selectOptions.map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={cell.type || "text"}
                        value={this.state.editingCell?.row === rowIndex &&
                          this.state.editingCell?.col === colIndex ? (
                          cell.value || ""
                        ) : (
                          this.formatValue(cell.isFormula ? this.calculateValue(cell.value as string || "") : cell.value, cell.type)
                        )}
                        title={cell.value?.toString() || ""}
                        onChange={(e) => this.onCellChange(cell, rowIndex, colIndex, e)}
                        disabled={this.state.editingCell?.row !== rowIndex ||
                          this.state.editingCell?.col !== colIndex}
                        onBlur={() => this.setState({ editingCell: null })}
                        autoFocus
                        style={{
                          width: '100%',
                          outline: 'none',
                          border: 'none',
                          backgroundColor: 'transparent',
                          textAlign: cell.align || 'left',
                          fontWeight: cell.bold ? 'bold' : 'normal',
                          pointerEvents: (this.state.editingCell?.row === rowIndex &&
                            this.state.editingCell?.col === colIndex) ? 'auto' : 'none',
                        }}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table >
    )
  }

  private onCellChange(cell: GridElement, curRowIndex: number, curColIndex: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (curRowIndex === -1 || curColIndex === -1) {
      return;
    }
    this.setState({
      grid: this.state.grid.map((row, rowIndex) => {
        if (rowIndex === curRowIndex) {
          return row.map((cell, colIndex) => {
            if (colIndex === curColIndex) {
              return {
                ...cell,
                value: e.target.value,
              }
            } else {
              return cell;
            }
          })
        } else {
          return row;
        }
      })
    },
      () => {
        this.updateMatches();
        setTimeout(() => {
          this.updateMatches();
        }, 100);
      })
  }

  private calculateValue(f: string | number) {
    if (typeof f === "number") f = f.toString();
    if (f.charAt(0) !== "=") return f;
    f = f.substring(1);
    const VALID_FUNCTIONS = ["SUM", "SUMPRODUCT"];
    // replace all functions with their values
    f = f.replace(/([A-Z]+)\(([A-Z0-9:,]+)\)/g, (match, fn, args) => {
      if (VALID_FUNCTIONS.includes(fn)) {
        return this.doFunction(fn, args.split(","));
      } else {
        console.log("Invalid function: " + fn);
        return "#ERR";
      }
    });
    return this.solveMath(this.replaceValues(f));
  }

  private doFunction(fn: string, args: string[]): string {
    if (fn === "SUM") {
      const range = args[0].split(":");
      const start = this.nameToCoordinates(range[0]);
      const end = this.nameToCoordinates(range[1]);
      let sum = 0;
      for (let i = start.row; i <= end.row; i++) {
        for (let j = start.col; j <= end.col; j++) {
          try {
            sum += Number(this.calculateValue(this.state.grid[i][j].value as string || ""));
          } catch (e) { }
        }
      }
      return sum.toString();
    } else if (fn === "SUMPRODUCT") {
      const range1 = args[0].split(":");
      const start1 = this.nameToCoordinates(range1[0]);
      const end1 = this.nameToCoordinates(range1[1]);
      const range2 = args[1].split(":");
      const start2 = this.nameToCoordinates(range2[0]);
      const end2 = this.nameToCoordinates(range2[1]);
      let arr1 = [];
      let arr2 = [];
      for (let i = start1.row; i <= end1.row; i++) {
        for (let j = start1.col; j <= end1.col; j++) {
          try {
            arr1.push(Number(this.calculateValue(this.state.grid[i][j].value as string || "")));
          } catch (e) { }
        }
      }
      for (let i = start2.row; i <= end2.row; i++) {
        for (let j = start2.col; j <= end2.col; j++) {
          try {
            arr2.push(Number(this.calculateValue(this.state.grid[i][j].value as string || "")));
          } catch (e) { }
        }
      }
      let sum = 0;
      for (let i = 0; i < arr1.length; i++) {
        sum += arr1[i] * arr2[i];
      }
      return sum.toString();
    }
    return "0";
  }

  private solveMath(f: string) {
    try {
      return math.evaluate(this.replaceValues(f));
    } catch (e) {
      return "0";
    }
  }

  private replaceValues(f: string): string {
    const result = f.replace(/[A-Z]+[0-9]+/g, (match) => {
      return this.calculateValue(this.getCell(match));
    });
    return result;
  }

  private getCell(name: string) {
    const { row, col } = this.nameToCoordinates(name);
    if (!this.state.grid[row] || !this.state.grid[row][col]) {
      return "0";
    }
    return this.state.grid[row][col].value as string || "";
  }

  private nameToCoordinates(name: string) {
    const col = name.charCodeAt(0) - 65;
    const row = parseInt(name.substring(1)) - 1;
    return { row, col };
  }

  private formatValue(value: string | number | null | undefined, type: string | undefined) {
    if (!value) {
      if (type === 'number') return 0;
      if (type === 'money') return '$0.00';
      if (type === 'percent') return '0%';
      return '';
    } else if (type === 'money') {
      return formatter.format(value as number);
    } else if (type === 'percent') {
      return (value as number) * 100 + '%';
    } else {
      return value;
    }
  }
}