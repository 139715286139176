import { Component } from "react";
import Chart, { ChartDataset } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { callAPI } from "../../utils/API";
import { CHART_OPTIONS, COLORS } from "./ChartUtils";
import { ORDER_TYPES } from "./RequirementsChart";

interface InventoryChartProps {
  products: number[];
  types: { [key: string]: boolean };
}

interface InventoryChartState {
  data: any;
  requirements: any;
  tooltipData: any;
  tooltipPosition: any;
}

Chart.register();

const INV_TYPES = ["Planned Builds", "Actual Builds"];

class InventoryChart extends Component<
  InventoryChartProps,
  InventoryChartState
> {
  constructor(props: InventoryChartProps) {
    super(props);
    this.state = {
      data: INV_TYPES.map((invType) => {
        return [];
      }),
      requirements: {},
      tooltipData: null,
      tooltipPosition: null,
    };
  }

  loadData() {
    callAPI("chart/inventory/", "POST", {
      id: JSON.stringify(this.props.products),
    }).then((response) => {
      this.setState({
        data: response,
      });
    });
    this.loadRequirements();
  }

  loadRequirements() {
    this.setState({
      requirements: {},
    }, () => {
      callAPI("chart/requirements/", "POST", {
        id: JSON.stringify(this.props.products),
      }).then((response) => {
        this.setState({
          requirements: response,
        });
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<InventoryChartProps>): void {
    if (prevProps.products !== this.props.products) {
      this.loadData();
    }
  }

  render() {
    let labels: string[] = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() + i);
      labels.push(date.toLocaleString("default", { month: "long" }));
    }
    return (
      <div style={{ height: "30vh", position: "relative" }}>
        <Bar
          options={{
            ...CHART_OPTIONS,
            plugins: {
              legend: {
                ...CHART_OPTIONS?.plugins?.legend,
                labels: {
                  ...CHART_OPTIONS?.plugins?.legend?.labels,
                  filter: (item: any, chart: any) => {
                    return item.text !== "Inventory";
                  },
                },
                onClick: function (this: any, e: any, legendItem: any) {
                  const clickedTitle = legendItem.text;
                  this.setState({
                    data: {
                      ...this.state.data,
                      [clickedTitle]: {
                        ...this.state.data[clickedTitle],
                        hidden: !this.state.data[clickedTitle].hidden,
                      },
                    },
                  });
                }.bind(this),
              },
              tooltip: {
                ...CHART_OPTIONS?.plugins?.tooltip,
                enabled: false,
                mode: "index",
                position: "nearest",
                external: ({ chart, tooltip }: { chart: any, tooltip: any }) => {
                  const canvas = chart.canvas;
                  if (canvas) {
                    const position = { top: tooltip.y, left: tooltip.x, opacity: tooltip.opacity };
                    if (
                      this.state.tooltipPosition?.top !== position.top ||
                      this.state.tooltipPosition?.left !== position.left ||
                      this.state.tooltipPosition?.opacity !== position.opacity
                    ) {
                      this.setState({ tooltipData: tooltip, tooltipPosition: position });
                    }
                  }
                },
              },
            },
            scales: {
              ...CHART_OPTIONS?.scales,
              y: {
                ...CHART_OPTIONS?.scales?.y,
                ticks: {
                  ...CHART_OPTIONS?.scales?.y?.ticks,
                  callback: (value: string | number) => {
                    if (typeof value === "string") {
                      return value;
                    }
                    if (Math.floor(value) === value) {
                      return value;
                    }
                  },
                },
              },
            }
          }}
          data={{
            labels,
            datasets: INV_TYPES.map((invType) => {
              const dataset = this.state.data[invType];
              let data = [];
              if (dataset) {
                data = dataset.data;
              }
              return {
                label: invType,
                data,
                hidden: dataset?.hidden,
                backgroundColor: COLORS[invType],
                borderColor: COLORS.border,
              } as ChartDataset<"bar", number[]>;
            }).concat([
              {
                label: "Inventory",
                backgroundColor: COLORS.avg,
                borderColor: COLORS.avg,
                borderWidth: 2,
                data: this.getInventoryArr(),
                type: "line",
                fill: false,
              } as any,
            ]).sort(
              (a, b) => {
                if (a.label === "Inventory") return -1;
                if (b.label === "Inventory") return 1;
                if (a.label === "Planned Builds") return -1;
                if (b.label === "Planned Builds") return 1;
                return 0;
              }
            ),
          }}
        />
        <CustomTooltip data={this.state.data} tooltip={this.state.tooltipData} requirements={this.state.requirements} types={this.props.types} />
      </div>
    );
  }

  private getInventoryArr() {
    let inv: number = parseInt(this.state.data.initial_inventory);
    let invArr = [];
    for (let i = 0; i < 12; i++) {
      if (this.state.data["Actual Builds"] && !this.state.data["Actual Builds"].hidden)
        inv += parseInt(this.state.data["Actual Builds"].data[i]);
      if (this.state.data["Planned Builds"] && !this.state.data["Planned Builds"].hidden)
        inv += parseInt(this.state.data["Planned Builds"].data[i]);
      for (let j = 0; j < ORDER_TYPES.length; j++) {
        if (this.props.types[ORDER_TYPES[j]] && this.state.requirements[ORDER_TYPES[j]]) {
          if (this.state.requirements[ORDER_TYPES[j]].data[i]) {
            inv -= parseInt(this.state.requirements[ORDER_TYPES[j]].data[i]);
          }
        }
      }
      invArr[i] = inv;
    }
    return invArr;
  }
}

function CustomTooltip({ tooltip, data, requirements, types }: { tooltip: any, data: any, requirements: any, types: any }) {
  if (!tooltip || !tooltip.dataPoints || !tooltip.dataPoints[0]) {
    return null;
  }
  const monthIndex = tooltip.dataPoints[0].dataIndex;
  return (
    <div
      style={{
        position: "absolute",
        top: tooltip.y,
        left: tooltip.x,
        font: tooltip.options.bodyFont.string,
        padding: tooltip.options.padding,
        opacity: tooltip.opacity,
        background: "rgba(0, 0, 0, 0.7)",
        borderRadius: "3px",
        color: "white",
        pointerEvents: "none",
        transform: "translate(-50%, 0)",
        transition: "all .1s ease",
        zIndex: 100,
      }}
    >
      <table style={{ margin: 0 }}>
        <thead>
          <tr>
            <th style={{ borderWidth: 0 }}>{tooltip.title[0]}: {
              tooltip.dataPoints[2].raw
            }</th>
          </tr>
        </thead>
        <tbody>
          {monthIndex === 0 && (
            <tr>
              <td style={{ borderWidth: 0 }}>
                <span
                  style={{
                    background: tooltip.labelColors[0].backgroundColor,
                    borderColor: tooltip.labelColors[0].backgroundColor,
                    borderWidth: "2px",
                    marginRight: "10px",
                    height: "10px",
                    width: "10px",
                    display: "inline-block",
                  }}
                />
                Initial Inventory: {data.initial_inventory}
              </td>
            </tr>
          )}
          {tooltip.body.filter((body: any, index: number) => {
            return tooltip.dataPoints[index].raw !== 0 && tooltip.dataPoints[index].dataset.label !== "Inventory";
          }).map((body: any) => {
            const index = tooltip.body.indexOf(body);
            const datasetLabel = tooltip.dataPoints[index].dataset.label;
            const colors = tooltip.labelColors[index];
            return (
              <tr key={index}>
                <td style={{ borderWidth: 0 }}>
                  <span
                    style={{
                      background: colors.backgroundColor,
                      borderColor: colors.borderColor,
                      borderWidth: "2px",
                      marginRight: "10px",
                      height: "10px",
                      width: "10px",
                      display: "inline-block",
                    }}
                  />
                  {body.lines[0]}
                  {data[datasetLabel] && data[datasetLabel].labels[monthIndex] && (
                    <div style={{
                      maxWidth: "200px",
                      padding: "0.25rem 0.5rem",
                      color: "#ffffff",
                      textAlign: "center",
                      backgroundColor: "#000000",
                      borderRadius: "0.25rem",
                    }}>
                      {data[datasetLabel].labels[monthIndex].split('<br>').map((label: any, index: number) => {
                        return (
                          <div key={index}>
                            {label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
          {ORDER_TYPES.filter((type) => types[type]).map((orderType: string) => {
            if (requirements[orderType] && requirements[orderType].data[monthIndex] > 0) {
              return (
                <div key={orderType}
                  style={{
                    maxWidth: "200px",
                    padding: "0.25rem 0.5rem",
                    color: "#ffffff",
                    textAlign: "center",
                    backgroundColor: "#000000",
                    borderRadius: "0.25rem",
                  }}>
                  REQUIREMENT: {requirements[orderType].data[monthIndex]}
                </div>
              );
            }
            return null;
          })}
        </tbody>
      </table>
    </div>
  );
}

export default InventoryChart;
