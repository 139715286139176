import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../../utils/API";
import { generateModalStyle } from "../../../utils/Misc";
import { TextField } from "@mui/material";

interface EditLaborRatesModalProps {
  open: boolean;
  onClose: () => void;
}

interface EditLaborRatesModalState {
  rates: LaborRate[];
}

interface LaborRate {
  name: string;
  rate: number;
}

class EditLaborRatesModal extends Component<
  EditLaborRatesModalProps,
  EditLaborRatesModalState
> {
  constructor(props: EditLaborRatesModalProps) {
    super(props);
    this.state = {
      rates: []
    };
  }

  componentDidMount() {
    this.getLaborRates();
  }

  getLaborRates() {
    callAPI("tables/labor_rates")
      .then((res) => {
        this.setState({
          rates: res
        });
      })
      .catch((err) => {
        toast.error("Error getting labor rates.");
      });
  }

  saveLaborRates() {
    callAPI("actions/settings", "POST", {
      action: "set_rates",
      kind: "labor_rates",
      rates: this.state.rates
    })
      .then((res) => {
        this.getLaborRates();
        this.props.onClose();
      })
      .catch((err) => {
        toast.error("Error updating labor rates.");
      });
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="selectProductModalLabel"
        >
          <Box sx={generateModalStyle("78%")}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Labor Rates
            </Typography>
            <div className="row row-cols-3">
              {this.state.rates.map((rate) => {
                return (
                  <div className="col" key={rate.name}>
                    <TextField
                      label={rate.name}
                      type="number"
                      fullWidth
                      value={rate.rate}
                      onChange={(e) => {
                        const newRates = this.state.rates.map((r) => {
                          if (r.name === rate.name) {
                            return { ...r, rate: Number(e.target.value) };
                          }
                          return r;
                        });
                        this.setState({ rates: newRates });
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <button
                onClick={() => {
                  this.saveLaborRates();
                }}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default EditLaborRatesModal;
