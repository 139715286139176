import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { generateModalStyle } from "../../utils/Misc";
import SmartsheetPicker from "./SmartsheetPicker";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import SmartsheetParser from "../../utils/SmartsheetParser";
import OrderDetailsEditor from "./OrderDetailsEditor";

interface ImportSmartsheetOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (id: number) => void;
}

interface ImportSmartsheetOrderModalState {
  sheet: any;
  stage: 0 | 1; // 0 = > select sheet, 1 => preview import
  sheetObj: any;
  items: any[];
  form: any;
  formValid: boolean;
}

class ImportSmartsheetOrderModal extends Component<
  ImportSmartsheetOrderModalProps,
  ImportSmartsheetOrderModalState
> {
  constructor(props: ImportSmartsheetOrderModalProps) {
    super(props);
    this.state = {
      sheet: null,
      stage: 0,
      sheetObj: null,
      items: [],
      form: {},
      formValid: false,
    };
  }

  previewImport() {
    const key = this.state.sheet.key;
    callAPI("actions/order", "POST", {
      action: "get_smartsheet_sheet",
      sheet_id: key,
    })
      .then((res) => {
        const parser = new SmartsheetParser(res.sheet);
        parser.parse();
        const order = parser.getOrderDetails();
        this.setState({
          stage: 1,
          sheetObj: res.sheet,
          form: {
            ...this.state.form,
            order_num: order.order_num,
            customer: order.customer,
            ship_date: order.ship_date,
            type: order.type,
            smartsheet_id: key,
          },
          items: parser.getOrderItems(),
        });
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error fetching order from Smartsheet");
          console.log(data);
        }
      });
  }

  completeImport() {
    callAPI("actions/order", "POST", {
      action: "add",
      data: this.state.form,
    })
      .then((res) => {
        return callAPI("actions/order", "POST", {
          action: "add_items",
          order_id: res.id,
          items: this.state.items,
        })
          .then(() => res)
          .catch(() => res);
      })
      .then((res) => {
        this.props.onClose();
        window.location.href = "/orders/" + res.id;
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error importing order from Smartsheet");
          console.log(data);
        }
      });
  }

  render() {
    if (this.state.stage === 1) {
      return (
        <Modal
          open={this.props.isOpen}
          onClose={this.props.onClose}
        >
          <Box sx={generateModalStyle("98%")}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Import Order from Smartsheet
            </Typography>
            <OrderDetailsEditor form={this.state.form} onChange={(form) => {
              this.setState({
                form: form
              })
            }} onFormValidation={(valid)=>this.setState({formValid: valid})}/>
            <div className="mt-3 table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Item #</th>
                    <th>LTC P/N</th>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Cat #</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.items.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.item_number}</td>
                        <td>{item.ltc_pn}</td>
                        <td>{item.name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.cat_num}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <button
                onClick={() => {
                  this.setState({
                    stage: 0,
                  });
                }}
                className="btn btn-secondary"
              >
                Back
              </button>
              <button
                disabled={!this.state.formValid}
                onClick={() => {
                  this.completeImport();
                }}
                className="btn btn-primary"
              >
                Import
              </button>
            </div>
          </Box>
        </Modal>
      );
    }
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Import Order from Smartsheet
          </Typography>
          <SmartsheetPicker
            onChange={(sheet) => {
              this.setState({
                sheet: sheet,
              });
            }}
            selectOption="sheets"
          />
          {this.state.sheet !== null && (
            <div className="mt-2">
              <b>Sheet Name:</b> {this.state.sheet.label}
            </div>
          )}
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={this.state.sheet === null}
              onClick={() => {
                this.previewImport();
              }}
              className="btn btn-primary"
            >
              Next
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default ImportSmartsheetOrderModal;
