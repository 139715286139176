import { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import ProductSelectModal from "../products/ProductSelectModal";
import { generateModalStyle } from "../../utils/Misc";
import { FormControl, FormGroup, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface AddBuildModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  product?: number;
}

const AddBuildModal: React.FC<AddBuildModalProps> = ({ isOpen, onClose, onSave, product }) => {
  const [form, setForm] = useState<any>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [productSelectModalOpen, setProductSelectModalOpen] = useState(false);

  const addBuild = () => {
    callAPI("actions/builds", "POST", {
      action: "add",
      data: form,
    })
      .then((response) => {
        onClose();
        onSave();
      })
      .catch((error) => {
        if (error.message !== "Unauthorized") {
          toast.error("Error adding build");
          console.log(error);
        }
      });
  };

  useEffect(() => {
    // validate form
    const newErrors = {
      product_id: form.product_id ? "" : "Required",
      qty: form.qty ? "" : "Required",
      finish_date: form.finish_date ? "" : "Required",
      status: form.status ? "" : "Required",
    };
    setErrors(newErrors);
  }, [form]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Build
          </Typography>
          <div className="row">
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Product"
                value={form.product_id || ""}
                disabled
                error={!!errors.product_id}
                helperText={errors.product_id}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setProductSelectModalOpen(true)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Qty"
                type="number"
                value={form.qty || ""}
                onChange={(e) =>
                  setForm({
                    ...form,
                    qty: e.target.value,
                  })
                }
                error={!!errors.qty}
                helperText={errors.qty}
              />
            </div>
            <div className="col-3 my-2">
              <FormControl fullWidth error={!!errors.status}>
                <InputLabel>Status</InputLabel>
                <Select
                  label="Status"
                  value={form.status || ""}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      status: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Planned">Planned</MenuItem>
                  <MenuItem value="Actual">Actual</MenuItem>
                  <MenuItem value="Closed">Closed</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-3 my-2">
              <TextField
                fullWidth
                label="W/O"
                value={form.wo || ""}
                onChange={(e) =>
                  setForm({
                    ...form,
                    wo: e.target.value,
                  })
                }
                error={!!errors.wo}
                helperText={errors.wo}
              />
            </div>
            <div className="col-3 my-2">
              <TextField
                fullWidth
                label="Start Date"
                type="date"
                value={form.start_date || ""}
                onChange={(e) =>
                  setForm({
                    ...form,
                    start_date: e.target.value,
                  })
                }
                error={!!errors.start_date}
                helperText={errors.start_date}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 my-2">
              <TextField
                fullWidth
                label="Finish Date"
                type="date"
                value={form.finish_date || ""}
                onChange={(e) =>
                  setForm({
                    ...form,
                    finish_date: e.target.value,
                  })
                }
                error={!!errors.finish_date}
                helperText={errors.finish_date}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-3 my-2">
              <TextField
                fullWidth
                label="Launch Date"
                type="date"
                value={form.launch_date || ""}
                onChange={(e) =>
                  setForm({
                    ...form,
                    launch_date: e.target.value,
                  })
                }
                error={!!errors.launch_date}
                helperText={errors.launch_date}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col-12">
              <TextField
                fullWidth
                label="Comment"
                multiline
                value={form.comment || ""}
                onChange={(e) =>
                  setForm({
                    ...form,
                    comment: e.target.value,
                  })
                }
                error={!!errors.comment}
                helperText={errors.comment}
              />
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={!Object.values(errors).every((v) => v === "")}
              onClick={() => {
                addBuild();
              }}
              className="btn btn-primary"
            >
              Add
            </button>
          </div>
        </Box>
      </Modal>
      <ProductSelectModal
        isOpen={productSelectModalOpen}
        onClose={() => {
          setProductSelectModalOpen(false);
        }}
        onSelect={(products) => {
          setForm((prevForm: any) => ({ ...prevForm, product_id: products[0] }));
          setProductSelectModalOpen(false);
        }}
      />
    </div>
  );
}

export default AddBuildModal;
