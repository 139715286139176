import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import Header from "./components/ui/Header";
import MainUi from "./components/ui/MainUi";
import Sidebar from "./components/ui/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./components/ui/main.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import React from "react";
import { Toaster } from "react-hot-toast";
import PlanningAuth from "./utils/Auth";
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import UserContext from "./components/ui/UserContext";
import DarkThemeSettings from "./components/settings/DarkThemeSettings";

interface AppState {
  auth: PlanningAuth;
  searchTerm: string;
  error: string;
  theme: "light" | "dark";
  hideSearch: boolean;
}

interface AppProps { }

let lastRedirect: string | null = null;
function RedirectHelper(props: { to: string | null }) {
  const navigate = useNavigate();
  if (props.to !== null && props.to !== lastRedirect) {
    lastRedirect = props.to;
    navigate(props.to);
  }
  return <></>;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


export default class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    const theme = DarkThemeSettings.getCurrentTheme();
    this.state = {
      auth: new PlanningAuth(),
      searchTerm: "",
      error: "",
      theme: theme,
      hideSearch: false,
    };
    document.body.setAttribute("data-theme", theme);
  }

  onPrefersDarkModeChange(e: MediaQueryListEvent) {
    const theme = localStorage.getItem("siteTheme");
    if (!theme) {
      const darkTheme = e.matches;
      this.setState({ theme: darkTheme ? "dark" : "light" });
      document.body.setAttribute("data-theme", darkTheme ? "dark" : "light");
    }
  }

  componentDidMount(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    this.state.auth.validate(code || undefined, state || undefined)
      .then(() => {
        this.setState({ auth: this.state.auth });
      });
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this.onPrefersDarkModeChange.bind(this));
  }

  render() {
    const darkTheme = createTheme({
      palette: {
        mode: this.state.theme,
      },
    });
    return (
      <ThemeProvider theme={darkTheme}>
        <div className="App">
          <Router>
            <RedirectHelper to={this.state.auth.redirectPath} />
            <Header
              user={this.state.auth.user}
              searchTerm={this.state.searchTerm}
              onSearch={(e) => this.setState({ searchTerm: e.target.value })}
              hideSearch={this.state.hideSearch}
            />
            <div className="container-fluid">
              {this.state.auth.user !== null ? (
                <div className="row">
                  <UserContext.Provider value={{ user: this.state.auth.user }}>
                    <Sidebar onNavigate={(hideSearch) => this.setState({ searchTerm: "", hideSearch })} />
                    <MainUi searchTerm={this.state.searchTerm} />
                  </UserContext.Provider>
                </div>
              ) : (
                <div className="text-center mt-5 mx-auto" style={{ maxWidth: "200px" }}>
                  <img src="/assets/img/favicon256.png" alt="LTC Logo" className="border" style={{ borderRadius: "50%", width: "100px" }} />
                  <BorderLinearProgress className="mb-2 mt-4" />
                  <p>Connecting to the database...</p>
                </div>
              )}
            </div>
          </Router>
          <Toaster
            toastOptions={{
              style: {
                background: '#363636',
                color: '#fff',
              },
              duration: 6000,
              success: {
                duration: 3000,
              },
            }}
          />
        </div>
      </ThemeProvider>
    );
  }
}