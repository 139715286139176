import React, { useState, useEffect } from 'react';
import { callAPI } from "../../../utils/API";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

interface CustomerSelectProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  name?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
}

const CustomerSelect: React.FC<CustomerSelectProps> = (props) => {
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldReload, setShouldReload] = useState<boolean>(true);

  useEffect(() => {
    if (!shouldReload) return;
    setShouldReload(false);
    callAPI("tables/customers_view", "POST", {
      order: "name ASC"
    })
      .then((res) => {
        setOptions(res.map((customer: any) => ({
          value: customer.id,
          label: customer.name,
        })));
        setLoading(false);
      })
      .catch((data) => {
        console.log(data);
      });
  }, [props, shouldReload]);

  return (
    <FormControl disabled={props.disabled} fullWidth error={props.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        label={props.label}
        value={props.value}
        onChange={(e) => {
          props.onChange(parseInt(e.target.value as string));
        }}
        name={props.name}
      >
        {loading ? (
          <MenuItem disabled value="0">Loading...</MenuItem>
        ) : (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
      {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

export default CustomerSelect;