import React from "react";

class DarkThemeSettings extends React.Component {
  render() {
    return (
      <div className="card my-2">
        <div className="card-body">
          <h1 className="h4">Theme</h1>
          <select
            className="form-select"
            defaultValue={localStorage.getItem("siteTheme") || ""}
            onChange={(event) => {
              let theme = event.target.value;
              if (!theme) {
                localStorage.removeItem("siteTheme");
              } else {
                localStorage.setItem("siteTheme", theme);
              }
              document.body.setAttribute("data-theme", DarkThemeSettings.getCurrentTheme());
            }}>
            <option value="">Use system setting</option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>
        </div>
      </div>
    );
  }
  static getCurrentTheme(): "light" | "dark" {
    const savedTheme = localStorage.getItem("siteTheme");
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (savedTheme) {
      return savedTheme as "light" | "dark";
    }
    return prefersDarkMode ? "dark" : "light";
  }
}

export default DarkThemeSettings;