import { Component } from "react";
import { Link } from "react-router-dom";
import { callAPI } from "../../utils/API";
import UserContext from "../ui/UserContext";

interface InventoryInfoCardProps {
  ids: number[];
  refreshBuilds?: number;
  onAddBuild?: () => void;
}

interface InventoryInfoCardState {
  inventoryData: any;
  builds: any[];
  addBuildModalOpen: boolean;
}

class InventoryInfoCard extends Component<
  InventoryInfoCardProps,
  InventoryInfoCardState
> {
  constructor(props: InventoryInfoCardProps) {
    super(props);
    this.state = {
      inventoryData: null,
      builds: [],
      addBuildModalOpen: false,
    };
  }

  loadData() {
    callAPI("data/inventory-data", "POST", {
      ids: this.props.ids,
    }).then((response) => {
      this.setState({
        inventoryData: response,
      });
    });
    callAPI("tables/builds_view", "POST", {
      where: {
        product_id: this.props.ids,
      },
      where_not: {
        status: "Closed",
      },
    }).then((response) => {
      this.setState({
        builds: response.sort((a: any, b: any) => {
          a = new Date(a.finish_date);
          b = new Date(b.finish_date);
          return a > b ? 1 : a < b ? -1 : 0;
        }),
      });
    });
  }

  componentDidMount(): void {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<InventoryInfoCardProps>): void {
    if (
      prevProps.ids !== this.props.ids ||
      prevProps.refreshBuilds !== this.props.refreshBuilds
    ) {
      this.loadData();
    }
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div className="card" id="tables-2">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 border-right">
                  <h1
                    className="h5"
                    title="This data is from the latest import on the Inventory page"
                  >
                    QuickBooks Inventory
                  </h1>
                  <div className="my-4 w-100" style={{ height: "380px" }}>
                    <p
                      className="text-muted"
                      title="The date that inventory data was last imported from QuickBooks"
                    >
                      Last Updated:{" "}
                      {this.state.inventoryData?.last_inv_upload?.replaceAll(
                        " ",
                        "-"
                      )}
                    </p>
                    <p title="Quantity on Hand">
                      Qty on hand: {this.state.inventoryData?.qty_oh}
                    </p>
                    <p title="Quantity on Sales Order">
                      Qty on sales order: {this.state.inventoryData?.qty_so}
                    </p>
                    <p title="Quantity to be Built">
                      Qty to be built: {this.state.inventoryData?.qty_tbb}
                    </p>
                    <p title="Quantity on Pending Builds">
                      Qty on pending builds: {this.state.inventoryData?.qty_pb}
                    </p>
                    <p title="Quantity on PO">
                      Qty on PO: {this.state.inventoryData?.qty_po}
                    </p>
                  </div>
                </div>
                <div className="col-md-3 border-right">
                  <h1 className="h5" title="Product Stats">
                    Product Info
                  </h1>
                  <div className="my-4 w-100" style={{ height: "380px" }}>
                    <p title="Qty on Hand">
                      # Inv: {this.state.inventoryData?.qty_oh}
                    </p>
                    <p title="# Requirements for this product not including Prospects)">
                      # Rqmts: {this.state.inventoryData?.num_rqmnts}
                    </p>
                    <p title="Builds for this product">
                      # Builds: {this.state.inventoryData?.num_builds}
                    </p>
                    <p title="# Available (not including Prospects)">
                      # Available:{" "}
                      {this.state.inventoryData?.num_available_noprospects}
                    </p>
                    <p>
                      <b>Including Prospects:</b>
                    </p>
                    <p title="# Requirements from Prospects">
                      # Prospects: {this.state.inventoryData?.num_prospects}
                    </p>
                    <p title="# Available (Including Prospects)">
                      # Available: {this.state.inventoryData?.num_available}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <h1 className="h5" title="Builds for this product">
                    Builds
                  </h1>
                  <div
                    className="w-100"
                    style={{ height: "380px", overflowY: "scroll" }}
                  >
                    {this.props.ids.length === 1 && !user?.isReadOnly && (
                      <button className="btn btn-sm btn-link m-0 p-0" onClick={() => {
                        if (this.props.onAddBuild) {
                          this.props.onAddBuild();
                        }
                      }}>
                        + Add Build
                      </button>
                    )}
                    <div className="table-responsive">
                      <table className="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th title="Build Status">Status</th>
                            <th title="Work Quote Number">W/O</th>
                            <th title="Build Quantity">Qty</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Launch Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.builds.map((build: any) => (
                            <tr key={build.id}>
                              <td>{build.status}</td>
                              <td>{build.wo}</td>
                              <td>{build.qty}</td>
                              <td>{build.start_date}</td>
                              <td>{build.finish_date}</td>
                              <td>{build.launch_date}</td>
                              <td>
                                <Link
                                  className="btn btn-sm btn-link m-0 p-0"
                                  to={"/builds/" + build.id}
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default InventoryInfoCard;
