import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import OrderDetailsEditor from "./OrderDetailsEditor";
import UserContext from "../ui/UserContext";

interface EditOrderDetailsModalProps {
  isOpen: boolean;
  order: any;
  onClose: () => void;
  onSave: () => void;
  prices?: boolean;
}

interface EditOrderDetailsModalState {
  form: any;
  formValid: boolean;
}

class EditOrderDetailsModal extends Component<
  EditOrderDetailsModalProps,
  EditOrderDetailsModalState
> {
  constructor(props: EditOrderDetailsModalProps) {
    super(props);
    this.state = {
      form: props.order,
      formValid: false,
    };
  }

  componentDidUpdate(prevProps: EditOrderDetailsModalProps) {
    if (prevProps.order !== this.props.order) {
      this.setState({
        form: this.props.order,
      });
    }
  }

  deleteOrder() {
    if (
      window.confirm(
        "Are you sure you want to delete this order? This cannot be undone."
      )
    ) {
      callAPI("actions/order", "POST", {
        action: "delete",
        order_id: this.props.order.id,
      })
        .then((res) => {
          this.props.onClose();
          window.history.back();
        })
        .catch((data) => {
          if (data.error !== "Unauthorized") {
            toast.error("Error deleting order");
            console.log(data);
          }
        });
    }
  }

  duplicateOrder() {
    if (window.confirm("Are you sure you want to duplicate this order?")) {
      callAPI("actions/order", "POST", {
        action: "duplicate",
        order_id: this.props.order.id,
      })
        .then((res) => {
          this.props.onClose();
          window.location.href = window.location.href.replace(
            this.props.order.id,
            res.id
          );
        })
        .catch((data) => {
          if (data.error !== "Unauthorized") {
            toast.error("Error duplicating order");
            console.log(data);
          }
        });
    }
  }

  refreshFromSmartsheet() {
    if (
      window.confirm(
        "Are you sure you want to refresh this order from Smartsheet? This will overwrite any changes you have made."
      )
    ) {
      callAPI("actions/order", "POST", {
        action: "refresh_from_smartsheet",
        order_id: this.props.order.id,
      })
        .then((res) => {
          this.props.onClose();
          window.location.reload();
        })
        .catch((data) => {
          if (data.error !== "Unauthorized") {
            toast.error("Error refreshing order from Smartsheet");
            console.log(data);
          }
        });
    }
  }

  saveChanges() {
    callAPI("actions/order", "POST", {
      action: "update",
      order_id: this.props.order.id,
      data: this.state.form,
    })
      .then((res) => {
        this.props.onClose();
        this.props.onSave();
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error saving changes");
          console.log(data);
        }
      });
  }

  render() {
    if (!this.state.form) return;
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <Modal
              open={this.props.isOpen}
              onClose={this.props.onClose}
              aria-labelledby="selectProductModalLabel"
            >
              <Box sx={generateModalStyle("98%")}>
                <div className="btn-group mb-3">
                  {!!user?.isAdmin && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.deleteOrder();
                      }}
                    >
                      Delete Order
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => {
                      this.duplicateOrder();
                    }}
                  >
                    Duplicate Order
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => {
                      this.refreshFromSmartsheet();
                    }}
                  >
                    Refresh From Smartsheet
                  </button>
                </div>
                <Typography variant="h6" component="h2">
                  Edit Details
                </Typography>
                <OrderDetailsEditor form={this.state.form} onChange={(form) => {
                  this.setState({
                    form: form
                  })
                }} prices={this.props.prices} onFormValidation={(valid) => this.setState({ formValid: valid })} />
                <div className="d-flex w-100 gap-2 justify-content-end mt-2">
                  <button
                    onClick={() => {
                      this.props.onClose();
                    }}
                    className="btn btn-outline-secondary"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      this.saveChanges();
                    }}
                    disabled={!this.state.formValid}
                    className="btn btn-outline-primary"
                  >
                    Save
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default EditOrderDetailsModal;
