import { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import DarkThemeSettings from "../components/settings/DarkThemeSettings";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import React from "react";
import "./DataGrid.css";

interface DataGridProps {
  rows: any[];
  columns: any[];
  defaultColumnProps?: any;
  className?: string;
  loading?: boolean;
  selection?: "single" | "multiple";
  onSelectionModelChange?: (selection: any[]) => void;
  getRowClassName?: (params: any) => string;
  searchTerm?: string;
  processRowUpdate?: (row: any) => void;
  defaultFilter?: any;
}

interface DataGridState {
}

class DataGrid extends Component<DataGridProps, DataGridState> {
  private gridRef: any;
  constructor(props: DataGridProps) {
    super(props);
    this.gridRef = React.createRef();
  }
  componentDidMount() {
    // this.gridRef.current.api.showLoadingOverlay();
  }
  componentDidUpdate(prevProps: DataGridProps) {
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.gridRef.current.api.setQuickFilter(this.props.searchTerm);
    }
    if (this.props.defaultFilter !== prevProps.defaultFilter) {
      this.gridRef.current.api.setFilterModel(this.props.defaultFilter);
    }
  }
  render() {
    let noRowsOverlayComponent: any = undefined;
    let noRowsOverlayComponentParams: any = undefined;
    if (this.props.loading) {
      noRowsOverlayComponent = "agLoadingOverlay";
      noRowsOverlayComponentParams = {
        loadingMessage: "Loading...",
      };
    }
    const theme = DarkThemeSettings.getCurrentTheme();
    return (
      <div className={(theme === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine") + " w-100 h-100" + (this.props.className ? " " + this.props.className : "")}>
        <AgGridReact
          ref={this.gridRef} // Ref for accessing Grid's API

          rowData={this.props.rows}

          columnDefs={this.props.columns}
          defaultColDef={this.props.defaultColumnProps}

          animateRows={true} // for sorting
          rowSelection={this.props.selection}
          suppressRowClickSelection={true}

          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}

          getRowClass={this.props.getRowClassName}

          readOnlyEdit={true}
          onCellEditRequest={(e) => {
            if (this.props.processRowUpdate) {
              const row = e.data;
              const newValue = e.newValue;
              const colId = e.column.getColId();
              this.props.processRowUpdate({
                ...row,
                [colId]: newValue
              });
            }
          }}

          onSelectionChanged={(e) => {
            const rows = e.api.getSelectedRows();
            if (this.props.onSelectionModelChange) {
              this.props.onSelectionModelChange(rows.map((row: any) => row.id));
            }
          }}
        />
      </div>
    );
  }
}

export default DataGrid;