import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import DataGrid from "../../utils/DataGrid";

interface ProductsDBProps {
  searchTerm?: string;
}

interface ProductsDBState {
  data: any[];
}

const columns: any[] = [
  { field: "id", headerName: "ID", width: 90, hide: true },
  { field: "ltc_pn", headerName: "LTC P/N", width: 150 },
  { field: "serial_number", headerName: "Serial #", width: 150 },
  {
    field: "software_key_sn",
    headerName: "SW Key S/N",
    width: 150,
    hide: true,
  },
  {
    field: "software_version",
    headerName: "SW Version",
    width: 150,
    hide: true,
  },
  { field: "wo_number", headerName: "WO #", width: 80, hide: true },
  { field: "date_shipped", headerName: "Date Shipped", width: 80, hide: true },
  { field: "catalog_number", headerName: "Cat #", width: 150, hide: true },
  { field: "sid_number", headerName: "SID #", width: 150, hide: true },
  { field: "tilt_sn", headerName: "Tilt SN", width: 150, hide: true },
  {
    field: "recall_needed",
    headerName: "Recall Needed",
    width: 150,
    hide: true,
  },
  {
    field: "recall_description",
    headerName: "Recall Description",
    width: 150,
    hide: true,
  },
  {
    field: "comments",
    headerName: "Comments",
    width: 150,
    hide: true,
  },
  {
    field: "cal_cert_number",
    headerName: "Cal Cert #",
    width: 150,
    hide: true,
  },
  {
    field: "certificate",
    headerName: "Certificate",
    width: 150,
    hide: true,
  },
  {
    field: "calibration",
    headerName: "Calibration",
    width: 150,
    hide: true,
  },
  {
    field: "fda_model",
    headerName: "FDA Model",
    width: 150,
    hide: true,
  },
  {
    field: "label_type",
    headerName: "Label Type",
    width: 150,
    hide: true,
  },
  { field: "quantity", headerName: "Qty", width: 80 },
  { field: "name", headerName: "Description", width: 300 },
  { field: "order_num", headerName: "Job #", width: 150 },
  { field: "customer", headerName: "Customer", width: 150 },
  { field: "end_user", headerName: "End User", width: 150 },
  { field: "item_number", headerName: "Item #", width: 80, hide: true },
];

class ProductsDB extends Component<
  ProductsDBProps,
  ProductsDBState
> {
  constructor(props: ProductsDBProps) {
    super(props);
    this.state = {
      data: [],
    };
  }

  loadData() {
    callAPI("tables/shipped_products_view")
      .then((res) => {
        this.setState({
          data: res,
        });
      })
      .catch((err) => {
        toast.error("Error loading data");
        console.log(err);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Products Database</h1>
        </div>
        <div style={{ height: "80vh" }}>
          <DataGrid
            className="mt-3"
            rows={this.state.data}
            columns={columns}
            loading={this.state.data.length === 0}
            defaultColumnProps={{
              sortable: true,
              resizable: true,
              suppressMovable: true,
            }}
            searchTerm={this.props.searchTerm}
          />
        </div>
      </div>
    );
  }
}

export default ProductsDB;
