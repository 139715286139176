import { Component } from "react";
import toast from "react-hot-toast";
import SoftwareTable from "./SoftwareTable";

interface SoftwareProps {
  searchTerm?: string;
}

interface SoftwareState {
  mode: string;
}

class Software extends Component<SoftwareProps, SoftwareState> {
  constructor(props: SoftwareProps) {
    super(props);
    this.state = {
      mode: "sensor_calibration_history",
    };
  }

  addNew() {
    // TODO: Implement this
    toast.error("Feature not yet implemented.");
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Software</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <button
              className="btn btn-sm btn-outline-secondary me-2"
              onClick={() => this.addNew()}
            >
              Add
            </button>
            <div
              className="btn-group me-2"
              role="group"
              aria-label="Software Type"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                checked={this.state.mode === "sensor_calibration_history"}
                onChange={() =>
                  this.setState({ mode: "sensor_calibration_history" })
                }
                id="btnradio1"
              />
              <label
                className="btn btn-sm btn-outline-secondary"
                htmlFor="btnradio1"
              >
                Calibration History
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                checked={this.state.mode === "software_serial_numbers"}
                onChange={() =>
                  this.setState({ mode: "software_serial_numbers" })
                }
                id="btnradio2"
              />
              <label
                className="btn btn-sm btn-outline-secondary"
                htmlFor="btnradio2"
              >
                Serial Numbers
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                checked={this.state.mode === "windows_licenses"}
                onChange={() => this.setState({ mode: "windows_licenses" })}
                id="btnradio3"
              />
              <label
                className="btn btn-sm btn-outline-secondary"
                htmlFor="btnradio3"
              >
                Windows Licenses
              </label>
            </div>
          </div>
        </div>
        <SoftwareTable mode={this.state.mode} searchTerm={this.props.searchTerm} />
      </div>
    );
  }
}

export default Software;
