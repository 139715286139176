import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { callAPI } from "../../utils/API";
import FolderChooserModal from "./FolderChooserModal";
import { generateModalStyle } from "../../utils/Misc";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

interface QuoteSaveModalProps {
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface QuoteSaveModalState {
  comment: string;
  revision: string;
  fileLocation: string;
  folderChooserOpen: boolean;
}

class QuoteSaveModal extends Component<
  QuoteSaveModalProps,
  QuoteSaveModalState
> {
  constructor(props: QuoteSaveModalProps) {
    super(props);
    this.state = {
      comment: "",
      revision: "",
      fileLocation: "",
      folderChooserOpen: false,
    };
  }

  createQuote() {
    callAPI("actions/quote", "POST", {
      action: "new_revision",
      order_id: this.props.orderId,
      comment: this.state.comment,
      fs3_requested_dir: this.state.fileLocation,
      rev: this.state.revision.replace("R", ""),
    }).then((data) => {
      this.props.onClose();
      this.props.onSave();
    });
  }

  loadNextRevision() {
    callAPI("tables/quotes", "POST", {
      select: "COALESCE(MAX(revision), -1) as revision",
      where: {
        order_id: this.props.orderId,
      }
    })
      .then((res) => {
        this.setState({
          revision: "R" + (parseInt(res[0]["revision"]) + 1),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.loadNextRevision();
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.isOpen}
          onClose={this.props.onClose}
          aria-labelledby="selectProductModalLabel"
        >
          <Box sx={generateModalStyle("98%")}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Save New Quote
            </Typography>
            <div className="row">
              <div className="col-md-2 col-sm-6 my-2">
                <TextField
                  fullWidth
                  label="Revision"
                  type="text"
                  value={this.state.revision}
                  onChange={(e) => this.setState({ revision: e.target.value })}
                />
              </div>
              <div className="col-md-10 col-sm-6 my-2">
                <TextField
                  disabled
                  className="form-control"
                  placeholder="M:\"
                  label="Save Location"
                  type="text"
                  value={this.state.fileLocation}
                  onClick={() => this.setState({ folderChooserOpen: true })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => this.setState({ folderChooserOpen: true })}
                        >
                          <FolderOpenIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <TextField
                fullWidth
                  label="Comment"
                  multiline
                  rows={4}
                  placeholder="Add Comment (optional)"
                  value={this.state.comment}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                />
              </div>
            </div>
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <Button
                variant="contained"
                color="primary"
                disabled={!this.state.revision || !this.state.fileLocation}
                onClick={() => {
                  this.createQuote();
                }}
              >
                Save
              </Button>
            </div>
          </Box>
        </Modal>
        <FolderChooserModal
          isOpen={this.state.folderChooserOpen}
          onClose={() => this.setState({ folderChooserOpen: false })}
          onSave={(path: string) => {
            this.setState({
              fileLocation: path,
              folderChooserOpen: false,
            });
          }}
        />
      </div>
    );
  }
}

export default QuoteSaveModal;
