import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface AddEndUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  customer_id: number;
  defense: string;
}

interface AddEndUserModalState {
  name: string;
  defense: string;
}

class AddEndUserModal extends Component<
  AddEndUserModalProps,
  AddEndUserModalState
> {
  constructor(props: AddEndUserModalProps) {
    super(props);
    this.state = {
      name: "",
      defense: props.defense,
    };
  }

  componentDidUpdate(prevProps: AddEndUserModalProps) {
    if (this.props.defense && !prevProps.defense) {
      this.setState({
        defense: this.props.defense,
      });
    }
  }

  addEndUser() {
    callAPI("actions/customers", "POST", {
      action: "add_end_user",
      name: this.state.name,
      defense: this.state.defense,
      customer_id: this.props.customer_id,
    })
      .then((res) => {
        this.props.onSave();
        this.props.onClose();
      })
      .catch((err) => {
        toast.error("Error adding end user");
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add End User
          </Typography>
          <div className="row">
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="End User Name"
                type="text"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="col-4">
              <FormControl fullWidth>
                <InputLabel id="defense-label">Defense</InputLabel>
                <Select
                  labelId="defense-label"
                  label="Defense"
                  value={this.state.defense}
                  onChange={(e) => this.setState({ defense: e.target.value })}
                >
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={!this.state.name}
              onClick={() => {
                this.addEndUser();
              }}
              className="btn btn-primary"
            >
              Add
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default AddEndUserModal;
