import { Component } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { callAPI } from "../../../utils/API";
import DataGrid from "../../../utils/DataGrid";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccessControlListEditorModal from "./access-control/AccessControlListEditor";

interface DomainAuditProps {
  searchTerm?: string;
}

interface DomainAuditState {
  loaded: boolean;
  audits: any[];
  users: User[],
  devices: Device[],
  groups: Group[],
  auditIndex: number;

  usersCollapsed: boolean;
  devicesCollapsed: boolean;
  groupsCollapsed: boolean;

  accessControlListEditorOpen: boolean;
}

interface User {
  Enabled: boolean;
  Groups: string;
  LastLogon: string;
  Name: string;
  UserName: string;
  PasswordNeverExpires: boolean;
}

interface Device {
  ComputerName?: string;
  DeviceType: number;
  IPAddress: string;
  MACAddress: string;
  Vendor?: string;
}

interface Group {
  name: string;
  members: string[];
}

class DomainAudit extends Component<
  DomainAuditProps,
  DomainAuditState
> {
  constructor(props: DomainAuditProps) {
    super(props);
    this.state = {
      audits: [],
      users: [],
      devices: [],
      groups: [],
      loaded: false,
      auditIndex: 0,

      usersCollapsed: false,
      devicesCollapsed: false,
      groupsCollapsed: false,

      accessControlListEditorOpen: false,
    };
  }

  loadData() {
    callAPI("tables/computer_audit_domain", "POST", {
      order: "timestamp DESC",
    })
      .then((res) => {
        this.setState({
          audits: res,
        }, () => {
          this.loadAudit(0);
        });
      })
      .catch((err) => {
        toast.error("Error loading data");
        console.log(err);
      });
  }

  loadAudit(index: number) {
    const audit = this.state.audits[index];
    if (!audit) {
      return;
    }
    const users = JSON.parse(audit.users);
    const devices = JSON.parse(audit.devices);

    // find groups by looking at the Groups column in users
    const groups: Group[] = [];
    users.forEach((user: User) => {
      const groupNames = user.Groups?.split(",") || [];
      groupNames.forEach((groupName: string) => {
        const group = groups.find((g) => g.name === groupName);
        if (group) {
          group.members.push(user.UserName);
        } else if (groupName) {
          groups.push({
            name: groupName,
            members: [user.UserName],
          });
        }
      });
    });
    this.setState({
      users,
      devices,
      groups,
      auditIndex: index,
      loaded: true,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  parseTimestamp(timestamp: string) {
    const lastUpdated = new Date(timestamp);
    // fix time zone
    const timezontOffset = lastUpdated.getTimezoneOffset();
    lastUpdated.setMinutes(lastUpdated.getMinutes() - timezontOffset);
    return lastUpdated.toLocaleString();
  }

  render() {
    const { users, devices, groups, loaded } = this.state;
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Domain Audit</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2" role="group" aria-label="Audit Type">
              <Link className="btn btn-sm btn-outline-secondary" to="/computers">
                Computer Audit
              </Link>
              <Link className="btn btn-sm btn-outline-secondary active" to="/computers/domain-audit">
                Domain Audit
              </Link>
            </div>
          </div>
        </div>

        {loaded ? (
          <div>
            {/* dropdown to choose which audit (by timestamp) */}
            <div className="mb-3">
              <select
                className="form-select"
                value={this.state.auditIndex}
                onChange={(e) => this.loadAudit(parseInt(e.target.value))}
              >
                {this.state.audits.map((audit, i) => (
                  <option key={i} value={i}>
                    {this.parseTimestamp(audit.timestamp)}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn btn-sm btn-primary mb-3" onClick={() => this.setState({ accessControlListEditorOpen: true })}>
              View Access Control List
            </button>
            <Accordion
              expanded={!this.state.usersCollapsed}
              onChange={() => {
                this.setState({
                  usersCollapsed: !this.state.usersCollapsed,
                });
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Users
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100 mb-3" style={{ height: 400 }}>
                  <DataGrid
                    rows={users}
                    columns={[
                      { field: "Name", headerName: "Name", width: 200 },
                      { field: "UserName", headerName: "Username", width: 200 },
                      {
                        field: "LastLogon", headerName: "Last Logon", width: 200, valueFormatter: (params: any) => {
                          let timestamp = parseInt(((params.value || "").match(/\d+/) || [""])[0]);
                          if (params.value.includes("-")) {
                            timestamp = 0;
                          }
                          return timestamp ? new Date(timestamp).toLocaleString() : "";
                        }
                      },
                      { field: "Enabled", headerName: "Enabled", width: 100 },
                      { field: "Groups", headerName: "Groups", width: 200 },
                      { field: "PasswordNeverExpires", headerName: "Password Never Expires", width: 200 },
                    ]}
                    defaultColumnProps={{
                      sortable: true,
                      resizable: true,
                      suppressMovable: true,
                    }}
                    searchTerm={this.props.searchTerm}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={!this.state.groupsCollapsed}
              onChange={() => {
                this.setState({
                  groupsCollapsed: !this.state.groupsCollapsed,
                });
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Groups
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100 mb-3" style={{ height: 400 }}>
                  <DataGrid
                    rows={groups.map((g) => {
                      return {
                        id: g.name,
                        name: g.name,
                        members: g.members.join(", "),
                      };
                    })}
                    columns={
                      [
                        { field: "name", headerName: "Name", width: 300 },
                        { field: "members", headerName: "Members", width: 500 },
                      ]}
                    defaultColumnProps={{
                      sortable: true,
                      resizable: true,
                      suppressMovable: true,
                    }}
                    searchTerm={this.props.searchTerm}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={!this.state.devicesCollapsed}
              onChange={() => {
                this.setState({
                  devicesCollapsed: !this.state.devicesCollapsed,
                });
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                  Devices
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100 mb-5" style={{ height: 400 }}>
                  <DataGrid
                    rows={devices}
                    columns={[
                      { field: "ComputerName", headerName: "Computer Name", width: 400 },
                      { field: "IPAddress", headerName: "IP Address", width: 200 },
                      {
                        field: "MACAddress", headerName: "MAC Address", width: 200, valueFormatter: (params: any) => {
                          return params.value.toUpperCase().replaceAll("-", ":");
                        }
                      },
                    ]}
                    defaultColumnProps={{
                      sortable: true,
                      resizable: true,
                      suppressMovable: true,
                    }}
                    searchTerm={this.props.searchTerm}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <p>Loading data...</p>
        )}
        <AccessControlListEditorModal
          open={this.state.accessControlListEditorOpen}
          onClose={() => {
            this.setState({
              accessControlListEditorOpen: false
            });
          }}
          audit={{
            users: users.map((u) => {
              return {
                id: 0,
                name: u.Name,
                username: u.UserName,
              }
            }), groups: groups.map((g) => {
              return {
                id: 0,
                name: g.name,
                description: ""
              }
            })
          }}
        />
      </div>
    );
  }
}

export default DomainAudit;
