import { useEffect, useState } from "react";
import * as Icons from "react-feather";
import { Icon } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import UserContext from "./UserContext";

interface SidebarProps {
  onNavigate: (hideSearch: boolean) => void;
}

interface Page {
  text: string;
  url: string;
  icon: Icon;
  adminOnly?: boolean;
  computerAdminOnly?: boolean;
  priceOnly?: boolean;
  financeOnly?: boolean;

  hidden?: boolean;
  searchHidden?: boolean;
}

const data: (Page[] | string)[] = [
  [
    { text: "Dashboard", url: "/", icon: Icons.Home, searchHidden: true },
    { text: "Orders", url: "/orders", icon: Icons.File },
    { text: "Products", url: "/products", icon: Icons.ShoppingCart },
    { text: "Inventory", url: "/inventory", icon: Icons.Truck },
    { text: "Builds", url: "/builds", icon: Icons.Tool },
    {
      text: "Price List",
      url: "/price-list",
      icon: Icons.DollarSign,
      priceOnly: true,
    },
    { text: "BizDev", url: "/bizdev", icon: Icons.DollarSign, priceOnly: true },
    { text: "Customers", url: "/customers", icon: Icons.Briefcase },
    {
      text: "Revenue",
      url: "/revenue",
      icon: Icons.BarChart2,
      financeOnly: true,
      searchHidden: true,
    },
  ],
  // "Database",
  // [
  //   { text: "Products DB", url: "/products-database", icon: Icons.Box },
  //   { text: "Software", url: "/software", icon: Icons.Code },
  // ],
  "Development",
  [
    { text: "Administration", url: "/admin", icon: Icons.File, hidden: true },
    { text: "Issues", url: "/issues", icon: Icons.AlertCircle, searchHidden: true },
    { text: "Computers", url: "/computers", icon: Icons.Monitor, computerAdminOnly: true },
    { text: "Dev", url: "/development", icon: Icons.Code, adminOnly: true, searchHidden: true },
    { text: "Products DB", url: "/products-database", icon: Icons.Box, adminOnly: true },
    { text: "Software", url: "/software", icon: Icons.Code, adminOnly: true },
  ],
];

function searchIsHidden(path: string) {
  for (const item of data) {
    if (typeof item === "string") continue;
    for (const page of item) {
      if (page.url === path) {
        return page.searchHidden || false;
      }
    }
  }
  return true;
}

const Sidebar = ({ onNavigate }: SidebarProps) => {
  const location = useLocation();
  const [currentUrl, setCurrentUrl] = useState<string>(location.pathname);

  useEffect(() => {
    setCurrentUrl(location.pathname);
    onNavigate(searchIsHidden(location.pathname));
  }, [location]);

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse" id="sidebarMenu">
          <div className="position-sticky pt-3 sidebar-sticky">
            {data.map((item, index) => {
              if (typeof item === "string") {
                return (
                  <h6
                    className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase"
                    key={"nav_L1_" + index}
                  >
                    <span>{item}</span>
                  </h6>
                );
              } else {
                return (
                  <ul className="nav flex-column" key={"nav_L1_" + index}>
                    {item.map((page: Page, index) => {
                      if (page.hidden) {
                        return null;
                      }
                      if (page.adminOnly && !user?.isAdmin) {
                        return null;
                      }
                      if (page.computerAdminOnly && !user?.isComputerAdmin) {
                        return null;
                      }
                      if (page.priceOnly && !user?.hasPriceAccess) {
                        return null;
                      }
                      if (page.financeOnly && !user?.hasFinanceAccess) {
                        return null;
                      }
                      return (
                        <li
                          className="nav-item"
                          key={"nav" + index}
                        >
                          <Link
                            className={
                              "nav-link " + (currentUrl === page.url
                                ? "active"
                                : "")
                            }
                            aria-current="page"
                            to={page.url}
                          >
                            {<page.icon className="sidebar-icon" />}
                            {page.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                );
              }
            })}
          </div>
        </nav>
      )}
    </UserContext.Consumer>
  );
};

export default Sidebar;
