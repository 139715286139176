import { Component } from "react";
import { callAPI } from "../../utils/API";

interface DefaultPageSettingsProps { }

interface DefaultPageSettingsState {
  page: string;
}

class DefaultPageSettings extends Component<DefaultPageSettingsProps, DefaultPageSettingsState> {
  constructor(props: DefaultPageSettingsProps) {
    super(props);
    this.state = {
      page: "/",
    };
  }

  componentDidMount(): void {
    this.loadData();
  }

  loadData(): void {
    callAPI("me").then((response) => {
      this.setState({
        page: response.defaultPage,
      });
    })
      .catch((error) => {
        console.log(error);
      });
  }

  saveSettings(): void {
    callAPI("actions/settings", "POST", {
      action: "set_default_page",
      page: this.state.page,
    }).then((response) => {
      if (response.success) {
        this.loadData();
      }
    })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="card my-2">
        <div className="card-body">
          <h1 className="h4">Login Settings</h1>
          <p>Default Page:</p>
          <select className="form-select" value={this.state.page} onChange={(event) => {
            this.setState({
              page: event.target.value,
            }, () => {
              this.saveSettings();
            });
          }}>
            <option value="/">Dashboard</option>
            <option value="/orders/">Orders</option>
            <option value="/products/">Products</option>
            <option value="/customers/">Customers</option>
            <option value="/builds/">Builds</option>
            <option value="/inventory/">Inventory</option>
            <option value="/issues/">
              Issues
            </option>
            <option value="/price-list/">Price List</option>
            <option value="/estimates/">Quotes</option>
            <option value="/estimates/projections-summary">
              Projections Summary
            </option>
            {/* <option value="/?CUSTOM">+ Custom</option> */}
          </select>
        </div>
      </div>
    );
  }
}

export default DefaultPageSettings;
