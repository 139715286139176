import { Component } from "react";
import { API_DOMAIN, callAPI } from "../../utils/API";
import SetSmartsheetTokenModal from "./SetSmartsheetTokenModal";

interface AccountPageProps { }

interface AccountPageState {
  data: any;
  smartsheetTokenModalOpen: boolean;
}

class AccountPage extends Component<AccountPageProps, AccountPageState> {
  constructor(props: AccountPageProps) {
    super(props);
    this.state = {
      data: {},
      smartsheetTokenModalOpen: false,
    };
  }

  loadData() {
    callAPI("me", "GET").then((response) => {
      this.setState({
        data: response,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
          <h1 className="h2">My Account</h1>
          <div className="card mb-2">
            <div className="card-body">
              <h1>
                <img
                  className="avatar avatar-user"
                  alt="Avatar"
                  src={API_DOMAIN + this.state.data.avatar}
                  onError={(e) => {
                    (e.target as HTMLImageElement).src =
                      "/assets/img/profile-picture.jfif";
                  }}
                  style={{
                    maxWidth: "50px",
                    maxHeight: "50px",
                  }}
                />{" "}
                {this.state.data.name}
              </h1>
              <div data-style={{ paddingLeft: "80px" }}>
                <p className="mb-1">Username: {this.state.data.username}</p>
                <p className="mb-0">Administrator: {this.state.data.isAdmin ? "Yes" : "No"}</p>
                <p className="mb-0">Computer Admin: {this.state.data.isComputerAdmin ? "Yes" : "No"}</p>
                <p className="mb-0">
                  Allowed to edit/delete:{" "}
                  {this.state.data.isEditor ? "Yes" : "No"}
                </p>
                <p className="mb-0">
                  Allowed to create: {this.state.data.isReadOnly ? "No" : "Yes"}
                </p>
                <p className="mb-0">
                  Allowed to view prices: {this.state.data.hasPriceAccess ? "Yes" : "No"}
                </p>
                <p className="mb-0">
                  Allowed to view finances: {this.state.data.hasFinanceAccess ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h4>Smartsheet</h4>
            <p>
              Smartsheet token: {this.state.data.smartsheetKeySet ? "PRESENT" : "NOT PRESENT"}
            </p>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => {
                this.setState({
                  smartsheetTokenModalOpen: true
                })
              }}>
              Set Smartsheet API Token
            </button>
          </div>
        </div>
        <SetSmartsheetTokenModal
          open={this.state.smartsheetTokenModalOpen}
          onClose={() => {
            this.setState({
              smartsheetTokenModalOpen: false
            })
          }}
        />
      </div>
    );
  }
}

export default AccountPage;
