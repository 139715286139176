import { Component } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { callAPI } from "../../utils/API";
import { CHART_OPTIONS, COLORS, yAxisFormatter } from "../dashboard/ChartUtils";
import { ORDER_TYPES } from "../dashboard/RequirementsChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface ProjectedBookingsChartProps {
  type: string;
}

interface ProjectedBookingsChartState {
  data: any;
}

let options = structuredClone(CHART_OPTIONS);
if (options && options.scales && options.scales.x) {
  options.scales.x.stacked = false;
  options.scales.y = {
    ticks: {
      callback: yAxisFormatter,
    },
    stacked: false,
  };
}

class ProjectedBookingsChart extends Component<
  ProjectedBookingsChartProps,
  ProjectedBookingsChartState
> {
  constructor(props: ProjectedBookingsChartProps) {
    super(props);
    this.state = {
      data: {},
    };
  }

  loadData() {
    ORDER_TYPES.forEach((orderType) => {
      callAPI("chart/projected-bookings/", "POST", {
        type: orderType,
        x_axis: this.props.type === "Projection" ? 1 : 0,
      }).then((response) => {
        this.setState({
          data: {
            ...this.state.data,
            [orderType]: response,
          },
        });
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: ProjectedBookingsChartProps) {
    if (prevProps.type !== this.props.type) {
      this.loadData();
    }
  }

  render() {
    const labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      <div>
        <Bar
          options={options}
          style={{ height: "30vh" }}
          data={{
            labels: this.props.type === "This Year" ? labels : (
              labels.slice(new Date().getMonth()).concat(labels.slice(0, new Date().getMonth()))
            ),
            datasets: ORDER_TYPES.map((orderType: string) => {
              const dataset = this.state.data[orderType];
              let data = [];
              if (dataset && dataset[1]) {
                data = dataset[1];
              }
              return {
                label: orderType,
                data,
                backgroundColor: COLORS[orderType],
                borderColor: COLORS.border,
              } as ChartDataset<"bar", number[]>;
            }),
          }}
        />
      </div>
    );
  }
}

export default ProjectedBookingsChart;
