import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import OrderDetailsEditor from "./OrderDetailsEditor";

interface NewOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (id: number) => void;
  prices?: boolean;
}

interface NewOrderModalState {
  form: any;
  formValid: boolean;
  lastQuoteNum: number;
}

function makeDoubleDigits(num: number): string {
  if (num < 10) return "0" + num;
  return num.toString()
}

class NewOrderModal extends Component<NewOrderModalProps, NewOrderModalState> {
  constructor(props: NewOrderModalProps) {
    super(props);
    this.state = {
      form: {
        quote_type: "QUOTATION"
      },
      formValid: false,
      lastQuoteNum: 1000,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    callAPI("data/largest-quote-num")
      .then((res) => {
        this.setState({
          lastQuoteNum: res.num,
        }, () => {
          this.setState({
            form: {
              ...this.state.form,
              order_num: this.generateQuoteNum(),
              job_num: this.generateQuoteNum(true),
            }
          })
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  addOrder() {
    callAPI("actions/order", "POST", {
      action: "add",
      data: this.state.form,
    })
      .then((res) => {
        toast.success("Order added");
        this.props.onClose();
        this.props.onSave(res.id);
      })
      .catch((err) => {
        toast.error("Error adding order");
        console.log(err);
      });
  }

  getTypeName(): string {
    if (!this.props.prices) return "Order";
    if (this.state.form.quote_type === "COST_ESTIMATE") return "Cost Estimate";
    if (this.state.form.quote_type === "QUOTATION") return "Quote";
    return "Communication";
  }

  generateQuoteNum(job?: boolean): string {
    const date = new Date();
    const month = makeDoubleDigits(date.getMonth() + 1);
    const day = makeDoubleDigits(date.getDate());
    const year = (date.getFullYear() % 1000);
    if (this.state.form.quote_type === "COST_ESTIMATE") {
      // EX: CE 042123 if today is 4/21/2023
      return "CE " + month + day + year;
    }
    else if (this.state.form.quote_type === "COMMUNICATION") {      // EX: CE 042123 if today is 4/21/2023
      // EX: COM 042123 if today is 4/21/2023
      return "COM " + month + day + year;
    }
    else {
      const nextQuote = this.state.lastQuoteNum + 1;
      if (isNaN(nextQuote)) return "";
      const currentYear = (new Date()).getFullYear();
      if (job) {
        return nextQuote + "-2" + (currentYear % 100);
      }
      return "Q-" + nextQuote + "-2" + (currentYear % 100);
    }
  }

  render() {
    const typeName = this.getTypeName();
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography variant="h6" component="h2">
            Add {typeName}
          </Typography>
          <OrderDetailsEditor form={this.state.form} onChange={(form) => {
            const typeChanged = form.quote_type !== this.state.form.quote_type;
            this.setState({
              form: form,
            }, () => {
              if (typeChanged) {
                this.setState({
                  form: {
                    ...this.state.form,
                    order_num: this.generateQuoteNum(),
                    job_num: this.generateQuoteNum(true),
                  }
                })
              }
            });
          }} prices={this.props.prices} onFormValidation={(valid)=>this.setState({formValid: valid})}/>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                this.props.onClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                this.setState({
                  form: {
                    quote_type: this.state.form.quote_type,
                    order_num: this.generateQuoteNum(),
                    job_num: this.generateQuoteNum(true),
                  }
                })
              }}
            >
              Clear Form
            </button>
            <button
              disabled={!this.state.formValid}
              onClick={() => {
                this.addOrder();
              }}
              className="btn btn-primary"
            >
              Add {typeName}
            </button>
          </div>
        </Box>
      </Modal >
    );
  }
}

export default NewOrderModal;
