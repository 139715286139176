import { Tree } from "primereact/tree";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";

interface SmartsheetPickerProps {
  selectOption: "folders" | "sheets";
  onChange: (value: any) => void;
}

interface SmartsheetPickerState {
  nodes: any[];
  sheets: any[];
  path: string;
  selectedKey: any;
  loading: boolean;
  error: string;
}

class SmartsheetPicker extends Component<
  SmartsheetPickerProps,
  SmartsheetPickerState
> {
  constructor(props: SmartsheetPickerProps) {
    super(props);
    this.state = {
      nodes: [],
      sheets: [],
      path: "",
      selectedKey: null,
      loading: true,
      error: "",
    };
  }

  componentDidMount() {
    this.loadWorkspaces();
    if (this.props.selectOption === "sheets") {
      this.loadSheets();
    }
  }

  loadWorkspaces() {
    callAPI("actions/order", "POST", {
      action: "get_smartsheet_workspaces",
    })
      .then((res) => {
        this.setState({
          nodes: res.data.map((w: any) => {
            return {
              key: w.id,
              label: w.name,
              data: "workspace",
              icon: "pi pi-fw pi-folder",
              leaf: false,
              path: w.permalink,
              selectable: false,
            };
          }),
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  loadSheets() {
    // Load sheets not in a workspace
    callAPI("actions/order", "POST", {
      action: "get_smartsheet_sheets",
    })
      .then((res) => {
        this.setState({
          sheets: res.data.map((w: any) => {
            return {
              key: w.id,
              label: w.name,
              data: "sheet",
              icon: "pi pi-fw pi-file",
              leaf: true,
              path: w.permalink,
              selectable: true,
            };
          }),
          loading: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  loadOnExpand(event: any) {
    const node = event.node;
    if (!node.children) {
      this.setState({
        loading: true,
      }, () => {
        callAPI("actions/order", "POST", {
          action: node.data === "workspace" ?
            "get_smartsheet_workspace_contents" :
            "get_smartsheet_folder_contents",
          id: node.key,
        }).then((res) => {
          if (res.folders || res.sheets || res.reports) {
            this.setState({
              loading: false,
              nodes: this.state.nodes.map((node) => {
                if (node.key === event.node.key) {
                  const folders = res.folders?.map(
                    (folder: any, index: number) => {
                      return {
                        key: folder.id,
                        label: folder.name,
                        data: "folder",
                        icon: "pi pi-fw pi-folder",
                        leaf: false,
                        path: folder.permalink,
                        selectable: this.props.selectOption === "folders",
                      };
                    }
                  ) || [];
                  const sheets = res.sheets?.map(
                    (sheet: any, index: number) => {
                      return {
                        key: sheet.id,
                        label: sheet.name,
                        data: "sheet",
                        icon: "pi pi-fw pi-file",
                        leaf: true,
                        path: sheet.permalink,
                        selectable: this.props.selectOption === "sheets",
                      };
                    }
                  ) || [];
                  if (this.props.selectOption === "folders") {
                    node.children = folders;
                  } else {
                    node.children = [...folders, ...sheets];
                  }
                  if (node.children.length === 0) {
                    delete node.children;
                    node.leaf = true;
                  }
                }
                return node;
              }),
            });
          } else {
            console.log(res);
            toast.error("Error loading folders");
            this.setState({
              loading: false,
            });
          }
        })
          .catch((err) => {
            this.setState({
              loading: false,
              error: "Failed to connect to Smartsheet. Check that you have your access token set."
            });
          });
      }
      );
    }
  }

  private findNodeByKey(key: any) {
    let node = this.state.sheets.find((n) => n.key === key);
    if (node) {
      return node;
    }
    for (let i = 0; i < this.state.nodes.length; i++) {
      node = this.findNodeByKeyRecursive(this.state.nodes[i], key);
      if (node) {
        return node;
      }
    }
    return null;
  }

  private findNodeByKeyRecursive(node: any, key: any): any {
    if (node.key === key) {
      return node;
    }
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        const n = this.findNodeByKeyRecursive(node.children[i], key);
        if (n) {
          return n;
        }
      }
    }
    return null;
  }

  render() {
    if (this.state.error) {
      return <div className="alert alert-danger" role="alert">
        {this.state.error}
      </div>;
    }
    return (
      <Tree
        value={[
          ...this.state.nodes,
          {
            label: "Sheets",
            icon: "pi pi-fw pi-folder",
            children: this.state.sheets,
            leaf: false
          },
        ]}
        selectionMode="single"
        selectionKeys={this.state.selectedKey}
        onSelectionChange={(e) => {
          this.setState({ selectedKey: e.value });
          const node = this.findNodeByKey(e.value);
          if (node?.selectable) {
            this.props.onChange(node);
          } else {
            this.props.onChange(null);
          }
        }}
        onSelect={(n: any) => {
          this.setState({ path: n.node.path });
        }}
        onUnselect={() => {
          this.setState({ path: "" });
        }}
        onExpand={(e) => {
          this.loadOnExpand(e);
        }}
        loading={this.state.loading}
      />
    );
  }
}

export default SmartsheetPicker;
