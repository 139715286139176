import { Component } from "react";
import { ORDER_TYPES } from "./RequirementsChart";

interface OrderTypesTogglerProps {
  defaultValues: { [key: string]: boolean };
  onChange: (values: { [key: string]: boolean }) => void;
  orderTypes?: string[];
}

interface OrderTypesTogglerState {
  values: { [key: string]: boolean };
}

class OrderTypesToggler extends Component<
  OrderTypesTogglerProps,
  OrderTypesTogglerState
> {
  constructor(props: OrderTypesTogglerProps) {
    super(props);
    this.state = {
      values: { ...props.defaultValues },
    };
  }

  componentDidUpdate(prevProps: Readonly<OrderTypesTogglerProps>): void {
    if (prevProps.defaultValues !== this.props.defaultValues) {
      this.setState({ values: { ...this.props.defaultValues } });
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row" style={{ margin: "0 auto" }}>
            {(this.props.orderTypes || ORDER_TYPES).map((orderType) => (
              <div
                className="form-check form-switch"
                style={{ margin: "10px", width: "auto" }}
                title="Show/Hide data from Orders"
                key={orderType}
              >
                <input
                  checked={this.state.values[orderType]}
                  onChange={(e) => {
                    this.setState({
                      values: {
                        ...this.state.values,
                        [orderType]: e.target.checked,
                      },
                    });
                    this.props.onChange({
                      ...this.state.values,
                      [orderType]: e.target.checked,
                    });
                  }}
                  type="checkbox"
                  className="form-check-input"
                  id={orderType}
                />
                <label className="form-check-label" htmlFor={orderType}>
                  {orderType}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default OrderTypesToggler;
