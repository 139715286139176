import { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { callAPIWithCache } from "../../utils/API";
import IssuePage from "./IssuePage";
import "./Issues.css";
import UserContext from "../ui/UserContext";

interface IssuesProps {
  id?: number;
  closed?: boolean;
}

interface IssuesState {
  data: any;
  num_open: number;
  num_closed: number;
}

export function relativeTime(timestamp: string): string {
  var now = new Date();
  var then = new Date(timestamp);
  var diff = now.getTime() - then.getTime();
  var seconds = Math.round(diff / 1000);
  var minutes = Math.round(seconds / 60);
  var hours = Math.round(minutes / 60);
  var days = Math.round(hours / 24);
  var years = Math.round(days / 365);
  if (years > 50) {
    return "never";
  }
  return years > 1
    ? years + " years ago"
    : years === 1
      ? "1 year ago"
      : days > 1
        ? days + " days ago"
        : days === 1
          ? "1 day ago"
          : hours > 1
            ? hours + " hours ago"
            : hours === 1
              ? "1 hour ago"
              : minutes > 1
                ? minutes + " minutes ago"
                : minutes === 1
                  ? "1 minute ago"
                  : seconds > 1
                    ? seconds + " seconds ago"
                    : seconds === 1
                      ? "1 second ago"
                      : "just now";
}

export function fixText(str: string): string {
  if (!str) return "";
  return str
    .replaceAll("&gt;", ">")
    .replaceAll("&lt;", "<")
    .replaceAll("&amp;", "&")
    .replaceAll("&quot;", '"')
    .replaceAll("&#039;", "'")
    .replaceAll("&nbsp;", " ");
}

class IssuesPageWithID extends Component<IssuesProps, IssuesState> {
  constructor(props: IssuesProps) {
    super(props);
    this.state = {
      data: [],
      num_open: 0,
      num_closed: 0,
    };
  }

  loadData() {
    const cacheResult = callAPIWithCache(this.props.closed ? "issues/closed" : "issues", "GET");
    if (cacheResult.cache && cacheResult.cache.data) {
      this.setState({
        data: cacheResult.cache.data.issues,
        num_open: cacheResult.cache.data.num_open,
        num_closed: cacheResult.cache.data.num_closed,
      });
    }
    cacheResult.result.then((response) => {
      response.issues.forEach((issue: any) => {
        issue.title = fixText(issue.title);
      });
      this.setState({
        data: response.issues,
        num_open: response.num_open,
        num_closed: response.num_closed,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: IssuesProps) {
    if (prevProps.closed !== this.props.closed) {
      this.loadData();
    }
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Issues</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  {!user?.isReadOnly && (
                    <Link
                      to="/issues/add"
                      className="btn btn-sm btn-outline-secondary"
                    >
                      Add New
                    </Link>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div className="Box mt-3 Box--responsive hx_Box--firstRowRounded0">
              <div className="Box-header d-flex flex-justify-between">
                <div className="mr-3 d-none d-md-block"></div>

                <div className="table-list-filters flex-auto d-flex min-width-0">
                  <div className="flex-auto d-none d-lg-block no-wrap">
                    <div className="table-list-header-toggle states flex-auto ps-0">
                      <Link className="btn-link text-decoration-none" to="/issues">
                        <span>{this.state.num_open}</span> Open
                      </Link>

                      <Link className="btn-link text-decoration-none" to="/issues/closed">
                        <span>{this.state.num_closed}</span> Closed
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div aria-label="Issues" role="group">
                {!this.props.id && (
                  <div className="js-navigation-container js-active-navigation-container">
                    {this.state.data.map((item: any) => {
                      item.opened_by = "unknown user";
                      let title = item.title.split(" - ");
                      if (title.length > 1) {
                        item.opened_by = title[1].split(":")[0];
                      }
                      return (
                        <div
                          className="Box-row Box-row--focus-gray p-0 mt-0 js-navigation-item js-issue-row"
                          key={item.id}
                        >
                          <div className="d-flex Box-row--drag-hide position-relative">
                            <div
                              className="flex-shrink-0 pt-2 ps-3"
                              style={{ color: "green" }}
                            ></div>
                            <div className="flex-auto min-width-0 p-2 pe-3 pe-md-2">
                              <Link
                                className="link-gray-dark v-align-middle no-underline fw-bold"
                                to={"/issues/" + item.number}
                              >
                                {item.title}
                              </Link>{" "}
                              <span className="labels lh-default d-block d-md-inline">
                                {item.labels.map((label: any) => (
                                  <button
                                    className="d-inline-block IssueLabel"
                                    style={{
                                      backgroundColor: "#" + label.color,
                                      color: "#000000",
                                    }}
                                    title={label.name}
                                    key={label.id}
                                  >
                                    {label.name}
                                  </button>
                                ))}
                              </span>
                              <div className="mt-1 text-small text-gray">
                                <span className="opened-by">
                                  #{item.number} opened{" "}
                                  {relativeTime(item.created_at)} by {item.opened_by}.
                                  Last updated {relativeTime(item.updated_at)}.
                                </span>
                              </div>
                            </div>
                            <div className="flex-shrink-0 col-3 pt-2 text-right pe-3 no-wrap d-flex hide-sm ">
                              <span className="ms-2 flex-1 flex-shrink-0"></span>
                              <span className="ml-2 flex-1 flex-shrink-0">
                                <div className="AvatarStack AvatarStack--right ms-2 flex-1 flex-shrink-0 ">
                                  <div
                                    className="AvatarStack-body tooltipped tooltipped-sw tooltipped-multiline tooltipped-align-right-1 mt-1"
                                    aria-label="Assigned to "
                                  ></div>
                                </div>
                              </span>
                            </div>
                            <Link
                              className="d-block d-md-none position-absolute top-0 bottom-0 left-0 right-0"
                              aria-label={item.title}
                              to={"/issues/" + item.id}
                            ></Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {!!this.props.id && (
                  <IssuePage id={this.props.id} />
                )}
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

function IssuesPage() {
  const { id } = useParams();
  if (id === "closed") {
    return <IssuesPageWithID closed />;
  } else if (!id) {
    return <IssuesPageWithID />;
  }
  return <IssuesPageWithID id={parseInt(id || "")} />;
}

export default IssuesPage;
