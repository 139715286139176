import { Component } from "react";
import { callAPI } from "../../utils/API";
import toast from "react-hot-toast";
import CustomEditor from "../ui/ckeditor/CustomEditor";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface AddIssuePageProps { }

interface AddIssuePageState {
  title: string;
  body: string;
  priority: string;
  topic: string;
  type: "Bug/Issue" | "Feature Request";
}

class AddIssuePage extends Component<AddIssuePageProps, AddIssuePageState> {
  constructor(props: AddIssuePageProps) {
    super(props);
    this.state = {
      title: "",
      body: "",
      priority: "3",
      topic: "none",
      type: "Bug/Issue",
    };
  }

  submitIssue() {
    callAPI("issues", "POST", {
      action: "add",
      title: this.state.title,
      body: this.state.body,
      priority: this.state.priority,
      topic: this.state.topic,
      type: this.state.type,
    })
      .then((response) => {
        toast.success("Issue created successfully!");
        window.history.back();
      })
      .catch(() => {
        toast.error("Failed to create issue");
      });
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">New Issue</h1>
        </div>
        <div className="row">
          <div className="col-12 mb-1">
            <TextField
              fullWidth
              className="mb-2"
              placeholder="Title"
              value={this.state.title}
              onChange={(e) => this.setState({ title: e.target.value })}
            />
          </div>
          <div className="col-12 mb-1">
            <CustomEditor
              data={this.state.body}
              onChange={(data) => {
                this.setState({ body: data });
              }}
            />
          </div>
          <div className="col-4 my-1">
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                label="Priority"
                className="mb-2"
                value={this.state.priority}
                onChange={(e) => this.setState({ priority: e.target.value })}
              >
                <MenuItem value="1">Priority 1</MenuItem>
                <MenuItem value="2">Priority 2</MenuItem>
                <MenuItem value="3">Priority 3</MenuItem>
                <MenuItem value="4">Priority 4</MenuItem>
                <MenuItem value="5">Priority 5</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-4 my-2">
            <FormControl fullWidth>
              <InputLabel>Topic</InputLabel>
              <Select
                label="Topic"
                className="mb-2"
                value={this.state.topic}
                onChange={(e) => this.setState({ topic: e.target.value })}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="dashboard-page">Dashboard</MenuItem>
                <MenuItem value="orders-page">Orders Page</MenuItem>
                <MenuItem value="products-page">Products Page</MenuItem>
                <MenuItem value="inventory-page">Inventory Page</MenuItem>
                <MenuItem value="builds-page">Builds Page</MenuItem>
                <MenuItem value="price-list-page">Price List Page</MenuItem>
                <MenuItem value="bizdev-page">BizDev Page</MenuItem>
                <MenuItem value="customers-page">Customers Page</MenuItem>
                <MenuItem value="revenue-page">Revenue Page</MenuItem>
                <MenuItem value="account-page">Account Page</MenuItem>
                <MenuItem value="products-db-page">Products DB Page</MenuItem>
                <MenuItem value="software-page">Software Page</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-4 my-2">
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                className="mb-2"
                value={this.state.type}
                onChange={(e) => this.setState({ type: e.target.value as any })}
              >
                <MenuItem value="Bug/Issue">Bug/Issue</MenuItem>
                <MenuItem value="Feature Request">Feature Request</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="d-flex w-100 gap-2 justify-content-end mt-2">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              this.submitIssue();
            }}
            disabled={this.state.title.length === 0}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default AddIssuePage;
