import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

interface ItemTreeViewProps {
  items: any[];
}

class ItemTreeView extends React.Component<ItemTreeViewProps> {
  render() {
    return (
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      >
        {this.props.items
          .filter((item) => !item.item_number.includes('.'))
          .map((item) => {
            const children = this.props.items.filter((i) => {
              const regex = new RegExp(`^${item.item_number}\\.[0-9]+$`);
              return regex.test(i.item_number);
            });
            return (
              <TreeItem nodeId={item.id} label={item.item_number + ": " + item.ltc_pn} key={item.id}>
                {children.map((child) => {
                  return (
                    <TreeItem nodeId={child.id} label={child.item_number + ": " + item.ltc_pn}>
                      {this.props.items
                        .filter((grandchild) =>
                          grandchild.item_number.includes(child.item_number + '.')
                        )
                        .map((grandchild) => {
                          return (
                            <TreeItem
                              nodeId={grandchild.id}
                              label={grandchild.item_number + ": " + item.ltc_pn}
                            />
                          );
                        })}
                    </TreeItem>
                  );
                })}
              </TreeItem>
            );
          })}
      </TreeView>
    );
  }
}

export default ItemTreeView;