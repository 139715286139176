import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";

interface PriceTierManagerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  priceTiers: { name: string; value: number; }[];
}

interface PriceTierManagerModalState {
  priceTierBeingEdited: string; // name of price tier being edited
  priceTierBeingEditedValue: string;
  priceTierBeingAdded: boolean;
  priceTierBeingAddedName: string;
  priceTierBeingAddedValue: string;
}

class PriceTierManagerModal extends Component<
  PriceTierManagerModalProps,
  PriceTierManagerModalState
> {
  constructor(props: PriceTierManagerModalProps) {
    super(props);
    this.state = {
      priceTierBeingEdited: "",
      priceTierBeingEditedValue: "",
      priceTierBeingAdded: false,
      priceTierBeingAddedName: "",
      priceTierBeingAddedValue: "",
    };
  }

  addPriceTier() {
    callAPI("actions/price-tiers", "POST", {
      action: "add",
      name: this.state.priceTierBeingAddedName,
      value: this.state.priceTierBeingAddedValue,
    })
      .then((res) => {
        this.props.onSave();
        this.setState({
          priceTierBeingAdded: false,
          priceTierBeingAddedName: "",
          priceTierBeingAddedValue: "",
        });
      })
      .catch((err) => {
        toast.error("Error adding price tier");
        console.log(err);
      });
  }

  saveEdits() {
    callAPI("actions/price-tiers", "POST", {
      action: "update",
      name: this.state.priceTierBeingEdited,
      value: this.state.priceTierBeingEditedValue,
    })
      .then((res) => {
        this.props.onSave();
        this.setState({
          priceTierBeingEdited: "",
          priceTierBeingEditedValue: "",
        });
      })
      .catch((err) => {
        toast.error("Error editing price tier");
        console.log(err);
      });
  }

  deletePriceTier() {
    if (!window.confirm("Are you sure you want to delete the price tier \"" + this.state.priceTierBeingEdited + "\"?")) {
      return;
    }
    callAPI("actions/price-tiers", "POST", {
      action: "delete",
      name: this.state.priceTierBeingEdited,
    })
      .then((res) => {
        this.props.onSave();
        this.setState({
          priceTierBeingEdited: "",
          priceTierBeingEditedValue: "",
        });
      })
      .catch((err) => {
        toast.error("Error deleting price tier");
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Box sx={generateModalStyle("80%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Manage Price Tiers
          </Typography>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.priceTiers.map((priceTier) => {
                return (
                  <tr key={priceTier.name}>
                    <td>{this.state.priceTierBeingEdited === priceTier.name ? (<input
                      type="text"
                      className="form-control"
                      value={this.state.priceTierBeingEdited}
                      disabled
                    />) : priceTier.name}</td>
                    <td>{this.state.priceTierBeingEdited === priceTier.name ? (<input
                      type="number"
                      className="form-control"
                      value={this.state.priceTierBeingEditedValue}
                      onChange={(e) => {
                        this.setState({
                          priceTierBeingEditedValue: e.target.value,
                        });
                      }}
                    />) : priceTier.value}</td>
                    {(priceTier.name === "GSA" || priceTier.name === "US Price") ? <td /> :
                      this.state.priceTierBeingEdited === priceTier.name ? (
                        <td>
                          <button
                            className="btn btn-sm btn-success me-2"
                            onClick={() => {
                              this.saveEdits();
                            }}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                              this.deletePriceTier();
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      ) : (
                        <td>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              this.setState({
                                priceTierBeingEdited: priceTier.name,
                                priceTierBeingEditedValue: priceTier.value.toString(),
                              });
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      )}
                  </tr>
                );
              })}
              {this.state.priceTierBeingAdded && (
                <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.priceTierBeingAddedName}
                      onChange={(e) => {
                        this.setState({
                          priceTierBeingAddedName: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={this.state.priceTierBeingAddedValue}
                      onChange={(e) => {
                        this.setState({
                          priceTierBeingAddedValue: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-success me-2"
                      onClick={() => {
                        this.addPriceTier();
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        this.setState({
                          priceTierBeingAdded: false,
                          priceTierBeingAddedName: "",
                          priceTierBeingAddedValue: "",
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.onClose();
              }}
            >
              Close
            </button>
            {!this.state.priceTierBeingAdded && (
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  this.setState({
                    priceTierBeingAdded: true,
                  });
                }}
              >
                Add Price Tier
              </button>
            )}
          </div>
        </Box>
      </Modal>
    );
  }
}

export default PriceTierManagerModal;
