import { Component } from "react";
import { callAPI } from "../../utils/API";
import toast from "react-hot-toast";

interface DevelopmentProps { }

interface DevelopmentState { }


class Development extends Component<DevelopmentProps, DevelopmentState> {
  constructor(props: DevelopmentProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="container px-4 py-5" id="hanging-icons">
          <h2 className="pb-2 border-bottom">Developer Tools</h2>
          <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
            {PAGES.map((page) => (
              <div className="col d-flex align-items-start" key={page.name}>
                <div className="icon-square bg-light text-dark flex-shrink-0 me-3">
                  <i className={"bi bi-" + page.icon}></i>
                </div>
                <div>
                  <h2>{page.name}</h2>
                  <p>{page.description}</p>
                  <button className="btn btn-primary" onClick={page.action}>
                    Run
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  static updateAllOrderTotals() {
    const promise = callAPI("actions/order", "POST", {
      action: "update_order_totals",
    });
    toast.promise(promise, {
      loading: "Updating order totals, this will take a while...",
      success: "Order totals updated successfully!",
      error: "Failed to update order totals.",
    });
  }

  static updateAllKitPrices() {
    const promise = callAPI("actions/product", "POST", {
      action: "update_kit_price",
    });
    toast.promise(promise, {
      loading: "Updating kit prices...",
      success: "Kit prices updated successfully!",
      error: "Failed to update kit prices.",
    });
  }

  static syncPriceHistory() {
    const promise = callAPI("actions/product", "POST", {
      action: "sync_price_history",
    });
    toast.promise(promise, {
      loading: "Syncing price history...",
      success: "Price history synced successfully!",
      error: "Failed to sync price history.",
    });
  }
}

const PAGES = [
  {
    name: "Update Order Totals",
    icon: "code-slash",
    description:
      "Use this tool to manually update all order totals using the order contents. This will take 20-30 seconds to complete.",
    action: Development.updateAllOrderTotals,
  },
  {
    name: "Update Kit Prices",
    icon: "code-slash",
    description:
      "Use this tool to manually update all kit prices using the kit's child items.",
    action: Development.updateAllKitPrices,
  },
  // {
  //   name: "Sync Price History",
  //   icon: "code-slash",
  //   description:
  //     "Copy over the old price history from admin_prod.",
  //   action: Development.syncPriceHistory,
  // },
  {
    name: "PHPMyAdmin",
    icon: "code-slash",
    description:
      "Use this tool to make manual changes to the database. Be careful!",
    action: () => { window.open("https://pma.laser-ndt.org", "_blank"); }
  },
];

export default Development;
