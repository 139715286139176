import { Component } from "react";
import { Link } from "react-router-dom";
import ProjectedBookingsTable from "./ProjectedBookingsTable";
import RevenueTableModal from "../revenue/RevenueTableModal";
import ProjectedBookingsChart from "./ProjectedBookingsChart";

interface ProjectedBookingsProps { }

interface ProjectedBookingsState {
  type: string;
  revenueTableMonthSelected: number;
}

class ProjectedBookings extends Component<
  ProjectedBookingsProps,
  ProjectedBookingsState
> {
  constructor(props: ProjectedBookingsProps) {
    super(props);
    this.state = {
      type: "Projection",
      revenueTableMonthSelected: 0,
    };
  }
  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Projected Bookings</h1>

          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2" role="group">
              <div className="btn-group" role="group">
                <button
                  className="btn btn-sm btn-outline-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {this.state.type}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        this.setState({ type: "Projection" });
                      }}
                    >
                      Projection
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => {
                        this.setState({ type: "This Year" });
                      }}
                    >
                      This Year
                    </button>
                  </li>
                </ul>
              </div>
              <Link to="/revenue" className="btn btn-sm btn-outline-secondary">
                Switch to Revenue
              </Link>
            </div>
          </div>
        </div>
        <ProjectedBookingsChart type={this.state.type} />
        <ProjectedBookingsTable type={this.state.type} onMonthClick={(month) => {
          this.setState({ revenueTableMonthSelected: month });
        }} />
        <RevenueTableModal
          month={this.state.revenueTableMonthSelected}
          onClose={() => {
            this.setState({ revenueTableMonthSelected: 0 });
          }}
        />
      </div>
    );
  }
}

export default ProjectedBookings;
