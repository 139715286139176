import { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AccountPage from "../account/AccountPage";
import BizDev from "../bizdev/BizDev";
import BizDevOrder from "../bizdev/BizDevOrder";
import Builds from "../builds/Builds";
import CustomerPage from "../customers/CustomerPage";
import Customers from "../customers/Customers";
import Dashboard from "../dashboard/Dashboard";
import AdminPage from "../dev/admin/AdminPage";
import Development from "../dev/Development";
import Inventory from "../inventory/Inventory";
import AddIssuePage from "../issues/AddIssuePage";
import Order from "../orders/Order";
import OrderItem from "../orders/EditOrderItem";
import Orders from "../orders/Orders";
import PriceList from "../price-list/PriceList";
import Products from "../products/Products";
import ViewProduct from "../products/ViewProduct";
import ProjectedBookings from "../projected-bookings/ProjectedBookings";
import ProjectionsSummary from "../projections-summary/ProjectionsSummary";
import Revenue from "../revenue/Revenue";
import Settings from "../settings/SettingsPage";
import ProductsDB from "../products-database/ProductsDB";
import Software from "../software/SoftwarePage";
import ComputerAudit from "../dev/computers/ComputerAudit";
import IssuesPage from "../issues/Issues";
import DomainAudit from "../dev/computers/DomainAudit";
import BuildPage from "../builds/Build";

interface MainUiProps {
  searchTerm?: string;
}

interface MainUiState {
  error: Error | null;
}

class MainUi extends Component<MainUiProps, MainUiState> {
  constructor(props: MainUiProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <main id="capture" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <div className="alert alert-danger mt-4" role="alert">
            <h4 className="alert-heading">Something went wrong.</h4>
            <p>
              Please contact the developer with the following information:
              <br />
              <code>{this.state.error.message}</code>
              <br />
              <code>{this.state.error.stack}</code>
            </p>
          </div>
        </main>
      );
    }

    return (
      <main id="capture" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/orders"
            element={<Orders searchTerm={this.props.searchTerm} />}
          />
          <Route path="/orders/:id" element={<Order />} />
          <Route
            path="/orders/:orderId/items/:itemNumber"
            element={<OrderItem />}
          />
          <Route
            path="/products"
            element={<Products searchTerm={this.props.searchTerm} />}
          />
          <Route path="/products/:id" element={<ViewProduct />} />
          <Route
            path="/inventory"
            element={<Inventory searchTerm={this.props.searchTerm} />}
          />
          <Route
            path="/builds"
            element={<Builds searchTerm={this.props.searchTerm} />}
          />
          <Route path="/builds/:id" element={<BuildPage />} />
          <Route path="/price-list" element={<PriceList searchTerm={this.props.searchTerm} />} />
          <Route path="/price-list/:id" element={<ViewProduct prices />} />
          <Route path="/bizdev" element={<BizDev searchTerm={this.props.searchTerm} />} />
          <Route path="/bizdev/:id" element={<BizDevOrder />} />
          <Route
            path="/bizdev/:orderId/items/:itemNumber"
            element={<OrderItem prices />}
          />
          <Route
            path="/customers"
            element={<Customers searchTerm={this.props.searchTerm} />}
          />
          <Route path="/customers/:id" element={<CustomerPage />} />
          <Route
            path="/customers/:id/end_users/:endUserID"
            element={<CustomerPage />}
          />
          <Route
            path="/software"
            element={<Software searchTerm={this.props.searchTerm} />}
          />
          <Route
            path="/products-database"
            element={<ProductsDB searchTerm={this.props.searchTerm} />}
          />
          <Route path="/revenue" element={<Revenue />} />
          <Route path="/projected-bookings" element={<ProjectedBookings />} />
          <Route path="/projections-summary" element={<ProjectionsSummary />} />
          <Route path="/development" element={<Development />} />
          <Route path="/admin" element={<AdminPage searchTerm={this.props.searchTerm} />} />
          <Route path="/issues" element={<IssuesPage />} />
          <Route path="/issues/:id" element={<IssuesPage />} />
          <Route path="/issues/add" element={<AddIssuePage />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/account" element={<AccountPage />} />
          <Route
            path="/computers"
            element={<ComputerAudit searchTerm={this.props.searchTerm} />}
          />
          <Route
            path="/computers/domain-audit"
            element={<DomainAudit searchTerm={this.props.searchTerm} />}
          />
        </Routes>
      </main>
    );
  }
}

export default MainUi;
