import { Component } from "react";
import toast from "react-hot-toast";
import { API_DOMAIN, callAPI, User } from "../../utils/API";
import { relativeTime } from "../issues/Issues";
import CustomEditor from "../ui/ckeditor/CustomEditor";
import UserContext from "../ui/UserContext";
import { TextField } from "@mui/material";

interface OrderCommentsProps {
  orderId: number;
  prices?: boolean;
  onExpandBtnClick: () => void;
  expanded?: boolean;
  disableCollapse?: boolean;
  onUpdate: () => void;
}

interface OrderCommentsState {
  comments: any[];
  newCommentText: string;
}

class OrderComments extends Component<OrderCommentsProps, OrderCommentsState> {
  constructor(props: OrderCommentsProps) {
    super(props);
    this.state = {
      comments: [],
      newCommentText: "",
    };
  }

  loadData() {
    callAPI("tables/order_comments_view", "POST", {
      where: {
        order_id: this.props.orderId,
      },
    })
      .then((res) => {
        this.setState({
          comments: res,
        });
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading order comments");
          console.log(data);
        }
      });
  }

  componentDidMount() {
    this.loadData();
  }

  addComment() {
    if (!this.state.newCommentText) {
      return;
    }
    callAPI("actions/order", "POST", {
      action: "add_comment",
      order_id: this.props.orderId,
      comment: this.state.newCommentText,
    })
      .then((res) => {
        this.setState({
          newCommentText: "",
        });
        this.loadData();
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error adding comment");
          console.log(data);
        }
      });
  }

  deleteComment(commentId: number) {
    if (!window.confirm("Are you sure you want to delete this comment?")) {
      return;
    }
    callAPI("actions/order", "POST", {
      action: "delete_comment",
      comment_id: commentId,
    })
      .then((res) => {
        this.loadData();
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error deleting comment");
          console.log(data);
        }
      });
  }

  changeQuoteType(type: string) {
    callAPI("actions/order", "POST", {
      action: "update",
      order_id: this.props.orderId,
      data: { quote_type: type },
    })
      .then((res) => {
        toast.success("Quote type changed");
        this.props.onUpdate();
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error changing quote type");
          console.log(data);
        }
      });
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div className={this.props.expanded ? "col-12" : "col-sm-12 col-md-6"}>
            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <div
                  className="card shadow-0 border order-comment-card"
                  style={this.props.expanded ? { maxHeight: "inherit" } : {}}
                >
                  <div className="card-header">
                    {!this.props.disableCollapse && (
                      <button
                        className="btn btn-sm btn-outline-primary float-end"
                        onClick={() => {
                          this.props.onExpandBtnClick();
                        }}
                      >
                        {this.props.expanded ? "Collapse" : "Expand"}
                      </button>
                    )}
                  </div>
                  <div className="card-body p-4 d-flex flex-column gap-2">
                    {!this.props.expanded && !user?.isReadOnly && (
                      <TextField
                        fullWidth
                        placeholder="Type comment..."
                        value={this.state.newCommentText}
                        onChange={(e) => {
                          this.setState({
                            newCommentText: e.target.value,
                          });
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            this.addComment();
                          }
                        }}
                      />
                    )}
                    {this.state.comments &&
                      this.state.comments.sort((a, b) => {
                        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
                      }).map((comment, i) => (
                        <div className="card" key={"comment" + i}>
                          <div className="card-body">
                            {comment.user_id === User.getID() && (
                              <span
                                title="Delete Comment"
                                className="del-btn"
                                onClick={() => {
                                  this.deleteComment(comment.id);
                                }}
                              >
                                ×
                              </span>
                            )}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: comment.content,
                              }}
                            ></div>

                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-row align-items-center">
                                <img
                                  src={API_DOMAIN + comment.profile_pic}
                                  className="avatar avatar-user me-2"
                                  alt="avatar"
                                  width="25"
                                  height="25"
                                  onError={(e) => {
                                    (e.target as HTMLImageElement).src =
                                      "/assets/img/profile-picture.jfif";
                                  }}
                                />
                                <p className="small mb-0 ms-2">{comment.name}</p>
                              </div>
                              <div className="d-flex flex-row align-items-center">
                                <p className="small text-muted mb-0">
                                  {relativeTime(comment.timestamp)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {this.props.expanded && !user?.isReadOnly && (
                      <CustomEditor
                        data={this.state.newCommentText}
                        onChange={(data) => {
                          this.setState({ newCommentText: data });
                        }}
                      />
                    )}
                    <div className="mt-2 d-flex justify-content-end">
                      {this.props.expanded && this.props.disableCollapse && !!user?.isEditor && (
                        <button
                          className="btn btn-sm btn-outline-secondary me-2"
                          onClick={() => {
                            this.changeQuoteType("COST_ESTIMATE");
                          }}
                        >
                          Convert to Cost Estimate
                        </button>
                      )}
                      {this.props.expanded && !user?.isReadOnly && (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            this.addComment();
                          }}
                        >
                          Add Comment
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default OrderComments;
