import { Component } from "react";
import toast from "react-hot-toast";
import { API_DOMAIN, callAPI } from "../../../utils/API";
import { relativeTime } from "../../issues/Issues";
import "./Accounts.css";
import EditLaborRatesModal from "./EditLaborRatesModal";
import EditOverheadRatesModal from "./EditOverheadRatesModal";
import EditUserModal from "./EditUserModal";
import SmartsheetSettings from "./SmartsheetSettings";

interface AdminPageProps {
  searchTerm?: string;
}

interface AdminPageState {
  users: any[];
  userSelected: any;
  overheadRatesModalOpen: boolean;
  laborRatesModalOpen: boolean;
}

function getPermissionSummary(user: any): string {
  let permissions = [];
  if (user.isAdmin === "1") {
    permissions.push("Admin");
  }
  if (user.isComputerAdmin === "1") {
    permissions.push("Computer Admin");
  }
  if (user.financeAccess === "1") {
    permissions.push("Finance Access");
  }
  if (user.priceAccess === "1") {
    permissions.push("Price Access");
  }
  if (user.isEditor === "1") {
    permissions.push("Editor");
  } else {
    permissions.push("Viewer");
  }
  return permissions.join(", ");
}

class AdminPage extends Component<AdminPageProps, AdminPageState> {
  constructor(props: AdminPageProps) {
    super(props);
    this.state = {
      users: [],
      userSelected: null,
      overheadRatesModalOpen: false,
      laborRatesModalOpen: false,
    };
  }

  loadData() {
    callAPI("tables/users", "POST").then((response) => {
      this.setState({
        users: response,
      });
      if (this.state.userSelected !== null) {
        this.setState({
          userSelected: response.find(
            (user: any) => user.id === this.state.userSelected.id
          ),
        });
      }
    });
  }

  azureSync() {
    // TODO: Implement this
    toast.error("Feature not yet implemented.");
  }

  componentDidMount(): void {
    this.loadData();
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Administration</h1>
        </div>
        <div className="card">
          <div className="card-body">
            <h1 className="h4">Database Management</h1>
            <a
              className="btn btn-sm btn-outline-secondary mb-1"
              href="https://pma.laser-ndt.org/db_export.php?db=admin_prod2"
              target="_blank"
              rel="noreferrer"
            >
              Export Database
            </a>
            <p>
              Exporting the database creates a backup of all MySQL data. Store
              this file in a secure location.
            </p>
            <h1 className="h4 my-2">Overhead Rates</h1>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => {
                this.setState({ overheadRatesModalOpen: true });
              }}
            >
              Edit Overhead Rates
            </button>
            <h1 className="h4 my-2">Labor Rates</h1>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => {
                this.setState({ laborRatesModalOpen: true });
              }}
            >
              Edit Labor Rates
            </button>
            <SmartsheetSettings />
            <h1 className="h4 my-2">Users</h1>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => this.azureSync()}
            >
              Sync Users from Azure AD
            </button>

            <table className="table table-lg">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Last login</th>
                </tr>
              </thead>
              <tbody>
                {this.state.users.filter((user) => {
                  const s = this.props.searchTerm?.toLowerCase();
                  if (!s) return true;
                  return (
                    user.name.toLowerCase().includes(s) ||
                    user.username.toLowerCase().includes(s)
                  );
                }).map((user) => (
                  <tr key={user.id}>
                    <td className="doVMIb XgRaPc ZLxxuf CtN5Z LmV4df fNbT4e">
                      <div className="jAirTd">Name</div>
                      <span>
                        <div className="ryDQid V4TKmb ktNf2c">
                          <div className="YSlEcd lNsEYb">
                            <img
                              className="uReVN"
                              src={
                                API_DOMAIN + user.profile_pic
                              }
                              onError={(e) => {
                                (e.target as HTMLImageElement).src =
                                  "/assets/img/profile-picture.jfif";
                              }}
                              alt="User Icon"
                            />
                          </div>
                          <div className="pLkCzb oJeWuf QLWMfe">
                            <div className="bVd1Ed">
                              <button
                                className="btn btn-link"
                                onClick={() => {
                                  this.setState({
                                    userSelected: user,
                                  });
                                }}
                              >
                                <span className="yJJ7Wd">{user.name}</span>
                              </button>
                            </div>
                          </div>
                          <div className="xPsMrf hEenZe QLWMfe">
                            <div className="bVd1Ed">
                              <span className="yJJ7Wd">{user.name}</span>
                            </div>
                          </div>
                        </div>
                      </span>
                    </td>
                    <td>{user.username + "@laser-ndt.com"}</td>
                    <td>{parseInt(user.enabled) ? "Enabled" : "Disabled"}</td>

                    <td className="doVMIb XgRaPc o5YINe LmV4df fNbT4e">
                      {getPermissionSummary(user)}
                    </td>
                    <td className="doVMIb XgRaPc o5YINe LmV4df fNbT4e">
                      {relativeTime(user.last_login)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <EditUserModal
          user={this.state.userSelected}
          onClose={() => {
            this.setState({ userSelected: null });
          }}
          onSave={() => {
            this.loadData();
          }}
        />
        <EditOverheadRatesModal
          open={this.state.overheadRatesModalOpen}
          onClose={() => {
            this.setState({ overheadRatesModalOpen: false });
          }}
        />
        <EditLaborRatesModal
          open={this.state.laborRatesModalOpen}
          onClose={() => {
            this.setState({ laborRatesModalOpen: false });
          }}
        />
      </div>
    );
  }
}

export default AdminPage;
