import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import AddProductModal from "./AddProductModal";
import ProductSection from "./ProductSection";
import UserContext from "../ui/UserContext";
import PriceTierManagerModal from "./PriceTierManagerModal";

interface ProductsProps {
  searchTerm?: string;
  prices?: boolean;
}

interface ProductsState {
  addModalOpen: boolean;
  priceTierModalOpen: boolean;
  selectedPriceTier: string;
  priceTierValue: number;
  onlyUsePriceTierForGSA: boolean;
  priceTiers: { name: string; value: number; }[];
}

class Products extends Component<ProductsProps, ProductsState> {
  constructor(props: ProductsProps) {
    super(props);
    this.state = {
      addModalOpen: false,
      priceTierModalOpen: false,
      selectedPriceTier: "US Price",
      priceTierValue: 1,
      onlyUsePriceTierForGSA: false,
      priceTiers: [],
    };
  }

  componentDidMount() {
    if (this.props.prices) {
      this.loadPriceTiers();
    }
  }

  loadPriceTiers() {
    callAPI("tables/price_tiers")
      .then((data) => {
        this.setState({
          priceTiers: data,
        });
      })
      .catch((data) => {
        toast.error("Error loading price tiers");
      });
  }

  exportPriceList(priceTierVal=1, priceTierName="USD") {
    callAPI("tables/products_view", "POST")
      .then((data) => {
        // convert JSON to CSV
        const columns = [
          {
            key: "ltc_pn",
            label: "LTC P/N",
          },
          {
            key: "cat_num",
            label: "Catalog Number",
          },
          {
            key: "category",
            label: "Category",
          },
          {
            key: "is_gsa",
            label: "GSA",
          },
          {
            key: "name",
            label: "Description",
          },
          {
            key: "cost",
            label: "Cost",
          },
          {
            key: "price",
            label: "Price",
          },
          {
            key: "price_tier",
            label: `Price (${priceTierName})`
          }
        ];
        let csv = columns.map((column) => column.label).join(",") + "\n";
        data.forEach((row: any) => {
          csv += columns
            .map((column) => {
              if (column.key === "price_tier") {
                if (priceTierName === "GSA" && parseInt(row["is_gsa"]) === 0) {
                  return row["price"];
                }
                return row["price"] * priceTierVal;
              }
              if (row[column.key] === undefined || row[column.key] === null) {
                return "";
              }
              return `"${row[column.key]?.replace(/"/g, '""')}"`;
            })
            .join(",") + "\n";
        });
        let url = window.URL.createObjectURL(
          new Blob([csv], { type: "text/csv" })
        );
        let a = document.createElement("a");
        a.href = url;
        a.download = "price-list-" + new Date().toISOString() + ".csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((data) => {
        console.log(data);
        toast.error("Error downloading price list");
      });
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                {!this.props.prices ? "Products" : "Price List"}
              </h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  {this.props.prices && (
                    <select
                      className="btn btn-sm btn-outline-secondary"
                      value={this.state.selectedPriceTier}
                      onChange={(e) => {
                        const priceTierValue = this.state.priceTiers.find(
                          (tier) => tier.name === e.target.value
                        )?.value;
                        if (!priceTierValue) {
                          console.log(this.state.priceTiers, e.target.value);
                          this.setState({
                            priceTierModalOpen: true,
                          });
                          return;
                        }
                        this.setState({
                          priceTierValue: priceTierValue,
                          selectedPriceTier: e.target.value,
                          onlyUsePriceTierForGSA: e.target.value === "GSA"
                        });
                      }}
                    >
                      {this.state.priceTiers.map((tier) => (
                        <option key={tier.name} value={tier.name}>
                          {tier.name}
                        </option>
                      ))}
                      <option value="0">Manage Price Tiers</option>
                    </select>
                  )}
                  {this.props.prices && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.exportPriceList(this.state.priceTierValue, this.state.selectedPriceTier);
                      }}
                    >
                      Export
                    </button>
                  )}
                  {!user?.isReadOnly && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.setState({
                          addModalOpen: true,
                        });
                      }}
                    >
                      Add New
                    </button>
                  )}
                </div>
              </div>
            </div>
            <ProductSection
              inv_code="KT"
              title="Kits"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            <ProductSection
              inv_code="FG"
              title="Finished Goods"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            <ProductSection
              inv_code="SA"
              title="Subassemblies"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            <ProductSection
              inv_code="LP5K"
              title="LP-5000 Parts"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            <ProductSection
              inv_code="ELEC"
              title="Electrical Parts"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            <ProductSection
              inv_code="MP"
              title="Manufactured Parts"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            <ProductSection
              inv_code="SW"
              title="Software"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            <ProductSection
              inv_code="MS"
              title="Warranties &amp; Service Plans"
              searchTerm={this.props.searchTerm}
              prices={this.props.prices}
              priceTierValue={this.state.priceTierValue}
              onlyUsePriceTierForGSA={this.state.onlyUsePriceTierForGSA}
            />
            {/* <ProductSection
          inv_code="CUST"
          title="Custom"
          searchTerm={this.props.searchTerm}
          prices={this.props.prices}
        /> */}
            <AddProductModal
              isOpen={this.state.addModalOpen}
              onClose={() => {
                this.setState({
                  addModalOpen: false,
                });
              }}
              onSave={() => { }}
              prices={this.props.prices}
            />
            <PriceTierManagerModal
              isOpen={this.state.priceTierModalOpen}
              onClose={() => {
                this.setState({
                  priceTierModalOpen: false,
                });
              }}
              onSave={() => {
                this.loadPriceTiers();
                this.setState({
                  priceTierValue: 1,
                });
              }}
              priceTiers={this.state.priceTiers}
            />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Products;
