import { FC, useEffect, useState } from "react";
import { PRODUCT_CATEGORIES } from "../../utils/Misc";
import { Alert, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, FormHelperText, Button } from "@mui/material";
import ImageUploader from "../../utils/ImageUploader";
import { API_DOMAIN, callAPI } from "../../utils/API";

interface EditProductDetailsProps {
  form: any;
  onChange: (form: any) => void;
  onFormValidation: (valid: boolean) => void;
  prices?: boolean;
}

const EditProductDetails: FC<EditProductDetailsProps> = (props) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const handleChange = (data: { [key: string]: any }) => {
    props.onChange({ ...props.form, ...data });
  };

  const recalculateKitPrice = () => {
    const children = JSON.parse(props.form.children);
    callAPI('tables/products_view', 'POST', { where: { id: children } }).then((res) => {
      let price = 0;
      let cost = 0;
      res.forEach((child: any) => {
        price += parseFloat(child.price);
        cost += parseFloat(child.cost);
      });

      price = Math.round(price * 100) / 100;
      cost = Math.round(cost * 100) / 100;

      handleChange({ price, cost });
    });
  };

  useEffect(() => {
    const newErrors = {
      ltc_pn: props.form.ltc_pn ? "" : "Required",
      cat_num: props.form.cat_num ? "" : "Required",
      inv_code: props.form.inv_code ? "" : "Required",
      name: props.form.name ? "" : "Required",
      category: props.form.category ? "" : "Required",
      price: (!props.prices || props.form.price) ? "" : "Required",
      cost: (!props.prices || props.form.cost) ? "" : "Required",
    };
    setErrors(newErrors);
    props.onFormValidation(Object.values(newErrors).every((v) => v === ""));

  }, [props]);

  return (
    <div className="row">
      <ImageUploader
        value={props.form.image ? API_DOMAIN + props.form.image : ""}
        onChange={(image) => handleChange({ image })}
      />
      {props.prices && props.form.inv_code === "KT" && (
        <Alert severity="info" className="col-12 my-2">
          Kit prices are calculated automatically based on the sum of the
          prices of the components.
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            className="ms-2"
            onClick={() => recalculateKitPrice()}
          >
            Recalculate
          </Button>
        </Alert>
      )}
      <div className="col-4 my-2">
        <TextField
          label="LTC P/N"
          fullWidth
          value={props.form.ltc_pn || ""}
          onChange={(e) => handleChange({ ltc_pn: e.target.value })}
          error={!!errors.ltc_pn}
          helperText={errors.ltc_pn}
        />
      </div>
      <div className="col-4 my-2">
        <TextField
          label="Description"
          fullWidth
          value={props.form.name || ""}
          onChange={(e) => handleChange({ name: e.target.value })}
          error={!!errors.name}
          helperText={errors.name}
        />
      </div>
      <div className="col-4 my-2">
        <TextField
          label="Cat #"
          fullWidth
          value={props.form.cat_num || ""}
          onChange={(e) => handleChange({ cat_num: e.target.value })}
          error={!!errors.cat_num}
          helperText={errors.cat_num}
        />
      </div>
      <div className="col-4 my-2">
        <FormControl fullWidth error={!!errors.category}>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            value={props.form.category || "N/A"}
            onChange={(e) => handleChange({ category: e.target.value })}
          >
            <MenuItem value="N/A">Select Category</MenuItem>
            {PRODUCT_CATEGORIES.map((category) => (
              <MenuItem value={category} key={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
          {errors.category && <FormHelperText>{errors.category}</FormHelperText>}
        </FormControl>
      </div>
      <div className="col-4 my-2">
        <FormControl fullWidth error={!!errors.inv_code}>
          <InputLabel>Inv Code</InputLabel>
          <Select
            label="Inv Code"
            value={props.form.inv_code || "FG"}
            onChange={(e) => handleChange({ inv_code: e.target.value })}
          >
            <MenuItem value="FG">Finished Goods</MenuItem>
            <MenuItem value="KT">Kit</MenuItem>
            <MenuItem value="ELEC">Electrical Part</MenuItem>
            <MenuItem value="MP">Manufactured Part</MenuItem>
            <MenuItem value="LP5K">LP-5000 Parts</MenuItem>
            <MenuItem value="SW">Software</MenuItem>
            <MenuItem value="SA">Subassembly</MenuItem>
            <MenuItem value="MS">Maintenance &amp; Service Plan</MenuItem>
          </Select>
          {errors.inv_code && <FormHelperText>{errors.inv_code}</FormHelperText>}
        </FormControl>
      </div>
      {props.prices && (
        <div className="col-4 my-2">
          <TextField
            label="Price"
            fullWidth
            type="number"
            value={props.form.price || "0"}
            disabled={props.form.inv_code === "KT"}
            onChange={(e) => handleChange({ price: e.target.value })}
            error={!!errors.price}
            helperText={errors.price}
          />
        </div>
      )}
      {props.prices && (
        <div className="col-4 my-2">
          <TextField
            label="Cost"
            fullWidth
            type="number"
            value={props.form.cost || "0"}
            disabled={props.form.inv_code === "KT"}
            onChange={(e) => handleChange({ cost: e.target.value })}
            error={!!errors.cost}
            helperText={errors.cost}
          />
        </div>
      )}
      <div className="col-4 my-2">
        <FormControlLabel
          control={
            <Switch
              checked={parseInt(props.form.standard) === 1}
              onChange={(e) => handleChange({ standard: e.target.checked ? 1 : 0 })}
            />
          }
          label={parseInt(props.form.standard) === 1 ? "Standard" : "Custom"}
        />
      </div>
      {props.prices && (
        <div className="col-4 my-2">
          <FormControlLabel
            control={
              <Switch
                checked={parseInt(props.form.is_gsa) === 1}
                onChange={(e) => handleChange({ is_gsa: e.target.checked ? 1 : 0 })}
              />
            }
            label={parseInt(props.form.is_gsa) === 1 ? "GSA" : "Not GSA"}
          />
        </div>
      )}
      <div className="col-12">
        <TextField
          label="Notes"
          fullWidth
          multiline
          value={props.form.notes || ""}
          onChange={(e) => handleChange({ notes: e.target.value })}
          error={!!errors.notes}
          helperText={errors.notes}
        />
      </div>
    </div>
  );
};

export default EditProductDetails;