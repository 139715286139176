import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Chip, Menu, Typography } from "@mui/material";
import { Component, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { API_DOMAIN, callAPI } from "../../utils/API";
import QuoteHistory from "../bizdev/QuoteHistory";
import QuoteTotals from "../bizdev/QuoteTotals";
import AddOrderItemModal from "./AddOrderItemModal";
import OrderCard from "./OrderCard";
import OrderComments from "./OrderComments";
import OrderContentsTable from "./OrderContentsTable";
import { emphasize, styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuoteViewerModal from "../quotes/QuoteViewerModal";
import QuoteSaveModal from "../bizdev/QuoteSaveModal";
import SelectSmartsheetExportLocationModal from "./SelectSmartsheetExportLocationModal";
import EditOrderDetailsModal from "./EditOrderDetailsModal";
import UserContext from "../ui/UserContext";
import { pdf } from "@react-pdf/renderer";
import BackupPDFGenerator from "../../utils/BackupPDFGenerator";
import { Packer } from "docx";
import saveAs from "file-saver";
import { IllustratedListGenerator } from "../../utils/IllustratedListGenerator";
import { Buffer } from "buffer";
import ReorderItemsModal from "../bizdev/ReorderItemsModal";


function Order() {
  const { id } = useParams();
  return <OrderWithId id={parseInt(id || "0") || 0} />;
}
interface OrderWithIdProps {
  id: number;
  prices?: boolean;
}

interface OrderWithIdState {
  quotePreviewModalOpen: boolean;
  quoteSaveModalOpen: boolean;
  showKitDetails: boolean;

  addingRqmnt: boolean;
  addingRqmntDefaultItemNumber: string;

  commentsView: boolean;
  contents: any[];
  collapsed: boolean;
  order: any | null;
  smartsheetExportModalOpen: boolean;
  editOrderDetailsModalOpen: boolean;
  reorderItemsModalOpen: boolean;

  // for custom backup pdf
  custom_product_labor: any[];
  custom_product_materials: any[];
  custom_product_travel: any[];
}

export class OrderWithId extends Component<OrderWithIdProps, OrderWithIdState> {
  constructor(props: OrderWithIdProps) {
    super(props);
    this.state = {
      quotePreviewModalOpen: false,
      quoteSaveModalOpen: false,
      showKitDetails: this.props.prices || false,

      addingRqmnt: false,
      addingRqmntDefaultItemNumber: "",

      commentsView: false,
      contents: [],
      collapsed: true,
      order: null,
      smartsheetExportModalOpen: false,
      editOrderDetailsModalOpen: false,
      reorderItemsModalOpen: false,

      custom_product_labor: [],
      custom_product_materials: [],
      custom_product_travel: [],
    };
  }

  componentDidMount() {
    this.getOrder();
    this.getOrderContents();
  }

  logOrderVisit() {
    const recentOrders = localStorage.getItem("recentOrders");
    let orders = JSON.parse(recentOrders || "[]");
    if (!Array.isArray(orders)) orders = [];
    orders = orders.filter((o: any) => o.id !== this.props.id);
    orders.unshift({
      id: this.props.id,
      timestamp: new Date().toISOString(),
      name: this.state.order.job_num,
      description: `${this.state.order.customer}\n${this.state.order.description}`
    });
    orders = orders.slice(0, 4);
    localStorage.setItem("recentOrders", JSON.stringify(orders));
  }

  getOrder() {
    callAPI("tables/orders_view", "POST", {
      where: {
        id: this.props.id,
      },
    }).then((response) => {
      this.setState({
        order: response[0],
        commentsView: response[0].quote_type === "COMMUNICATION",
      }, () => {
        this.logOrderVisit();
      });
    });
  }

  getOrderContents() {
    callAPI("tables/order_contents_view", "POST", {
      where: {
        order_id: this.props.id,
      },
    })
      .then((res) => {
        this.setState({
          contents: res,
        });
      })
      .catch((data) => {
        toast.error(data.message || "Error getting order contents");
        console.log(data);
      });
  }


  exportToSmartsheet(folder_id: number) {
    callAPI("actions/order", "POST", {
      action: "export_to_smartsheet",
      destinationId: folder_id,
      order_id: this.props.id,
    })
      .then((res) => {
        const url = res.sheet?.result?.permalink;
        const sheet_id = res.sheet?.result?.id;
        if (url) {
          toast.success(
            <div>
              <span>Exported to Smartsheet</span>
              <a href={url} target="_blank" rel="noreferrer" className="m-1">View Sheet</a>
            </div>
          );
          this.setState({
            smartsheetExportModalOpen: false,
            order: {
              ...this.state.order,
              smartsheet_id: sheet_id,
            },
          });
          window.open(url, "_blank");
        } else {
          throw new Error("No sheet URL returned");
        }
      })
      .catch((data) => {
        toast.error(data.message || "Error exporting to Smartsheet");
        console.log(data);
      });
  }

  async viewBackupPDF() {
    const labor = callAPI("tables/custom_product_labor", "POST", {
      where: {
        order_id: this.props.id,
      }
    });
    const materials = callAPI("tables/custom_product_materials", "POST", {
      where: {
        order_id: this.props.id,
      }
    });
    const travel = callAPI("tables/custom_product_travel", "POST", {
      where: {
        order_id: this.props.id,
      }
    });
    const contents = callAPI("tables/order_contents_view", "POST", {
      where: {
        order_id: this.props.id,
      }
    });
    const customer = callAPI("tables/customers_view", "POST", {
      where: {
        id: this.state.order.customer_id,
      }
    });
    const contact = callAPI("tables/contacts", "POST", {
      where: {
        id: this.state.order.contact_id,
      }
    });
    const labor_rates = callAPI("tables/labor_rates");
    const overhead_rates = callAPI("tables/overhead_rates");
    const [laborData, materialsData, travelData, contentsData, customerData, contactData, laborRatesData, overheadRatesData] = await Promise.all([labor, materials, travel, contents, customer, contact, labor_rates, overhead_rates]);
    // for each order item check if it has custom product data
    const backupData = [];
    for (const item of contentsData) {
      const labor = laborData.filter((l: any) => l.item_id === item.id);
      const materials = materialsData.filter((m: any) => m.item_id === item.id);
      const travel = travelData.find((t: any) => t.item_id === item.id);
      if (labor.length || materials.length || travel) {
        backupData.push({
          task_name: item.name,
          item_number: item.item_number,
          materialsData: materials,
          laborData: labor,
          travelData: travel,
        });
      }
    }
    console.log(backupData);
    const pdfBlob = await pdf(
      <BackupPDFGenerator
        backupData={backupData}
        order={{
          job_number: this.state.order.job_num,
          description: this.state.order.description,
          customer: customerData[0],
          contact: contactData[0],
        }}
        laborRates={laborRatesData}
        overheadRates={overheadRatesData}
      />
    ).toBlob();
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");
  }

  async downloadIllustratedList() {
    const customer = callAPI("tables/customers_view", "POST", {
      where: {
        id: this.state.order.customer_id,
      }
    });
    const contact = callAPI("tables/contacts", "POST", {
      where: {
        id: this.state.order.contact_id,
      }
    });
    const contents = callAPI("tables/order_contents_view", "POST", {
      where: {
        order_id: this.props.id,
      }
    });
    const user = callAPI("tables/users", "POST", {
      where: {
        id: this.state.order.attn,
      }
    });
    const [customerData, contactData, contentsData, userData] = await Promise.all([customer, contact, contents, user]);
    const imageUrls = await callAPI("tables/products_view", "POST", {
      where: {
        id: [...contentsData.map((c: any) => c.product_id)]
      },
      select: "image, id",
    });
    // load all images and get base64 data
    const imageData = await Promise.all(imageUrls.map(async (i: any) => {
      if (!i.image.startsWith("/")) {
        return {
          id: i.id,
          data: null,
          width: 0,
          height: 0,
        };
      }
      const response = await fetch(API_DOMAIN + i.image);
      const blob = await response.blob();
      // convert to Buffer
      const buffer = await blob.arrayBuffer();
      const data = Buffer.from(buffer);
      const { width, height } = await getImageDimensions(data);
      return {
        id: i.id,
        data,
        width,
        height,
      };
    }));
    const gen = new IllustratedListGenerator();
    const doc = gen.create(this.state.order, userData[0], contentsData, imageData, customerData[0], contactData[0]);
    Packer.toBlob(doc).then(async (blob) => {
      saveAs(blob, `${this.state.order.job_num} - Illustrated List.docx`);
    });
  }

  refreshAllPrices() {
    const promise = callAPI("actions/order", "POST", {
      action: "refresh_all_prices",
      order_id: this.props.id,
    });
    toast.promise(promise, {
      loading: "Refreshing prices...",
      success: "Prices refreshed",
      error: "Error refreshing prices",
    });
  }

  updateItemNumbers(items: any[]) {
    let promises: Promise<any>[] = [];
    items.forEach((item: any) => {
      const content = this.state.contents.find((c: any) => c.id === item.id);
      // if item number changed, update it
      if (content && content.item_number !== item.item_number) {
        promises.push(callAPI("actions/order", "POST", {
          action: "update_item_number",
          id: content.id,
          item_number: item.item_number,
        }));
      }
    });
    Promise.all(promises).then(() => {
      this.getOrderContents();
    })
      .catch((data) => {
        toast.error(data.message || "Error updating item numbers");
        console.log(data);
      });
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">{this.props.prices ? (
                this.state.order?.quote_type === "QUOTATION" ? "Quote" : "Estimate"
              ) : "Order"} Details</h1>
              {!this.state.commentsView && (
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    {!user?.isReadOnly && (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          this.setState({ addingRqmnt: true });
                        }}
                      >
                        Add Rqmnt
                      </button>
                    )}
                    {this.props.prices && (
                      <div className="btn-group" role="group">
                        <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Revision
                        </button>
                        <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => {
                              this.setState({ quotePreviewModalOpen: true });
                            }}
                          >
                            Preview PDF
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => {
                              const promise = this.viewBackupPDF();
                              toast.promise(promise, {
                                loading: "Generating Backup PDF...",
                                success: "PDF Loaded",
                                error: "Error loading PDF",
                              });
                            }}
                          >
                            View Backup PDF
                          </button>
                          <button
                            type="button"
                            className="dropdown-item"
                            onClick={() => {
                              this.refreshAllPrices();
                            }}
                          >
                            Refresh All Prices
                          </button>
                        </div>
                      </div>
                    )}
                    {!this.props.prices && (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          this.setState({ smartsheetExportModalOpen: true });
                        }}
                        disabled={!!this.state.order?.smartsheet_id}
                      >
                        Export to Smartsheet
                      </button>
                    )}
                    {!!user?.isEditor && (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          this.setState({
                            editOrderDetailsModalOpen: true,
                          });
                        }}
                      >
                        Edit Order Details
                      </button>
                    )}
                    {!!user?.isEditor && (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          this.setState({
                            reorderItemsModalOpen: true,
                          });
                        }}
                      >
                        Reorder Items
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.setState({
                          showKitDetails: !this.state.showKitDetails,
                        });
                      }}
                    >
                      {this.state.showKitDetails ? "Hide" : "Show"} Kit Details
                    </button>
                    {!this.props.prices && (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          this.downloadIllustratedList();
                        }}
                      >
                        Illustrated List
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <StyledBreadcrumb
                label={
                  window.location.pathname.includes("/orders/")
                    ? "Orders"
                    : "BizDev"
                }
                component={Link}
                to={
                  window.location.pathname.includes("/orders/")
                    ? "/orders"
                    : "/bizdev"
                }
              />
              {user?.hasPriceAccess ? (
                <DropdownBreadcrumb
                  label={this.state.order ? (this.state.order.order_num + " (" + this.state.order.customer + ")") : ("Order #" + this.props.id)}
                  orderId={this.props.id}
                />
              ) : (
                <StyledBreadcrumb
                  label={this.state.order ? (this.state.order.order_num + " (" + this.state.order.customer + ")") : ("Order #" + this.props.id)}
                />
              )}
            </Breadcrumbs>
            {!this.state.commentsView && (
              <Accordion
                expanded={!this.state.collapsed}
                onChange={() => {
                  this.setState({
                    collapsed: !this.state.collapsed,
                  });
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <OrderCard
                      order={this.state.order}
                      prices={this.props.prices}
                      onEdit={() => { this.setState({ editOrderDetailsModalOpen: true }); }}
                      onExpandBtnClick={() => {
                        this.setState({ commentsView: true });
                      }}
                    />
                    <OrderComments
                      orderId={this.props.id}
                      prices={this.props.prices}
                      onExpandBtnClick={() => {
                        this.setState({ commentsView: true });
                      }}
                      onUpdate={() => { this.getOrder(); }}
                    />
                    {this.props.prices && (
                      <QuoteHistory orderId={this.props.id} />
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {!this.state.commentsView && (
              <div className="row container">
                <OrderContentsTable
                  order={this.state.order}
                  contents={this.state.contents.filter(
                    (c: any) => c.is_option === "0"
                  )}
                  showKitDetails={this.state.showKitDetails}
                  prices={this.props.prices}
                  onDelete={() => { this.getOrderContents(); }}
                  onReplaceRequest={(item_num: string) => {
                    this.setState({ addingRqmnt: true, addingRqmntDefaultItemNumber: item_num });
                  }}
                />
                {this.props.prices && (
                  <QuoteTotals order={this.state.order} contents={this.state.contents} />
                )}
                <OrderContentsTable
                  order={this.state.order}
                  contents={this.state.contents.filter(
                    (c: any) => c.is_option === "1"
                  )}
                  showKitDetails={this.state.showKitDetails}
                  prices={this.props.prices}
                  onDelete={() => { this.getOrderContents(); }}
                  onReplaceRequest={(item_num: string) => {
                    this.setState({ addingRqmnt: true, addingRqmntDefaultItemNumber: item_num });
                  }}
                  options
                />
              </div>
            )}
            {this.state.commentsView && (
              <div className="row container gap-2">
                <OrderCard
                  order={this.state.order}
                  prices={this.props.prices}
                  onEdit={() => { this.setState({ editOrderDetailsModalOpen: true }); }}
                  expanded
                />
                <OrderComments
                  orderId={this.props.id}
                  prices={this.props.prices}
                  onExpandBtnClick={() => {
                    this.setState({ commentsView: false });
                  }}
                  disableCollapse={this.state.order.quote_type === "COMMUNICATION"}
                  onUpdate={() => { this.getOrder(); }}
                  expanded
                />
              </div>
            )}
            <QuoteViewerModal
              order={this.state.order}
              contents={this.state.contents}
              isOpen={this.state.quotePreviewModalOpen}
              onClose={() => {
                this.setState({ quotePreviewModalOpen: false });
              }}
              onSave={() => {
                this.setState({ quoteSaveModalOpen: true });
              }}
            />
            <AddOrderItemModal
              orderId={this.props.id}
              isOpen={this.state.addingRqmnt}
              onClose={() => {
                this.setState({ addingRqmnt: false, addingRqmntDefaultItemNumber: "" });
              }}
              onSave={() => {
                this.getOrderContents();
              }}
              defaultItemNumber={this.state.addingRqmntDefaultItemNumber}
              prices={this.props.prices}
            />
            <QuoteSaveModal
              orderId={this.props.id}
              isOpen={this.state.quoteSaveModalOpen}
              onClose={() => this.setState({ quoteSaveModalOpen: false })}
              onSave={() => { }}
            />
            <SelectSmartsheetExportLocationModal
              isOpen={this.state.smartsheetExportModalOpen}
              onClose={() => {
                this.setState({
                  smartsheetExportModalOpen: false,
                });
              }}
              onSave={(folder_id) => {
                this.exportToSmartsheet(folder_id);
              }}
            />
            <EditOrderDetailsModal
              order={this.state.order}
              isOpen={this.state.editOrderDetailsModalOpen}
              onClose={() => {
                this.setState({ editOrderDetailsModalOpen: false });
              }}
              onSave={() => {
                this.getOrder();
              }}
              prices={this.props.prices}
            />
            <ReorderItemsModal
              contents={this.state.contents}
              isOpen={this.state.reorderItemsModalOpen}
              onClose={() => {
                this.setState({ reorderItemsModalOpen: false });
              }}
              onSave={(items) => {
                this.updateItemNumbers(items);
              }}
            />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function DropdownBreadcrumb({ label, orderId }: { label: string, orderId: number }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledBreadcrumb
        label={label}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={handleClick}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledBreadcrumb
          component={Link}
          to={"/orders/" + orderId}
          label="Orders"
        />
        <StyledBreadcrumb
          component={Link}
          to={"/bizdev/" + orderId}
          label="BizDev"
        />
      </Menu>
    </>
  );
}

export default Order;

function getImageDimensions(data: Buffer): { width: any; height: any; } | PromiseLike<{ width: any; height: any; }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    const base64 = data.toString('base64');
    img.src = 'data:image/jpeg;base64,' + base64;
  });
}
