import React from "react";
import ProductSelectModal from "../products/ProductSelectModal";
import { PRODUCT_CATEGORIES } from "../../utils/Misc";
import { callAPI } from "../../utils/API";
import { Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface DeliverableEditorProps {
  prices?: boolean;
  isNew?: boolean; // should we add the select product button?
  form: FormInstance;
  onChange: (value: FormInstance) => void;
  errors: { [key: string]: string };
}

interface DeliverableEditorState {
  productSelectModalOpen: boolean;
}

export interface FormInstance {
  inv_code: string;
  ltc_pn: string;
  catalog_number: string;
  name: string;
  quantity: number;
  product_id: number;
  item_number: string;
  ship_container: string;
  category: typeof PRODUCT_CATEGORIES[number];
  price: number;
  cost: number;
  price_hidden: 0 | 1;
  price_override: 0 | 1;
  calculated_pricing: 0 | 1;
  is_option: 0 | 1;
  is_shipped: 0 | 1;
  is_gsa: 0 | 1;
  notes: string;
}

class DeliverableEditor extends React.Component<
  DeliverableEditorProps,
  DeliverableEditorState
> {
  constructor(props: DeliverableEditorProps) {
    super(props);
    this.state = {
      productSelectModalOpen: false,
    };
  }

  /**
   * Loads the data for a product
   * @param id The product ID
   */
  loadProduct(id: number) {
    callAPI("tables/products_view", "POST", {
      where: {
        id: id,
      },
    }).then((res) => {
      this.props.onChange({
        ...this.props.form,
        ...res[0],
        product_id: id,
      });
    });
  }

  render() {
    if (!this.props.form) return null;
    return (
      <div className="row">
        {this.props.isNew && this.props.form.inv_code !== "CUSTOM" && (
          <div className="col-4 my-2">
            <FormGroup row>
              <TextField
                fullWidth
                label="Product"
                value={this.props.form.product_id || ""}
                disabled
                error={!!this.props.errors.product_id}
                helperText={this.props.errors.product_id}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({ productSelectModalOpen: true })
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
          </div>
        )}
        <div className="col-4 my-2">
          <TextField
            type="text"
            fullWidth
            label="LTC P/N"
            name="ltc_pn"
            disabled={this.props.form?.inv_code !== "CUSTOM"}
            placeholder="LTC P/N"
            value={this.props.form?.ltc_pn || ""}
            onChange={(e) => {
              this.props.onChange({
                ...this.props.form,
                ltc_pn: e.target.value,
              });
            }}
            error={!!this.props.errors?.ltc_pn}
            helperText={this.props.errors?.ltc_pn}
          />
        </div>
        <div className="col-4 my-2">
          <TextField
            id="cat_num"
            fullWidth
            label="Cat #"
            type="text"
            name="cat_num"
            disabled={this.props.form?.inv_code !== "CUSTOM"}
            placeholder="Cat #"
            value={this.props.form?.catalog_number || ""}
            onChange={(e) => {
              this.props.onChange({
                ...this.props.form,
                catalog_number: e.target.value,
              });
            }}
            error={!!this.props.errors?.catalog_number}
            helperText={this.props.errors?.catalog_number}
          />
        </div>
        <div className="col-4 my-2">
          <FormControl fullWidth error={!!this.props.errors?.category}>
            <InputLabel id="category-edit-label">Category</InputLabel>
            <Select
              labelId="category-edit-label"
              label="Category"
              id="category-edit"
              value={this.props.form.category || ""}
              onChange={(e) => {
                this.props.onChange({
                  ...this.props.form,
                  category: e.target.value,
                });
              }}
              disabled={this.props.form?.inv_code !== "CUSTOM"}
            >
              <MenuItem value="N/A">Select Category</MenuItem>
              {PRODUCT_CATEGORIES.map((category) => (
                <MenuItem value={category} key={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-4 my-2">
          <TextField
            id="name"
            fullWidth
            label="Description"
            type="text"
            name="name"
            disabled={this.props.form?.inv_code !== "CUSTOM"}
            placeholder="Description"
            value={this.props.form?.name || ""}
            onChange={(e) => {
              this.props.onChange({
                ...this.props.form,
                name: e.target.value,
              });
            }}
            error={!!this.props.errors?.name}
            helperText={this.props.errors?.name}
          />
        </div>
        <div className="col-4 my-2">
          <TextField
            id="quantity"
            fullWidth
            label="Qty"
            type="number"
            name="quantity"
            InputProps={{ inputProps: { min: 0 } }}
            value={this.props.form?.quantity || ""}
            onChange={(e) => {
              this.props.onChange({
                ...this.props.form,
                quantity: parseInt(e.target.value),
              });
            }}
            error={!!this.props.errors?.quantity}
            helperText={this.props.errors?.quantity}
          />
        </div>
        <div className="col-4 my-2">
          <TextField
            id="item_number"
            fullWidth
            label="Item Number"
            type="text"
            name="item_number"
            placeholder="Item #"
            value={this.props.form?.item_number || ""}
            onChange={(e) => {
              this.props.onChange({
                ...this.props.form,
                item_number: e.target.value,
              });
            }}
            error={!!this.props.errors?.item_number}
            helperText={this.props.errors?.item_number}
          />
        </div>
        {!this.props.prices && (
          <div className="col-4 my-2">
            <TextField
              id="ship_container"
              fullWidth
              label="Ship Case"
              type="text"
              name="ship_container"
              placeholder="Ship Case #"
              value={this.props.form?.ship_container || ""}
              onChange={(e) => {
                this.props.onChange({
                  ...this.props.form,
                  ship_container: e.target.value,
                });
              }}
              error={!!this.props.errors?.ship_container}
              helperText={this.props.errors?.ship_container}
            />
          </div>
        )}
        {this.props.prices && (
          <div className="col-4 my-2">
            <TextField
              id="price"
              fullWidth
              label="Price"
              type="number"
              name="price"
              disabled={
                (this.props.form?.inv_code !== "CUSTOM" &&
                  parseInt(this.props.form?.price_override?.toString()) !== 1)
                || parseInt(this.props.form?.calculated_pricing?.toString()) === 1
              }
              placeholder="Price (USD)"
              value={this.props.form?.price || ""}
              onChange={(e) => {
                this.props.onChange({
                  ...this.props.form,
                  price: parseFloat(e.target.value),
                });
              }}
              error={!!this.props.errors?.price}
              helperText={this.props.errors?.price}
            />
          </div>
        )}
        {
          this.props.prices && (
            <div className="col-4 my-2">
              <TextField
                id="cost"
                fullWidth
                label="Cost"
                type="number"
                name="cost"
                disabled={
                  this.props.form?.inv_code !== "CUSTOM" || parseInt(this.props.form?.calculated_pricing?.toString()) === 1
                }
                placeholder="Cost (USD)"
                value={this.props.form?.cost || ""}
                onChange={(e) => {
                  this.props.onChange({
                    ...this.props.form,
                    cost: parseFloat(e.target.value),
                  });
                }}
                error={!!this.props.errors?.cost}
                helperText={this.props.errors?.cost}
              />
            </div>
          )}
        <div className="col-4 my-2">
          {this.props.prices && (
            <FormControlLabel
              control={
                <Checkbox
                  name="price_hidden"
                  checked={parseInt(this.props.form?.price_hidden?.toString()) === 1}
                  onChange={(e) => {
                    this.props.onChange({
                      ...this.props.form,
                      price_hidden: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              }
              label="Hide Individual Price"
            />
          )}
          {this.props.prices && (
            <FormControlLabel
              control={
                <Checkbox
                  name="is_gsa"
                  checked={parseInt(this.props.form?.is_gsa?.toString()) === 1}
                  onChange={(e) => {
                    this.props.onChange({
                      ...this.props.form,
                      is_gsa: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              }
              label="GSA Product"
            />
          )}
          {this.props.prices && this.props.form?.inv_code !== "CUSTOM" && parseInt(this.props.form?.calculated_pricing?.toString()) !== 1 && (
            <FormControlLabel
              control={
                <Checkbox
                  name="price_override"
                  checked={parseInt(this.props.form?.price_override?.toString()) === 1}
                  onChange={(e) => {
                    this.props.onChange({
                      ...this.props.form,
                      price_override: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              }
              label="Override Price"
            />
          )}
          {this.props.prices && this.props.form?.inv_code === "CUSTOM" && (
            <FormControlLabel
              control={
                <Checkbox
                  name="calculated_pricing"
                  checked={parseInt(this.props.form?.calculated_pricing?.toString()) === 1}
                  onChange={(e) => {
                    this.props.onChange({
                      ...this.props.form,
                      calculated_pricing: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              }
              label="Calculate Pricing"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                name="is_option"
                checked={parseInt(this.props.form?.is_option?.toString()) === 1}
                onChange={(e) => {
                  this.props.onChange({
                    ...this.props.form,
                    is_option: e.target.checked ? 1 : 0,
                    item_number: e.target.checked
                      ? this.props.form?.item_number?.includes("O-")
                        ? this.props.form?.item_number
                        : "O-" + this.props.form?.item_number
                      : this.props.form?.item_number?.replace("O-", ""),
                  });
                }}
              />
            }
            label="Is Option"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="is_shipped"
                checked={parseInt(this.props.form?.is_shipped?.toString()) === 1}
                onChange={(e) => {
                  this.props.onChange({
                    ...this.props.form,
                    is_shipped: e.target.checked ? 1 : 0,
                  });
                }}
              />
            }
            label="Shipped"
          />
        </div>
        <div className="col-12">
          <TextField
            id="notes"
            fullWidth
            label="Notes"
            multiline
            rows={4}
            variant="outlined"
            name="notes"
            value={this.props.form?.notes || ""}
            onChange={(e) => {
              this.props.onChange({
                ...this.props.form,
                notes: e.target.value,
              });
            }}
            error={!!this.props.errors?.notes}
            helperText={this.props.errors?.notes}
          />
        </div>
        {
          this.props.isNew && (
            <ProductSelectModal
              isOpen={this.state.productSelectModalOpen}
              onClose={() => {
                this.setState({ productSelectModalOpen: false });
              }}
              onSelect={(products) => {
                this.loadProduct(products[0]);
                this.setState({ productSelectModalOpen: false });
              }}
            />
          )
        }
      </div >
    );
  }
}

export default DeliverableEditor;