import React from "react";
import { DEFAULT_PAYMENT_STR, Quote, formatHTML, getItemPrice } from "./QuoteGenerator";
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { formatter } from "./Misc";
import { ITEM_NUM_COMPARATOR } from "../components/orders/OrderContentsTable";
import { ltcLogoBase64 } from "./LTCLogo";

interface PDFQuoteGeneratorProps {
  quote: Quote;
}

class PDFQuoteGenerator extends React.Component<PDFQuoteGeneratorProps> {
  private contents: any[];
  private options: any[];
  private customer: any;
  private contact: any;
  constructor(props: PDFQuoteGeneratorProps) {
    super(props);
    this.contents = JSON.parse(props.quote.contents);
    this.options = JSON.parse(props.quote.options);
    this.customer = JSON.parse(props.quote.customer);
    this.contact = JSON.parse(props.quote.contact);
  }
  render() {
    const styles = StyleSheet.create({
      page: {
        fontSize: 10,
        paddingTop: 25,
        paddingBottom: 40,
        paddingHorizontal: 40,
      },
    });
    if (!this.customer) {
      this.customer = {
        name: "",
        addr_line1: "",
        addr_line2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      };
    }
    if (!this.contact) {
      this.contact = {
        name: "",
        email: "",
        phone: "",
      };
    }
    if (!this.props.quote) {
      console.error("Error generating PDF quote: quote is missing");
      return (
        <Document>
          <Page size="A4" style={styles.page}>
            <Header />
            <Error reason="No quote data was found." />
            <Footer />
          </Page>
        </Document>
      );
    }
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Header />
          <QuoteDetailsTable quote={this.props.quote} customer={this.customer} contact={this.contact} />
          <ContentsTable contents={this.contents} quote={this.props.quote} showTotal />
          <ContentsTable contents={this.options} quote={this.props.quote} />
          <TermsAndConditionsTable quote={this.props.quote} />
          <Footer />
        </Page>
      </Document>
    );
  }
}

export function Table(props: any) {
  const styles = StyleSheet.create({
    table: {
      display: "flex",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    }
  });
  return (
    <View style={styles.table} wrap={props.wrap}>
      {props.children}
    </View>
  );
}

export function Row(props: any) {
  const styles = StyleSheet.create({
    row: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      width: "100%",
    }
  });
  return (
    <View style={styles.row} wrap={false}>
      {props.children}
    </View>
  );
}

export function Cell(props: any) {
  const styles = StyleSheet.create({
    cell: {
      flexGrow: 1,
      width: props.width || "100%",
      height: props.height || "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
      textAlign: props.align || "left",
      opacity: props.hidden ? 0 : 1,
    }
  });
  return (
    <View style={styles.cell} wrap={false}>
      {props.children}
    </View>
  );
}

export class Header extends React.Component {
  render() {
    const styles = StyleSheet.create({
      header: {
        fontSize: 12,
        marginBottom: 20,
        color: 'grey',
        textAlign: 'right',
      },
      image: {
        width: 346 * 0.7,
        height: 36 * 0.7,
        marginLeft: '0',
        marginRight: 'auto',
      }
    });
    return (
      <View fixed style={styles.header}>
        <Image
          style={styles.image}
          src={"data:image/png;base64," + ltcLogoBase64}
        />
        <Text render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />
      </View>
    );
  }
}

export class Footer extends React.Component {
  render() {
    const styles = StyleSheet.create({
      footer: {
        fontSize: 8,
        marginBottom: 0,
        textAlign: 'center',
        position: 'absolute',
        bottom: 10,
        left: 40,
        width: "100%",
      },
      line: {
        width: "100%",
        height: 1,
        backgroundColor: "black",
        marginBottom: 5,
      }
    });
    return (
      <View fixed style={styles.footer}>
        <hr style={styles.line} />
        <Text>
          11431 Willows Rd NE, Suite 100 • Redmond, WA 98052 • Phone (425) 885-0607 • Fax (425) 885-0802 • www.laser-ndt.com
        </Text>
      </View>
    );
  }
}

export function Error(props: any) {
  const styles = StyleSheet.create({
    error: {
      fontSize: 12,
      marginBottom: 20,
      color: 'red',
    },
  });
  return (
    <View>
      <Text style={styles.error}>
        An error occurred while generating this document.
      </Text>
      {props.reason && <Text style={styles.error}>
        {props.reason}
      </Text>}
    </View>
  );
}

interface QuoteDetailsTableProps {
  quote: Quote;
  customer: any;
  contact: any;
}

class QuoteDetailsTable extends React.Component<QuoteDetailsTableProps> {
  render() {
    const styles = StyleSheet.create({
      section: {
      },
      header: {
        fontSize: 12,
        marginBottom: 20,
        color: 'grey',
        textAlign: 'right',
      },
      title: {
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 10,
        paddingBottom: 10
      }
    });
    // shift for timezone offset
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const date = new Date(new Date(this.props.quote.created_at).getTime() - offset);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
    return (
      <View style={styles.section}>
        <Text style={styles.title}>{
          this.props.quote.type === "QUOTATION" ? "Quotation" : "Cost Estimate"
        }</Text>
        <Table>
          <Row>
            <Cell>
              <Text>To:</Text>
            </Cell>
            <Cell>
              <Text>{this.props.customer.name}</Text>
              <Text>{formatHTML(this.props.customer.addr_line1)}</Text>
              {this.props.customer.addr_line2 ?
                <Text>{formatHTML(this.props.customer.addr_line2)}</Text> : null}
              <Text>{this.props.customer.city + ", " + this.props.customer.state + " " + this.props.customer.country + " " + this.props.customer.zip}</Text>
              <Text>Attn: {this.props.contact.name}</Text>
            </Cell>
            <Cell>
              <Text>Date:</Text>
              <Text>RFQ:</Text>
              <Text>Ref.:</Text>
              <Text>Phone:</Text>
              <Text>Email:</Text>
            </Cell>
            <Cell>
              <Text>{formattedDate}</Text>
              <Text>{this.props.quote.rfq}</Text>
              <Text>{(this.props.quote.quote_number || this.props.quote.job_number)}{this.props.quote.revision > 0 ? ` R${this.props.quote.revision}` : ""}</Text>
              <Text>{this.props.contact.phone}</Text>
              <Text>{this.props.contact.email}</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>LTC P.O.C.:</Text>
            </Cell>
            <Cell>
              <Text>{this.props.quote.ltc_poc_name}</Text>
            </Cell>
            <Cell>
              <Text>Email:</Text>
            </Cell>
            <Cell>
              <Text>{this.props.quote.ltc_poc_email}</Text>
            </Cell>
          </Row>
        </Table>
        <Text style={styles.title}>{this.props.quote.description}</Text>
      </View>
    );
  }
}


interface ContentsTableProps {
  contents: any[];
  quote: Quote;
  showTotal?: boolean;
}

class ContentsTable extends React.Component<ContentsTableProps> {
  render() {
    if (this.props.contents.length === 0) {
      return null;
    }
    const styles = StyleSheet.create({
      section: {
      },
      header: {
        fontSize: 12,
        marginBottom: 20,
        color: 'grey',
        textAlign: 'right',
      },
      title: {
        fontSize: 16,
        textAlign: 'center',
      },
    });
    const WIDTHS = {
      item_col: "110em",
      catalog_col: "200em",
      description_col: "auto",
      price_col: "200em",
      qty_col: "110em",
      total_col: "200em",
      totals_col: "90em",
    };

    const priceTierVal = this.props.quote.price_tier || 1;
    const priceTierName = this.props.quote.price_tier_name || "US Price";
    const isGSAOrder = priceTierName === "GSA";

    const SALEX_TAX_PERCENT = 0.0; // this will be added manually
    let subTotal = 0;
    let salesTax = 0;
    let ccFee = 0;
    if (this.props.showTotal) {
      subTotal = this.props.contents.reduce((acc, item) => {
        return acc + getItemPrice(item, this.props.contents, isGSAOrder, priceTierVal, true);
      }, 0);
      salesTax = SALEX_TAX_PERCENT * subTotal;
      ccFee = this.props.quote.credit_card_percent / 100 * (subTotal + salesTax);
    }
    const total = subTotal + salesTax + ccFee + parseFloat(this.props.quote.shipping?.toString() || "0");

    return (
      <View style={styles.section}>
        <Table>
          <Row>
            <Cell width={WIDTHS.item_col}>
              <Text style={{
                fontWeight: 'bold',
              }}>Item</Text>
            </Cell>
            <Cell width={WIDTHS.catalog_col}>
              <Text style={{
                fontWeight: 'bold',
              }}>Catalog #</Text>
            </Cell>
            <Cell>
              <Text style={{
                fontWeight: 'bold',
              }}>Description</Text>
            </Cell>
            <Cell width={WIDTHS.price_col}>
              <Text style={{
                fontWeight: 'bold',
              }}>Price Each</Text>
            </Cell>
            <Cell width={WIDTHS.qty_col}>
              <Text style={{
                fontWeight: 'bold',
              }}>Qty</Text>
            </Cell>
            <Cell width={WIDTHS.total_col}>
              <Text style={{
                fontWeight: 'bold',
              }}>Total Price</Text>
            </Cell>
          </Row>
          {this.props.contents.sort(ITEM_NUM_COMPARATOR).map((item, index) => {
            const fontWeight = !item.item_number.includes('.') ? 'bold' : 'normal';
            const pricePerInstance = getItemPrice(item, this.props.contents, isGSAOrder, priceTierVal, false);
            const totalItemPrice = getItemPrice(item, this.props.contents, isGSAOrder, priceTierVal, true); return (
              <Row key={index}>
                <Cell width={WIDTHS.item_col} align="center">
                  <Text style={{
                    fontWeight
                  }}>{item.item_number}</Text>
                </Cell>
                <Cell width={WIDTHS.catalog_col}>
                  <Text style={{
                    fontWeight
                  }}>{item.catalog_number}</Text>
                </Cell>
                <Cell>
                  <Text style={{
                    fontWeight
                  }}>{item.name + (isGSAOrder && parseInt(item.is_gsa) === 1 ? " (GSA)" : "")}</Text>
                  {!!item.notes && item.notes.split("\n").map((note: string, index: number) => {
                    return (
                      <Text key={index} style={{
                        fontWeight
                      }}>• {note}</Text>
                    );
                  })}
                </Cell>
                <Cell width={WIDTHS.price_col} align="right">
                  <Text style={{
                    fontWeight
                  }}>{parseInt(item.price_hidden) === 1 ||
                    parseFloat(item.price) === 0 ||
                    item.inv_code === "KT"
                    ? ""
                    : formatter.format(pricePerInstance)}</Text>
                </Cell>
                <Cell width={WIDTHS.qty_col} align="center">
                  <Text style={{
                    fontWeight
                  }}>{parseFloat(item.price) === 0
                    ? ""
                    : item.quantity}</Text>
                </Cell>
                <Cell width={WIDTHS.total_col} align="right">
                  <Text style={{
                    fontWeight
                  }}>{totalItemPrice === 0 && item.item_number.includes(".") ? "" : formatter.format(totalItemPrice)}</Text>
                </Cell>
              </Row>
            );
          })}
          {this.props.showTotal && (
            <Row>
              <Cell align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>Subtotal</Text>
              </Cell>
              <Cell width={WIDTHS.totals_col} align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>{formatter.format(subTotal)}</Text>
              </Cell>
            </Row>
          )}
          {this.props.showTotal && (
            <Row>
              <Cell align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>Shipping and Handling</Text>
              </Cell>
              <Cell width={WIDTHS.totals_col} align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>{parseInt(this.props.quote.shipping_option.toString()) === 0
                  ? "Not Included"
                  : parseInt(this.props.quote.shipping_option.toString()) ===
                    1
                    ? formatter.format(this.props.quote.shipping)
                    : parseInt(this.props.quote.shipping_option.toString()) ===
                      2
                      ? "Not Included"
                      : ""}</Text>
              </Cell>
            </Row>
          )}
          {this.props.showTotal && (
            <Row>
              <Cell align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>Sales Tax</Text>
              </Cell>
              <Cell width={WIDTHS.totals_col} align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>{formatter.format(salesTax)}</Text>
              </Cell>
            </Row>
          )}
          {this.props.showTotal && this.props.quote.credit_card_percent > 0 && (
            <Row>
              <Cell align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>3% Credit Card Transaction Fee</Text>
              </Cell>
              <Cell width={WIDTHS.totals_col} align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>{formatter.format(ccFee)}</Text>
              </Cell>
            </Row>
          )}
          {this.props.showTotal && (
            <Row>
              <Cell align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>Total</Text>
              </Cell>
              <Cell width={WIDTHS.totals_col} align="right">
                <Text style={{
                  fontWeight: 'bold',
                }}>{formatter.format(total)}</Text>
              </Cell>
            </Row>
          )}
        </Table>
      </View>
    );
  }
}

interface TermsAndConditionsTableProps {
  quote: Quote;
}

class TermsAndConditionsTable extends React.Component<TermsAndConditionsTableProps> {
  render() {
    const styles = StyleSheet.create({
      section: {
      },
      header: {
        fontSize: 12,
        marginBottom: 20,
        color: 'grey',
        textAlign: 'right',
      },
      title: {
        fontSize: 16,
        textAlign: 'left',
        marginTop: 20,
        marginBottom: 5,
        fontWeight: 'bold',
      }
    });
    const priceTierName = this.props.quote.price_tier_name || "US Price";
    const isGSAOrder = priceTierName === "GSA";
    const TERMS_WIDTH = "90em";
    if (this.props.quote.type !== "QUOTATION") {
      return (
        <View style={styles.section}>
          <Text style={styles.title}>Terms and Conditions:</Text>
          <Table>
            <Row>
              <Cell width={TERMS_WIDTH}>
                <Text>Delivery:</Text>
              </Cell>
              <Cell>
                <Text>{this.props.quote.delivery_str ||
                  this.props.quote.delivery_timeline +
                  " Days After Receipt of Order"}</Text>
              </Cell>
            </Row>
            <Row>
              <Cell width={TERMS_WIDTH}>
                <Text>Privacy:</Text>
              </Cell>
              <Cell>
                <Text>This quotation is not for distribution and is intended for the recipient individual and organization only.</Text>
              </Cell>
            </Row>
          </Table>
        </View>
      );
    }
    return (
      <View style={styles.section}>
        <Text style={styles.title}>Terms and Conditions:</Text>
        <Table>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Delivery:</Text>
            </Cell>
            <Cell>
              <Text>{formatHTML(this.props.quote.delivery_str ||
                this.props.quote.delivery_timeline +
                " Days After Receipt of Order")}</Text>
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Quote Valid:</Text>
            </Cell>
            <Cell>
              <Text>{this.props.quote.valid_days + " Days"}</Text>
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Terms:</Text>
            </Cell>
            <Cell>
              <Text>All sales are subject to Laser Techniques Company Standard Terms and Conditions.</Text>
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Payment:</Text>
            </Cell>
            <Cell>
              {formatHTML(this.props.quote.payment_str || DEFAULT_PAYMENT_STR).split("\n").map((str) => {
                return <Text key={str}>{str}</Text>;
              })}
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>F.O.B.:</Text>
            </Cell>
            <Cell>
              <Text>Redmond, WA</Text>
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Shipping:</Text>
            </Cell>
            <Cell>
              <Text>TODO: Create checkboxes</Text>
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Note:</Text>
            </Cell>
            <Cell>
              <Text>All international shipments Ex Works</Text>
              {isGSAOrder && (
                <Text>All items not specifically marked as GSA are considered open market.</Text>
              )}
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Taxes & Duties:</Text>
            </Cell>
            <Cell>
              <Text>All prices are exclusive of any applicable taxes and/or duties.</Text>
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Privacy:</Text>
            </Cell>
            <Cell>
              <Text>This quotation is not for distribution and is intended for the recipient individual and organization only.</Text>
            </Cell>
          </Row>
          <Row>
            <Cell width={TERMS_WIDTH}>
              <Text>Warranty:</Text>
            </Cell>
            <Cell>
              <Text>All OEM equipment, such as computers and commercial PC boards, carry the manufacturer's standard warranty. All LTC manufactured components are covered by a limited one-year warranty as described in LTC's Standard Terms and Conditionsr</Text>
            </Cell>
          </Row>
        </Table>
      </View>
    );
  }
}


export default PDFQuoteGenerator;