import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import OrderTypesToggler from "../dashboard/OrderTypesToggler";
import ProjectedShipments from "./ProjectedShipments";
import ProjectionsSummaryTable from "./ProjectionsSummaryTable";
import { callAPI } from "../../utils/API";

interface ProjectionsSummaryProps { }

interface ProjectionsSummaryState {
  enabledOrderTypes: { [key: string]: boolean };
  orders: any[];
}

class ProjectionsSummary extends Component<
  ProjectionsSummaryProps,
  ProjectionsSummaryState
> {
  constructor(props: ProjectionsSummaryProps) {
    super(props);
    this.state = {
      enabledOrderTypes: { Order: true, Projection: true, Prospect: false },
      orders: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const types = this.getEnabledOrderTypes();
    callAPI("data/projections-summary", "POST", {
      types: Object.keys(types).filter(
        (key) => types[key]
      ),
    })
      .then((response) => {
        this.setState({
          orders: response,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2" id="est-title">
            Projections Summary
          </h1>

          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              <Link
                to="/bizdev"
                className="only-tabular btn btn-sm btn-outline-secondary"
              >
                Quote List
              </Link>
              <Link
                to="/revenue"
                className="only-tabular btn btn-sm btn-outline-secondary"
              >
                Bookings Chart
              </Link>
            </div>
          </div>
        </div>
        <OrderTypesToggler
          defaultValues={this.state.enabledOrderTypes}
          orderTypes={["Order", "Projection", "Prospect"]}
          onChange={(values) => {
            this.setState({ enabledOrderTypes: values }, () => {
              this.loadData();
            });
          }}
        />
        <CustomAccordion
          domestic={true}
          orders={this.state.orders}
          onUpdate={() => {
            this.loadData();
          }}
        />
        <CustomAccordion
          domestic={false}
          orders={this.state.orders}
          onUpdate={() => {
            this.loadData();
          }}
        />
        <h4 className="mt-5">Projected Shipments by Quarter</h4>
        <p className="text-muted">
          Includes Orders, Temp Jobs, and Projections
        </p>
        <ProjectedShipments types={this.getEnabledOrderTypes()} />
      </div>
    );
  }

  getEnabledOrderTypes(): { [key: string]: boolean } {
    return {
      Order: this.state.enabledOrderTypes.Order,
      Prospect: this.state.enabledOrderTypes.Prospect,
      "Temp Job": this.state.enabledOrderTypes.Projection,
      "Projection 80%": this.state.enabledOrderTypes.Projection,
      "Projection 90%": this.state.enabledOrderTypes.Projection,
    };
  }
}

interface CustomAccordionProps {
  domestic: boolean;
  orders: any[];
  onUpdate: () => void;
}

function CustomAccordion(props: CustomAccordionProps) {
  const [open, setOpen] = React.useState(true);
  return (
    <Accordion
      expanded={open}
      onChange={() => {
        setOpen(!open);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {props.domestic ? "Domestic" : "International"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <p>
            <b>Defense:</b>
          </p>
          <ProjectionsSummaryTable
            domestic={props.domestic}
            defense={true}
            orders={props.orders}
            onUpdate={props.onUpdate}
          />
          <p className="mt-3">
            <b>Non-Defense:</b>
          </p>
          <ProjectionsSummaryTable
            domestic={props.domestic}
            defense={false}
            orders={props.orders}
            onUpdate={props.onUpdate}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default ProjectionsSummary;
