import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { generateModalStyle } from "../../utils/Misc";
import SmartsheetPicker from "./SmartsheetPicker";

interface SelectSmartsheetExportLocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (folder_id: number) => void;
}

interface SelectSmartsheetExportLocationModalState {
  folder: any;
}

class SelectSmartsheetExportLocationModal extends Component<
  SelectSmartsheetExportLocationModalProps,
  SelectSmartsheetExportLocationModalState
> {
  constructor(props: SelectSmartsheetExportLocationModalProps) {
    super(props);
    this.state = {
      folder: null,
    };
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select Export Location
          </Typography>
          <SmartsheetPicker
            onChange={(folder) => {
              this.setState({
                folder: folder,
              });
            }}
            selectOption="folders"
          />
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={this.state.folder === null}
              onClick={() => {
                this.props.onSave(this.state.folder.key);
              }}
              className="btn btn-primary"
            >
              Export
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default SelectSmartsheetExportLocationModal;
