import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { Tree } from "primereact/tree";
import { callFileServerAPI } from "../../utils/API";
import toast from "react-hot-toast";
import { generateModalStyle } from "../../utils/Misc";
import 'primeicons/primeicons.css';

interface FolderChooserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
}

interface FolderChooserModalState {
  nodes: any[];
  path: string;
  selectedKey: any;
  loading: boolean;
  error: string;
}

class FolderChooserModal extends Component<
  FolderChooserModalProps,
  FolderChooserModalState
> {
  constructor(props: FolderChooserModalProps) {
    super(props);
    this.state = {
      nodes: [],
      path: "",
      selectedKey: null,
      loading: true,
      error: "",
    };
  }

  loadData() {
    this.setState({
      nodes: [
        {
          key: "0",
          label: "Quotations",
          data: "Quotations Folder",
          icon: "pi pi-fw pi-folder",
          leaf: false,
          path: "B:\\Quotations\\" + new Date().getFullYear() + " Quotes",
        },
        {
          key: "1",
          label: "Cost Estimates",
          data: "Cost Estimates Folder",
          icon: "pi pi-fw pi-folder",
          leaf: false,
          path: "B:\\Cost Estimates\\" + new Date().getFullYear() + " Cost Estimates",
        },
        {
          key: "2",
          label: "Job Costing - Estimates",
          data: "Job Costing - Estimates Folder",
          icon: "pi pi-fw pi-folder",
          leaf: false,
          path: "B:\\Job Costing - Estimates",
        },
      ],
      loading: false,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  loadOnExpand(event: any) {
    if (!event.node.children) {
      this.setState({
        loading: true,
      });
      let path = event.node.path.replace("M:\\", "\\\\LT-FS3\\Business Development\\");
      path = event.node.path.replace("B:\\", "\\\\LT-FS3\\Business Development\\");
      callFileServerAPI("dirs/?path=" + path).then((res) => {
        if (res.success && res.folders) {
          this.setState({
            loading: false,
            nodes: this.state.nodes.map((node) => {
              if (node.key === event.node.key) {
                if (res.folders.length > 0) {
                  node.children = res.folders.map(
                    (folder: string, index: number) => {
                      return {
                        key: event.node.key + "-" + index,
                        label: folder,
                        data: folder + " Folder",
                        icon: "pi pi-fw pi-folder",
                        leaf: false,
                        path: event.node.path + "\\" + folder,
                      };
                    }
                  );
                } else {
                  delete node.children;
                  node.leaf = true;
                }
              }
              return node;
            }),
          });
        } else {
          console.log(res);
          toast.error("Error loading folders");
          this.setState({
            loading: false,
          });
        }
      })
        .catch((err) => {
          this.setState({
            loading: false,
            error: "Failed to connect to the file server. Check that you are on the LTC network."
          });
        });
    }
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.isOpen}
          onClose={this.props.onClose}
          aria-labelledby="selectProductModalLabel"
        >
          <Box sx={generateModalStyle("98%")}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select Save Location
            </Typography>
            {!this.state.error ? (
              <Tree
                value={this.state.nodes}
                selectionMode="single"
                selectionKeys={this.state.selectedKey}
                onSelectionChange={(e) => this.setState({ selectedKey: e.value })}
                onSelect={(n: any) => {
                  this.setState({ path: n.node.path });
                }}
                onUnselect={() => {
                  this.setState({ path: "" });
                }}
                onExpand={(e) => {
                  this.loadOnExpand(e);
                }}
                loading={this.state.loading}
              />
            ) : (
              <div className="alert alert-danger" role="alert">
                {this.state.error}
              </div>
            )}
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <button
                disabled={!this.state.selectedKey}
                onClick={() => {
                  this.props.onSave(this.state.path);
                }}
                className="btn btn-primary"
              >
                Select
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default FolderChooserModal;
