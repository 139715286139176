import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, callAPIWithCache } from "../../utils/API";
import DataGrid from "../../utils/DataGrid";

interface SoftwareTableProps {
  searchTerm?: string;
  mode: string;
}

interface SoftwareTableState {
  data: any[];
  columns: any[];
  loading: boolean;
}

class SoftwareTable extends Component<SoftwareTableProps, SoftwareTableState> {
  constructor(props: SoftwareTableProps) {
    super(props);
    this.state = {
      data: [],
      columns: [],
      loading: false,
    };
  }

  loadData() {
    this.setState({
      data: [],
    });
    let cacheResult = callAPIWithCache("tables/" + this.props.mode, "POST", {});
    if (cacheResult.cache && cacheResult.cache.data) {
      this.setState({
        data: cacheResult.cache.data,
        loading: false,
      });
      this.updateColumns();
    }
    cacheResult.result
      .then((res) => {
        this.setState({
          data: res,
          loading: false,
        });
        this.updateColumns();
      })
      .catch((err) => {
        toast.error("Error loading data");
        console.log(err);
      });
  }

  updateColumns() {
    if (this.props.mode === "sensor_calibration_history") {
      this.setState({
        columns: [
          { field: "id", headerName: "ID", editable: false, hide: true },
          { field: "programmer", headerName: "Programmer", editable: true },
          { field: "serial_number", headerName: "Serial #", editable: false },
          { field: "reason", headerName: "Reason", editable: true },
          {
            field: "parameters",
            headerName: "Parameters",
            editable: true,
            width: 500,
          },
        ],
      });
    } else if (this.props.mode === "software_serial_numbers") {
      this.setState({
        columns: [
          { field: "id", headerName: "ID", editable: false, hide: true },
          { field: "key_serial_number", headerName: "Key Serial #", editable: false },
          { field: "key_type", headerName: "Key Type", editable: false },
          { field: "year", headerName: "Year", editable: false },
        ],
      });
    } else if (this.props.mode === "windows_licenses") {
      this.setState({
        columns: [
          { field: "id", headerName: "ID", editable: false, hide: true },
          { field: "windows_software", headerName: "Software", editable: false },
          { field: "serial_number", headerName: "Serial #", editable: false },
          { field: "year", headerName: "Year", editable: false },
          { field: "ltc_pn", headerName: "LTC P/N", editable: false },
          { field: "ltc_po_number", headerName: "LTC PO #", editable: false },
        ],
      });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: SoftwareTableProps) {
    if (prevProps.mode !== this.props.mode) {
      this.loadData();
    }
  }

  updateSW(oldVals: any, newVals: any) {
    // find what property/properties changed
    let changedProps: any = {};
    for (let prop in newVals) {
      if (newVals[prop] !== oldVals[prop]) {
        if (newVals[prop] instanceof Date) {
          changedProps[prop] = newVals[prop]
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
        } else {
          changedProps[prop] = newVals[prop].trim();
        }
      }
    }
    callAPI("actions/software", "POST", {
      action: "update",
      table: this.props.mode,
      id: oldVals.id,
      data: changedProps,
    })
      .then((response) => {
        toast.success("Updated item #" + oldVals.id);
        this.loadData();
      })
      .catch((error) => {
        if (error.message !== "Unauthorized") {
          toast.error("Failed to update item #" + oldVals.id);
        }
      });
  }

  render() {
    return (
      <div style={{ height: "80vh" }}>
        <DataGrid
          className="mt-0"
          rows={this.state.data}
          columns={this.state.columns}
          loading={this.state.loading}
          defaultColumnProps={{
            sortable: true,
            resizable: true,
            suppressMovable: true,
          }}
          searchTerm={this.props.searchTerm}
        />
      </div>
    );
  }
}

export default SoftwareTable;
