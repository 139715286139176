import { Component } from "react";
import { callAPI } from "../../utils/API";
import { formatter } from "../../utils/Misc";

interface ProjectedShipmentsProps {
  types: { [key: string]: boolean };
}

interface ProjectedShipmentsState {
  data: any[];
}

class ProjectedShipments extends Component<
  ProjectedShipmentsProps,
  ProjectedShipmentsState
> {
  constructor(props: ProjectedShipmentsProps) {
    super(props);
    this.state = {
      data: [],
    };
  }

  loadData() {
    callAPI("data/projected-shipments", "POST", {
      types: this.props.types,
    })
      .then((response) => {
        this.setState({ data: response });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <table className="table table-responsive table-sm">
          <thead>
            <tr>
              <th></th>
              <th>
                Domestic
                <br />
                Defense
              </th>
              <th>
                Domestic
                <br />
                Other
              </th>
              <th>
                International
                <br />
                Defense
              </th>
              <th>
                International
                <br />
                Other
              </th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((row, index) => (
              <tr key={"row_" + index}>
                {row.map((cell: any, index: number) => (
                  <td key={"cell_" + index}>
                    {typeof cell === "number" ? formatter.format(cell) : cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProjectedShipments;
