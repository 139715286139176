import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import PriceTierSelect from "../ui/select/PriceTierSelect";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface EditCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  customer: any;
}

interface EditCustomerModalState {
  form: any;
}

class EditCustomerModal extends Component<
  EditCustomerModalProps,
  EditCustomerModalState
> {
  constructor(props: EditCustomerModalProps) {
    super(props);
    this.state = {
      form: this.props.customer,
    };
  }

  componentDidUpdate(prevProps: EditCustomerModalProps) {
    if (prevProps.customer !== this.props.customer) {
      this.setState({
        form: this.props.customer,
      });
    }
  }

  saveChanges() {
    callAPI("actions/customers", "POST", {
      action: "update",
      data: this.state.form,
    })
      .then((res) => {
        this.props.onSave();
        this.props.onClose();
      })
      .catch((err) => {
        toast.error("Error updating customer");
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Customer
          </Typography>
          <div className="row">
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Customer Name"
                type="text"
                value={this.state.form.name || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, name: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Address Line 1"
                type="text"
                value={this.state.form.Editr_line1 || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, Editr_line1: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Address Line 2"
                type="text"
                value={this.state.form.Editr_line2 || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, Editr_line2: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="City"
                type="text"
                value={this.state.form.city || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, city: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="State"
                type="text"
                value={this.state.form.state || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, state: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Tax Rate (%)"
                type="number"
                value={this.state.form.tax_rate || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, tax_rate: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-3 my-2">
              <TextField
                fullWidth
                label="Country"
                type="text"
                value={this.state.form.country || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, country: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-3 my-2">
              <TextField
                fullWidth
                label="ZIP Code"
                type="text"
                value={this.state.form.zip || ""}
                onChange={(e) =>
                  this.setState({
                    form: { ...this.state.form, zip: e.target.value },
                  })
                }
              />
            </div>
            <div className="col-3 my-2">
              <PriceTierSelect
                value={this.state.form.price_tier || ""}
                label="Price Tier"
                onChange={(price_tier) =>
                  this.setState({
                    form: { ...this.state.form, price_tier: price_tier },
                  })
                }
              />
            </div>
            <div className="col-3 my-2">
              <FormControl fullWidth>
                <InputLabel id="defense-label">Defense</InputLabel>
                <Select
                  labelId="defense-label"
                  label="Defense"
                  value={this.state.form.defense || ""}
                  onChange={(e) =>
                    this.setState({
                      form: { ...this.state.form, defense: e.target.value },
                    })
                  }
                >
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={!this.state.form.name}
              onClick={() => {
                this.saveChanges();
              }}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default EditCustomerModal;
