import { ChartOptions } from "chart.js";

export const COLORS: { [key: string]: string } = {
  border: "rgba(100, 100, 100, 0.3)",
  Order: "rgb(227, 247, 218)",
  "Temp Job": "rgb(218, 246, 247)",
  "Projection 90%": "rgb(199, 211, 255)",
  "Projection 80%": "rgb(244, 227, 252)",
  Prospect: "rgb(240, 240, 240)",
  Inventory: "rgb(225, 225, 225)",
  "Actual Builds": "rgb(175, 175, 175)",
  "Planned Builds": "rgb(125, 125, 125)",
  placeholder_block: "rgb(255, 255, 255)",
  Builds: "rgb(209, 127, 44)",
  text: "rgb(0, 0, 0)",
  avg: "rgba(0, 0, 0, 0.7)",
  zeroLine: "rgb(50, 50, 50)",
  grid: "rgb(225, 225, 225)",
};

export const yAxisFormatter = (value: string | number, index: any, values: any) => {
  if (typeof value === "string") {
    value = Number(value);
  }
  if (Math.floor(value) === value) {
    return value;
  }
};

export const CHART_OPTIONS: ChartOptions<"bar"> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
      labels: {},
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    }
  },
};
