import React from "react";

class PaginationPreferences extends React.Component {
  render() {
    return (
      <div className="card my-2">
        <div className="card-body">
          <h1 className="h4">Accordion Preference</h1>
          <p className="text-muted">
            Select whether accordions should be default-expanded or default-collapsed.
          </p>
          <p>Preference:</p>
          <select
            className="form-select"
            defaultValue={localStorage.getItem("paginationPreference") || "paginate"}
            onChange={(event) => {
              localStorage.setItem("paginationPreference", event.target.value);
            }}>
            <option value="paginate">Default-collapse</option>
            <option value="scroll">Default-expand</option>
          </select>
        </div>
      </div>
    );
  }
}

export default PaginationPreferences;