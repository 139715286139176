interface Row {
  cells: Cell[];
  createdAt: string;
  expanded: boolean;
  id: number;
  modifiedAt: string;
  rowNumber: number;
}

interface Cell {
  columnId: number;
  displayValue?: string;
  value?: string;
  formula?: string;
}

interface Column {
  id: number;
  index: number;
  title: string;
  type: string;
  validation: boolean;
  hidden: boolean;
  version: number;
  width: number;
}

interface Sheet {
  columns: Column[];
  rows: Row[];
  id: number;
  name: string;
  permalink: string;
  accessLevel: string;
  createdAt: string;
  readOnly: boolean;
}

class SmartsheetParser {
  private sheet: Sheet;
  private data: { [key: string]: any } = {};
  constructor(sheet: Sheet) {
    this.sheet = sheet;
  }

  public parse() {
    const columns: { [key: number]: any } = {};
    this.sheet.columns.forEach((column) => {
      columns[column.id] = { title: column.title, rows: [] };
    });

    this.sheet.rows.forEach((row) => {
      row.cells.forEach((cell) => {
        columns[cell.columnId].rows.push(cell.value || cell.displayValue || "");
      });
    });

    // map columns to data (use title as key instead of id)
    Object.keys(columns).forEach((key) => {
      this.data[columns[parseInt(key)].title] = columns[parseInt(key)].rows;
    });

    return this.data;
  }

  public getOrderDetails() {
    if (!this.data) {
      this.parse();
    }
    return {
      order_num: this.data["Contract Number"][0],
      customer: this.data["Customer"][0],
      ship_date: this.data["Committed Ship Date"][0],
      type: this.data["Type"][0],
    };
  }

  public getOrderItems() {
    if (!this.data) {
      this.parse();
    }
    const items: {
      ltc_pn: string;
      item_number: string;
      cat_num: string;
      quantity: string;
      name: string;
    }[] = [];
    // look for rows where Item Number is not empty
    this.data["Item Number"].forEach((item: any, index: number) => {
      if (item && this.data["Type"][index] === "Deliverable") {
        items.push({
          item_number: item,
          ltc_pn: this.data["LTC P/N"][index],
          cat_num: this.data["Catalog Number"][index],
          name: this.data["Task Name"][index],
          quantity: this.data["QTY"][index],
        });
      }
    });
    return items;
  }
}

export default SmartsheetParser;
