import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface EditEndUserModalProps {
  onClose: () => void;
  onSave: () => void;
  id: number;
}

interface EditEndUserModalState {
  name: string;
  defense: string;
}

class EditEndUserModal extends Component<
  EditEndUserModalProps,
  EditEndUserModalState
> {
  constructor(props: EditEndUserModalProps) {
    super(props);
    this.state = {
      name: "",
      defense: "0",
    };
  }

  loadData() {
    if (this.props.id > 0) {
      callAPI("tables/end_users", "POST", {
        where: {
          id: this.props.id,
        },
      })
        .then((res) => {
          if (res.length > 0) {
            this.setState({
              name: res[0].name,
              defense: res[0].defense,
            });
          } else {
            toast.error("Error loading end user");
          }
        })
        .catch((err) => {
          toast.error("Error loading end user");
          console.log(err);
        });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: EditEndUserModalProps) {
    if (prevProps.id !== this.props.id) {
      this.loadData();
    }
  }

  deleteEndUser() {
    if (!window.confirm("Are you sure you want to delete this end user?")) {
      return;
    }
    callAPI("actions/customers", "POST", {
      action: "delete_end_user",
      end_user_id: this.props.id,
    })
      .then((res) => {
        this.props.onSave();
        this.props.onClose();
      })
      .catch((err) => {
        toast.error("Error deleting end user");
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        open={this.props.id > 0}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Edit End User</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group me-2">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => {
                    this.deleteEndUser();
                  }}
                >Delete</button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <TextField
                label="End User Name"
                type="text"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="col-4">
              <FormControl fullWidth>
                <InputLabel id="defense-label">Defense</InputLabel>
                <Select
                  labelId="defense-label"
                  label="Defense"
                  value={this.state.defense}
                  onChange={(e) => this.setState({ defense: e.target.value })}
                >
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={!this.state.name}
              onClick={() => {
                callAPI("actions/customers", "POST", {
                  action: "edit_end_user",
                  end_user_id: this.props.id,
                  name: this.state.name,
                })
                  .then((res) => {
                    this.props.onSave();
                    this.props.onClose();
                  })
                  .catch((err) => {
                    toast.error("Error updating end user");
                    console.log(err);
                  });
              }}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </Box >
      </Modal >
    );
  }
}

export default EditEndUserModal;
