import {
  AlignmentType,
  Document,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { Buffer } from "buffer";
import { ltcLogoBase64 } from "./LTCLogo";

const FONT = "Arial";

interface Order {
  description: string;
  job_num: string;
}

interface Customer {
  name: string;
  addr_line1: string;
  addr_line2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Contact {
  name: string;
  email: string;
  phone: string;
}

interface Item {
  item_number: string;
  ship_container: string;
  catalog_number: string;
  name: string;
  product_id: string;
  image?: Image;
}

interface Image {
  id: string; // product id
  data: Buffer;
  width: number;
  height: number;
}

interface User {
  name: string;
  username: string;
}

export class IllustratedListGenerator {
  // tslint:disable-next-line: typedef
  public create(
    order: Order,
    poc: User,
    contents: Item[],
    images: Image[],
    customer: Customer,
    contact: Contact
  ): Document {
    if (!order) {
      return new Document({
        sections: [
          {
            properties: {
              page: {
                margin: {
                  top: "1 in",
                  bottom: "1 in",
                  left: "0.75 in",
                  right: "0.75 in",
                },
              },
            },
            headers: {
              default: this.createHeader(),
            },
            children: [this.createErrorPage("No order data was found.")],
          },
        ],
      });
    }

    // first group contents by ship_container
    const groupedContents = contents.reduce((acc, item) => {
      const container = item.ship_container || "Items Not in a Container";
      if (!acc[container]) {
        acc[container] = [];
      }
      item.image = images.find((image) => image.id === item.product_id);
      acc[container].push(item);
      return acc;
    }, {} as { [key: string]: Item[] });

    const elements = [
      this.createSummaryTable(order, poc, customer, contact),
      this.createTitle(order),
    ];

    Object.keys(groupedContents).forEach((container) => {
      elements.push(this.createSubtitle(container));
      elements.push(
        this.createContainerTable(container, groupedContents[container])
      );
    });

    const document = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: "1.2 in",
                bottom: "1 in",
                left: "0.75 in",
                right: "0.75 in",
              },
            },
          },
          headers: {
            default: this.createHeader(),
          },
          children: elements,
        },
      ],
    });

    return document;
  }

  private createHeader(): Header {
    return new Header({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: Buffer.from(ltcLogoBase64, "base64"),
              transformation: {
                width: 346 * 0.9,
                height: 36 * 0.9,
              },
              altText: {
                title: "LTC Logo",
                description: "LTC Logo",
                name: "LTC Logo",
              },
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              font: FONT,
              children: [
                "Page ",
                PageNumber.CURRENT,
                " of ",
                PageNumber.TOTAL_PAGES,
              ],
            }),
          ],
        }),
      ],
    });
  }

  private createTitle(order: Order): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text: order.description,
          bold: true,
          size: 32,
          font: FONT,
        }),
      ],
      spacing: {
        before: 100,
        after: 100,
      },
      // heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
    });
  }

  private createSubtitle(text: string): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text,
          bold: true,
          size: 24,
          font: FONT,
        }),
      ],
      spacing: {
        before: 100,
        after: 100,
      },
      // heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
    });
  }

  private createErrorPage(error?: string): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text:
            "An error occurred while generating the document: " +
            (error || "Unknown error"),
          bold: true,
          size: 32,
          font: FONT,
        }),
      ],
      spacing: {
        before: 100,
        after: 100,
      },
      // heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
    });
  }

  private createSummaryTable(
    order: Order,
    poc: User,
    customer: Customer,
    contact: Contact
  ): Table {
    return new Table({
      columnWidths: [2300, 2300, 1800, 4000],
      margins: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "To:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: customer.name,
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: customer.addr_line1,
                      font: FONT,
                    }),
                  ],
                }),
              ]
                .concat(
                  customer.addr_line2
                    ? [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: customer.addr_line2,
                              font: FONT,
                            }),
                          ],
                        }),
                      ]
                    : []
                )
                .concat([
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${customer.city}, ${customer.state} ${customer.country} ${customer.zip}`,
                        font: FONT,
                      }),
                    ],
                  }),
                ])
                .concat(
                  contact?.name
                    ? [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Attn: " + contact.name,
                              font: FONT,
                            }),
                          ],
                        }),
                      ]
                    : []
                ),
            }),
            new TableCell({
              width: {
                size: 1800,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Date:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "RFQ:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Ref.:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Phone:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Email:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 4000,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: order.job_num,
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: contact.phone,
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: contact.email,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "LTC P.O.C.:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: poc.name,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Email:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: poc.username + "@laser-ndt.com",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  private createContainerTable(ship_case: string, contents: Item[]): Table {
    const rows: TableRow[] = [];
    contents.forEach((item) => {
      const desiredDimensions = {
        width: 300,
        height: 200,
      };
      const desiredAspectRatio =
        desiredDimensions.width / desiredDimensions.height;
      const actualAspectRatio = item.image
        ? item.image.width / item.image.height
        : 0;
      // check if it is the width or height that is the limiting factor
      const widthIsLimiting = actualAspectRatio > desiredAspectRatio;
      const { width, height } = item.image
        ? fitImage(
            item.image,
            widthIsLimiting ? desiredDimensions.width : 0,
            widthIsLimiting ? 0 : desiredDimensions.height
          )
        : { width: 0, height: 0 };

      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "PO Item Number",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.item_number,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: item.image
                    ? [
                        new ImageRun({
                          data: item.image.data,
                          transformation: {
                            width,
                            height,
                          },
                        }),
                      ]
                    : [],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              rowSpan: 4,
              verticalAlign: VerticalAlign.CENTER,
            }),
          ],
        })
      );
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Catalog Number",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.catalog_number,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        })
      );
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Product Description",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: item.name,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        })
      );
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Serial Number",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "N/A",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        })
      );
    });
    return new Table({
      columnWidths: [2300, 2300, 4000],
      margins: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      rows,
    });
  }
}

function fitImage(image: Image, targetWidth?: number, targetHeight?: number) {
  const originalSize = { width: image.width, height: image.height };
  if (!originalSize.width || !originalSize.height)
    throw new Error("Invalid image");
  const originalAspectRatio = originalSize.width / originalSize.height;

  let width: number;
  let height: number;

  if (!targetWidth && targetHeight) {
    // fixed height, calc width
    height = targetHeight;
    width = height * originalAspectRatio;
  } else if (!targetHeight && targetWidth) {
    // fixed width, calc height
    width = targetWidth;
    height = width / originalAspectRatio;
  } else if (targetWidth && targetHeight) {
    const targetRatio = targetWidth / targetHeight;
    if (targetRatio > originalAspectRatio) {
      // fill height, calc width
      height = targetHeight;
      width = height * originalAspectRatio;
    } else {
      // fill width, calc height
      width = targetWidth;
      height = width / originalAspectRatio;
    }
  } else {
    // no target dimensions, use original
    width = originalSize.width;
    height = originalSize.height;
  }
  return {
    width,
    height,
  };
}
