import { Component } from "react";
import DefaultPageSettings from "./DefaultPageSettings";
import PaginationPreferences from "./PaginationPreferences";
import DarkThemeSettings from "./DarkThemeSettings";

interface SettingsProps {
}

interface SettingsState { }

class Settings extends Component<SettingsProps, SettingsState> {
  constructor(props: SettingsProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Settings</h1>
        </div>
        {/* <EmailSettings /> */}
        <DefaultPageSettings />
        <PaginationPreferences />
        <DarkThemeSettings />
      </div>
    );
  }
}

export default Settings;
