import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import EditProductDetails from "./EditProductDetails";

interface AddProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  prices?: boolean;
}

interface AddProductModalState {
  rows: any[];
  form: any;
  formValid: boolean;
}

class AddProductModal extends Component<
  AddProductModalProps,
  AddProductModalState
> {
  constructor(props: AddProductModalProps) {
    super(props);
    this.state = {
      rows: [],
      form: { standard: 1, inv_code: "FG" },
      formValid: false,
    };
  }

  addProduct() {
    callAPI("actions/product", "POST", {
      action: "add",
      data: this.state.form,
    })
      .then((res) => {
        toast.success("Product added");
        this.props.onClose();
        this.props.onSave();
      })
      .catch((err) => {
        toast.error("Error adding product");
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("80%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Product
          </Typography>
          <EditProductDetails
            form={this.state.form}
            onChange={(form) => {
              this.setState({ form });
            }}
            prices={this.props.prices}
            onFormValidation={(valid) => this.setState({ formValid: valid })}
          />
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              disabled={!this.state.formValid}
              onClick={() => {
                this.addProduct();
              }}
              className="btn btn-primary"
            >
              Add
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default AddProductModal;
