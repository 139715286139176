import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../../utils/API";
import { generateModalStyle } from "../../../utils/Misc";
import SmartsheetPicker from "../../orders/SmartsheetPicker";

interface SmartsheetSettingsProps { }

interface SmartsheetSettingsState {
  templateName: string;
  modalOpen: boolean;
  sheet: any;
  loading: boolean;
  error: string;
}

class SmartsheetSettings extends Component<SmartsheetSettingsProps, SmartsheetSettingsState> {
  constructor(props: SmartsheetSettingsProps) {
    super(props);
    this.state = {
      templateName: "<unknown template>",
      modalOpen: false,
      sheet: null,
      loading: true,
      error: "",
    };
  }

  componentDidMount(): void {
    this.loadData();
  }

  loadData(): void {
    callAPI("tables/smartsheet_template").then((response) => {
      if (response.length) {
        this.setState({
          templateName: response[0].name,
        });
      }
    })
      .catch((error) => {
        console.log(error);
      });
  }

  saveTemplate() {
    if (this.state.sheet) {
      callAPI("actions/settings", "POST", {
        action: "set_smartsheet_template",
        templateName: this.state.sheet.label,
        templateID: this.state.sheet.key,
      }).then((res) => {
        this.setState({
          templateName: this.state.sheet.label,
          modalOpen: false,
        });
      })
        .catch((err) => {
          console.error(err);
          toast.error("Failed to save template");
        });
    }
  }

  render() {
    return (
      <div className="card my-4">
        <div className="card-body">
          <h1 className="h4">Smartsheet Template</h1>
          <p>
            If the Smartsheet template has been deleted, change it here. If
            the template is set incorrectly, order exports to Smartsheet will
            not work correctly.
          </p>
          <span id="cur_template">
            Current template: <code>{this.state.templateName}</code>
          </span>
          <br />
          <button className="btn btn-sm btn-outline-secondary"
            onClick={() => this.setState({ modalOpen: true })}
          >Change Template</button>
        </div>
        <Modal
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
        >
          <Box sx={generateModalStyle("98%")}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select Sheet from Smartsheet
            </Typography>
            <SmartsheetPicker
              onChange={(sheet: any) => {
                this.setState({
                  sheet: sheet,
                });
              }}
              selectOption="sheets"
            />
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <button
                disabled={this.state.sheet === null}
                onClick={() => {
                  this.saveTemplate();
                }}
                className="btn btn-primary"
              >
                Select
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default SmartsheetSettings;
