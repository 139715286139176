import { useEffect, useState } from "react";
import { Box, Typography, Switch, Button, Modal } from '@mui/material';
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { CalculatedPricing } from "../bizdev/CalculatedPricing";
import { LaborTask } from "../bizdev/LaborTable";
import { MaterialItem } from "../bizdev/MaterialsTable";
import { TravelData } from "../bizdev/TravelTable";
import DeliverableEditor from "./DeliverableEditor";
import { Alert } from "@mui/material";
import ItemTreeView from "./ItemTreeView";

interface AddOrderItemModalProps {
  prices?: boolean;
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  defaultItemNumber?: string;
}

const AddOrderItemModal = (props: AddOrderItemModalProps) => {
  const [form, setForm] = useState<any>({
    catalog_number: "CUSTOM",
    ltc_pn: "N/A",
    item_number: props.defaultItemNumber || "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [customLabor, setCustomLabor] = useState<LaborTask[] | null>(null);
  const [customMaterials, setCustomMaterials] = useState<MaterialItem[] | null>(null);
  const [customTravel, setCustomTravel] = useState<TravelData | null>(null);
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [ignoreItemNumConflicts, setIgnoreItemNumConflicts] = useState<boolean>(false);

  useEffect(() => {
    callAPI("tables/order_contents_view", "POST", {
      where: {
        order_id: props.orderId,
      },
    })
      .then((res) => {
        setCurrentItems(res);
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading order contents");
          console.log(data);
        }
      });
  }, [props.orderId]);

  useEffect(() => {
    // validate form
    if (form.inv_code === "CUSTOM") {
      setErrors({
        item_number: form.item_number ? "" : "Required",
        quantity: form.quantity ? "" : "Required",
        ltc_pn: form.ltc_pn ? "" : "Required",
        catalog_number: form.catalog_number ? "" : "Required",
        name: form.name ? "" : "Required",
        category: form.category ? "" : "Required",
      });
    } else {
      setErrors({
        item_number: form.item_number ? "" : "Required",
        quantity: form.quantity ? "" : "Required",
        product_id: form.product_id ? "" : "Required",
      });
    }
  }, [form]);

  const addItem = () => {
    callAPI("actions/order", "POST", {
      order_id: props.orderId,
      action: "add_item",
      data: form,
      custom: form.inv_code === "CUSTOM",
    })
      .then((r) => {
        saveCustomPricing(r.id);
        props.onSave();
        props.onClose();
        setForm({} as any);
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error adding order item");
          console.log(data);
        }
      });
  }

  /**
   * Save any custom pricing data, if it exists
   * @param item_id the id of the item that was just saved
   */
  const saveCustomPricing = (item_id: number) => {
    if (customLabor) {
      callAPI("actions/order", "POST", {
        action: "update_custom_product_labor",
        item_id: item_id,
        data: customLabor,
      })
        .then((data) => {
          // toast.success("Custom labor updated");
        })
        .catch((data) => {
          toast.error("Error updating custom labor");
          console.log(data);
        });
    }
    if (customMaterials) {
      callAPI("actions/order", "POST", {
        action: "update_custom_product_materials",
        item_id: item_id,
        data: customMaterials,
      })
        .then((data) => {
          // toast.success("Custom materials updated");
        })
        .catch((data) => {
          toast.error("Error updating custom materials");
          console.log(data);
        });
    }
    if (customTravel) {
      callAPI("actions/order", "POST", {
        action: "update_custom_product_travel",
        item_id: item_id,
        data: customTravel,
      })
        .then((data) => {
          // toast.success("Custom travel updated");
        })
        .catch((data) => {
          toast.error("Error updating custom travel");
          console.log(data);
        });
    }
  }

  const item_number = form?.item_number;
  const item_number_exists = !ignoreItemNumConflicts && item_number && currentItems.find((item) => item.item_number === item_number);
  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={() => {
          props.onClose();
          setForm({
            catalog_number: "CUSTOM",
            ltc_pn: "N/A",
          });
          setIgnoreItemNumConflicts(false);
        }}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Deliverable
          </Typography>
          <Box
            sx={{ margin: "10px", width: "auto" }}
          >
            <Switch
              checked={form.inv_code === "CUSTOM"}
              onChange={(e) => {
                setForm({
                  ...form,
                  product_id: 0,
                  ltc_pn: e.target.checked ? "TBD" : "",
                  catalog_number: e.target.checked ? "TBD" : "",
                  name: e.target.checked ? "Custom Item" : "",
                  category: e.target.checked ? "N/A" : "",
                  price: 0,
                  cost: 0,
                  inv_code: e.target.checked ? "CUSTOM" : "N/A",
                  calculated_pricing: 0,
                });
              }}
              id="customBtn"
            />
            <label htmlFor="customBtn">
              Custom
            </label>
          </Box>
          <DeliverableEditor
            isNew
            prices={props.prices}
            form={form}
            onChange={(form) => setForm(form)}
            errors={errors}
          />
          {item_number_exists && (
            <Alert severity="warning" className="mt-2" onClose={() => {
              setIgnoreItemNumConflicts(true);
            }}>
              <p>Item number already exists in this order. If you continue, the item will be overwritten. Current tree:</p>
              <ItemTreeView items={currentItems} />
            </Alert>
          )}
          <Box
            sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}
          >
            <Button
              disabled={!Object.values(errors).every((v) => v === "")}
              onClick={() => {
                addItem();
              }}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Box>
          {props.prices && parseInt(form?.calculated_pricing) === 1 && (
            <CalculatedPricing
              onTotalsChange={(price, cost) => {
                setForm({
                  ...form,
                  price_override: 1,
                  price: price.toFixed(2),
                  cost: cost.toFixed(2),
                })
              }}
              onLaborChange={(tasks) => { setCustomLabor(tasks) }}
              onMaterialsChange={(items) => { setCustomMaterials(items) }}
              onTravelChange={(data) => { setCustomTravel(data) }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default AddOrderItemModal;
