import * as React from 'react';
import { LaborTable, LaborTask } from './LaborTable';
import { MaterialItem, MaterialsTable } from './MaterialsTable';
import { TravelData, TravelTable } from './TravelTable';

interface CalculatedPricingProps {
  item_id?: number;
  onTotalsChange: (price: number, cost: number) => void;
  onLaborChange: (tasks: LaborTask[]) => void;
  onMaterialsChange: (items: MaterialItem[]) => void;
  onTravelChange: (data: TravelData) => void;
}

interface CalculatedPricingState {
  laborPrice: number;
  laborCost: number;
  materialsPrice: number;
  materialsCost: number;
  travelPrice: number;
  travelCost: number;
}

export class CalculatedPricing extends React.Component<CalculatedPricingProps, CalculatedPricingState> {
  constructor(props: CalculatedPricingProps) {
    super(props)
    this.state = {
      laborPrice: 0,
      laborCost: 0,
      materialsPrice: 0,
      materialsCost: 0,
      travelPrice: 0,
      travelCost: 0,
    }
  }

  sendNewTotals() {
    this.props.onTotalsChange(
      this.state.laborPrice + this.state.materialsPrice + this.state.travelPrice,
      this.state.laborCost + this.state.materialsCost + this.state.travelCost
    );
  }

  render() {
    return (
      <div className="row border-top mt-3">
        <div className="col-12">
          <h1 className="h4 text-center mt-2">Labor</h1>
          <LaborTable
            onTotalsChange={(price, cost) => {
              this.setState({ laborPrice: price || 0, laborCost: cost || 0 }, () => {
                this.sendNewTotals();
              });
            }}
            onChange={(tasks) => {
              this.props.onLaborChange(tasks);
            }}
            item_id={this.props.item_id}
          />
        </div>
        <div className="col-lg-8 col-sm-12">
          <h1 className="h4 text-center mt-2">Materials</h1>
          <MaterialsTable
            onTotalsChange={(price, cost) =>
              this.setState({ materialsPrice: price || 0, materialsCost: cost || 0 }, () => {
                this.sendNewTotals();
              })
            }
            onChange={(items) => {
              this.props.onMaterialsChange(items);
            }}
            item_id={this.props.item_id}
          />
        </div>
        <div className="col-lg-4 col-sm-12">
          <h1 className="h4 text-center mt-2">Travel</h1>
          <TravelTable
            onTotalsChange={(price, cost) =>
              this.setState({ travelPrice: price || 0, travelCost: cost || 0 }, () => {
                this.sendNewTotals();
              })
            }
            onChange={(data) => {
              this.props.onTravelChange(data);
            }}
            item_id={this.props.item_id}
          />
        </div>
      </div>
    );
  }
}