import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { formatterNoCents, generateModalStyle } from "../../utils/Misc";
import { callAPI } from "../../utils/API";
import DataGrid from "../../utils/DataGrid";

const NUM_YEARS = 7;
const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface RevenueEditManuallyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface RevenueEditManuallyModalState {
  data: any;
  isLoading: boolean;
}

class RevenueEditManuallyModal extends Component<
  RevenueEditManuallyModalProps,
  RevenueEditManuallyModalState
> {
  constructor(props: RevenueEditManuallyModalProps) {
    super(props);
    this.state = {
      isLoading: true,
      data: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    callAPI("tables/manual_revenue")
      .then((res) => {
        const data = Array.from({ length: NUM_YEARS }, (_, i) => {
          const year = new Date().getFullYear() - NUM_YEARS + 1 + i;
          return {
            id: year,
            ...Object.fromEntries(MONTHS.map((month) => [month, ""])),
            ...Object.fromEntries(
              res
                .filter((row: any) => parseInt(row.year) === year)
                .map((row: any) => [MONTHS[parseInt(row.month) - 1], row.value])
            ),
          };
        });
        this.setState({
          data,
          isLoading: false
        });
      });
  }

  saveChanges() {
    const data = this.state.data.map((row: any) => {
      const year = row.id;
      const months = Object.keys(row).filter((key) => key !== "id");
      return months.map((month) => {
        return {
          year,
          month: MONTHS.indexOf(month) + 1,
          value: row[month],
        };
      });
    }).flat();

    callAPI("actions/revenue", "POST", {
      action: "update_manual_revenue",
      data,
    })
      .then((res) => {
        toast.success("Revenue updated successfully.");
        this.props.onClose();
        this.props.onSave();
      })
      .catch((err) => {
        toast.error("Failed to update revenue.");
      });
  }

  render() {
    const columns: any[] = [
      {
        field: "id",
        headerName: "Year",
        editable: false,
        valueFormatter: (p: any) => p.value,
      },
      { field: "Jan", headerName: "Jan" },
      { field: "Feb", headerName: "Feb" },
      { field: "Mar", headerName: "Mar" },
      { field: "Apr", headerName: "Apr" },
      { field: "May", headerName: "May" },
      { field: "Jun", headerName: "Jun" },
      { field: "Jul", headerName: "Jul" },
      { field: "Aug", headerName: "Aug" },
      { field: "Sep", headerName: "Sep" },
      { field: "Oct", headerName: "Oct" },
      { field: "Nov", headerName: "Nov" },
      { field: "Dec", headerName: "Dec" },
    ];
    return (
      <div>
        <Modal
          open={this.props.isOpen}
          onClose={this.props.onClose}
          aria-labelledby="selectProductModalLabel"
        >
          <Box sx={generateModalStyle("98%")}>
            <Typography variant="h6" component="h2">
              Edit Historical Revenue
            </Typography>
            <Typography>
              Any cells left blank will be calculated from the database.
            </Typography>
            <div className="pb-3 mt-3" style={{ height: "400px" }}>
              <DataGrid
                className="mt-0"
                rows={this.state.data}
                columns={columns}
                loading={this.state.isLoading}
                defaultColumnProps={{
                  sortable: false,
                  resizable: true,
                  editable: true,
                  width: 90,
                  suppressMovable: true,
                  valueFormatter: (params: any) => {
                    return !params.value ? "" : formatterNoCents.format(params.value);
                  },
                }}
                processRowUpdate={(row) => {
                  const updatedData = this.state.data.map((r: any) => {
                    if (r.id === row.id) {
                      return row;
                    }
                    return r;
                  });
                  this.setState({ data: updatedData });
                }}
              />
            </div>
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <button
                onClick={() => {
                  this.saveChanges();
                }}
                className="btn btn-outline-primary"
              >
                Save
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default RevenueEditManuallyModal;
