import { Component } from "react";
import toast from "react-hot-toast";
import { API_DOMAIN, callAPI } from "../../utils/API";
import QuoteDetailsModal from "./QuoteDetailsModal";
import QuoteSaveModal from "./QuoteSaveModal";

interface QuoteHistoryProps {
  orderId: number;
}

interface QuoteHistoryState {
  quotes: any[];
  usernames: { [key: number]: string };
  saveModalOpen: boolean;
  quoteOpen: any;
}

class QuoteHistory extends Component<QuoteHistoryProps, QuoteHistoryState> {
  constructor(props: QuoteHistoryProps) {
    super(props);
    this.state = {
      quotes: [],
      usernames: {},
      saveModalOpen: false,
      quoteOpen: null,
    };
  }

  loadData() {
    callAPI("tables/quotes", "POST", {
      where: {
        order_id: this.props.orderId,
      },
    }).then((data) => {
      this.setState({
        quotes: data,
      });
    });
    callAPI("tables/users")
      .then((data) => {
        let usernames: any = {};
        data.forEach((user: any) => {
          usernames[user.id] = user.username;
        });
        this.setState({
          usernames: usernames,
        });
      })
      .catch((data) => {
        toast.error("Error loading usernames");
      });
  }

  componentDidMount() {
    this.loadData();
  }

  deleteQuote(id: number) {
    if (
      window.confirm(
        "Are you sure you want to delete this quote? This cannot be undone."
      )
    ) {
      callAPI("actions/quote", "POST", {
        action: "delete",
        quote_id: id,
      })
        .then((data) => {
          toast.success("Quote deleted");
          this.loadData();
        })
        .catch((data) => {
          toast.error("Error deleting quote");
        });
    }
  }

  markAsSent(id: number) {
    callAPI("actions/quote", "POST", {
      action: "mark_as_sent",
      quote_id: id,
    })
      .then((data) => {
        toast.success("Quote marked as sent");
        this.loadData();
      })
      .catch((data) => {
        toast.error("Error marking quote as sent");
      });
  }

  unmarkAsSent(id: number) {
    callAPI("actions/quote", "POST", {
      action: "unmark_as_sent",
      quote_id: id,
    })
      .then((data) => {
        toast.success("Quote unmarked as sent");
        this.loadData();
      })
      .catch((data) => {
        toast.error("Error unmarking quote as sent");
      });
  }

  findUser(id: number) {
    return this.state.usernames[id] || "Unknown User";
  }

  render() {
    return (
      <div className="col-sm-12 col-md-6">
        <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-purple rounded shadow-sm">
          <img
            className="me-3"
            src="/assets/img/favicon256.png"
            alt=""
            width="48"
            height="48"
          />
          <div className="lh-100">
            <h6 className="mb-0 text-white lh-100">Quote History</h6>
            <p className="bold">{this.state.quotes[0]?.job_number}</p>
          </div>
          <button
            className="me-0 btn btn-outline-light d-flex ms-auto"
            onClick={() => this.setState({ saveModalOpen: true })}
          >
            Save New Revision
          </button>
        </div>
        <div className="my-3 p-3 bg-white rounded shadow-sm">
          {this.state.quotes?.length === 0 ? (
            <h6 className="border-bottom border-gray pb-2 mb-0">
              No saved quotes found
            </h6>
          ) : (
            this.state.quotes.map && this.state.quotes.map((quote) => (
              <div className="media text-muted pt-3" key={quote.id}>
                <h3
                  // style="font-size: 150%; width: 32px; height: 32px"
                  className="mouse-pointer"
                  onClick={() => this.setState({ quoteOpen: quote })}
                >
                  R{quote.revision}
                </h3>
                <div className="media-body pb-3 mb-0 lh-125 border-bottom border-gray">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <strong className="text-gray-dark mouse-pointer"></strong>
                  </div>
                  <span
                    className="d-block mouse-pointer"
                    onClick={() => this.setState({ quoteOpen: quote })}
                  >
                    {quote.order_num} R{quote.revision}
                  </span>
                  <span className="d-block">
                    Saved by {this.findUser(quote.saved_by)} on{" "}
                    {new Date(quote.created_at).toLocaleDateString()}
                  </span>
                  {parseInt(quote.sent_to_customer) === 1 ? (
                    <button
                      className="btn btn-sm btn-outline-primary mt-1"
                      onClick={() => this.unmarkAsSent(quote.id)}
                    >
                      Unmark as Sent
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-outline-primary mt-1"
                      onClick={() => this.markAsSent(quote.id)}
                    >
                      Mark as Sent
                    </button>
                  )}
                  {parseInt(quote.sent_to_customer) !== 1 && (
                    <button
                      className="btn btn-sm btn-outline-danger ms-1 mt-1"
                      onClick={() => this.deleteQuote(quote.id)}
                    >
                      Delete Revision
                    </button>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        <QuoteSaveModal
          orderId={this.props.orderId}
          isOpen={this.state.saveModalOpen}
          onClose={() => this.setState({ saveModalOpen: false })}
          onSave={() => this.loadData()}
        />
        <QuoteDetailsModal
          quote={this.state.quoteOpen}
          onClose={() => this.setState({ quoteOpen: null })}
          onUpdate={() => this.loadData()}
        />
      </div>
    );
  }
}

export default QuoteHistory;
