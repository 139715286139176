import { Component } from "react";
import { callAPI } from "../../utils/API";
import { formatter } from "../../utils/Misc";

interface ProjectedBookingsTableProps {
  type: string;
  onMonthClick?: (month: number) => void;
}

interface ProjectedBookingsTableState {
  data: any;
}

class ProjectedBookingsTable extends Component<
  ProjectedBookingsTableProps,
  ProjectedBookingsTableState
> {
  constructor(props: ProjectedBookingsTableProps) {
    super(props);
    this.state = {
      data: {},
    };
  }

  loadData() {
    let startAtMonth = 1;
    if (this.props.type === "Projection") {
      startAtMonth = new Date().getMonth() + 1;
    }
    callAPI("data/revenue/", "POST", {
      startAtMonth,
    }).then((response) => {
      this.setState({
        data: response,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: ProjectedBookingsTableProps) {
    if (prevProps.type !== this.props.type) {
      this.setState({ data: {} });
      this.loadData();
    }
  }

  render() {
    let months: number[] = [];
    if (this.props.type === "This Year") {
      // months are Jan-Dec
      months = Array.from({ length: 12 }, (v, k) => k + 1);
    } else {
      // months are current month to current month + 11
      const currentMonth = new Date().getMonth() + 1;
      months = Array.from({ length: 12 }, (v, k) => (k + currentMonth) % 12);
    }
    return (
      <div>
        <div className="tables mt-4">
          <p className="text-muted text-center">
            Click on any month below to view the Revenue Table
          </p>
          <table className="table table-sm text-right">
            <thead>
              <tr>
                <th>{new Date().getFullYear()}</th>
                {months.map((month) => (
                  <th
                    key={month}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (this.props.onMonthClick) {
                        this.props.onMonthClick(month || 12);
                      }
                    }}
                  >
                    {new Date(
                      new Date().getFullYear(),
                      month - 1,
                      1
                    ).toLocaleString("default", { month: "short" })}
                  </th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(this.state.data).map((key) => {
                let data = this.state.data[key];
                return (
                  <tr key={key}>
                    <td>{key}</td>
                    {data.map((value: number, index: number) => (
                      <td key={index}>{formatter.format(value)}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ProjectedBookingsTable;
