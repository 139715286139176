import { Component } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { callAPI } from "../../utils/API";
import UserContext from "../ui/UserContext";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface BuildPageProps {
  id: number;
}

interface BuildPageState {
  build: any;
}

class BuildPageWithID extends Component<
  BuildPageProps,
  BuildPageState
> {
  constructor(props: BuildPageProps) {
    super(props);
    this.state = {
      build: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    callAPI("tables/builds_view", "POST", {
      where: {
        id: this.props.id,
      }
    })
      .then((res) => {
        const build = res[0];
        build.start_date = parseDate(build.start_date);
        build.finish_date = parseDate(build.finish_date);
        build.launch_date = parseDate(build.launch_date);
        this.setState({ build });
      })
      .catch((err) => {
        toast.error("Error loading build");
        console.log(err);
      });
  }

  deleteBuild() {
    if (!window.confirm("Are you sure you want to delete this build?")) {
      return;
    }
    callAPI("actions/builds", "POST", {
      action: "delete",
      build_id: this.props.id,
    })
      .then((res) => {
        window.location.href = "/builds";
      })
      .catch((err) => {
        toast.error("Error deleting build");
        console.log(err);
      });
  }

  loadDatesFromSmartsheet() {
    callAPI("actions/builds", "POST", {
      action: "load_dates_from_smartsheet",
      build_id: this.props.id,
    })
      .then((res) => {
        this.loadData();
      })
      .catch((err) => {
        toast.error("Error loading dates from Smartsheet");
        console.log(err);
      });
  }

  saveChanges() {
    callAPI("actions/builds", "POST", {
      action: "update",
      id: this.props.id,
      data: this.state.build,
    })
      .then((res) => {
        window.location.href = "/builds";
      })
      .catch((err) => {
        toast.error("Error saving changes");
        console.log(err);
      });
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Build Details</h1>
              {!!user?.isEditor && (
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.loadDatesFromSmartsheet();
                      }}
                    >
                      Load Start/End From Smartsheet
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.deleteBuild();
                      }}
                    >
                      Delete Build
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-4 my-2">
                <TextField
                  fullWidth
                  disabled
                  label="Description"
                  type="text"
                  placeholder="Description"
                  value={this.state.build?.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-4 my-2">
                <TextField
                  fullWidth
                  label="Qty"
                  type="number"
                  placeholder="Qty"
                  value={this.state.build?.qty}
                  onChange={(e) => {
                    this.setState({
                      build: {
                        ...this.state.build,
                        qty: e.target.value,
                      },
                    });
                  }}
                  disabled={!user?.isEditor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-4 my-2">
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    label="Status"
                    value={this.state.build?.status}
                    onChange={(e) => {
                      this.setState({
                        build: {
                          ...this.state.build,
                          status: e.target.value,
                        },
                      });
                    }}
                    disabled={!user?.isEditor}
                  >
                    <MenuItem value="Planned">Planned</MenuItem>
                    <MenuItem value="Actual">Actual</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-4 my-2">
                <TextField
                  fullWidth
                  label="W/O"
                  type="text"
                  placeholder="W/O"
                  value={this.state.build?.wo}
                  onChange={(e) => {
                    this.setState({
                      build: {
                        ...this.state.build,
                        wo: e.target.value,
                      },
                    });
                  }}
                  disabled={!user?.isEditor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-4 my-2">
                <TextField
                  fullWidth
                  label="Start Date"
                  type="date"
                  value={this.state.build?.start_date}
                  onChange={(e) => {
                    this.setState({
                      build: {
                        ...this.state.build,
                        start_date: e.target.value,
                      },
                    });
                  }}
                  disabled={!user?.isEditor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-4 my-2">
                <TextField
                  fullWidth
                  label="Finish Date"
                  type="date"
                  value={this.state.build?.finish_date}
                  onChange={(e) => {
                    this.setState({
                      build: {
                        ...this.state.build,
                        finish_date: e.target.value,
                      },
                    });
                  }}
                  disabled={!user?.isEditor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-4 my-2">
                <TextField
                  fullWidth
                  label="Launch Date"
                  type="date"
                  value={this.state.build?.launch_date}
                  onChange={(e) => {
                    this.setState({
                      build: {
                        ...this.state.build,
                        launch_date: e.target.value,
                      },
                    });
                  }}
                  disabled={!user?.isEditor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-12 my-2">
                <TextField
                  fullWidth
                  label="Comment"
                  multiline
                  rows={3}
                  value={this.state.build?.comment}
                  onChange={(e) => {
                    this.setState({
                      build: {
                        ...this.state.build,
                        comment: e.target.value,
                      },
                    });
                  }}
                  disabled={!user?.isEditor}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
            {!!user?.isEditor && (
              <div className="d-flex w-100 gap-2 justify-content-end mt-2">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.saveChanges();
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

function parseDate(date: string): string {
  if (!date) return "";
  const d = new Date(date);
  // convert to yyyy-MM-dd
  return d.toISOString().split("T")[0];
}

function BuildPage({ searchTerm }: { searchTerm?: string }) {
  const { id } = useParams();
  return (
    <BuildPageWithID
      id={parseInt(id || "")}
    />
  );
}

export default BuildPage;
