import * as React from 'react';
import toast from 'react-hot-toast';
import { callAPI } from '../../utils/API';
import { DataTable, GridElement } from '../../utils/DataTable';

interface LaborTableProps {
  item_id?: number;
  onTotalsChange: (price: number, cost: number) => void;
  onChange: (tasks: LaborTask[]) => void;
}

interface LaborTableState {
  laborRates: LaborRate[];
  tasks: LaborTask[];
  totalPrice: number;
  totalCost: number;
  loaded: boolean;
  laborMargin: number;
}

interface LaborRate {
  name: string;
  rate: number;
}

export interface LaborTask {
  name: string;
  hours: number[];
}

const ORDER = [
  { name: "Scientist", id: "hours_scientist" },
  { name: "Proj Mgr", id: "hours_proj_mgr" },
  { name: "Engr III", id: "hours_engr_iii" },
  { name: "Engr II", id: "hours_engr_ii" },
  { name: "Engr I", id: "hours_engr_i" },
  { name: "Dsgnr III", id: "hours_dsgnr_iii" },
  { name: "Dsgnr II", id: "hours_dsgnr_ii" },
  { name: "Dsgnr I", id: "hours_dsgnr_i" },
  { name: "Tech III", id: "hours_tech_iii" },
  { name: "Tech II", id: "hours_tech_ii" },
  { name: "Tech I", id: "hours_tech_i" },
];

export class LaborTable extends React.Component<LaborTableProps, LaborTableState> {
  constructor(props: LaborTableProps) {
    super(props)
    this.state = {
      laborRates: [],
      tasks: [],
      totalPrice: 0,
      totalCost: 0,
      loaded: false,
      laborMargin: 0,
    }
  }

  componentDidMount() {
    this.loadLaborRates(() => {
      this.loadData();
    });
  }

  loadLaborRates(callback?: () => void) {
    callAPI("tables/labor_rates")
      .then((res) => {
        this.setState({
          laborRates: res
        }, callback);
      })
      .catch((err) => {
        toast.error("Error getting labor rates.");
      });
    callAPI("tables/overhead_rates")
      .then((res) => {
        this.setState({
          laborMargin: (res.find((r: any) => r.name === "Labor Margin").rate || 0) / 100,
        });
      });
  }

  loadData() {
    if (!this.props.item_id) {
      const res = [
        { name: "", hours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
      ];
      this.setState({
        tasks: res.map((task: any) => {
          return {
            name: task.task_name,
            hours: [
              task.hours_scientist,
              task.hours_proj_mgr,
              task.hours_engr_iii,
              task.hours_engr_ii,
              task.hours_engr_i,
              task.hours_dsgnr_iii,
              task.hours_dsgnr_ii,
              task.hours_dsgnr_i,
              task.hours_tech_iii,
              task.hours_tech_ii,
              task.hours_tech_i
            ]
          };
        }),
        loaded: true,
      });
      return;
    }
    callAPI("tables/custom_product_labor", "POST", {
      where: {
        item_id: this.props.item_id,
      }
    })
      .then((res) => {
        if (res.length === 0) {
          res = [
            { name: "", hours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
          ];
        }
        this.setState({
          tasks: res.map((task: any) => {
            return {
              name: task.task_name,
              hours: [
                task.hours_scientist,
                task.hours_proj_mgr,
                task.hours_engr_iii,
                task.hours_engr_ii,
                task.hours_engr_i,
                task.hours_dsgnr_iii,
                task.hours_dsgnr_ii,
                task.hours_dsgnr_i,
                task.hours_tech_iii,
                task.hours_tech_ii,
                task.hours_tech_i
              ]
            };
          }),
          loaded: true,
        });
      })
      .catch((err) => {
        toast.error("Error getting labor data.");
      });
  }

  generateGrid(): GridElement[][] | null {
    if (!this.state.loaded) return null;
    return [
      [{}, { value: "Rate", colSpan: 11, align: 'center', bold: true }, { value: "Margin", colSpan: 2 }, { value: this.state.laborMargin, type: "percent" }],
      [{}, ...ORDER.map((rate) => ({ value: this.state.laborRates.find((r) => r.name === rate.name)?.rate, type: "money" })), { value: "Est. Cost" }, {}, { value: "=(1-D1)*O4", type: "money", isFormula: true, watched: true }],
      [{ value: "Subtask Description" }, ...ORDER.map((rate) => ({ value: rate.name })), { value: "Hour Total" }, { value: "Price Total" }, { value: "Labor Price" },],
      ...this.state.tasks.map((task, i) => {
        return [
          { value: task.name, editable: true } as GridElement,
          ...task.hours.map((hours) => ({ value: (hours || 0).toString(), type: "number", editable: true })),
          { value: `=SUM(B${i + 4}:L${i + 4})`, type: "number", isFormula: true },
          { value: `=SUMPRODUCT(B2:L2,B${i + 4}:L${i + 4})`, type: "money", isFormula: true },
          (i === 0) ? { value: `=SUM(N4:N${this.state.tasks.length + 3})`, type: "money", isFormula: true, rowSpan: this.state.tasks.length, watched: true } : { doNotRender: true }
        ];
      })
    ] as GridElement[][];
  }

  reverseData(grid: GridElement[][]) {
    const tasks: LaborTask[] = [];
    for (let i = 3; i < grid.length; i++) {
      const task: LaborTask = {
        name: grid[i][0].value?.toString() || "",
        hours: []
      };
      for (let j = 1; j < 12; j++) {
        if (grid[i][j].type === "number") {
          task.hours.push(Math.max(0, parseFloat(grid[i][j].value?.toString() || "")));
        }
      }
      tasks.push(task);
    }
    this.setState({ tasks },
      () => {
        this.props.onChange(this.state.tasks);
      }
    );
  }

  updateTotals(watchedValues: string[]) {
    const totalPrice = parseFloat(watchedValues[1]);
    const totalCost = parseFloat(watchedValues[0]);
    const previousPrice = this.state.totalPrice;
    const previousCost = this.state.totalCost;
    if (totalPrice !== previousPrice || totalCost !== previousCost) {
      this.setState({ totalPrice, totalCost }, () => {
        this.props.onTotalsChange(this.state.totalPrice, this.state.totalCost);
      });
    }
  }

  render() {
    return (
      <div className="w-100">
        <DataTable
          defaultValue={this.generateGrid() || undefined}
          onChange={(value, watchedCells) => {
            this.reverseData(value);
            this.updateTotals(watchedCells);
          }}
        />
        <div className="d-flex justify-content-start mt-3">
          <button className="btn btn-outline-secondary" onClick={() => {
            this.setState({
              tasks: [...this.state.tasks, { name: "", hours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }],
            });
          }}>Add Row</button>
        </div>
      </div>
    )
  }
}