import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

interface JobTypeSelectProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  name?: string;
  label?: string;
  error?: boolean;
  helperText?: string
}

const JobTypeSelect: React.FC<JobTypeSelectProps> = (props) => {
  return (
    <FormControl disabled={props.disabled} fullWidth error={props.error}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        label={props.label}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value as string);
        }}
        name={props.name}
      >
        <MenuItem value="">Choose...</MenuItem>
        <MenuItem value="Order">Order</MenuItem>
        <MenuItem value="Temp Job">Temp Job</MenuItem>
        <MenuItem value="Projection 90%">Projection 90%</MenuItem>
        <MenuItem value="Projection 80%">Projection 80%</MenuItem>
        <MenuItem value="Prospect">Prospect</MenuItem>
        <MenuItem value="Shipped">Shipped</MenuItem>
        <MenuItem value="Archived">Archived</MenuItem>
      </Select>
      {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
    </FormControl>
  );
}

export default JobTypeSelect;