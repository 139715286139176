import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import PriceTierSelect from "../ui/select/PriceTierSelect";

interface AddCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const AddCustomerModal: React.FC<AddCustomerModalProps> = (props) => {
  const [form, setForm] = useState<any>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const addCustomer = () => {
    callAPI("actions/customers", "POST", {
      action: "add",
      data: form,
    })
      .then((data) => {
        props.onSave();
        props.onClose();
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error adding customer");
          console.log(data);
        }
      });
  };

  useEffect(() => {
    // validate form
    const newErrors = {
      name: form.name ? "" : "Required",
      defense: form.defense ? "" : "Required",
      price_tier: form.price_tier ? "" : "Required",
    };
    console.log(newErrors);
    setErrors(newErrors);
  }, [form]);

  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="selectProductModalLabel"
    >
      <Box sx={generateModalStyle("98%")}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Customer
        </Typography>
        <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
          <div className="col my-2">
            <TextField
              id="name-edit"
              fullWidth
              label="Customer Name"
              value={form.name || ""}
              onChange={(e) =>
                setForm(
                  { ...form, name: e.target.value },
                )
              }
              error={!!errors.name}
              helperText={errors.name}
            />
          </div>
          <div className="col my-2">
            <TextField
              id="addr_line1-edit"
              fullWidth
              label="Address Line 1"
              value={form.addr_line1 || ""}
              onChange={(e) =>
                setForm(
                  { ...form, addr_line1: e.target.value },
                )
              }
              error={!!errors.addr_line1}
              helperText={errors.addr_line1}
            />
          </div>
          <div className="col my-2">
            <TextField
              id="addr_line2-edit"
              fullWidth
              label="Address Line 2"
              value={form.addr_line2 || ""}
              onChange={(e) =>
                setForm(
                  { ...form, addr_line2: e.target.value },
                )
              }
              error={!!errors.addr_line2}
              helperText={errors.addr_line2}
            />
          </div>
          <div className="col my-2">
            <TextField
              id="city-edit"
              fullWidth
              label="City"
              value={form.city || ""}
              onChange={(e) =>
                setForm(
                  { ...form, city: e.target.value },
                )
              }
              error={!!errors.city}
              helperText={errors.city}
            />
          </div>
          <div className="col my-2">
            <TextField
              id="state-edit"
              fullWidth
              label="State"
              value={form.state || ""}
              onChange={(e) =>
                setForm(
                  { ...form, state: e.target.value },
                )
              }
              error={!!errors.state}
              helperText={errors.state}
            />
          </div>
          <div className="col my-2">
            <TextField
              id="zip-edit"
              fullWidth
              label="Zip Code"
              value={form.zip || ""}
              onChange={(e) =>
                setForm(
                  { ...form, zip: e.target.value },
                )
              }
              error={!!errors.zip}
              helperText={errors.zip}
            />
          </div>
          <div className="col my-2">
            <PriceTierSelect
              label="Price Tier"
              value={form.price_tier || ""}
              onChange={(value) =>
                setForm(
                  { ...form, price_tier: value },
                )
              }
            />
          </div>
          <div className="col my-2">
            <FormControl fullWidth error={!!errors.defense}>
              <InputLabel id="defense-edit">Defense</InputLabel>
              <Select
                label="Defense"
                labelId="defense-edit"
                value={form.defense || ""}
                onChange={(e) =>
                  setForm(
                    { ...form, defense: e.target.value },
                  )
                }
              >
                <MenuItem value="0">No</MenuItem>
                <MenuItem value="1">Yes</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="d-flex w-100 gap-2 justify-content-end mt-2">
          <button
            disabled={Object.values(errors).some((x) => x !== "")}
            onClick={() => {
              addCustomer();
            }}
            className="btn btn-primary"
          >
            Add
          </button>
        </div>
      </Box >
    </Modal >
  );
};

export default AddCustomerModal;