import { Component } from "react";
import { Link } from "react-router-dom";
import { formatter } from "../../utils/Misc";
import UserContext from "../ui/UserContext";
import { callAPI } from "../../utils/API";
import toast from "react-hot-toast";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface OrderContentsTableProps {
  order: any;
  contents: any[];
  onDelete: () => void;
  onReplaceRequest: (item_num: string) => void;
  showKitDetails?: boolean;
  prices?: boolean;
  counter?: number;
  options?: boolean;
}

interface OrderContentsTableState {
  itemMenuOpen: string;
  itemMenuAnchorEl: any;
}

export const ITEM_NUM_COMPARATOR = (a: any, b: any) => {
  // remove all non-numeric characters
  a = a.item_number.replace(/[^0-9.]/g, "").split(".");
  b = b.item_number.replace(/[^0-9.]/g, "").split(".");
  // first compare the first number, then compare the second number if it exists
  if (!b[1]) b[1] = "0";
  if (!a[1]) a[1] = "0";
  if (a[0] !== b[0]) {
    return a[0] - b[0];
  } else if (a[1] !== b[1]) {
    return a[1] - b[1];
  }
  return 0;
};

class OrderContentsTable extends Component<
  OrderContentsTableProps,
  OrderContentsTableState
> {
  constructor(props: OrderContentsTableProps) {
    super(props);
    this.state = {
      itemMenuOpen: "",
      itemMenuAnchorEl: undefined,
    };
  }

  removeItem(item_num: string) {
    if (
      window.confirm(
        "Are you sure you want to delete this item from the order?"
      )
    ) {
      callAPI("actions/order", "POST", {
        action: "delete_item",
        order_id: this.props.order?.id,
        item_number: item_num,
      })
        .then((data) => {
          toast.success("Item deleted");
          this.props.onDelete();
        })
        .catch((data) => {
          toast.error("Error deleting item");
          console.log(data);
        });
    }
  }

  refreshPrice(item_num: string) {
    let product_id = this.props.contents.find(
      (item) => item.item_number === item_num
    )?.product_id;
    if (!product_id) {
      toast.error("Error refreshing price");
      return;
    }
    callAPI("tables/products_view", "POST", {
      where: {
        id: product_id,
      },
    })
      .then((res) => {
        const product = res[0];
        if (!product) { return; }
        return callAPI("actions/order", "POST", {
          action: "edit_item",
          item_number: item_num,
          order_id: this.props.order?.id,
          data: {
            price: product.price,
            is_gsa: product.is_gsa,
          }
        })
      })
      .then((data) => {
        toast.success("Price refreshed");
        this.props.onDelete();
      })
      .catch((data) => {
        toast.error("Error refreshing price");
        console.log(data);
      });
  }

  render() {
    if (this.props.contents.length === 0) {
      return null;
    }
    let rows: {
      name: string;
      db_name: string;
      type?: string;
      link?: string;
      shared?: boolean;
      sum?: boolean;
      hideIfDesired?: boolean;
    }[] = [
        { name: "Item #", db_name: "item_number" },
        { name: "LTC P/N", db_name: "ltc_pn", link: "/products/<product_id>" },
        { name: "Cat #", db_name: "catalog_number" },
        { name: "Name", db_name: "name" },
        { name: "Shipped", db_name: "is_shipped", type: "boolean" },
        { name: "Qty", db_name: "quantity" },
        { name: "Type", db_name: "inv_code" },
        { name: "Ship Case", db_name: "ship_container" },
      ];
    if (this.props.prices) {
      rows = [
        { name: "Item #", db_name: "item_number" },
        { name: "LTC P/N", db_name: "ltc_pn", link: "/price-list/<product_id>" },
        { name: "Cat #", db_name: "catalog_number" },
        { name: "Name", db_name: "name" },
        { name: "Price", db_name: "price", type: "money", sum: true, hideIfDesired: true },
        { name: "Shipped", db_name: "is_shipped", type: "boolean" },
        { name: "Qty", db_name: "quantity" },
        { name: "Item Total", db_name: "total_price", type: "money", shared: true },
      ];
    }
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div className="my-4 w-100">
            <div className="table-responsive">
              {this.props.options &&
                (<div className="text-center"><h5>OPTIONS</h5></div>)}
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    {rows.map((row, index) => (
                      <th scope="col" key={this.props.order?.id + "_" + index}>
                        {row.name}
                      </th>
                    ))}
                    {!!user?.isEditor && (
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.props.contents
                    .sort(ITEM_NUM_COMPARATOR)
                    .map((item, i) => {
                      item = { ...item };
                      if (
                        !this.props.showKitDetails &&
                        item.item_number.includes(".")
                      ) {
                        return null;
                      }
                      const hasParent = this.props.contents.some(
                        (c) => c.item_number === item.item_number.split(".")[0]
                      );
                      const isGSA = parseInt(item['is_gsa']) === 1;
                      const orderIsGSA = this.props.order?.price_tier_name === "GSA";
                      if (isGSA && orderIsGSA && this.props.prices) {
                        item.name = item.name + " (GSA)";
                      }
                      return (
                        <tr
                          key={"content" + i}
                          className={
                            item.item_number.includes(".") ? "" : "fw-bold"
                          }
                        >
                          {rows.map((row, index) => {
                            let value = item[row.db_name];
                            if (row.type === "money") {
                              value = formatter.format(this.applyPriceTier(value, parseInt(item['is_gsa']) === 1));
                            } else if (row.type === "boolean") {
                              value = parseInt(value) ? "Yes" : "No";
                            }
                            if (row.link && parseInt(item.product_id)) {
                              value = (
                                <Link
                                  to={row.link.replace(
                                    "<product_id>",
                                    item.product_id
                                  )}
                                >
                                  {value}
                                </Link>
                              );
                            }
                            const children = this.props.contents.filter(
                              (c: any) => new RegExp("^" + item.item_number + "\\.").test(c.item_number)
                            ) || [];
                            if (row.shared || row.sum) {
                              if (children.length > 0) {
                                value = children.reduce((a: number, b: any) => a + this.applyPriceTier(parseFloat(b[row.db_name]) || 0, parseInt(b['is_gsa']) === 1), 0).toFixed(2);
                                value = formatter.format(value);
                              }
                            }
                            if (row.hideIfDesired && parseInt(item.price_hidden) === 1) {
                              return <td key={"content" + i + "_" + index}></td>;
                            }
                            if (["price", "quantity", "is_shipped"].includes(row.db_name) && children.length > 0) {
                              return <td key={"content" + i + "_" + index}></td>;
                            }
                            if (row.shared) {
                              if (this.props.showKitDetails) {
                                if (item.item_number.includes(".") && hasParent) {
                                  return null;
                                } else {
                                  return (
                                    <td key={"content" + i + "_" + index} style={{
                                      verticalAlign: "middle",
                                    }} rowSpan={children.length + 1}>
                                      {value}
                                    </td>
                                  );
                                }
                              }
                            }
                            if (row.db_name === "name" && item.notes) {
                              // add notes as bullet points
                              value = (
                                <div>
                                  {value}
                                  <ul>
                                    {item.notes.split("\n").map((note: string, i: number) => (
                                      <li key={"note" + i}>{note}</li>
                                    ))}
                                  </ul>
                                </div>
                              );
                            }
                            return (
                              <td key={"content" + i + "_" + index}>{value}</td>
                            );
                          })}
                          {!!user?.isEditor && (
                            <td>
                              <IconButton
                                id={"edit-button" + i}
                                aria-controls={this.state.itemMenuOpen === item.item_number ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={this.state.itemMenuOpen === item.item_number ? 'true' : undefined}
                                onClick={() => {
                                  this.setState({
                                    itemMenuOpen: item.item_number,
                                    itemMenuAnchorEl: document.getElementById("edit-button" + i),
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={"edit-menu" + i}
                                anchorEl={this.state.itemMenuAnchorEl}
                                open={this.state.itemMenuOpen === item.item_number}
                                onClose={() => { this.setState({ itemMenuOpen: "" }) }}
                                MenuListProps={{
                                  'aria-labelledby': 'edit-button' + i,
                                }}
                              >
                                <MenuItem
                                  component={Link}
                                  to={"./items/" + item.item_number}
                                >Edit Item</MenuItem>
                                <MenuItem onClick={() => {
                                  this.setState({ itemMenuOpen: "" });
                                  this.removeItem(item.item_number);
                                }}>Delete Item</MenuItem>
                                <MenuItem onClick={() => {
                                  this.setState({ itemMenuOpen: "" });
                                  this.props.onReplaceRequest(item.item_number);
                                }}>Replace Item</MenuItem>
                                {!!item.product_id && this.props.prices && (
                                  <MenuItem onClick={() => {
                                    this.setState({ itemMenuOpen: "" });
                                    this.refreshPrice(item.item_number);
                                  }}>Refresh Price</MenuItem>
                                )}
                              </Menu>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div >
        )
        }
      </UserContext.Consumer>
    );
  }

  applyPriceTier(price: number, isGSAProduct?: boolean): number {
    if (this.props.order?.price_tier && (this.props.order?.price_tier_name !== "GSA" || isGSAProduct)) {
      price = price * this.props.order.price_tier;
    }
    return price;
  }
}

export default OrderContentsTable;
