import { Component } from "react";
import { Link } from "react-router-dom";
import { callAPI } from "../../utils/API";
import AddCustomerModal from "./AddCustomerModal";
import UserContext from "../ui/UserContext";
import { getAccordionHeight } from "../../utils/Misc";
import DataGrid from "../../utils/DataGrid";

interface CustomersProps {
  searchTerm?: string;
}

interface CustomersState {
  data: any;
  modalOpen: boolean;
  loaded: boolean;
}

function formatLocation(customer: any): string {
  // format city, state or country
  let location = "";
  if (customer.city && customer.city.trim()) {
    location += customer.city.trim();
  }
  if (customer.state && customer.state.trim()) {
    if (location) {
      location += ", ";
    }
    location += customer.state.trim();
  } else if (customer.country && customer.country.trim()) {
    if (location) {
      location += ", ";
    }
    location += customer.country.trim();
  }
  return location;
}

const columns: any[] = [
  { field: "id", headerName: "ID", width: 90, hide: true },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    cellRenderer: (params: any) => (
      <Link to={params.data.id}>
        {params.value}
      </Link>
    ),
  },
  { field: "location", headerName: "Location", width: 150, cellRenderer: (params: any) => formatLocation(params.data) },
  { field: "price_tier", headerName: "Price Tier", width: 150 },
  { field: "tax_rate", headerName: "Tax Rate", width: 150, valueFormatter: (params: any) => params.value + "%" },
  { field: "end_users", headerName: "End User(s)", width: 150 },
  { field: "contacts", headerName: "Contact(s)", width: 150 },
];

class Customers extends Component<CustomersProps, CustomersState> {
  constructor(props: CustomersProps) {
    super(props);
    this.state = {
      data: [],
      modalOpen: false,
      loaded: false,
    };
  }

  loadData() {
    callAPI("tables/customers_view", "POST",
      {
        order: "name",
      })
      .then((response) => {
        this.setState({
          data: response,
          loaded: true,
        });
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Customers</h1>
              {!user?.isReadOnly && (
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.setState({
                          modalOpen: true,
                        });
                      }}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="pb-3" style={{ height: getAccordionHeight(this.state.data.length), width: "100%" }}>
              <DataGrid
                className="mt-0"
                rows={this.state.data}
                columns={columns}
                loading={!this.state.loaded}
                defaultColumnProps={{
                  sortable: true,
                  suppressMovable: true,
                  resizable: true,
                }}
                searchTerm={this.props.searchTerm}
              />
            </div>
            <AddCustomerModal
              isOpen={this.state.modalOpen}
              onClose={() => {
                this.setState({
                  modalOpen: false,
                });
              }}
              onSave={() => { }}
            />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Customers;
