import toast from "react-hot-toast";
export const API_DOMAIN = "https://dev.laser-ndt.org";
export const API_URL = API_DOMAIN + "/api/index.php?q=";
const FILE_SERVER_URL = "https://tfs2.laser-ndt.org/ltc-planning-sync/";

export function callAPI(
  endpoint: RequestInfo | URL,
  method: string = "GET",
  body?: any,
  type: string = "json"
) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = localStorage.getItem("jwt");
  if (jwt) {
    headers.append("Authorization", `Bearer ${jwt}`);
  }
  if (body instanceof FormData) {
    headers.delete("Content-Type");
  }
  return fetch(API_URL + endpoint, {
    method: method,
    headers: headers,
    body:
      body === undefined
        ? body
        : typeof body === "object" && body.constructor === Object
          ? JSON.stringify(body)
          : body,
    cache: "no-cache",
  })
    .then(statusCheck)
    .then((response) => {
      if (type === "json") {
        return response.json();
      } else if (type === "blob") {
        return response.blob();
      } else if (type === "text") {
        return response.text();
      } else {
        return response;
      }
    })
    .then((data) => {
      if (type === "json" && data.success === false) {
        if (data.error === "Unauthorized" && localStorage.getItem("jwt")) {
          localStorage.removeItem("jwt");
          toast.error(
            "Your session has expired. Please reload the page to log in again.",
            {
              duration: Infinity,
              id: "session-expired",
            }
          );
          throw new Error(data.error);
        } else {
          throw new Error(data.error);
        }
      }
      return data;
    });
}

export function callAPIWithCache(
  endpoint: RequestInfo | URL,
  method: string = "GET",
  body?: any,
  type: string = "json"
) {
  const cacheKey = JSON.stringify({ endpoint, method, body, type });
  const cache = getCache(cacheKey);
  return {
    cache: cache,
    result: callAPI(endpoint, method, body, type).then((data) => {
      localStorage.setItem(
        cacheKey,
        JSON.stringify({ data, timestamp: new Date().getTime() })
      );
      return data;
    }),
  };
}

export function callFileServerAPI(endpoint: string, method: string = "GET", body?: any) {
  return fetch(FILE_SERVER_URL + endpoint, {
    method: method,
    body: body,
    cache: "no-cache",
  })
    .then(statusCheck)
    .then((response) => response.json());
}

function getCache(key: string) {
  const cache = localStorage.getItem(key);
  if (cache) {
    return JSON.parse(cache);
  }
  return null;
}

function statusCheck(response: Response) {
  if (response.ok) return response;
  throw new Error(response.statusText);
}

interface JWTPayload {
  username: string;
  exp: number;
  user_id: number;
}

export class User {
  static getID(): number | undefined {
    return this.parseJWT()?.user_id;
  }
  static getUsername(): string | undefined {
    return this.parseJWT()?.username;
  }
  static parseJWT(): JWTPayload | null {
    if (localStorage.getItem("jwt")) {
      let jwt = localStorage.getItem("jwt") || "";
      let jwt_parts = jwt.split(".");
      let jwt_payload = JSON.parse(atob(jwt_parts[1]));
      return jwt_payload;
    }
    return null;
  }
}
