import { Link } from "react-router-dom";
import { relativeTime } from "../issues/Issues";
import { useEffect, useState } from "react";
import { callAPI } from "../../utils/API";

interface RelevantOrder {
    id: string;
    name: string;
    description: string;
    timestamp: string;
  }

function RecentsSidebar({ onClose }: { onClose: () => void }) {
    const [RelevantOrders, setRelevantOrders] = useState<RelevantOrder[]>([]);
    const [modifiedOrders, setModifiedOrders] = useState<RelevantOrder[]>([]);
    useEffect(() => {
        const orders = JSON.parse(localStorage.getItem('recentOrders') || '[]');
        if (Array.isArray(orders)) {
            setRelevantOrders(orders.reverse());
        } else {
            localStorage.setItem('recentOrders', '[]');
        }
        callAPI("tables/orders_view", "POST", {
            order: "last_modified_at DESC",
            limit: 5,
        }).then((res) => {
            setModifiedOrders(res.results.map((order: any) => ({
                id: order.id,
                name: order.job_num,
                description: `${order.customer}\n${order.description}`,
                timestamp: order.last_modified_at,
            })));
        });
    }, []);
    return (
        <div className="border-start bg-light d-none d-lg-block" style={{ width: '25%' }}>
            <button
                style={{ position: 'absolute', right: 28, top: 52, fontSize: '0.5rem' }}
                type="button"
                onClick={() => onClose()}
                className="btn btn-sm btn-close"
            ></button>
            <h3 className="p-3 h6 text-muted">Recently Viewed</h3>
            <div className="list-group list-group-flush">
                {RelevantOrders.map((order, index) => (
                    <Link key={index} className="list-group-item list-group-item-action py-3 lh-tight" to={`./${order.id}`}>
                        <div className="d-flex w-100 align-items-center justify-content-between">
                            <strong className="mb-1">{order.name}</strong>
                            <small className="text-muted">{relativeTime(order.timestamp)}</small>
                        </div>
                        <div className="col-10 mb-1 small">{order.description}</div>
                    </Link>
                ))}
                {RelevantOrders.length === 0 && (
                    <p className="mx-3 text-muted">No recent orders</p>
                )}
            </div>
            <h3 className="p-3 h6 text-muted">Recently Modified</h3>
            <div className="list-group list-group-flush">
                {modifiedOrders.map((order, index) => (
                    <Link key={index} className="list-group-item list-group-item-action py-3 lh-tight" to={`/bizdev/${order.id}`}>
                        <div className="d-flex w-100 align-items-center justify-content-between">
                            <strong className="mb-1">{order.name}</strong>
                            <small className="text-muted">{relativeTime(order.timestamp)}</small>
                        </div>
                        <div className="col-10 mb-1 small">{order.description}</div>
                    </Link>
                ))}
                {modifiedOrders.length === 0 && (
                    <p className="mx-3 text-muted">No recently modified orders</p>
                )}
            </div>
        </div>
    );
}

export default RecentsSidebar;