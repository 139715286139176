import { Component } from "react";
import { Link } from "react-router-dom";
import UserContext from "../ui/UserContext";

interface OrderCardProps {
  order: any;
  prices?: boolean;
  expanded?: boolean;
  onExpandBtnClick?: () => void;
  onEdit: () => void;
}

class OrderCard extends Component<OrderCardProps> {
  render() {
    let customProps: any[] = [];
    if (this.props.prices) {
      customProps = [
        {
          name: "Price Tier",
          db_name: "price_tier_name",
        },
        {
          name: "Contact",
          db_name: "contact",
        },
        // {
        //   name: "Last Updated",
        //   db_name: "last_updated",
        // },
      ];
    }
    if (!this.props.order) {
      return (
        <div
          className={
            this.props.expanded ? "col-12" : "col-6"
          }
        >
          <div className="card card-body color-prospect">

          </div>
        </div>
      );
    }
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div
            className={
              this.props.expanded ? "col-12" : "col-6"
            }
          >
            <div className="card card-body color-prospect">
              <h3>
                <span
                  className={user?.isEditor ? "link mouse-pointer" : ""}
                  onClick={user?.isEditor ? () => this.props.onEdit() : undefined}
                >
                  {!!this.props.order.smartsheet_id && (
                    <a href={"https://app.smartsheet.com/sheets/" + this.props.order.smartsheet_id} target="_blank" rel="noreferrer">
                      <img title="Connected to Smartsheet" height="15px" width="15px" src="/assets/img/smartsheet-icon.ico" alt="Smartsheet Logo" />
                    </a>
                  )}
                  {this.props.order.order_num}
                  {this.props.prices && this.props.order.price_tier_name === "GSA" && (
                    <img title="GSA" height="28px" width="28px" className="ms-2" src="/assets/img/gsa.png" alt="GSA Logo" />
                  )}
                </span>
              </h3>
              {!this.props.expanded && (
                <p className="mb-1">Job Number: {this.props.order.job_num}</p>
              )}
              <p className="mb-1">Description: {this.props.order.description}</p>
              {!this.props.expanded && <p className="mb-1">Type: {this.props.order.type}</p>}
              <p className="mb-1">
                Customer:
                <Link
                  className="ms-2"
                  to={"/customers/" + this.props.order.customer_id}
                >
                  {this.props.order.customer}
                </Link>
              </p>
              <p className="mb-1">
                End User:
                <Link
                  className="ms-2"
                  to={
                    "/customers/" +
                    this.props.order.customer_id +
                    "/end_users/" +
                    this.props.order.end_user_id
                  }
                >
                  {this.props.order.end_user}
                </Link>
              </p>
              {!this.props.expanded && (
                <p className="mb-1">Expected Ship Date: {this.props.order.ship_date}</p>
              )}
              {!this.props.expanded &&
                customProps.map((prop) => {
                  return (
                    <p className="mb-1" key={prop.db_name}>
                      {prop.name}: {this.props.order[prop.db_name]}
                    </p>
                  );
                })}
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default OrderCard;
