import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { generateModalStyle } from "../../utils/Misc";

interface ReorderItemsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (items: any) => void;
  contents: any[];
}

const ReorderItemsModal = (props: ReorderItemsModalProps) => {
  const [items, setItems] = useState((props.contents || []).filter((item) => !item.item_number.includes('.')));

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // create a new items array
    const newItems = Array.from(items);
    // remove the item from source index
    const [removed] = newItems.splice(source.index, 1);
    // insert the item at destination index
    newItems.splice(destination.index, 0, removed);
    // update item numbers
    newItems.forEach((item, index) => {
      item.item_number = `${index + 1}`;
    });

    // update state
    setItems(newItems);
  };

  useEffect(() => {
    setItems((props.contents || []).filter((item) => !item.item_number.includes('.')));
  }, [props.contents]);


  return (
    <div>
      <Modal open={props.isOpen} onClose={props.onClose} aria-labelledby="selectProductModalLabel">
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reorder Items
          </Typography>
          <p>Click and drag to reorder items.</p>
          <DragDropContext onDragEnd={handleDragEnd} >
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Item Number</th>
                  <th>LTC PN</th>
                  <th>Name</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <Droppable droppableId="users">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <td>{item.item_number}</td>
                            <td>{item.ltc_pn}</td>
                            <td>{item.name}</td>
                            <td>{item.quantity}</td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button onClick={()=>{
              props.onSave(items);
              props.onClose();
            }} className="btn btn-primary">
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ReorderItemsModal;