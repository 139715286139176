import { Component } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { API_DOMAIN, callAPI } from "../../utils/API";
import EditProductModal from "./EditProductModal";
import ProductSelectModal from "./ProductSelectModal";
import UserContext from "../ui/UserContext";
import { formatter } from "../../utils/Misc";
import PriceHistoryModal from "./PriceHistoryModal";

interface ViewProductProps {
  id: number;
  prices?: boolean;
}

interface ViewProductState {
  isLoading: boolean;
  product: any;
  editModalOpen: boolean;
  addSubproductModalOpen: boolean;
  priceHistoryModalOpen: boolean;
  subproducts: any[];
}

class ViewProductWithID extends Component<ViewProductProps, ViewProductState> {
  constructor(props: ViewProductProps) {
    super(props);
    this.state = {
      isLoading: true,
      product: {},
      editModalOpen: false,
      addSubproductModalOpen: false,
      priceHistoryModalOpen: false,
      subproducts: [],
    };
  }

  loadData() {
    callAPI("tables/products_view", "POST", {
      where: {
        id: this.props.id,
      },
    })
      .then((data) => {
        this.setState({
          isLoading: false,
          product: data[0],
          subproducts: [],
        });
        const children = JSON.parse(data[0].children || "[]");
        if (children.length > 0) {
          callAPI("tables/products_view", "POST", {
            where: {
              id: children,
            },
          })
            .then((data) => {
              this.setState({
                subproducts: data,
              });
            })
            .catch((err) => {
              toast.error(err);
            });
        }
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading product");
          console.log(data);
        }
      });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: ViewProductProps) {
    if (prevProps.id !== this.props.id) {
      this.loadData();
    }
  }

  duplicateProduct() {
    callAPI("actions/product", "POST", {
      action: "duplicate",
      id: this.props.id,
    })
      .then((data) => {
        if (data.id) {
          toast.success("Product duplicated");
          window.location.href = "/products/" + data.id;
        }
      })
      .catch((data) => {
        toast.error("Error duplicating product");
        console.log(data);
      });
  }

  archiveProduct() {
    if (window.confirm("Are you sure you want to archive this product")) {
      callAPI("actions/product", "POST", {
        action: "archive",
        id: this.props.id,
      })
        .then((data) => {
          toast.success("Product archived");
          window.history.back();
        })
        .catch((data) => {
          toast.error("Error archiving product");
          console.log(data);
        });
    }
  }

  deleteProduct() {
    if (
      window.confirm(
        "Are you sure you want to delete this product This action cannot be undone."
      )
    ) {
      callAPI("actions/product", "POST", {
        action: "delete",
        id: this.props.id,
      })
        .then((data) => {
          toast.success("Product deleted");
          window.history.back();
        })
        .catch((data) => {
          toast.error("Error deleting product");
          console.log(data);
        });
    }
  }

  addSubproduct(subproduct_id: number) {
    callAPI("actions/product", "POST", {
      action: "add_subproduct",
      id: this.props.id,
      subproduct_id: subproduct_id,
    })
      .then((data) => {
        this.loadData();
      })
      .catch((data) => {
        toast.error("Error adding subproduct");
        console.log(data);
      });
  }

  removeSubproduct(subproduct_id: number) {
    if (window.confirm("Are you sure you want to remove this subproduct")) {
      callAPI("actions/product", "POST", {
        action: "remove_subproduct",
        id: this.props.id,
        subproduct_id: subproduct_id,
      })
        .then((data) => {
          this.loadData();
        })
        .catch((data) => {
          toast.error("Error removing subproduct");
          console.log(data);
        });
    }
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }
    if (!this.state.product) {
      return <div>Product not found</div>;
    }
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex border-bottom justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
              <h1 className="h2">Product Details</h1>
              {!!user?.isEditor && (
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    {!!user?.isAdmin && this.props.prices && (
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => {
                          this.setState({ priceHistoryModalOpen: true });
                        }}
                      >
                        Price History
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.duplicateProduct();
                      }}
                    >
                      Duplicate
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.archiveProduct();
                      }}
                    >
                      Archive
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.deleteProduct();
                      }}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.setState({ editModalOpen: true });
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
            {parseInt(this.state.product.archived) === 1 && (
              <div className="alert alert-warning" role="alert">
                This product is archived. It will not be displayed in the products list.
              </div>
            )}
            {this.state.product.inv_code === "CUSTOM" && (
              <div className="alert alert-warning" role="alert">
                This product represents a custom deliverable, created for a particular order.
              </div>
            )}
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <strong className="d-inline-block mb-2 text-primary">
                  {this.state.product.category}
                </strong>
                <h3 className="mb-0">{this.state.product.ltc_pn}</h3>
                <div className="mb-1 text-muted">{this.state.product.cat_num}</div>
                <p className="card-text mb-auto">{this.state.product.name}</p>
                <hr />
                <p className="mb-1">{this.state.product.standard ? "Standard" : "Custom"}</p>
                {!!user?.hasPriceAccess && (
                  <p className="mb-1">
                    <strong>Price:</strong> {formatter.format(this.state.product.price)}
                  </p>
                )}
                {!!user?.hasPriceAccess && (
                  <p className="mb-1">
                    <strong>Cost:</strong> {formatter.format(this.state.product.cost)}
                  </p>
                )}
              </div>
              <div
                className="col-auto d-none d-lg-block"
                style={{ background: "#fff", height: "100%", maxWidth: "400px" }}
              >
                <img
                  className="bd-placeholder-img"
                  alt="Product"
                  src={API_DOMAIN + this.state.product.image}
                  style={{ width: "100%", height: "100%", objectFit: "contain" }}
                  onError={(e) => {
                    (e.target as HTMLImageElement).src =
                      "/assets/img/default-product-image.jpg";
                  }}
                />
              </div>
            </div>
            <div>
              <h4>Subproducts:</h4>
              <table className="table table-striped table-sm">
                <thead>
                  <tr>
                    <th>LTC P/N</th>
                    <th>Cat #</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th># Rqmnts</th>
                    <th># Available</th>
                    {!!user?.hasPriceAccess && (
                      <th>Price</th>
                    )}
                    {!!user?.isEditor && (
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.subproducts.map((subproduct: any) => (
                    <tr key={subproduct.id}>
                      <td>
                        <Link to={"/products/" + subproduct.id}>
                          {subproduct.ltc_pn}
                        </Link>
                      </td>
                      <td>{subproduct.cat_num}</td>
                      <td>{subproduct.name}</td>
                      <td>{subproduct.category}</td>
                      <td>{subproduct.num_rqmnts}</td>
                      <td>{subproduct.available}</td>
                      {!!user?.hasPriceAccess && (
                        <td>{formatter.format(subproduct.price)}</td>
                      )}
                      {!!user?.isEditor && (
                        <td>
                          <button
                            className="btn"
                            title="Remove Subproduct"
                            onClick={() => {
                              this.removeSubproduct(subproduct.id);
                            }}
                          >
                            ✖
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {!!user?.isEditor && (
                <p>
                  <button
                    className="btn btn-sm btn-link"
                    onClick={() => {
                      this.setState({ addSubproductModalOpen: true });
                    }}
                  >
                    + Add a subproduct
                  </button>
                </p>
              )}
            </div>
            <EditProductModal
              isOpen={this.state.editModalOpen}
              product={this.state.product}
              onClose={() => {
                this.setState({ editModalOpen: false });
              }}
              onSave={() => {
                this.loadData();
              }}
              prices={this.props.prices}
            />
            <ProductSelectModal
              isOpen={this.state.addSubproductModalOpen}
              onClose={() => {
                this.setState({ addSubproductModalOpen: false });
              }}
              onSelect={(products: number[]) => {
                this.addSubproduct(products[0]);
                this.setState({ addSubproductModalOpen: false });
              }}
            />
            {!!user?.isAdmin && this.props.prices && (
              <PriceHistoryModal
                product_id={this.state.product.id}
                isOpen={this.state.priceHistoryModalOpen}
                onClose={() => {
                  this.setState({ priceHistoryModalOpen: false });
                }}
              />
            )}
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

function ViewProduct({ prices }: { prices?: boolean }) {
  const { id } = useParams();
  return <ViewProductWithID id={parseInt(id || "")} prices={prices} />;
}

export default ViewProduct;
