import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OrderGroup from "../orders/OrderGroup";
import NewOrderModal from "../orders/NewOrderModal";
import UserContext from "../ui/UserContext";
import RecentsSidebar from "../orders/RecentsSidebar";

interface BizDevProps {
  searchTerm?: string;
}

interface RecentlyViewedOrder {
  id: string;
  name: string;
  description: string;
  timestamp: string;
}

const BizDev: React.FC<BizDevProps> = (props: BizDevProps) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const navigate = useNavigate();
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <div className="d-flex">
          <div className="w-100">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">
                Quotes &amp; Estimates
              </h1>

              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  {!user?.isReadOnly && (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        setAddModalOpen(true);
                      }}
                    >
                      Add New
                    </button>
                  )}
                  <Link
                    className="btn btn-sm btn-outline-secondary"
                    to="/projections-summary"
                  >
                    Projections Summary
                  </Link>
                </div>
              </div>
            </div>
            <OrderGroup
              prices
              name="Communication"
              types={["COMMUNICATION"]}
              use="quote_type"
              searchTerm={props.searchTerm}
            />
            <OrderGroup
              prices
              name="Quotes"
              types={["QUOTATION"]}
              use="quote_type"
              searchTerm={props.searchTerm}
            />
            <OrderGroup
              prices
              name="Cost Estimates"
              types={["COST_ESTIMATE"]}
              use="quote_type"
              searchTerm={props.searchTerm} />
            <OrderGroup
              prices
              name="Shipped"
              types={["Shipped"]}
              use="type"
              searchTerm={props.searchTerm} />
            <OrderGroup
              prices
              name="Archived"
              types={["Archived"]}
              use="type"
              searchTerm={props.searchTerm} />
            <NewOrderModal
              isOpen={addModalOpen}
              onClose={() => {
                setAddModalOpen(false);
              }}
              prices
              onSave={(id) => {
                navigate("/bizdev/" + id);
              }}
            />
          </div>
          {showSidebar && (
            <RecentsSidebar onClose={() => setShowSidebar(false)} />
          )}
        </div>
      )
      }
    </UserContext.Consumer >
  );
}

export default BizDev;
