import React from "react";
import { Account } from "../../utils/Auth";

const UserContext = React.createContext({
  user: null,
} as {
  user: Account | null;
});

export default UserContext;
