import { Tooltip } from "@mui/material";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, callAPIWithCache } from "../../utils/API";
import { INVENTORY_UPLOAD_MAX_FILE_SIZE } from "../../utils/Misc";
import "./Inventory.css";
import UserContext from "../ui/UserContext";
import DataGrid from "../../utils/DataGrid";

let timeout: NodeJS.Timeout | null = null;

interface InventoryProps {
  searchTerm?: string;
}

interface InventoryState {
  data: any;
  compareData: any;
  onlyShowAlerts: boolean;
  inv_date: string;
  loading: boolean;
}

function getAlertMsg(alert: any): string {
  let msg = "";
  if (alert.type === 2) {
    msg =
      "Qty to be built (" +
      alert.qb +
      ") from QB does not match actual builds (" +
      alert.actual +
      ")";
  } else if (alert.type === 3) {
    msg = "Multiple products found";
  }
  return msg;
}

class Inventory extends Component<InventoryProps, InventoryState> {
  constructor(props: InventoryProps) {
    super(props);
    this.state = {
      data: [],
      compareData: [],
      onlyShowAlerts: false,
      inv_date: "",
      loading: true,
    };
  }

  loadData() {
    let search = undefined;
    if (this.props.searchTerm) {
      search = {
        query: "%" + this.props.searchTerm + "%",
        fields: [
          "ltc_pn",
          "rev",
          "type",
          "inv_code",
          "category",
          "description",
        ],
      };
    }
    const onResult = () => {
      const date_elem = this.state.data
        .find((item: any) => item.item === "INV_DATE");
      if (date_elem) {
        this.setState({ inv_date: date_elem.description.replaceAll(" ", "-") });
      }
    };
    const cacheResult = callAPIWithCache("tables/inventory", "POST", { search });
    if (cacheResult.cache && cacheResult.cache.data) {
      this.setState({
        data: cacheResult.cache.data,
        loading: false,
      }, onResult);
    }
    cacheResult.result.then((response) => {
      this.setState({
        data: response,
        loading: false,
      }, onResult);
    });
  }

  loadCompare() {
    let cacheResult = callAPIWithCache("actions/inventory-comparison", "POST");
    if (cacheResult.cache && cacheResult.cache.data) {
      this.setState({
        compareData: cacheResult.cache.data.alerts,
      });
    }
    cacheResult.result.then((response) => {
      this.setState({
        compareData: response.alerts,
      });
    });
    toast.promise(cacheResult.result, {
      loading: "Comparing products...",
      success: "Success!",
      error: "Error performing inventory/product comparison",
    });
  }

  componentDidMount() {
    this.loadData();
    this.loadCompare();
  }

  componentDidUpdate(prevProps: Readonly<InventoryProps>): void {
    if (prevProps.searchTerm !== this.props.searchTerm) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        this.loadData();
      }, 500);
    }
  }

  componentWillUnmount() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }

  uploadInventory() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".xlsx";
    fileInput.onchange = (e: any) => {
      const file = e.target.files[0];
      const formData = new FormData();
      if (file.size > INVENTORY_UPLOAD_MAX_FILE_SIZE) {
        toast.error("File is too large");
        return;
      }
      formData.append("file-input", file);
      formData.append("upload", "true");
      callAPI("upload/inventory", "POST", formData).then(
        (response) => {
          if (response.success) {
            this.loadData();
            this.loadCompare();
          } else {
            toast.error("Error uploading file");
          }
        }
      )
        .catch((error) => {
          toast.error("Error uploading file");
        });
    };
    fileInput.click();
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">QuickBooks Inventory</h1>
              {!user?.isReadOnly && (
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="btn-group me-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        this.uploadInventory();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                  <input type="file" className="d-none" accept=".xlsx, .xls" />
                </div>
              )}
            </div>
            {this.state.compareData.length > 0 && (
              <div className="alert alert-warning" role="alert">
                <strong>Warning!</strong> There are span{" "}
                {this.state.compareData.length} items that need your attention.
                <br />
                Click{" "}
                <button
                  className="btn btn-link p-0 mb-1"
                  onClick={() => {
                    this.setState({ onlyShowAlerts: !this.state.onlyShowAlerts });
                  }}
                >
                  here
                </button>{" "}
                to{" "}
                {this.state.onlyShowAlerts
                  ? "show all items"
                  : "only show items with alerts"}
                .
              </div>
            )}
            <h1 className="h5">
              Inventory Date:{" "}
              {this.state.inv_date}
            </h1>
            <div style={{ height: "70vh" }}>
              <DataGrid
                className="mt-0"
                rows={this.state.data
                  .filter((row: any) => {
                    if (row.item === "INV_DATE") {
                      return false;
                    }
                    const alert = this.state.compareData.find(
                      (alert: any) => alert.id === row.id
                    );
                    if (this.state.onlyShowAlerts && !alert) {
                      return false;
                    }
                    if (this.props.searchTerm) {
                      const fields = [
                        "ltc_pn",
                        "rev",
                        "type",
                        "inv_code",
                        "category",
                        "description",
                      ];
                      for (const field of fields) {
                        if (
                          row[field] &&
                          row[field]
                            .toLowerCase()
                            .includes(this.props.searchTerm.toLowerCase())
                        ) {
                          return true;
                        }
                      }
                      return false;
                    }
                    return true;
                  })}
                getRowClassName={(params: any) => {
                  const alert = this.state.compareData.find(
                    (alert: any) => parseInt(alert.id) === parseInt(params.data.id)
                  );
                  return alert ? "table-danger" : "";
                }}
                columns={[
                  { field: "ltc_pn", headerName: "LTC P/N", width: 120 },
                  { field: "rev", headerName: "Rev", width: 60 },
                  { field: "description", headerName: "Description", width: 220 },
                  { field: "qty_oh", headerName: "Qty OH", width: 70 },
                  { field: "qty_so", headerName: "Qty SO", width: 70 },
                  { field: "qty_tbb", headerName: "Qty TBB", width: 70 },
                  { field: "qty_pb", headerName: "Qty PB", width: 70 },
                  { field: "qty_po", headerName: "Qty PO", width: 70 },
                  { field: "type", headerName: "Type", width: 130 },
                  { field: "location", headerName: "Bin loc.", width: 100 },
                  { field: "inv_code", headerName: "Inv. Code", width: 100 },
                  { field: "cost", headerName: "Cost", width: 80 },
                  { field: "cat_num", headerName: "Cat #", width: 120 },
                  { field: "category", headerName: "Category", width: 75 },
                  { field: "standard", headerName: "Std/Cust", width: 100, valueFormatter: (params: any) => params.value === "1" ? "Standard" : "Custom" },
                  {
                    field: "message", headerName: "", width: 50, cellRenderer: (params: any) => {
                      const alert = this.state.compareData.find(
                        (alert: any) => alert.id === params.data.id
                      );
                      return (
                        <div>
                          {alert ? (
                            <Tooltip title={getAlertMsg(alert)}>
                              <i className="bi bi-info-circle"></i>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }
                  },
                ]}
                loading={this.state.loading}
                defaultColumnProps={{
                  sortable: true,
                  resizable: true,
                  suppressMovable: true,
                }}
              />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Inventory;
