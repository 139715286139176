import { Component } from "react";
import { formatter } from "../../utils/Misc";
import { getItemPrice } from "../../utils/QuoteGenerator";
import { Alert } from "@mui/material";

interface QuoteTotalsProps {
  order: any;
  contents: any[];
}

class QuoteTotals extends Component<QuoteTotalsProps> {
  getSubTotal() {
    return this.props.contents.filter((item) => !parseInt(item.is_option)).reduce((acc, item) => {
      return acc + getItemPrice(
        item,
        this.props.contents,
        this.props.order.price_tier_name === "GSA",
        this.props.order.price_tier,
        true
      );
    }, 0);
  }

  getGSATotal() {
    if (this.props.order.price_tier_name !== "GSA") return 0;
    return this.props.contents.filter((item) => !parseInt(item.is_option)).reduce((acc, item) => {
      if (this.props.contents.filter((item) => !parseInt(item.is_option))
        .filter((i) => {
          const regex = new RegExp(`^${item.item_number}\\.[0-9]+$`);
          return regex.test(i.item_number);
        }).length === 0) return acc;
      return acc + getItemPrice(
        item,
        this.props.contents.filter((i) => parseInt(i.is_gsa) === 1),
        this.props.order.price_tier_name === "GSA",
        this.props.order.price_tier,
        true
      );
    }, 0);
  }

  getTotalCost() {
    return this.props.contents.filter((item) => !parseInt(item.is_option)).reduce((acc, item) => {
      return acc + item.cost * (item.quantity || 0);
    }, 0);
  }

  render() {
    if (!this.props.order) return null;
    const subTotal = this.getSubTotal();
    const gsaTotal = this.getGSATotal();
    const margin = subTotal - this.getTotalCost();
    const shipping = parseFloat(this.props.order.shipping);
    const ccFee = (this.props.order.credit_card_percent / 100) * (subTotal + shipping);
    const total = subTotal + ccFee + shipping;
    return (
      <div className="text-right border-top pt-3">
        <h1 className="h6">Subtotal: {formatter.format(subTotal)}</h1>
        {this.props.order.price_tier_name === "GSA" ? (
          <>
            <h1 className="h6 ms-4">GSA: {formatter.format(gsaTotal)}</h1>
            <h1 className="h6 ms-4">Non-GSA: {formatter.format(subTotal - gsaTotal)}</h1>
            {gsaTotal > 200000 && (
              <Alert severity="info" className="col-12 my-2">
                GSA orders over $200,000 typically get a 2% discount. These totals do not reflect this discount.
              </Alert>
            )}
          </>
        ) : null}
        <h1 className="h6">Margin: {formatter.format(margin)}</h1>
        <h1 className="h6">Shipping &amp; Handling: {shipping > 0 ? formatter.format(shipping) : "Not Included"}</h1>
        {ccFee > 0 ? (
          <h1 className="h6">{this.props.order.credit_card_percent}% Credit Card Transaction Fee: {formatter.format(ccFee)}</h1>
        ) : null}
        <h1 className="h5">Total: {formatter.format(total)}</h1>
      </div>
    );
  }
}

export default QuoteTotals;
