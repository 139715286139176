import * as React from 'react';
import toast from 'react-hot-toast';
import { callAPI } from '../../utils/API';
import { DataTable, GridElement } from '../../utils/DataTable';

interface MaterialsTableProps {
  item_id?: number;
  onTotalsChange: (price: number, cost: number) => void;
  onChange: (items: MaterialItem[]) => void;
}

interface MaterialsTableState {
  items: MaterialItem[];
  totalPrice: number;
  totalCost: number;
  loaded: boolean;
  adminOH: number;
  mtlOH: number;
  passThruOH: number;
}

export interface MaterialItem {
  name: string;
  raw_materials: string;
  type: 'Parts' | 'Pass-thru';
}

export class MaterialsTable extends React.Component<MaterialsTableProps, MaterialsTableState> {
  constructor(props: MaterialsTableProps) {
    super(props)
    this.state = {
      items: [],
      totalPrice: 0,
      totalCost: 0,
      loaded: false,
      adminOH: 0,
      mtlOH: 0,
      passThruOH: 0,
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    callAPI("tables/overhead_rates")
      .then((res) => {
        this.setState({
          adminOH: (res.find((r: any) => r.name === "Admin Rate").rate || 0) / 100,
          mtlOH: (res.find((r: any) => r.name === "MTL Rate").rate || 0) / 100,
          passThruOH: (res.find((r: any) => r.name === "Pass-through Rate").rate || 0) / 100,
        });
      });
    if (!this.props.item_id) {
      this.setState({
        loaded: true,
        items: [
          { name: "", raw_materials: "0", type: "Parts" },
        ],
      });
      return;
    }
    callAPI("tables/custom_product_materials", "POST", {
      where: {
        item_id: this.props.item_id,
      }
    })
      .then((res) => {
        if (res.length === 0) {
          res = [
            { name: "", raw_materials: "0", type: "Parts" },
          ];
        }
        this.setState({
          items: res,
          loaded: true,
        });
      })
      .catch((err) => {
        toast.error("Error getting materials data.");
      });
  }

  generateGrid(): GridElement[][] | null {
    if (!this.state.loaded) return null;
    return this.state.items.map((item, index) => {
      return [
        { value: item.name, editable: true },
        { value: item.raw_materials, type: "money", editable: true },
        { value: item.type, editable: true, type: 'select', selectOptions: ['Parts', 'Pass-thru'] },
        { value: item.type === 'Parts' ? this.state.mtlOH : this.state.passThruOH, type: "percent" },
        { value: `=B${index + 1}*D${index + 1}`, isFormula: true, type: "money" },
        {
          value: item.type === 'Parts' ? (
            (parseFloat(item.raw_materials) + parseFloat(item.raw_materials) * (item.type === 'Parts' ? this.state.mtlOH : this.state.passThruOH)) * this.state.adminOH
          ) : "", type: "money"
        },
        index === 0 ? { value: `=SUM(E1:F${this.state.items.length})+SUM(B1:B${this.state.items.length})`, type: "money", isFormula: true, rowSpan: this.state.items.length, watched: true } : { doNotRender: true }
      ]
    });
  }

  updateTotals(watchedValues: string[]) {
    const totalPrice = parseFloat(watchedValues[0]);
    // cost is the sum of the raw materials
    const totalCost = this.state.items.reduce((acc, item) => {
      return acc + parseFloat(item.raw_materials);
    }, 0);
    const previousPrice = this.state.totalPrice;
    const previousCost = this.state.totalCost;
    if (totalPrice !== previousPrice || totalCost !== previousCost) {
      this.setState({ totalPrice, totalCost }, () => {
        this.props.onTotalsChange(this.state.totalPrice, this.state.totalCost);
      });
    }
  }

  reverseData(grid: GridElement[][], callback?: () => void) {
    const items: MaterialItem[] = [];
    for (let i = 0; i < grid.length; i++) {
      const item: MaterialItem = {
        name: grid[i][0].value?.toString() || "",
        raw_materials: grid[i][1].value?.toString() || "",
        type: grid[i][2].value?.toString() === "Parts" ? "Parts" : "Pass-thru",
      };
      items.push(item);
    }
    this.setState({ items }, () => {
      this.props.onChange(this.state.items);
      if (callback) callback();
    });
  }

  render() {
    return (
      <div className="w-100">
        <DataTable
          columns={[
            { label: 'Items' },
            { label: 'Raw Materials' },
            { label: 'Type' },
            { label: 'MTL OH %' },
            { label: 'MTL OH' },
            { label: 'Admin OH (' + this.state.adminOH * 100 + '%)' },
            { label: 'Total Material' },
          ]}
          defaultValue={this.generateGrid() || undefined}
          onChange={(value, watchedCells) => {
            this.reverseData(value, () => {
              this.updateTotals(watchedCells);
            });
          }}
        />
        <div className="d-flex justify-content-start mt-3">
          <button className="btn btn-outline-secondary" onClick={() => {
            this.setState({
              items: [...this.state.items, { name: "", raw_materials: "", type: "Parts" }],
            });
          }}>Add Row</button>
        </div>
      </div>
    )
  }
}