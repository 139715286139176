import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { Button, TextField } from "@mui/material";

interface AddContactModalProps {
  customer_id: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

interface AddContactModalState {
  name: string;
  email: string;
  phone: string;
}

class AddContactModal extends Component<
  AddContactModalProps,
  AddContactModalState
> {
  constructor(props: AddContactModalProps) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
    };
  }

  addContact() {
    callAPI("actions/customers", "POST", {
      action: "add_contact",
      data: {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        customer_id: this.props.customer_id,
      }
    })
      .then((res) => {
        this.props.onSave();
        this.props.onClose();
      })
      .catch((err) => {
        toast.error("Error adding contact");
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Contact
          </Typography>
          <div className="row">
            <div className="col-4 my-2">
              <TextField
              fullWidth
                label="Contact Name"
                type="text"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="col-4 my-2">
              <TextField
              fullWidth
                label="Contact Email"
                type="text"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
            <div className="col-4 my-2">
              <TextField
              fullWidth
                label="Contact Phone"
                type="text"
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.name}
              onClick={() => {
                this.addContact();
              }}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default AddContactModal;
