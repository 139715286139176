import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, callFileServerAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { Quote, QuoteGenerator } from "../../utils/QuoteGenerator";
import saveAs from "file-saver";
import { Packer } from "docx";
import PDFQuoteGenerator from "../../utils/PDFQuoteGenerator";
import { pdf } from "@react-pdf/renderer";

interface QuoteDetailsModalProps {
  quote: any; // quote without the contents/options data
  onClose: () => void;
  onUpdate: () => void;
}

interface QuoteDetailsModalState {
  pdf_path_invalid: boolean;
  word_path_invalid: boolean;

  pdfBlob: Blob | null;
  docxBlob: Blob | null;
  quote: Quote | null;
}

class QuoteDetailsModal extends Component<
  QuoteDetailsModalProps,
  QuoteDetailsModalState
> {
  constructor(props: QuoteDetailsModalProps) {
    super(props);
    this.state = {
      pdf_path_invalid: false,
      word_path_invalid: false,

      pdfBlob: null,
      docxBlob: null,
      quote: null,
    };
  }

  componentDidMount() {
    this.testPaths();
    this.loadData();
  }

  componentDidUpdate(prevProps: QuoteDetailsModalProps) {
    if (prevProps.quote !== this.props.quote) {
      this.testPaths();
      this.loadData();
    }
  }

  loadData() {
    if (!this.props.quote) return;
    callAPI("tables/quotes", "POST", {
      where: {
        id: this.props.quote.id,
      }
    })
      .then((res) => {
        const quoteGenerator = new QuoteGenerator();
        const quote = quoteGenerator.create(res[0]);
        Packer.toBlob(quote).then(async (blob) => {
          // use ReactPDF to render the PDF
          const pdfBlob = await pdf(<PDFQuoteGenerator quote={res[0]} />).toBlob();
          this.setState({
            pdfBlob: pdfBlob,
            docxBlob: blob,
            quote: res[0],
          });
        });
      })
      .catch((data) => {
        console.log(data);
        toast.error("Error loading quote data.");
      });
  }

  testPaths() {
    if (this.props.quote?.pdf_path) {
      callFileServerAPI("test?path=" + this.props.quote?.pdf_path)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ pdf_path_invalid: true });
          }
        })
        .catch((error) => {
          this.setState({ pdf_path_invalid: true });
        });
    }
    if (this.props.quote?.word_path) {
      callFileServerAPI("test?path=" + this.props.quote?.word_path)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ word_path_invalid: true });
          }
        })
        .catch((error) => {
          this.setState({ word_path_invalid: true });
        });
    }
  }

  resaveDoc() {
    const formData = new FormData();
    const file: File = this.state.docxBlob as File;
    formData.append("file", file);
    formData.append("path", this.props.quote?.requested_path);
    formData.append("quote_id", this.props.quote?.id?.toString() || "");

    const promise = callFileServerAPI("save_quote", "POST", formData)
      .then((data) => {
        this.props.onUpdate();
      });
    toast.promise(promise, {
      loading: "Saving Word document...",
      success: "Word document saved",
      error: "Error saving Word document",
    });
  }

  resavePDF() {
    const formData = new FormData();
    formData.append("word_path", this.props.quote?.word_path);
    formData.append("quote_id", this.props.quote?.id?.toString() || "");

    const promise = callFileServerAPI("save_pdf", "POST", formData)
      .then((data) => {
        this.props.onUpdate();
      });
    toast.promise(promise, {
      loading: "Saving PDF...",
      success: "PDF saved",
      error: "Error saving PDF",
    });
  }

  downloadWord() {
    if (!this.state.docxBlob) return;
    if (!this.state.quote) return;
    const fileName = this.state.quote.job_number + "_R" + this.state.quote.revision + ".docx";
    saveAs(this.state.docxBlob, fileName);
  }

  downloadPDF() {
    if (!this.state.pdfBlob) return;
    if (!this.state.quote) return;
    const fileName = this.state.quote.job_number + "_R" + this.state.quote.revision + ".pdf";
    saveAs(this.state.pdfBlob, fileName);
  }

  openInNewTab() {
    if (!this.state.pdfBlob) return;
    const dataURL = URL.createObjectURL(this.state.pdfBlob);
    window.open(dataURL, "_blank");
  }

  render() {
    if (!this.props.quote) {
      return null;
    }
    return (
      <div>
        <Modal
          open={!!this.props.quote}
          onClose={this.props.onClose}
          aria-labelledby="selectProductModalLabel"
        >
          <Box sx={generateModalStyle("98%")}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Saved Revision
            </Typography>
            <div>
              <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-purple rounded shadow-sm">
                <img
                  className="me-3"
                  src="/assets/img/favicon256.png"
                  alt="LTC Logo"
                  width="48"
                  height="48"
                />
                <div className="lh-100">
                  <h6 className="mb-0 text-white lh-100">Download Options</h6>
                  <p className="bold">{this.props.quote.order_num}</p>
                </div>
              </div>
              <div className="my-3 p-3 bg-white rounded shadow-sm">
                <div className="media text-muted pt-3">
                  <h3
                    className="mr-2"
                  // style="font-size: 150%; width: 32px; height: 32px"
                  >
                    <img
                      width="35"
                      alt="Word Document"
                      src="/assets/img/word.png"
                    />
                  </h3>
                  <div className="media-body pb-3 mb-0 lh-125 border-bottom border-gray">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <strong className="text-gray-dark ">
                        Word Document (.docx)
                      </strong>
                    </div>
                    {this.state.word_path_invalid && (
                      <span style={{ color: "red" }}>
                        This path is invalid. The file was either moved,
                        renamed, or deleted.
                      </span>
                    )}
                    {!this.props.quote.word_path && (
                      <span className="d-block" id="docx_location">
                        File not saved yet
                      </span>
                    )}
                    <div className="mt-2 text-right">
                      {(!this.props.quote.word_path ||
                        this.state.word_path_invalid) && (
                          <button
                            className="ms-1 btn btn-outline-danger docx_nopath"
                            onClick={() => this.resaveDoc()}
                          >
                            Re-Save Document
                          </button>
                        )}
                      {this.state.word_path_invalid && (
                        <button className="ms-1 btn btn-outline-danger docx_nopath">
                          Locate File
                        </button>
                      )}
                      <button
                        className="ms-1 btn btn-outline-primary"
                        onClick={() => {
                          this.downloadWord();
                        }}
                      >
                        Download
                      </button>
                      {!!this.props.quote.word_path && (
                        <button
                          className="ms-1 btn btn-outline-primary"
                          onClick={() => {
                            console.log(
                              "ms-word:ofe|u|" + this.props.quote.word_path
                            );
                            window.open(
                              "ms-word:ofe|u|" + this.props.quote.word_path
                            );
                          }}
                        >
                          Edit in Word
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="media text-muted pt-3">
                  <h3
                    className="mr-2"
                  // style="font-size: 150%; width: 32px; height: 32px"
                  >
                    <img
                      width="35"
                      alt="PDF Document"
                      src="/assets/img/pdf.png"
                    />
                  </h3>
                  <div className="media-body pb-3 mb-0 lh-125 border-bottom border-gray">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <strong className="text-gray-dark ">
                        PDF Document (.pdf)
                      </strong>
                    </div>
                    {this.state.pdf_path_invalid &&
                      !!this.props.quote.pdf_path && (
                        <span style={{ color: "red" }}>
                          This path is invalid. The file was either moved,
                          renamed, or deleted.
                        </span>
                      )}
                    {!this.props.quote.pdf_path && (
                      <span className="d-block" id="pdf_location">
                        File not saved yet
                      </span>
                    )}
                    <div className="mt-2 text-right">
                      {(!this.props.quote.pdf_path ||
                        this.state.pdf_path_invalid) && (
                          <button
                            className="ms-1 btn btn-outline-danger pdf_nopath"
                            onClick={() => this.resavePDF()}
                            disabled={!this.props.quote.word_path}
                          >
                            Re-Save PDF
                          </button>
                        )}
                      {this.state.pdf_path_invalid &&
                        !!this.props.quote.pdf_path && (
                          <button className="ms-1 btn btn-outline-danger pdf_nopath">
                            Locate File
                          </button>
                        )}
                      <button
                        className="ms-1 btn btn-outline-primary"
                        onClick={() => {
                          this.downloadPDF();
                        }}
                      >
                        Download
                      </button>
                      {!!this.props.quote.pdf_path && (
                        <button className="ms-1 btn btn-outline-primary" onClick={() => {
                          this.openInNewTab();
                        }}>
                          Open in New Tab
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default QuoteDetailsModal;
