export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const formatterNoCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const getAccordionHeight = (numRows: number) => {
  return Math.max(300, numRows * 32 + 40);
};

export const generateModalStyle = (width: string | number, height?: string | number) => {
  return {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    height: height || "auto",
    maxHeight: "98%",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };
}

export const PRODUCT_CATEGORIES = [
  "ACC", "ALN", "CAL", "CBL", "GTA", "KIT", "LIC", "LP5", "N/A", "OTS", "PCA", "PRB", "SCN", "SDU", "SEN", "SFW", "SHP", "SUP", "TEST"
];

export const INVENTORY_UPLOAD_MAX_FILE_SIZE = 1000000; // 1MB