import { Component } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { callAPI } from "../../utils/API";
import { CalculatedPricing } from "../bizdev/CalculatedPricing";
import { LaborTask } from "../bizdev/LaborTable";
import { MaterialItem } from "../bizdev/MaterialsTable";
import { TravelData } from "../bizdev/TravelTable";
import DeliverableEditor from "./DeliverableEditor";

function EditOrderItem({ prices }: { prices?: boolean }) {
  const { orderId, itemNumber } = useParams();
  return (
    <EditOrderItemWithId
      orderId={orderId || ""}
      itemNumber={itemNumber || ""}
      prices={prices}
    />
  );
}
interface EditOrderItemWithIdProps {
  orderId: string;
  itemNumber: string;
  prices?: boolean;
}

interface EditOrderItemWithIdState {
  item: any;
  itemErrors: { [key: string]: string};
  customLabor: LaborTask[] | null;
  customMaterials: MaterialItem[] | null;
  customTravel: TravelData | null;
}

class EditOrderItemWithId extends Component<EditOrderItemWithIdProps, EditOrderItemWithIdState> {
  constructor(props: EditOrderItemWithIdProps) {
    super(props);
    this.state = {
      item: null,
      itemErrors: {},
      customLabor: null,
      customMaterials: null,
      customTravel: null,
    };
  }

  loadData() {
    callAPI("tables/order_contents_view", "POST", {
      where: {
        item_number: this.props.itemNumber,
        order_id: this.props.orderId,
      },
    })
      .then((data) => {
        this.setState({
          item: data[0],
        });
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading order item");
          console.log(data);
        }
      });
  }

  componentDidMount() {
    this.loadData();
  }

  deleteItem() {
    if (
      window.confirm(
        "Are you sure you want to delete this item from the order?"
      )
    ) {
      callAPI("actions/order", "POST", {
        action: "delete_item",
        order_id: this.props.orderId,
        item_number: this.props.itemNumber,
      })
        .then((data) => {
          toast.success("Item deleted");
          window.history.back();
        })
        .catch((data) => {
          toast.error("Error deleting item");
          console.log(data);
        });
    }
  }

  saveChanges() {
    let promises = [];
    promises.push(callAPI("actions/order", "POST", {
      action: "edit_item",
      item_number: this.props.itemNumber,
      order_id: this.props.orderId,
      custom: this.state.item && this.state.item.inv_code === "CUSTOM",
      data: this.state.item,
    })
      .catch((data) => {
        toast.error("Error updating item");
        console.log(data);
      }));
    if (this.state.customLabor) {
      promises.push(callAPI("actions/order", "POST", {
        action: "update_custom_product_labor",
        item_id: this.state.item?.id,
        data: this.state.customLabor,
      })
        .then((data) => {
          // toast.success("Custom labor updated");
        })
        .catch((data) => {
          toast.error("Error updating custom labor");
          console.log(data);
        }));
    }
    if (this.state.customMaterials) {
      promises.push(callAPI("actions/order", "POST", {
        action: "update_custom_product_materials",
        item_id: this.state.item?.id,
        data: this.state.customMaterials,
      })
        .then((data) => {
          // toast.success("Custom materials updated");
        })
        .catch((data) => {
          toast.error("Error updating custom materials");
          console.log(data);
        }));
    }
    if (this.state.customTravel) {
      promises.push(callAPI("actions/order", "POST", {
        action: "update_custom_product_travel",
        item_id: this.state.item?.id,
        data: this.state.customTravel,
      })
        .then((data) => {
          // toast.success("Custom travel updated");
        })
        .catch((data) => {
          toast.error("Error updating custom travel");
          console.log(data);
        }));
    }
    Promise.all(promises)
      .then(() => {
        toast.success("Item updated");
        window.history.back();
      });
  }

  render() {
    return (
      <div>
        <div className="d-flex border-bottom justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
          <h1 className="h2">Edit Deliverable</h1>

          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  this.deleteItem();
                }}
              >
                Delete Item
              </button>
            </div>
          </div>
        </div>
        <DeliverableEditor form={this.state.item} onChange={(item) => {
          this.setState({
            item,
          });
        }}
          prices={this.props.prices}
          errors={{}}
        />
        <div className="my-3 w-100">
          <Link
            to={
              (this.props.prices ? "/bizdev/" : "/orders/") + this.props.orderId
            }
            className="btn btn-outline-secondary"
          >
            Cancel
          </Link>
          <button
            className="btn btn-outline-primary ms-2"
            disabled={!Object.values(this.state.itemErrors).every((v) => v === "")}
            onClick={() => {
              this.saveChanges();
            }}
          >
            Save
          </button>
        </div>
        {this.props.prices && parseInt(this.state.item?.calculated_pricing) === 1 && (
          <CalculatedPricing
            onTotalsChange={(price, cost) => {
              this.setState({
                item: {
                  ...this.state.item,
                  price_override: 1,
                  price: price.toFixed(2),
                  cost: cost.toFixed(2),
                }
              })
            }}
            onLaborChange={(tasks) => { this.setState({ customLabor: tasks }) }}
            onMaterialsChange={(items) => { this.setState({ customMaterials: items }) }}
            onTravelChange={(data) => { this.setState({ customTravel: data }) }}
            item_id={this.state.item?.id}
          />
        )}
      </div>
    );
  }
}

export default EditOrderItem;
