import {
  AlignmentType,
  Document,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  SymbolRun,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { Buffer } from "buffer";
import { ltcLogoBase64 } from "./LTCLogo";
import { formatter } from "./Misc";
import { ITEM_NUM_COMPARATOR } from "../components/orders/OrderContentsTable";

export interface Quote {
  id: number;
  order_id: number;
  job_number: string;
  quote_number: string;
  comment: string;
  saved_by: number;
  sent_to_customer: 0 | 1;
  type: "QUOTATION" | "COST_ESTIMATE";

  price_tier: number;
  price_tier_name: string;

  created_at: string;
  rfq: string;
  revision: number;
  ltc_poc_name: string;
  ltc_poc_email: string;
  valid_days: number;

  description: string;
  payment_str: string;
  shipping: number;
  shipping_option: 0 | 1 | 2;
  delivery_str: string;
  credit_card_percent: number;
  delivery_timeline: number;

  fs3_requested_dir: string;
  fs3_docx_path: string;
  fs3_pdf_path: string;

  contents: string; // JSON data
  options: string; // JSON data
  customer: string; // JSON data
  contact: string; // JSON data

  progress_payments_enabled: 0 | 1;
  progress_payment_data: string;
}

export const DEFAULT_PAYMENT_STR = `Net 30 days<br>All prices in U.S. dollars unless otherwise specified.<br>2.5% per month charge for late payment.<br>Payment discount of 1/2 of 1% if paid Net 10 Days.<br>A convenience fee of 3% of the total will be charged on all credit card transactions.`;

const FONT = "Arial";

export function formatHTML(html: string): string {
  if (!html) return "";
  return html
    .replaceAll("<br>", "\n")
    .replaceAll("</p><p>", "\n")
    .replaceAll("<p>", "")
    .replaceAll("</p>", "")
    .replaceAll("<b>", "")
    .replaceAll("</b>", "")
    .replaceAll("<i>", "")
    .replaceAll("</i>", "");
}

export function getItemPrice(
  item: any,
  contents: any[],
  isGSAOrder: boolean,
  priceTierVal: number,
  total?: boolean
): number {
  const parent = contents.find((parent) => {
    return (
      parent.item_number === item.item_number.split(".")[0] &&
      parent.item_number !== item.item_number
    );
  });
  // if we are calculating a total and the parent is KT, count as 0
  if (total && parent) {
    return 0;
  }

  let price = parseFloat(item.price);
  let hasChildren = false;

  if ((total && price) === 0 || item.inv_code === "KT") {
    // price is the sum of the children
    const children = contents
      .filter((i) => {
        const regex = new RegExp(`^${item.item_number}\\.[0-9]+$`);
        return regex.test(i.item_number);
      });
    price = children
      .reduce((acc, child) => {
        return (
          acc +
          getItemPrice(child, contents, isGSAOrder, priceTierVal) *
          child.quantity
        );
      }, 0);
      hasChildren = children.length > 0;
  } else if (total) {
    price *= item.quantity;
  }
  // apply price tier
  if (!hasChildren) {
    price *= !isGSAOrder || parseInt(item.is_gsa) === 1 ? priceTierVal : 1;
  }
  return parseFloat(price.toFixed(2));
}

export class QuoteGenerator {
  // tslint:disable-next-line: typedef
  public create(quote: Quote): Document {
    const contents = JSON.parse(quote.contents);
    const options = JSON.parse(quote.options);
    let customer = quote.customer ? JSON.parse(quote.customer) : null;
    let contact = quote.contact ? JSON.parse(quote.contact) : null;
    if (!customer) {
      customer = {
        name: "",
        addr_line1: "",
        addr_line2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      };
    }
    if (!contact) {
      contact = {
        name: "",
        email: "",
        phone: "",
      };
    }
    if (!quote) {
      return new Document({
        sections: [
          {
            properties: {
              page: {
                margin: {
                  top: "0.5 in",
                  bottom: "0.5 in",
                  left: "0.4 in",
                  right: "0.4 in",
                },
              },
            },
            headers: {
              default: this.createHeader(),
            },
            children: [this.createErrorPage("No quote data was found.")],
          },
        ],
      });
    }
    const document = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: "0.5 in",
                bottom: "0.5 in",
                left: "0.4 in",
                right: "0.4 in",
              },
            },
          },
          headers: {
            default: this.createHeader(),
          },
          children: [
            this.createTitle(quote),
            this.createSummaryTable(quote, customer, contact),
            this.createSubtitle(quote),
          ]
            .concat(this.createContentsTable(contents, quote, true) || [])
            .concat(options.length ? this.createOptionsSubtitle() : [])
            .concat(this.createContentsTable(options, quote) || [])
            .concat([
              this.createTermsAndConditionsTitle(),
              this.createTermsAndConditionsTable(quote),
            ]),
        },
      ],
    });

    return document;
  }

  private createHeader(): Header {
    return new Header({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: Buffer.from(ltcLogoBase64, "base64"),
              transformation: {
                width: 346 * 0.9,
                height: 36 * 0.9,
              },
              altText: {
                title: "LTC Logo",
                description: "LTC Logo",
                name: "LTC Logo",
              },
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              font: FONT,
              children: [
                "Page ",
                PageNumber.CURRENT,
                " of ",
                PageNumber.TOTAL_PAGES,
              ],
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              size: 7,
            }),
          ],
        }),
      ],
    });
  }

  private createTitle(quote: Quote): Paragraph {
    const title = quote.type === "QUOTATION" ? "Quotation" : "Cost Estimate";
    return new Paragraph({
      children: [
        new TextRun({
          text: title,
          bold: true,
          size: 32,
          font: FONT,
        }),
      ],
      spacing: {
        before: 100,
        after: 100,
      },
      // heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
    });
  }

  private createErrorPage(error?: string): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text:
            "An error occurred while generating the document: " +
            (error || "Unknown error"),
          bold: true,
          size: 32,
          font: FONT,
        }),
      ],
      spacing: {
        before: 100,
        after: 100,
      },
      // heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
    });
  }

  private createSummaryTable(quote: Quote, customer: any, contact: any): Table {
    // shift for timezone offset
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const date = new Date(new Date(quote.created_at).getTime() - offset);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });

    return new Table({
      columnWidths: [2300, 2300, 1800, 4000],
      margins: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 1800,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "To:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
            new TableCell({
              width: {
                size: 4500,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: customer.name,
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: formatHTML(customer.addr_line1),
                      font: FONT,
                    }),
                  ],
                }),
              ]
                .concat(
                  customer.addr_line2
                    ? [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: formatHTML(customer.addr_line2),
                            font: FONT,
                          }),
                        ],
                      }),
                    ]
                    : []
                )
                .concat([
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: `${customer.city}, ${customer.state} ${customer.country} ${customer.zip}`,
                        font: FONT,
                      }),
                    ],
                  }),
                ])
                .concat(
                  contact?.name
                    ? [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Attn: " + contact.name,
                            font: FONT,
                          }),
                        ],
                      }),
                    ]
                    : []
                ),
            }),
            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Date:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "RFQ:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Ref.:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Phone:",
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Email:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 3500,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: formattedDate,
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: quote.rfq,
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        (quote.quote_number || quote.job_number) +
                        (quote.revision > 0 ? " R" + quote.revision : ""),
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: contact.phone,
                      font: FONT,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: contact.email,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "LTC P.O.C.:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: quote.ltc_poc_name,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Email:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: quote.ltc_poc_email,
                      font: FONT,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  private createSubtitle(quote: Quote): Paragraph {
    const subtitle = quote.description;
    return new Paragraph({
      children: [
        new TextRun({
          text: subtitle,
          bold: true,
          size: 28,
          font: FONT,
        }),
      ],
      spacing: {
        before: 80,
        after: 80,
      },
      // heading: HeadingLevel.TITLE,
      alignment: AlignmentType.CENTER,
    });
  }

  private createOptionsSubtitle(): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text: "OPTIONS",
          bold: true,
          size: 28,
          font: FONT,
        }),
      ],
      spacing: {
        before: 80,
        after: 80,
      },
      alignment: AlignmentType.CENTER,
    });
  }

  private createTermsAndConditionsTitle(): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text: "Terms and Conditions:",
          bold: true,
          size: 28,
          font: FONT,
        }),
      ],
      spacing: {
        before: 80,
        after: 80,
      },
      // heading: HeadingLevel.TITLE,
      alignment: AlignmentType.LEFT,
    });
  }

  private createContentsTable(
    contents: any[],
    quote: Quote,
    showTotal?: boolean
  ): Table | undefined {
    if (contents.length === 0) {
      return undefined;
    }

    const priceTierVal = quote.price_tier || 1;
    const priceTierName = quote.price_tier_name || "US Price";
    const isGSAOrder = priceTierName === "GSA";

    const SALEX_TAX_PERCENT = 0.0; // this will be added manually
    let subTotal = 0;
    let salesTax = 0;
    let ccFee = 0;
    if (showTotal) {
      subTotal = contents.reduce((acc, item) => {
        return (
          acc + getItemPrice(item, contents, isGSAOrder, priceTierVal, true)
        );
      }, 0);
      salesTax = SALEX_TAX_PERCENT * subTotal;
      ccFee = (quote.credit_card_percent / 100) * (subTotal + salesTax);
    }
    const total =
      subTotal +
      salesTax +
      ccFee +
      parseFloat(quote.shipping?.toString() || "0");

    return new Table({
      columnWidths: [800, 2300, 4000, 1000, 800, 1000],
      margins: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 800,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Item",
                      bold: true,
                      font: FONT,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              width: {
                size: 2300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Catalog #",
                      bold: true,
                      font: FONT,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              width: {
                size: 4700,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Description",
                      bold: true,
                      font: FONT,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Price Each",
                      bold: true,
                      font: FONT,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              width: {
                size: 800,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Qty",
                      bold: true,
                      font: FONT,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Total Price",
                      bold: true,
                      font: FONT,
                    }),
                  ],
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
          ],
        }),
        ...contents.sort(ITEM_NUM_COMPARATOR).map((item) => {
          const numChildren = contents.filter((i) => {
            const regex = new RegExp(`^${item.item_number}\\.[0-9]+$`);
            return regex.test(i.item_number);
          }).length;
          const pricePerInstance = getItemPrice(
            item,
            contents,
            isGSAOrder,
            priceTierVal,
            false
          );
          const totalItemPrice = getItemPrice(
            item,
            contents,
            isGSAOrder,
            priceTierVal,
            true
          );
          return new TableRow({
            children: [
              new TableCell({
                width: {
                  size: 800,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: item.item_number,
                        bold: !item.item_number.includes("."),
                        font: FONT,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                width: {
                  size: 2300,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: item.catalog_number,
                        bold: !item.item_number.includes("."),
                        font: FONT,
                      }),
                    ],
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                width: {
                  size: 4000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: item.name,
                        bold: !item.item_number.includes("."),
                        font: FONT,
                      }),
                      new TextRun({
                        text:
                          isGSAOrder && parseInt(item.is_gsa) === 1
                            ? " (GSA)"
                            : "",
                        bold: true,
                        font: FONT,
                      }),
                    ],
                  }),
                ].concat(
                  item.notes
                    ? item.notes.split("\n").map(
                      (note: string) =>
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: note,
                              font: FONT,
                            }),
                          ],
                          bullet: {
                            level: 0,
                          },
                        })
                    )
                    : []
                ),
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                width: {
                  size: 1000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text:
                          parseInt(item.price_hidden) === 1 ||
                            parseFloat(item.price) === 0 ||
                            item.inv_code === "KT"
                            ? ""
                            : formatter.format(pricePerInstance),
                        bold: !item.item_number.includes("."),
                        font: FONT,
                      }),
                    ],
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                width: {
                  size: 800,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text:
                          parseFloat(item.price) === 0
                            ? ""
                            : item.quantity.toString(),
                        bold: !item.item_number.includes("."),
                        font: FONT,
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
            ].concat(
              item.item_number.includes(".")
                ? []
                : [
                  new TableCell({
                    width: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: formatter.format(totalItemPrice),
                            bold: !item.item_number.includes("."),
                            font: FONT,
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    rowSpan: numChildren + 1,
                  }),
                ]
            ),
          });
        }),
      ].concat(
        !showTotal
          ? []
          : // TOTALS TABLE
          [
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 1000,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "Subtotal",
                          bold: true,
                          font: FONT,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  columnSpan: 5,
                }),
                new TableCell({
                  width: {
                    size: 800,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: formatter.format(subTotal),
                          bold: true,
                          font: FONT,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 1000,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "Shipping and Handling",
                          bold: true,
                          font: FONT,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  columnSpan: 5,
                }),
                new TableCell({
                  width: {
                    size: 800,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text:
                            parseInt(quote.shipping_option.toString()) === 0
                              ? "Not Included"
                              : parseInt(quote.shipping_option.toString()) ===
                                1
                                ? formatter.format(quote.shipping)
                                : parseInt(quote.shipping_option.toString()) ===
                                  2
                                  ? "Not Included"
                                  : "",
                          bold: true,
                          font: FONT,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ],
            }),
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 1000,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "Sales Tax",
                          bold: true,
                          font: FONT,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  columnSpan: 5,
                }),
                new TableCell({
                  width: {
                    size: 800,
                    type: WidthType.DXA,
                  },
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: formatter.format(salesTax),
                          bold: true,
                          font: FONT,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ],
            }),
          ]
            .concat(
              quote.credit_card_percent > 0
                ? [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 1000,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "3% Credit Card Transaction Fee",
                                bold: true,
                                font: FONT,
                              }),
                            ],
                            alignment: AlignmentType.RIGHT,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 5,
                      }),
                      new TableCell({
                        width: {
                          size: 800,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: formatter.format(ccFee),
                                bold: true,
                                font: FONT,
                              }),
                            ],
                            alignment: AlignmentType.RIGHT,
                          }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                      }),
                    ],
                  }),
                ]
                : []
            )
            .concat([
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: "Total",
                            bold: true,
                            font: FONT,
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    columnSpan: 5,
                  }),
                  new TableCell({
                    width: {
                      size: 800,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: formatter.format(total),
                            bold: true,
                            font: FONT,
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                ],
              }),
            ])
      ),
    });
  }

  private createTermsAndConditionsTable(quote: Quote): Table {
    const priceTierName = quote.price_tier_name || "US Price";
    const isGSAOrder = priceTierName === "GSA";
    if (quote.type !== "QUOTATION") {
      return new Table({
        columnWidths: [2000, 5500],
        margins: {
          top: 100,
          bottom: 100,
          left: 100,
          right: 100,
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                width: {
                  size: 1300,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Delivery:",
                        font: FONT,
                      }),
                    ],
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                width: {
                  size: 12000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text:
                          formatHTML(quote.delivery_str ||
                            quote.delivery_timeline +
                            " Days After Receipt of Order"),
                        font: FONT,
                      }),
                    ],
                  }),
                ],
                verticalAlign: VerticalAlign.TOP,
              }),
            ],
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Privacy:",
                        font: FONT,
                      }),
                    ],
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "This quotation is not for distribution and is intended for the recipient individual and organization only.",
                        font: FONT,
                      }),
                    ],
                  }),
                ],
                verticalAlign: VerticalAlign.TOP,
              }),
            ],
          }),
        ],
      });
    }
    return new Table({
      columnWidths: [2000, 7000],
      margins: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 2000,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Delivery:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              width: {
                size: 7000,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text:
                        quote.delivery_str ||
                        quote.delivery_timeline +
                        " Days After Receipt of Order",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Quote Valid:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: quote.valid_days + " Days",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Terms:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "All sales are subject to Laser Techniques Company Standard Terms and Conditions.",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Payment:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: formatHTML(quote.payment_str || DEFAULT_PAYMENT_STR)
                .split("\n")
                .map((str) => {
                  return new Paragraph({
                    children: [
                      new TextRun({
                        text: str,
                        font: FONT,
                      }),
                    ],
                  });
                }),
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "F.O.B.:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Redmond, WA",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Shipping:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new SymbolRun(
                      parseInt(quote.shipping_option.toString()) === 0
                        ? "F0FE"
                        : "F071"
                    ),
                    new TextRun({
                      text: " ",
                      font: FONT,
                    }),
                    new TextRun({
                      text: "Paid By Customer",
                      font: FONT,
                    }),
                    new TextRun({
                      text: "  ",
                      font: FONT,
                    }),
                    new SymbolRun(
                      parseInt(quote.shipping_option.toString()) === 1
                        ? "F0FE"
                        : "F071"
                    ),
                    new TextRun({
                      text: " ",
                      font: FONT,
                    }),
                    new TextRun({
                      text: "Included",
                      font: FONT,
                    }),
                    new TextRun({
                      text: "  ",
                      font: FONT,
                    }),
                    new SymbolRun(
                      parseInt(quote.shipping_option.toString()) === 2
                        ? "F0FE"
                        : "F071"
                    ),
                    new TextRun({
                      text: " ",
                      font: FONT,
                    }),
                    new TextRun({
                      text: "Prepay & Add",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Note:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "All international shipments Ex Works.",
                      font: FONT,
                    }),
                  ],
                }),
              ].concat(
                isGSAOrder
                  ? [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: "All items not specifically marked as GSA are considered open market.",
                          font: FONT,
                        }),
                      ],
                    }),
                  ]
                  : []
              ),
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Taxes & Duties:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "All prices are exclusive of any applicable taxes and/or duties.",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Privacy:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "This quotation is not for distribution and is intended for the recipient individual and organization only.",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Warranty:",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "All OEM equipment, such as computers and commercial PC boards, carry the manufacturer's standard warranty. All LTC manufactured components are covered by a limited one-year warranty as described in LTC's Standard Terms and Conditions",
                      font: FONT,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.TOP,
            }),
          ],
        }),
      ],
    });
  }
}
