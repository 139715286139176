import { Component } from "react";
import Chart, { ChartDataset } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { callAPI } from "../../utils/API";
import { CHART_OPTIONS, COLORS, yAxisFormatter } from "../dashboard/ChartUtils";

interface HistoricalRevenueChartProps {
  cumulative: boolean;
}

interface HistoricalRevenueChartState {
  data: { [key: number]: number[] };
}

const CHART_COLORS = [
  "#255e91",
  "#15ad70",
  "#3366cc",
  "#ffc000",
  "#a5a5a5",
  "#ed7d31",
  "#5b9bd5",
];

Chart.register();

let options = structuredClone(CHART_OPTIONS);
if (options && options.scales && options.scales.x) {
  options.scales.x.stacked = false;
  options.scales.y = {
    ticks: {
      callback: yAxisFormatter,
    },
    stacked: false,
  };
}

class HistoricalRevenueChart extends Component<
  HistoricalRevenueChartProps,
  HistoricalRevenueChartState
> {
  constructor(props: HistoricalRevenueChartProps) {
    super(props);
    this.state = {
      data: {},
    };
  }

  loadData() {
    this.setState({
      data: {},
    }, () => {
      const years = Array.from(
        { length: new Date().getFullYear() - 2017 + 1 },
        (v, k) => k + 2017
      );
      callAPI("chart/historical-revenue/", "POST", {
        years,
        cumulative: this.props.cumulative,
      }).then((response) => {
        this.setState({
          data: response,
        });
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: HistoricalRevenueChartProps) {
    if (prevProps.cumulative !== this.props.cumulative) {
      this.loadData();
    }
  }

  render() {
    const labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return (
      <div>
        <Bar
          options={options}
          style={{ height: "30vh" }}
          // key={this.state.counter}
          data={{
            labels,
            datasets: [
              {
                label: "Average",
                backgroundColor: COLORS.avg,
                borderColor: COLORS.avg,
                borderWidth: 2,
                data: labels.map(
                  (_, index) =>
                    Object.values(this.state.data)
                      .map((data) => data[index])
                      .reduce((a, b) => a + (b || 0), 0) /
                    Object.values(this.state.data).length
                ),
                type: "line",
                fill: false,
              } as any,
            ].concat(
              Object.keys(this.state.data).map((year: any, index) => {
                const data = this.state.data[year];
                return {
                  label: year.toString(),
                  data,
                  backgroundColor: CHART_COLORS[index],
                  borderColor: COLORS.border,
                } as ChartDataset<"bar", number[]>;
              })
            ),
          }}
        />
      </div>
    );
  }
}

export default HistoricalRevenueChart;
