import { Component } from "react";
import { callAPI } from "../../utils/API";

interface ChartTitleProps {
  ids: number[];
}

interface ChartTitleState {
  products: any[];
}

export default class ChartTitle extends Component<ChartTitleProps, ChartTitleState> {
  constructor(props: ChartTitleProps) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    this.loadNames();
  }

  componentDidUpdate(prevProps: ChartTitleProps) {
    if (prevProps.ids !== this.props.ids) {
      this.loadNames();
    }
  }

  loadNames() {
    this.setState({
      products: [],
    }, () => {
      this.props.ids.forEach((id) => {
        callAPI("tables/products_view", "POST", {
          where: { id },
        }).then((response) => {
          this.setState({
            products: [...this.state.products, response[0]],
          });
        });
      });
    });
  }

  render() {
    if (!this.state.products.length) {
      return (
        <div className="chart-title">
          <h4 className="text-center mt-4">Loading...</h4>
        </div>
      );
    } else if (this.state.products.length === 1) {
      let name = this.state.products[0]?.name || "";
      if (name.length > 30) {
        name = name.slice(0, 30) + "...";
      }
      return (
        <div className="chart-title">
          <h4 className="text-center mt-4">{this.state.products[0]?.ltc_pn}: {name}</h4>
        </div>
      );
    }
    let ltc_pns = this.state.products.map((product) => {
      return product?.ltc_pn;
    }).join(", ");
    if (ltc_pns.length > 30) {
      ltc_pns = ltc_pns.slice(0, 30) + "...";
    }
    return (
      <div className="chart-title">
        <h4 className="text-center mt-4">{this.state.products.length} Products ({ltc_pns})</h4>
        <p className="text-center">
          {this.state.products.map((product, index) => {
            if (!product) product = {};
            return (
              <span key={product.id + "_" + index}>
                {product.ltc_pn}: {product.name}<br />
              </span>
            );
          })}
        </p>
      </div>
    );
  }
}