import { Component } from "react";
import AddBuildModal from "../builds/AddBuildModal";
import ProductSelectModal from "../products/ProductSelectModal";
import ChartTitle from "./ChartTitle";
import InventoryChart from "./InventoryChart";
import InventoryInfoCard from "./InventoryInfoCard";
import OrderTypesToggler from "./OrderTypesToggler";
import RequirementsChart, { ORDER_TYPES } from "./RequirementsChart";
import RequirementsTable from "./RequirementsTable";
import UserContext from "../ui/UserContext";

const TABS: string[] = ["Graphical View", "Requirements", "Inventory"];
const DEFAULT_PRODUCT = 1453;

interface DashboardProps { }

interface DashboardState {
  currentView: string;
  selectingProduct: boolean;
  selectedProducts: number[];
  enabledOrderTypes: { [key: string]: boolean };
  buildModalOpen: boolean;
  buildAdded: number;
}

class Dashboard extends Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props);
    const FROM_URL = new URLSearchParams(window.location.search).get(
      "id"
    );
    if (FROM_URL) {
      if (!isNaN(parseInt(FROM_URL))) {
        localStorage.setItem("selectedProducts", JSON.stringify([parseInt(FROM_URL)]));
      } else {
        localStorage.setItem("selectedProducts", FROM_URL);
      }
    }
    const SAVED_PRODUCTS = JSON.parse(
      localStorage.getItem("selectedProducts") || "[]"
    );
    let currentView = "Graphical View";
    if (window.location.hash === "#Inventory") {
      currentView = "Inventory";
    } else if (window.location.hash === "#Requirements") {
      currentView = "Requirements";
    }
    // window.history.replaceState({}, document.title, "/");
    this.state = {
      currentView,
      selectingProduct: false,
      selectedProducts: SAVED_PRODUCTS.length > 0 ? SAVED_PRODUCTS : [DEFAULT_PRODUCT],
      enabledOrderTypes: ORDER_TYPES.reduce((obj: any, type) => {
        obj[type] = type !== "Prospect"; // default to all but prospect
        return obj;
      }, {}),
      buildModalOpen: false,
      buildAdded: 0,
    };
  }

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <ProductSelectModal
              isOpen={this.state.selectingProduct}
              onClose={() => this.setState({ selectingProduct: false })}
              onSelect={(products) => {
                this.setState({
                  selectingProduct: false,
                  selectedProducts: products,
                });
                localStorage.setItem("selectedProducts", JSON.stringify(products));
              }}
              multiple
            />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
              <h1 className="h2">{this.state.currentView}</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2"></div>
                <div className="input-group">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => this.setState({ selectingProduct: true })}
                  >
                    Switch product
                  </button>
                </div>
              </div>
            </div>
            <div>
              <ul
                className="nav nav-tabs justify-content-end d-none d-lg-flex"
                style={{
                  paddingRight: "143px",
                  position: "relative",
                  top: "-57px",
                }}
              >
                {TABS.map((tab, index) => {
                  // desktop tabs
                  return (
                    <li className="nav-item" key={"tab" + index}>
                      <a
                        onClick={() => this.setState({ currentView: tab })}
                        className={
                          "nav-link " +
                          (this.state.currentView === tab ? "active" : "")
                        }
                        href={"#" + tab.replaceAll(" ", "-")}
                        data-bs-toggle="tab"
                      >
                        {tab}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <ul className="nav nav-pills d-flex d-lg-none">
                {TABS.map((tab, index) => {
                  // mobile tabs
                  return (
                    <li className="nav-item" key={"tab" + index}>
                      <a
                        onClick={() => this.setState({ currentView: tab })}
                        className={
                          "nav-link " +
                          (this.state.currentView === tab ? "active" : "")
                        }
                        href={"#" + tab.replaceAll(" ", "-")}
                        data-bs-toggle="tab"
                      >
                        {tab}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div className="tab-content dashboard-page">
                <div className={"tab-pane fade" + (this.state.currentView === "Graphical View" ? " active show" : "")} id="Graphical-View">
                  <ChartTitle ids={this.state.selectedProducts} />
                  <RequirementsChart
                    products={this.state.selectedProducts}
                    types={this.state.enabledOrderTypes}
                    onTypeChange={(type, value) => {
                      this.setState({
                        enabledOrderTypes: {
                          ...this.state.enabledOrderTypes,
                          [type]: value,
                        },
                      });
                    }}
                  />
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1
                      className="h2"
                      id="title-x2"
                      title="This data comes from tallying inventory, orders, and builds for this product from the database."
                    >
                      Projected Inventory
                    </h1>

                    {!user?.isReadOnly && (
                      <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group me-2">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => {
                              this.setState({ buildModalOpen: true });
                            }}
                            title="Add a new build of this product"
                            disabled={this.state.selectedProducts.length !== 1}
                          >
                            Add Build
                          </button>
                          {this.state.selectedProducts.length === 1 && (
                            <AddBuildModal
                              product={this.state.selectedProducts[0]}
                              isOpen={this.state.buildModalOpen}
                              onClose={() => this.setState({ buildModalOpen: false })}
                              onSave={() =>
                                this.setState({
                                  buildAdded: this.state.buildAdded + 1,
                                })
                              }
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <InventoryChart
                    products={this.state.selectedProducts}
                    types={this.state.enabledOrderTypes}
                  />
                </div>
                <div className={"tab-pane fade" + (this.state.currentView === "Requirements" ? " active show" : "")} id="Requirements">
                  <ChartTitle ids={this.state.selectedProducts} />
                  <RequirementsChart
                    products={this.state.selectedProducts}
                    types={this.state.enabledOrderTypes}
                    onTypeChange={(type, value) => {
                      this.setState({
                        enabledOrderTypes: {
                          ...this.state.enabledOrderTypes,
                          [type]: value,
                        },
                      });
                    }}
                  />
                  <hr className="my-4" />
                  {this.state.enabledOrderTypes["Builds"] && (
                    <RequirementsTable ids={this.state.selectedProducts} title="Parent Builds" types={[
                      "Parent Build"
                    ]} />
                  )}
                  {(this.state.enabledOrderTypes["Order"] || this.state.enabledOrderTypes["Temp Job"]) && (
                    <RequirementsTable ids={this.state.selectedProducts} title="Existing Orders" types={[
                      "Order",
                      "Temp Job",
                    ]} />
                  )}
                  {(this.state.enabledOrderTypes["Projection 90%"] || this.state.enabledOrderTypes["Projection 80%"]) && (
                    <RequirementsTable ids={this.state.selectedProducts} title="Projected Orders" types={[
                      "Projection 90%",
                      "Projection 80%",
                    ]} />
                  )}
                  {this.state.enabledOrderTypes["Prospect"] && (
                    <RequirementsTable ids={this.state.selectedProducts} title="Prospect Orders" types={[
                      "Prospect",
                    ]} />
                  )}
                </div>
                <div className={"tab-pane fade" + (this.state.currentView === "Inventory" ? " active show" : "")} id="Inventory">
                  <ChartTitle ids={this.state.selectedProducts} />
                  <InventoryChart
                    products={this.state.selectedProducts}
                    types={this.state.enabledOrderTypes}
                  />
                  <OrderTypesToggler
                    defaultValues={this.state.enabledOrderTypes}
                    onChange={(values) => {
                      this.setState({ enabledOrderTypes: values });
                    }}
                  />
                  <InventoryInfoCard
                    ids={this.state.selectedProducts}
                    refreshBuilds={this.state.buildAdded}
                    onAddBuild={() => {
                      this.setState({ buildModalOpen: true });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Dashboard;
