import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { Alert, TextField } from "@mui/material";

interface SetSmartsheetTokenModalProps {
  open: boolean;
  onClose: () => void;
}

interface SetSmartsheetTokenModalState {
  token: string;
}

class SetSmartsheetTokenModal extends Component<
  SetSmartsheetTokenModalProps,
  SetSmartsheetTokenModalState
> {
  constructor(props: SetSmartsheetTokenModalProps) {
    super(props);
    this.state = {
      token: ""
    };
  }

  updateToken() {
    callAPI("actions/settings", "POST", {
      action: "set_smartsheet_token",
      token: this.state.token
    })
      .then((res) => {
        this.props.onClose();
      })
      .catch((err) => {
        toast.error("Error updating smartsheet token.");
      });
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <Box sx={generateModalStyle("78%")}>
            <Typography variant="h6" component="h2">
              Set Smartsheet Token
            </Typography>
            <p>
              In order to connect to Smartsheet, you need to get an API token.
              Go to{" "}
              <a
                target="_blank"
                href="https://app.smartsheet.com/home"
                rel="noreferrer"
              >
                Smartsheet
              </a>
              , then click on your profile picture -&gt; "Personal Settings"
              -&gt; "API Access" -&gt; "Generate new access token".
            </p>
            <TextField
              label="Token"
              variant="outlined"
              value={this.state.token}
              onChange={(e) => {
                this.setState({
                  token: e.target.value
                });
              }}
              className="mt-2"
              fullWidth
            />
            {this.state.token.length !== 26 && (
              <Alert severity="warning" className="mt-2">
                This token is not valid.
              </Alert>
            )}
            <div className="d-flex w-100 gap-2 justify-content-end mt-2">
              <button
                onClick={() => {
                  this.updateToken();
                }}
                className="btn btn-primary"
                disabled={this.state.token.length !== 26}
              >
                Save
              </button>
            </div>
          </Box>
        </Modal>
      </div >
    );
  }
}

export default SetSmartsheetTokenModal;
