import { Component } from "react";
import { callAPI } from "../../utils/API";
import { fixText, relativeTime } from "./Issues";
import "./Issues.css";
import toast from "react-hot-toast";
import CustomEditor from "../ui/ckeditor/CustomEditor";

interface IssuePageProps {
  id: number;
}

interface IssuePageState {
  issue: any;
  comments: any;
  newComment: string;
}

class IssuePage extends Component<IssuePageProps, IssuePageState> {
  constructor(props: IssuePageProps) {
    super(props);
    this.state = {
      issue: {},
      comments: [],
      newComment: "",
    };
  }

  loadData() {
    callAPI("issues/" + this.props.id, "GET").then((response) => {
      response.issue.body = fixText(response.issue.body);
      response.issue.title = fixText(response.issue.title);
      response.issue.author = response.issue.title
        .split(" - ")[1]
        .split(":")[0];
      this.setState({
        issue: response.issue,
      });
    });
    callAPI("issues/" + this.props.id + "/comments", "GET").then((response) => {
      for (var i = 0; i < response.comments.length; i++) {
        response.comments[i].author = response.comments[i].body.split(":")[0];
        response.comments[i].body = fixText(
          response.comments[i].body.substring(
            response.comments[i].body.indexOf(":") + 2
          )
        );
      }
      this.setState({
        comments: response.comments,
      });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  closeIssue() {
    callAPI("issues/" + this.props.id, "POST", {
      action: "close",
    })
      .then((response) => {
        toast.success("Issue closed.");
        this.loadData();
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to close issue");
      });
  }

  openIssue() {
    callAPI("issues/" + this.props.id, "POST", {
      action: "reopen",
    })
      .then((response) => {
        toast.success("Issue reopened.");
        this.loadData();
      })
      .catch(() => {
        toast.error("Failed to reopen issue");
      });
  }

  addComment() {
    callAPI("issues/" + this.props.id, "POST", {
      action: "comment",
      body: this.state.newComment,
    })
      .then((response) => {
        toast.success("Comment added.");
        this.setState({ newComment: "" });
        this.loadData();
      })
      .catch(() => {
        toast.error("Failed to add comment");
      });
  }

  render() {
    return (
      <div>
        <div>
          <br />
          <h5 className="issue-title ms-2">
            Issue #{this.props.id}: {this.state.issue.title}
          </h5>
          <p className="ms-2">
            <span className="badge badge-pill badge-success">
              {this.state.issue.state}
            </span>
            {this.state.issue.author} opened this issue{" "}
            {relativeTime(this.state.issue.created_at)}. Last updated{" "}
            {relativeTime(this.state.issue.updated_at)}.
          </p>
        </div>
        <div
          // style="left: 28px;"
          className="flex-shrink-0 col-12 col-md-11 mb-4 mb-md-0"
        >
          <div className="js-quote-selection-container">
            <div
              className="js-discussion js-socket-channel ms-0 ps-0 ms-md-6 ps-md-3"
              id="timeline-comment-group"
            >
              <div className="TimelineItem pt-0 js-comment-container js-socket-channel js-updatable-content">
                <div
                  className="timeline-comment-group js-minimizable-comment-group js-targetable-element TimelineItem-body my-0"
                  id="timeline-comment-group"
                >
                  <div className="ml-n3 timeline-comment unminimized-comment comment previewable-edit js-task-list-container editable-comment js-comment timeline-comment--caret reorderable-task-lists current-user">
                    <div className="timeline-comment-header clearfix d-block d-sm-flex">
                      <h3 className="timeline-comment-header-text fs-6 text-normal">
                        <strong className="css-truncate">
                          <a
                            className="author link-gray-dark css-truncate-target width-fit"
                            href="#"
                          >
                            {this.state.issue.author}
                          </a>
                        </strong>{" "}
                        commented{" "}
                        <a href="#" className="link-gray js-timestamp">
                          <div
                            className="no-wrap"
                            title={this.state.issue.created_at}
                          >
                            {relativeTime(this.state.issue.created_at)}
                          </div>
                        </a>
                      </h3>
                    </div>
                    <div className="edit-comment-hide">
                      <div>
                        <table className="d-block">
                          <tbody className="d-block">
                            <tr className="d-block">
                              <td className="d-block comment-body markdown-body js-comment-body">
                                <div
                                  className="ck ck-content"
                                  dangerouslySetInnerHTML={
                                    { __html: this.state.issue.body } as any
                                  }
                                ></div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="js-comment-update">
                      <div className="js-previewable-comment-form previewable-comment-form write-selected">
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.comments.map((comment: any) => (
                <div
                  className="TimelineItem pt-0 js-comment-container js-socket-channel js-updatable-content"
                  key={comment.id}
                >
                  <div
                    className="timeline-comment-group js-minimizable-comment-group js-targetable-element TimelineItem-body my-0"
                    id="timeline-comment-group"
                  >
                    <div className="ml-n3 timeline-comment unminimized-comment comment previewable-edit js-task-list-container editable-comment js-comment timeline-comment--caret reorderable-task-lists current-user">
                      <div className="timeline-comment-header clearfix d-block d-sm-flex">
                        <h3 className="timeline-comment-header-text fs-6 text-normal">
                          <strong className="css-truncate">
                            <a
                              className="author link-gray-dark css-truncate-target width-fit"
                              href="#"
                            >
                              {comment.author}
                            </a>
                          </strong>{" "}
                          commented{" "}
                          <a href="#" className="link-gray js-timestamp">
                            <div className="no-wrap" title={comment.created_at}>
                              {relativeTime(comment.created_at)}
                            </div>
                          </a>
                        </h3>
                      </div>
                      <div className="edit-comment-hide">
                        <div>
                          <table className="d-block">
                            <tbody className="d-block">
                              <tr className="d-block">
                                <td className="d-block comment-body markdown-body js-comment-body">
                                  <div
                                    className="ck ck-content"
                                    dangerouslySetInnerHTML={
                                      { __html: comment.body } as any
                                    }
                                  ></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="js-comment-update">
                        <div className="js-previewable-comment-form previewable-comment-form write-selected">
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="discussion-timeline-actions">
              <div className="timeline-comment-wrapper timeline-new-comment js-comment-container ms-0 ps-0 ms-md-6 ps-md-3">
                <div className="js-new-comment-form js-needs-timeline-marker-header">
                  <div className="border-0 border-md timeline-comment timeline-comment--caret">
                    <div className="js-previewable-comment-form-fieldset min-width-0">
                      <div
                        className="js-previewable-comment-form previewable-comment-form write-selected"
                        data-preview-url="/preview?markdown_unsupported=false&amp;repository=283623097&amp;subject=24&amp;subject_type=Issue"
                      >
                        <div
                          className="ck ck-content write-content js-write-bucket tooltipped tooltipped-ne tooltipped-no-delay tooltipped-align-left-1 hide-reaction-suggestion upload-enabled mx-0 mb-2 mx-md-2 hx_sm-hide-drag-drop js-reaction-suggestion"
                          data-reaction-markup="Would you like to leave a reaction instead?"
                        >
                          <CustomEditor
                            data={this.state.newComment}
                            onChange={(data) => {
                              this.setState({ newComment: data });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-actions m-0 mx-md-2 my-md-2 p-0">
                    <div
                      id="partial-new-comment-form-actions"
                      className="js-socket-channel js-updatable-content"
                    >
                      <div className="d-flex flex-justify-end">
                        <div className="bg-gray-light">
                          {this.state.issue.state === "open" ? (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.closeIssue()}
                            >
                              Close issue
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => this.openIssue()}
                            >
                              Re-open issue
                            </button>
                          )}
                        </div>
                        <div className="bg-gray-light ms-1">
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={this.state.newComment.length === 0}
                            onClick={() => this.addComment()}
                          >
                            Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IssuePage;
