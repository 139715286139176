import React from "react";
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Cell, Header, Footer, Row, Table } from "./PDFQuoteGenerator";
import { formatter } from "./Misc";

interface PDFQuoteGeneratorProps {
  backupData: BackupData[];
  laborRates: LaborRate[];
  overheadRates: OverheadRate[];
  order: Order;
}

export interface BackupData {
  task_name: string;
  item_number: string;
  materialsData: MaterialsData[];
  laborData: LaborData[];
  travelData: TravelData;
}

interface Order {
  job_number: string;
  description: string;
  customer: Customer;
  contact: Contact;
}

interface Customer {
  name: string;
  addr_line1: string;
  addr_line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface Contact {
  name: string;
  email: string;
  phone: string;
}

interface LaborRate {
  name: string;
  rate: number;
  id: string;
}

interface OverheadRate {
  name: string;
  rate: number;
}

interface TravelData {
  airfare: number;
  daily_car_rental: number;
  daily_conus_lodging: number;
  daily_parking: number;
  num_people: number;
  per_diem_on_site: number;
  days_on_site: number;
  travel_days: number;
  travel_location: string;
}

interface LaborData {
  hours_scientist: number;
  hours_proj_mgr: number;
  hours_engr_iii: number;
  hours_engr_ii: number;
  hours_engr_i: number;
  hours_dsgnr_iii: number;
  hours_dsgnr_ii: number;
  hours_dsgnr_i: number;
  hours_tech_iii: number;
  hours_tech_ii: number;
  hours_tech_i: number;
  task_name: string;
}

interface MaterialsData {
  name: string;
  raw_materials: string;
  type: string;
}

class PDFQuoteGenerator extends React.Component<PDFQuoteGeneratorProps> {
  render() {
    const styles = StyleSheet.create({
      page: {
        fontSize: 10,
        paddingTop: 25,
        paddingBottom: 40,
        paddingHorizontal: 40,
      },
    });
    return (
      <Document>
        <Page size="A4" style={styles.page} orientation="landscape">
          <Header />
          <TaskSummary data={this.props.order} />
          <LaborTable
            data={this.props.backupData}
            laborMargin={(this.props.overheadRates.find(
              (item) => item.name === "Labor Margin"
            )?.rate || 0) / 100}
            laborPrices={this.props.laborRates}
          />
          <MaterialsTable
            data={this.props.backupData}
            adminOH={(this.props.overheadRates.find(
              (item) => item.name === "Admin Rate"
            )?.rate || 0) / 100}
            mtlOH={(this.props.overheadRates.find(
              (item) => item.name === "MTL Rate"
            )?.rate || 0) / 100}
            passThruOH={(this.props.overheadRates.find(
              (item) => item.name === "Pass-through Rate"
            )?.rate || 0) / 100}
          />
          {this.props.backupData.map((task) => {
            return <TravelTable
              data={task.travelData}
              travelOH={(this.props.overheadRates.find(
                (item) => item.name === "Travel Rate"
              )?.rate || 0) / 100}
            />
          })}
          <Footer />
        </Page>
      </Document>
    );
  }
}

interface TaskSummaryProps {
  data: Order;
}

class TaskSummary extends React.Component<TaskSummaryProps> {
  render() {
    const styles = StyleSheet.create({
      title: {
        fontSize: 16,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 10,
        paddingBottom: 10
      }
    });
    return (
      <View>
        <Text style={styles.title}>{this.props.data.description} Budget</Text>
        <Table>
          <Row>
            <Cell width="200em">
              <Text>To:</Text>
            </Cell>
            <Cell>
              <Text>{this.props.data.customer.name}</Text>
              <Text>{this.props.data.customer.addr_line1}</Text>
              {this.props.data.customer.addr_line2 ? <Text>{this.props.data.customer.addr_line2}</Text> : null}
              <Text>{this.props.data.customer.city}, {this.props.data.customer.state} {this.props.data.customer.country} {this.props.data.customer.zip}</Text>
              <Text>Attn: {this.props.data.contact.name}</Text>
            </Cell>
            <Cell width="200em">
              <Text>Ref.:</Text>
              <Text>Phone:</Text>
              <Text>Email:</Text>
            </Cell>
            <Cell>
              <Text>{this.props.data.job_number}</Text>
              <Text>{this.props.data.contact.phone}</Text>
              <Text>{this.props.data.contact.email}</Text>
            </Cell>
          </Row>
        </Table>
      </View>
    );
  }
}

interface MaterialsTableProps {
  data: BackupData[];
  adminOH: number;   // Admin OH Rate
  mtlOH: number;  // MTL OH Rate
  passThruOH: number; // Pass Thru OH Rate
}

class MaterialsTable extends React.Component<MaterialsTableProps> {
  render() {
    if (!this.props.data) return null;
    const styles = StyleSheet.create({
      title: {
        fontSize: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 20,
        paddingBottom: 5
      },
      total: {
        fontSize: 10,
        textAlign: 'right',
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 5
      }
    });
    let total = 0;
    this.props.data.forEach((task) => {
      if (task.materialsData.filter((item) => parseFloat(item.raw_materials) > 0).length === 0) return null;
      task.materialsData.forEach((item) => {
        let raw_materials = parseFloat(item.raw_materials);
        if (isNaN(raw_materials)) raw_materials = 0;
        const OH_PERCENTAGE = item.type === "Parts" ? this.props.mtlOH : this.props.passThruOH;
        const OH = raw_materials * OH_PERCENTAGE;
        const ADMIN_OH = item.type === "Parts" ? ((raw_materials + OH) * this.props.adminOH) : 0;
        total += raw_materials + OH + ADMIN_OH;
      });
    });
    return (
      <View>
        <Text style={styles.title}>Materials</Text>
        <Table>
          <Row>
            <Cell><Text>Task</Text></Cell>
            <Cell><Text>Item</Text></Cell>
            <Cell><Text>Raw Mtr</Text></Cell>
            <Cell><Text>Type</Text></Cell>
            <Cell><Text>MTL OH %</Text></Cell>
            <Cell><Text>MTL OH</Text></Cell>
            <Cell><Text>Admin OH</Text></Cell>
            <Cell><Text>Total</Text></Cell>
          </Row>
          {this.props.data.map((task, taskIndex) => {
            if (task.materialsData.filter((item) => parseFloat(item.raw_materials) > 0).length === 0) return null;
            return task.materialsData.map((item, itemIndex) => {
              let raw_materials = parseFloat(item.raw_materials);
              if (isNaN(raw_materials)) raw_materials = 0;
              if (!raw_materials) return null;
              const OH_PERCENTAGE = item.type === "Parts" ? this.props.mtlOH : this.props.passThruOH;
              const OH = raw_materials * OH_PERCENTAGE;
              const ADMIN_OH = item.type === "Parts" ? ((raw_materials + OH) * this.props.adminOH) : 0;
              return (
                <Row key={taskIndex + "-" + itemIndex}>
                  {itemIndex === 0 ? (
                    <Cell><Text>Task {task.item_number}: {task.task_name}</Text></Cell>
                  ) : <Cell hidden />}
                  <Cell><Text>{item.name}</Text></Cell>
                  <Cell><Text>{formatter.format(raw_materials)}</Text></Cell>
                  <Cell><Text>{item.type}</Text></Cell>
                  <Cell><Text>{OH_PERCENTAGE * 100}%</Text></Cell>
                  <Cell><Text>{formatter.format(OH)}</Text></Cell>
                  <Cell><Text>{formatter.format(ADMIN_OH)}</Text></Cell>
                  <Cell><Text>{formatter.format(raw_materials + OH + ADMIN_OH)}</Text></Cell>
                </Row>
              );
            });
          })}
        </Table>
        <Text style={styles.total}>Materials Total: {formatter.format(total)}</Text>
      </View>
    );
  }
}

interface LaborTableProps {
  data: BackupData[];
  laborPrices: LaborRate[];
  laborMargin: number;
}

class LaborTable extends React.Component<LaborTableProps> {
  tableHasData(data: LaborData[]) {
    return (data.filter((item) =>
      parseInt(item.hours_scientist.toString()) > 0 ||
      parseInt(item.hours_proj_mgr.toString()) > 0 ||
      parseInt(item.hours_engr_iii.toString()) > 0 ||
      parseInt(item.hours_engr_ii.toString()) > 0 ||
      parseInt(item.hours_engr_i.toString()) > 0 ||
      parseInt(item.hours_dsgnr_iii.toString()) > 0 ||
      parseInt(item.hours_dsgnr_ii.toString()) > 0 ||
      parseInt(item.hours_dsgnr_i.toString()) > 0 ||
      parseInt(item.hours_tech_iii.toString()) > 0 ||
      parseInt(item.hours_tech_ii.toString()) > 0 ||
      parseInt(item.hours_tech_i.toString()) > 0
    ).length !== 0);
  }

  render() {
    if (!this.props.data) return null;
    const COL_HEIGHT = 100;
    const COL_WIDTH = 50;
    const styles = StyleSheet.create({
      title: {
        fontSize: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 20,
        paddingBottom: 5
      },
      total: {
        fontSize: 10,
        textAlign: 'right',
        fontWeight: 'bold',
        paddingTop: 5,
        paddingBottom: 5
      },
      vertical: {
        transform: "rotate(-90deg)",
        width: COL_HEIGHT,
        height: COL_WIDTH,
        position: "absolute",
        top: 20,
        left: -20,
      }
    });

    let total = 0;
    this.props.data.forEach((task) => {
      if (!this.tableHasData(task.laborData)) return null;
      task.laborData.forEach((item) => {
        total += this.props.laborPrices.reduce((prev, curr, index) => {
          return prev + curr.rate * parseFloat((item as any)[curr.id]);
        }, 0);
      });
    });
    return (
      <View>
        <Text style={styles.title}>Labor</Text>
        <Table>
          <Row>
            <Cell height={COL_HEIGHT} width={COL_WIDTH * 4}><Text>Task</Text></Cell>
            <Cell height={COL_HEIGHT}><Text>Item</Text></Cell>
            {this.props.laborPrices.map((item, index) => {
              return (
                <Cell key={index} width={COL_WIDTH} height={COL_HEIGHT}>
                  <Text style={styles.vertical}>{item.name.toUpperCase()} (${parseInt(item.rate.toString())})</Text>
                </Cell>
              );
            })}
            <Cell width={COL_WIDTH} height={COL_HEIGHT}>
              <Text style={styles.vertical}>TOTAL HRS</Text>
            </Cell>
            <Cell height={COL_HEIGHT} width={COL_WIDTH * 3}>
              <Text style={{ fontWeight: 'bold' }}>Subtask Total</Text>
            </Cell>
          </Row>
          {this.props.data.map((task, taskIndex) => {
            if (!this.tableHasData(task.laborData)) return null;
            return task.laborData.map((item, itemIndex) => {
              const HOUR_TOTAL = this.props.laborPrices.reduce((prev, curr, index) => {
                return prev + parseFloat((item as any)[curr.id]);
              }, 0);
              const LABOR_TOTAL = this.props.laborPrices.reduce((prev, curr, index) => {
                return prev + curr.rate * parseFloat((item as any)[curr.id]);
              }, 0);
              return (
                <Row key={taskIndex + "-" + itemIndex}>
                  {itemIndex === 0 ? (
                    <Cell width={COL_WIDTH * 4}><Text>Task {task.item_number}: {task.task_name}</Text></Cell>
                  ) : <Cell width={COL_WIDTH * 4} hidden />}
                  <Cell><Text>{item.task_name}</Text></Cell>
                  {this.props.laborPrices.map((price, index) => {
                    const hours = parseFloat((item as any)[price.id]);
                    return (
                      <Cell key={index} width={COL_WIDTH}><Text>{hours > 0 ? hours : ""}</Text></Cell>
                    );
                  })}
                  <Cell width={COL_WIDTH}><Text>{HOUR_TOTAL}</Text></Cell>
                  <Cell width={COL_WIDTH * 3}><Text>{formatter.format(LABOR_TOTAL)}</Text></Cell>
                </Row>
              );
            })
          })}
        </Table>
        <Text style={styles.total}>Labor Total: {formatter.format(total)}</Text>
      </View>
    );
  }
}

interface TravelTableProps {
  data: TravelData;
  travelOH: number;
}

class TravelTable extends React.Component<TravelTableProps> {
  render() {
    if (!this.props.data) return null;
    if (!(
      parseFloat(this.props.data.airfare.toString()) > 0 ||
      parseFloat(this.props.data.daily_car_rental.toString()) > 0 ||
      parseFloat(this.props.data.daily_conus_lodging.toString()) > 0 ||
      parseFloat(this.props.data.daily_parking.toString()) > 0 ||
      parseFloat(this.props.data.per_diem_on_site.toString()) > 0
    )) return null;
    const styles = StyleSheet.create({
      title: {
        fontSize: 10,
        textAlign: 'center',
        fontWeight: 'bold',
        paddingTop: 20,
        paddingBottom: 5
      }
    });
    const WIDTHS = [400, 100, 100, 100];

    const TOTAL_AIRFARE = this.props.data.airfare * this.props.data.num_people;
    const TOTAL_CAR_RENTAL = this.props.data.daily_car_rental * (
      parseInt(this.props.data.travel_days.toString()) +
      parseInt(this.props.data.days_on_site.toString())
    );
    const TOTAL_PARKING = this.props.data.daily_parking * (
      parseInt(this.props.data.travel_days.toString()) +
      parseInt(this.props.data.days_on_site.toString())
    );
    const TOTAL_CONUS_LODGING =
      this.props.data.daily_conus_lodging * (
        (parseInt(this.props.data.travel_days.toString()) +
          parseInt(this.props.data.days_on_site.toString()) - 1)
        * parseInt(this.props.data.num_people.toString())
      );
    const TOTAL_PER_DIEM_ON_SITE = this.props.data.num_people * this.props.data.per_diem_on_site * this.props.data.days_on_site;
    const TOTAL_PER_DIEM_TRAVEL = this.props.data.num_people * this.props.data.per_diem_on_site * 0.75 * this.props.data.travel_days;

    const SUBTOTAL = TOTAL_AIRFARE + TOTAL_CAR_RENTAL + TOTAL_PARKING + TOTAL_CONUS_LODGING + TOTAL_PER_DIEM_ON_SITE + TOTAL_PER_DIEM_TRAVEL;
    const OH = SUBTOTAL * this.props.travelOH;
    const TOTAL = SUBTOTAL + OH;

    return (
      <View>
        <Text style={styles.title}>Travel</Text>
        <Table wrap={false}>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Travel Calculator</Text></Cell>
            <Cell width={WIDTHS[1]}></Cell>
            <Cell width={WIDTHS[2]}><Text>Qty</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>Cost</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text># people</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{this.props.data.num_people}</Text></Cell>
            <Cell width={WIDTHS[2]}></Cell>
            <Cell width={WIDTHS[3]}></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Days on site</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{this.props.data.days_on_site}</Text></Cell>
            <Cell width={WIDTHS[2]}></Cell>
            <Cell width={WIDTHS[3]}></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Travel days</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{this.props.data.travel_days}</Text></Cell>
            <Cell width={WIDTHS[2]}></Cell>
            <Cell width={WIDTHS[3]}></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Travel location</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{this.props.data.travel_location}</Text></Cell>
            <Cell width={WIDTHS[2]}></Cell>
            <Cell width={WIDTHS[3]}></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Airfare</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{formatter.format(this.props.data.airfare)}</Text></Cell>
            <Cell width={WIDTHS[2]}><Text>{this.props.data.num_people}</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(TOTAL_AIRFARE)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Daily Car Rental</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{formatter.format(this.props.data.daily_car_rental)}</Text></Cell>
            <Cell width={WIDTHS[2]}><Text>{
              parseInt(this.props.data.travel_days.toString()) +
              parseInt(this.props.data.days_on_site.toString())
            }</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(TOTAL_CAR_RENTAL)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Daily Parking</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{formatter.format(this.props.data.daily_parking)}</Text></Cell>
            <Cell width={WIDTHS[2]}><Text>{
              parseInt(this.props.data.travel_days.toString()) +
              parseInt(this.props.data.days_on_site.toString())
            }</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(TOTAL_PARKING)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Daily CONUS Lodging</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{formatter.format(this.props.data.daily_conus_lodging)}</Text></Cell>
            <Cell width={WIDTHS[2]}><Text>{
              (parseInt(this.props.data.travel_days.toString()) +
                parseInt(this.props.data.days_on_site.toString()) - 1)
              * parseInt(this.props.data.num_people.toString())
            }</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(TOTAL_CONUS_LODGING)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Per diem on site</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{formatter.format(this.props.data.per_diem_on_site)}</Text></Cell>
            <Cell width={WIDTHS[2]}><Text>{this.props.data.num_people * this.props.data.days_on_site}</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(TOTAL_PER_DIEM_ON_SITE)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}><Text>Per diem 1st/last days</Text></Cell>
            <Cell width={WIDTHS[1]}><Text>{formatter.format(this.props.data.per_diem_on_site * 0.75)}</Text></Cell>
            <Cell width={WIDTHS[2]}><Text>{this.props.data.num_people * this.props.data.travel_days}</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(TOTAL_PER_DIEM_TRAVEL)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}></Cell>
            <Cell width={WIDTHS[1]}></Cell>
            <Cell width={WIDTHS[2]}><Text>Subtotal travel</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(SUBTOTAL)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}></Cell>
            <Cell width={WIDTHS[1]}><Text>{parseInt((this.props.travelOH * 100).toString())}%</Text></Cell>
            <Cell width={WIDTHS[2]}><Text>Travel OH</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(OH)}</Text></Cell>
          </Row>
          <Row>
            <Cell width={WIDTHS[0]}></Cell>
            <Cell width={WIDTHS[1]}></Cell>
            <Cell width={WIDTHS[2]}><Text>Total travel</Text></Cell>
            <Cell width={WIDTHS[3]}><Text>{formatter.format(TOTAL)}</Text></Cell>
          </Row>
        </Table>
      </View>
    );
  }
}


export default PDFQuoteGenerator;