import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { formatter, generateModalStyle } from "../../utils/Misc";

interface PriceHistoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  product_id: number;
}

interface PriceHistoryModalState {
  history: PriceHistory[];
  users: { [key: number]: string };
}

interface PriceHistory {
  from_price: number;
  to_price: number;
  user_id: number;
  timestamp: string;
}

class PriceHistoryModal extends Component<
  PriceHistoryModalProps,
  PriceHistoryModalState
> {
  constructor(props: PriceHistoryModalProps) {
    super(props);
    this.state = {
      history: [],
      users: {},
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: PriceHistoryModalProps) {
    if (prevProps.product_id !== this.props.product_id) {
      this.loadData();
    }
  }

  loadData() {
    if (!this.props.product_id) {
      return;
    }
    callAPI("tables/price_history", "POST", {
      where: {
        product_id: this.props.product_id,
      },
    })
      .then((response) => {
        this.setState({
          history: response || [],
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error loading price history");
      });
    callAPI("tables/users")
      .then((response) => {
        const users: { [key: number]: string } = {};
        response.forEach((user: any) => {
          users[user.id] = user.username;
        });
        this.setState({
          users: users,
        });
      });
  }

  render() {
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
      >
        <Box sx={generateModalStyle("80%")}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Price History
          </Typography>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>From Price</th>
                <th>To Price</th>
                <th>Timestamp</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {this.state.history.map((row: PriceHistory) => (
                <tr key={row.timestamp}>
                  <td>{formatter.format(row.from_price)}</td>
                  <td>{formatter.format(row.to_price)}</td>
                  <td>{row.timestamp}</td>
                  <td>{this.state.users[row.user_id]}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {this.state.history.length === 0 && (
            <div className="text-center">No price history</div>
          )}
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.onClose();
              }}
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default PriceHistoryModal;
