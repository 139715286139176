import React, { ChangeEvent, Component } from 'react';
import './ImageUploader.css';
import { API_URL } from './API';
import { toast } from 'react-hot-toast';

interface ImageUploaderProps {
  value: string;
  onChange: (value: string) => void;
}

interface ImageUploaderState {
  file: File | null;
  imageUrl: string;
  hover: boolean;
}

class ImageUploader extends Component<ImageUploaderProps, ImageUploaderState> {
  constructor(props: ImageUploaderProps) {
    super(props);
    this.state = {
      file: null,
      imageUrl: props.value || '',
      hover: false,
    };
  }

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      this.setState({
        file,
        imageUrl: URL.createObjectURL(file),
      });
      this.upload(file)
        .then((res: any) => {
          const url = res.url;
          // remove host from url if present
          if (url.indexOf(".org") !== -1 || url.indexOf(".com") !== -1) {
            const parts = url.split("/");
            parts.shift();
            parts.shift();
            parts.shift();
            this.props.onChange("/" + parts.join("/"));
          } else {
            this.props.onChange(url);
          }
        })
        .catch((err: any) => {
          console.log(err);
          toast.error("Upload failed");
        });
    }
  };

  async upload(file: File) {
    const data = new FormData();
    data.append('upload', file);

    return new Promise((resolve, reject) => {
      const headers = new Headers();
      const jwt = localStorage.getItem("jwt");
      if (jwt) {
        headers.append("Authorization", `Bearer ${jwt}`);
      }
      fetch(API_URL + 'actions/upload-image', {
        method: 'POST',
        headers,
        body: data
      }).then(res => {
        return res.json();
      }).then(res => {
        if (res.uploaded) {
          res.default = res.url;
          resolve(res);
        } else {
          reject(res.error);
        }
      }).catch(error => {
        console.log(error)
        reject(error)
      });
    });
  }

  handleRemoveClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    this.setState({
      file: null,
      imageUrl: '',
    });
    this.props.onChange('');
    const fileInput = document.getElementById('imageInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  handleImageClick = () => {
    const fileInput = document.getElementById('imageInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({ imageUrl: value });
    }
  }

  componentDidUpdate(prevProps: ImageUploaderProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState({ imageUrl: value });
    }
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.imageUrl);
  }

  render() {
    const { imageUrl, hover } = this.state;

    return (
      <div
        className={`image-uploader ${hover ? 'hover' : ''}`}
        onClick={this.handleImageClick}
      >
        <input
          id="imageInput"
          type="file"
          accept="image/*"
          onChange={this.handleFileChange}
          style={{ display: 'none' }}
        />
        {imageUrl && <img src={imageUrl} alt="Uploaded" className="uploaded-image" />}
        {!imageUrl && (
          <div className="placeholder-text">
            <span>Click to upload image</span>
          </div>
        )}
        {imageUrl && (
          <button
            type="button"
            className="btn btn-danger remove-button"
            onClick={this.handleRemoveClick}
          >
            Remove
          </button>
        )}
      </div>
    );
  }
}

export default ImageUploader;
