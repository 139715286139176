import { Box, Modal, Typography } from "@mui/material";
import { Component } from "react";
import { generateModalStyle } from "../../../../utils/Misc";
import { DataTable, GridElement } from "../../../../utils/DataTable";
import { callAPI } from "../../../../utils/API";
import { toast } from "react-hot-toast";

interface AccessControlListEditorModalProps {
  open: boolean;
  onClose: () => void;
  audit: {
    users: User[];
    groups: Group[];
  };
}

interface AccessControlListEditorModalState {
  users: User[];
  groups: Group[];
  mappings: Mapping[];
}

export interface User {
  id: number;
  name: string;
  username: string;
}

export interface Group {
  id: number;
  name: string;
  description: string;
}

export interface Mapping {
  user_id: number;
  group_id: number;
}

class AccessControlListEditorModal extends Component<AccessControlListEditorModalProps, AccessControlListEditorModalState> {
  constructor(props: AccessControlListEditorModalProps) {
    super(props);
    this.state = {
      users: [],
      groups: [],
      mappings: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const usersPromise = callAPI("tables/access_control_users");
    const groupsPromise = callAPI("tables/access_control_groups");
    const mappingsPromise = callAPI("tables/access_control_mappings");
    Promise.all([usersPromise, groupsPromise, mappingsPromise]).then((values) => {
      this.setState({
        users: values[0],
        groups: values[1],
        mappings: values[2],
      });
    });
  }

  generateGrid(): GridElement[][] {
    // generate an adjacency matrix grid with users as rows and groups as columns
    const grid = this.state.users.map((user) => {
      const usernameCell = {
        value: user.username,
        editable: false,
      };
      const cells = this.state.groups.map((group) => {
        return {
          value: this.state.mappings.find((mapping) => mapping.user_id === user.id && mapping.group_id === group.id) ? "1" : "0",
          editable: true,
          type: "checkbox",
        };
      });
      return [usernameCell, ...cells];
    });
    // add a row at the top with all of the group names, add a column on the left for all of the usernames
    const groupnames = this.state.groups.map((group) => group.name);
    const groupnameRow = groupnames.map((groupname) => {
      return {
        value: groupname,
        editable: false,
      };
    });
    grid.unshift([{ value: "", editable: false }, ...groupnameRow]);
    return grid;
  }

  syncUsers() {
    // for each username in the audit but not in the state, add it to the state
    const usernames = this.state.users.map((user) => user.username.trim());
    const promises = this.props.audit.users.map((user) => {
      if (!usernames.includes(user.username.trim())) {
        return callAPI("actions/access-control", "POST", {
          action: "add_user",
          username: user.username.trim(),
          name: user.name.trim(),
        });
      }
    });
    if (!promises.length) {
      toast.success("No users to sync!");
      return;
    }
    const overallPromise = Promise.all(promises).then(() => {
      this.loadData();
    });
    toast.promise(overallPromise, {
      loading: "Syncing users...",
      success: "Users synced!",
      error: "Failed to sync users!"
    });
  }

  syncGroups() {
    // for each group in the audit but not in the state, add it to the state
    const groupnames = this.state.groups.map((group) => group.name.trim());
    const promises = this.props.audit.groups.map((group) => {
      if (!groupnames.includes(group.name.trim())) {
        return callAPI("actions/access-control", "POST", {
          action: "add_group",
          name: group.name.trim(),
          description: group.description.trim(),
        });
      }
    });
    if (!promises.length) {
      toast.success("No groups to sync!");
      return;
    }
    const overallPromise = Promise.all(promises).then(() => {
      this.loadData();
    });
    toast.promise(overallPromise, {
      loading: "Syncing groups...",
      success: "Groups synced!",
      error: "Failed to sync groups!"
    });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <Box sx={generateModalStyle("78%")}>
          <Typography variant="h6" component="h2">
            Access Control List
          </Typography>
          <div className="mb-2">
            <DataTable
              defaultValue={this.generateGrid() || undefined}
              onChange={(value) => {
                console.log(value); // the entire grid
                // this.reverseData(value);
                // this.updateTotals(watchedCells);
              }}
            />
          </div>
          <button className="btn btn-primary btn-sm me-1" onClick={() => {
            this.syncUsers();
          }}>Sync Users</button>
          <button className="btn btn-primary btn-sm" onClick={() => {
            this.syncGroups();
          }}>Sync Groups</button>
        </Box>
      </Modal>
    );
  }
}

export default AccessControlListEditorModal;