import { Component } from "react";
import { callAPI } from "../../utils/API";

interface RequirementsTableProps {
  ids: number[];
  title: string;
  types: string[];
}

interface RequirementsTableState {
  orders: any[];
}

class RequirementsTable extends Component<
  RequirementsTableProps,
  RequirementsTableState
> {
  constructor(props: RequirementsTableProps) {
    super(props);
    this.state = {
      orders: [],
    };
  }

  loadData() {
    callAPI("data/orders-by-item", "POST", {
      ids: this.props.ids,
      types: this.props.types,
    }).then((response) => {
      this.setState({
        orders: response,
      });
    });
  }

  componentDidMount(): void {
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<RequirementsTableProps>): void {
    if (
      prevProps.ids !== this.props.ids
    ) {
      this.loadData();
    }
  }

  render() {
    if (this.state.orders.length === 0) {
      return <div />;
    } else if (this.props.types.length === 1 && this.props.types[0] === "Parent Build") {
      return (
        <div>
          <h1 className="h5">{this.props.title}</h1>
          <div className="my-4 w-100">
            <div className="table-responsive">
              <table className="table table-striped table-sm" role="grid">
                <thead>
                  <tr role="row">
                    <th>Parent LTC P/N</th>
                    <th>Parent Description</th>
                    <th>Comment</th>
                    <th>Status</th>
                    <th>W/O</th>
                    <th>Qty</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orders.map((order, index) => {
                    return (
                      <tr key={this.props.title + '_' + index}>
                        <td>{order.ltc_pn}</td>
                        <td>{order.name}</td>
                        <td>{order.comment}</td>
                        <td>{order.status}</td>
                        <td>{order.wo}</td>
                        <td>{order.qty}</td>
                        <td>{new Date(order.start_date).toLocaleDateString()}</td>
                        <td>{new Date(order.finish_date).toLocaleDateString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div >
      );
    }
    let runningTotal = 0;
    return (
      <div>
        <h1 className="h5">{this.props.title}</h1>
        <div className="my-4 w-100">
          <div className="table-responsive">
            <table className="table table-striped table-sm" role="grid">
              <thead>
                <tr role="row">
                  <th>Running Total</th>
                  <th>Customer</th>
                  <th>End User</th>
                  <th>Quote Number</th>
                  <th>Job Type</th>
                  <th>Job Description</th>
                  <th>Qty</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state.orders.map((order, index) => {
                  runningTotal += parseInt(order.quantity);
                  return (
                    <tr key={this.props.title + '_' + index}>
                      <td>{runningTotal}</td>
                      <td>{order.customer}</td>
                      <td>{order.end_user}</td>
                      <td>{order.order_num}</td>
                      <td>{order.type}</td>
                      <td>{order.description}</td>
                      <td>{order.quantity}</td>
                      <td>{order.ship_date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div >
    );
  }
}

export default RequirementsTable;
