import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { callAPI, callAPIWithCache } from "../../utils/API";
import toast from "react-hot-toast";
import { generateModalStyle } from "../../utils/Misc";
import DataGrid from "../../utils/DataGrid";
import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ProductSelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (products: number[]) => void;
  multiple?: boolean;
}

interface ProductSelectModalState {
  rows: any[];
  selected: number[];
  loading: boolean;
  searchTerm: string;
}

class ProductSelectModal extends Component<
  ProductSelectModalProps,
  ProductSelectModalState
> {
  constructor(props: ProductSelectModalProps) {
    super(props);
    this.state = {
      rows: [],
      selected: [],
      searchTerm: "",
      loading: false,
    };
  }

  loadData() {
    this.setState({ loading: true }, () => {
      const cacheResult = callAPIWithCache("tables/products_view", "POST", {
        where: {
          archived: 0,
        },
      });

      if (cacheResult.cache && cacheResult.cache.data) {
        this.setState({
          loading: false,
          rows: cacheResult.cache.data,
        });
      }
      cacheResult.result
        .then((data) => {
          if (!data.products) data.products = [];
          this.setState({
            rows: data,
            loading: false,
          }, () => {
            this.loadStats();
          });
        })
        .catch((data) => {
          if (data.error !== "Unauthorized") {
            toast.error("Error loading products");
            console.log(data);
          }
        });
    });
  }

  loadStats() {
    callAPI("data/product-stats", "POST", {
      ids: this.state.rows.map((p: any) => p.id),
    })
      .then((data) => {
        let products = this.state.rows;
        for (let i = 0; i < products.length; i++) {
          products[i].num_rqmnts = data[products[i].id].num_rqmnts;
          products[i].num_builds = data[products[i].id].num_builds;
        }
        this.setState({
          rows: products,
        });
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading product stats", { id: "product-stats" });
          console.log(data);
        }
      });
  }

  componentDidUpdate(prevProps: ProductSelectModalProps) {
    if (this.props.isOpen && !prevProps.isOpen && !this.state.rows.length) {
      this.loadData();
    }
  }

  render() {
    const columns: any[] = [
      { field: "id", headerName: "ID", minWidth: 90, hide: true },
      {
        field: "ltc_pn", headerName: "LTC P/N", minWidth: 150,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        field: "name",
        headerName: "Name",
        minWidth: 250,
      },
      {
        field: "cat_num",
        headerName: "Cat #",
        description: "Catalog Number",
        minWidth: 175,
      },
      {
        field: "category",
        headerName: "Category",
        minWidth: 90,
      },
      {
        field: "num_rqmnts",
        headerName: "# Rqmnts",
        description: "Number of Requirements",
        minWidth: 90,
      },
      {
        field: "num_builds",
        headerName: "# Builds",
        description: "Number of Builds",
        minWidth: 90,
      },
      {
        field: "qty_oh",
        headerName: "# Inv",
        description: "Quantity on Hand / Number in Inventory",
        minWidth: 90,
      },
      {
        field: "num_available",
        headerName: "# Available",
        description: "Number Available",
        minWidth: 90,
      },
    ];
    return (
      <Modal
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%", "98%")}>
          <div className="d-flex flex-column h-100">
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Select Product
              </Typography>
              <IconButton
                aria-label="close"
                onClick={this.props.onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <TextField
                fullWidth
                className="mb-2"
                type="text"
                placeholder="Search"
                value={this.state.searchTerm}
                onChange={(e) => {
                  this.setState({ searchTerm: e.target.value });
                }}
              />
            </div>
            <div className="h-100 pb-3">
              <DataGrid
                className="mt-3"
                rows={this.state.rows}
                searchTerm={this.state.searchTerm}
                columns={columns}
                defaultColumnProps={{
                  sortable: true,
                  resizable: true,
                  suppressMovable: true,
                }}
                selection="multiple"
                loading={this.state.loading}
                onSelectionModelChange={(newSelection) => {
                  this.setState({ selected: newSelection as number[] });
                  if (!this.props.multiple) {
                    this.props.onSelect([parseInt(newSelection[0] as any)]);
                  }
                }}
              />
            </div>
            <div>
              <div className="d-flex w-100 gap-2 justify-content-end mt-2">
                <button
                  disabled={
                    this.state.selected.length === 0 ||
                    (!this.props.multiple && this.state.selected.length > 1)
                  }
                  onClick={() => {
                    const selected = this.state.selected;
                    for (let i = 0; i < selected.length; i++) {
                      selected[i] = parseInt(selected[i] as any);
                    }
                    this.props.onSelect(selected);
                  }}
                  className="btn btn-primary"
                >
                  Select
                </button>
              </div>
              {!this.props.multiple && this.state.selected.length > 1 && (
                <div className="alert alert-danger mt-2">
                  You can only select one product
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default ProductSelectModal;
