import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI, callAPIWithCache } from "../../utils/API";
import { formatter } from "../../utils/Misc";
import { Link } from "react-router-dom";
import { getAccordionHeight } from "../../utils/Misc";
import DataGrid from "../../utils/DataGrid";

interface ProductSectionProps {
  inv_code: string;
  title: string;
  searchTerm?: string;
  prices?: boolean;
  priceTierValue?: number;
  onlyUsePriceTierForGSA?: boolean;
  includeArchived?: boolean;
}

interface ProductSectionState {
  isLoading: boolean;
  products: any;
  collapsed: boolean;
}

class ProductSection extends Component<
  ProductSectionProps,
  ProductSectionState
> {
  constructor(props: ProductSectionProps) {
    const pgPref = localStorage.getItem("paginationPreference") || "paginate"
    super(props);
    this.state = {
      isLoading: false,
      products: [],
      collapsed: pgPref === "paginate",
    };
  }

  load() {
    if (!this.state.isLoading) {
      this.setState({
        isLoading: true,
      });
    }
    const cacheResult = callAPIWithCache("tables/products_view", "POST", {
      where: this.props.includeArchived ? undefined : {
        archived: 0,
      },
      search: {
        query: this.props.inv_code,
        fields: ["inv_code"],
      }
    });

    if (cacheResult.cache && cacheResult.cache.data) {
      this.setState({
        isLoading: false,
        products: cacheResult.cache.data,
      });
    }
    cacheResult.result
      .then((data) => {
        this.setState({
          isLoading: false,
          products: data,
        }, () => {
          this.loadStats();
        });
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading products", { id: "product-load" });
          console.log(data);
        }
      });
  }

  loadStats() {
    callAPI("data/product-stats", "POST", {
      ids: this.state.products.map((p: any) => p.id),
    })
      .then((data) => {
        let products = this.state.products;
        for (let i = 0; i < products.length; i++) {
          products[i].num_rqmnts = data[products[i].id].num_rqmnts;
          products[i].num_builds = data[products[i].id].num_builds;
          products[i].num_available = parseInt(data[products[i].id].qty_oh || 0) - parseInt(products[i].num_rqmnts) + parseInt(products[i].num_builds);
        }
        this.setState({
          products: products,
        });
      })
      .catch((data) => {
        if (data.error !== "Unauthorized") {
          toast.error("Error loading product stats", { id: "product-stats" });
          console.log(data);
        }
      });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const products = this.state.products.filter((p: any) => {
      const s = this.props.searchTerm?.toLowerCase();
      if (!s) return true;
      const params = [p.ltc_pn, p.name, p.cat_num, p.category];
      for (let i = 0; i < params.length; i++) {
        if (params[i].toLowerCase().indexOf(s) !== -1) {
          return true;
        }
      }
      return false;
    });
    if (this.state.isLoading || products.length === 0) {
      return null;
    }
    let columns: any[] = [
      { field: "id", headerName: "ID", width: 90, hide: true },
      {
        field: "ltc_pn",
        headerName: "LTC P/N",
        width: 150,
        cellRenderer: (params: any) => (
          <Link to={params.data.id}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "cat_num",
        headerName: "Cat #",
        description: "Catalog Number",
        width: 175,
      },
      {
        field: "name",
        headerName: "Name",
        description: "Product Description",
        width: 175,
      },
      {
        field: "category",
        headerName: "Category",
        width: 90,
      },
      {
        field: "standard",
        headerName: "Std/Cust",
        description: "Standard?",
        valueFormatter: (params: any) => (params.value ? "Standard" : "Custom"),
      },
      {
        field: "num_rqmnts",
        headerName: "# Rqmnts",
        description: "Number of Requirements",
        width: 90,
        cellRenderer: (params: any) => (
          <Link to={"/?id=[" + params.data.id + "]"}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "num_builds",
        headerName: "# Builds",
        description: "Number of Builds",
        width: 90,
        cellRenderer: (params: any) => (
          <Link to={"/?id=[" + params.data.id + "]#Inventory"}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "qty_oh",
        headerName: "# Inv.",
        description: "Quantity on Hand / Number in Inventory",
        width: 60,
      },
      {
        field: "num_available",
        headerName: "# Avail.",
        description: "Number Available",
        width: 60,
      },
    ];
    if (this.props.prices) {
      columns = [
        { field: "id", headerName: "ID", width: 90, hide: true },
        {
          field: "ltc_pn",
          headerName: "LTC P/N",
          width: 150,
          cellRenderer: (params: any) => (
            <Link to={params.data.id}>
              {params.value}
            </Link>
          ),
        },
        {
          field: "cat_num",
          headerName: "Cat #",
          description: "Catalog Number",
          width: 175,
        },
        {
          field: "category",
          headerName: "Category",
          width: 90,
        },
        {
          field: "is_gsa",
          headerName: "GSA",
          description: "GSA?",
          width: 60,
          valueFormatter: (params: any) => (parseInt(params.value) === 1 ? "Yes" : "No"),
        },
        {
          field: "price",
          headerName: "Price",
          description: "Price",
          width: 110,
          cellRenderer: (params: any) => {
            const isGSA = parseInt(params.data.is_gsa) === 1;
            let price = params.value as number;
            if (this.props.priceTierValue && (!this.props.onlyUsePriceTierForGSA || isGSA)) {
              price *= this.props.priceTierValue;
            }
            return formatter.format(price);
          },
        },
        {
          field: "cost",
          headerName: "Cost",
          description: "Cost",
          width: 110,
          valueFormatter: (params: any) => formatter.format(params.value as number),
        },
        {
          field: "margin",
          headerName: "Margin",
          description: "Margin",
          width: 110,
          valueFormatter: (params: any) => formatter.format(params.value as number),
        },
      ];
    }
    return (
      <Accordion
        expanded={!this.state.collapsed}
        onChange={() => {
          this.setState({
            collapsed: !this.state.collapsed,
          });
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {this.props.title}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>{products.length} product{products.length === 1 ? "" : "s"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="pb-3" style={{ height: getAccordionHeight(products.length), width: "100%" }}>
            <DataGrid
              className="mt-0"
              rows={products}
              columns={columns}
              loading={this.state.isLoading}
              defaultColumnProps={{
                sortable: true,
                resizable: true,
                suppressMovable: true,
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion >
    );
  }
}

export default ProductSection;
