import { API_URL } from "../../../utils/API";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface CustomEditorProps {
  data: string;
  onChange: (data: string) => void;
  placeholder?: string;
}

class UploadAdapter {
  constructor(loader: any) {
    // Save Loader instance to update upload progress.
    this.loader = loader;
  }

  async upload() {
    const data = new FormData();
    const file: File = await this.loader.file;
    data.append('upload', file);

    return new Promise((resolve, reject) => {
      const headers = new Headers();
      const jwt = localStorage.getItem("jwt");
      if (jwt) {
        headers.append("Authorization", `Bearer ${jwt}`);
      }
      fetch(API_URL + 'actions/upload-image', {
        method: 'POST',
        headers,
        body: data
      }).then(res => {
        return res.json();
      }).then(res => {
        if (res.uploaded) {
          res.default = res.url;
          resolve(res);
        } else {
          reject(res.error);
        }
      }).catch(error => {
        console.log(error)
        reject(error)
      });
    });
  }

  abort() {
    // Reject promise returned from upload() method.
  }

  private loader: any;
}

function CustomEditor(props: CustomEditorProps) {
  return (<CKEditor
    editor={ClassicEditor}
    data={props.data}
    onChange={(event: any, editor: any) => {
      const data = editor.getData();
      props.onChange(data);
    }}
    placeholder={props.placeholder}
    // config={{
    //   ckfinder: {
    //     uploadUrl: API_URL + 'actions/upload-image',
    //   },
    // }}
    onReady={(editor: any) => {
      editor.plugins.get('FileRepository').createUploadAdapter = function (loader: any) {
        return new UploadAdapter(loader);
      };
    }}
  />);
}

export default CustomEditor;