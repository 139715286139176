import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Component } from "react";
import toast from "react-hot-toast";
import { callAPI } from "../../utils/API";
import { generateModalStyle } from "../../utils/Misc";
import { Button, TextField } from "@mui/material";

interface EditContactModalProps {
  onClose: () => void;
  onSave: () => void;
  id: number;
}

interface EditContactModalState {
  name: string;
  email: string;
  phone: string;
}

class EditContactModal extends Component<
  EditContactModalProps,
  EditContactModalState
> {
  constructor(props: EditContactModalProps) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
    };
  }

  loadData() {
    if (this.props.id > 0) {
      callAPI("tables/contacts", "POST", {
        where: {
          id: this.props.id,
        },
      })
        .then((res) => {
          if (res.length > 0) {
            this.setState({
              name: res[0].name,
              email: res[0].email,
              phone: res[0].phone,
            });
          } else {
            toast.error("Error loading contact");
          }
        })
        .catch((err) => {
          toast.error("Error loading contact");
          console.log(err);
        });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: EditContactModalProps) {
    if (prevProps.id !== this.props.id) {
      this.loadData();
    }
  }

  deleteContact() {
    if (!window.confirm("Are you sure you want to delete this contact?")) {
      return;
    }
    if (this.props.id > 0) {
      callAPI("actions/customers", "POST", {
        action: "delete_contact",
        contact_id: this.props.id,
      })
        .then((res) => {
          toast.success("Contact deleted");
          this.props.onSave();
          this.props.onClose();
        })
        .catch((err) => {
          toast.error("Error deleting contact");
          console.log(err);
        });
    }
  }

  render() {
    return (
      <Modal
        open={this.props.id > 0}
        onClose={this.props.onClose}
        aria-labelledby="selectProductModalLabel"
      >
        <Box sx={generateModalStyle("98%")}>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Edit Contact</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group me-2">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => {
                    this.deleteContact();
                  }}
                >Delete</button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Contact Name"
                type="text"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Contact Email"
                type="text"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
            <div className="col-4 my-2">
              <TextField
                fullWidth
                label="Contact Phone"
                type="text"
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </div>
          </div>
          <div className="d-flex w-100 gap-2 justify-content-end mt-2">
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.name}
              onClick={() => {
                callAPI("actions/customers", "POST", {
                  action: "edit_contact",
                  name: this.state.name,
                  email: this.state.email,
                  phone: this.state.phone,
                  contact_id: this.props.id,
                })
                  .then((res) => {
                    this.props.onSave();
                    this.props.onClose();
                  })
                  .catch((err) => {
                    toast.error("Error updating contact");
                    console.log(err);
                  });
              }}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    );
  }
}

export default EditContactModal;
