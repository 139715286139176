import React, { useEffect, useState } from "react";
import CustomEditor from "../ui/ckeditor/CustomEditor";
import CustomerSelect from "../ui/select/CustomerSelect";
import JobTypeSelect from "../ui/select/JobTypeSelect";
import EndUserSelect from "../ui/select/EndUserSelect";
import ContactSelect from "../ui/select/ContactSelect";
import UserSelect from "../ui/select/UserSelect";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface OrderDetailsEditorProps {
  prices?: boolean;
  isNew?: boolean; // should we add the select product button?
  form: FormInstance;
  onChange: (value: FormInstance) => void;
  onFormValidation: (valid: boolean) => void;
}

export interface FormInstance {
  order_num: string;
  job_num: string;
  description: string;
  type: string;
  customer_id: number;
  end_user_id: number;
  contact_id: number;
  ship_date: string;
  award_date: string;
  quote_type: string;
  shipping_option: number; // 0|1|2
  shipping: number;
  delivery_timeline: string;
  attn: number; // user id
  rfq: string;
  quote_valid_days: number;
  payment_str: string;
  delivery_str: string;
  credit_card_percent: number;
}

const OrderDetailsEditor: React.FC<OrderDetailsEditorProps> = (props) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const newErrors = {
      order_num: props.form.order_num ? "" : "Required",
      ship_date: props.form.ship_date ? "" : "Required",
      type: props.form.type ? "" : "Required",
      customer_id: props.form.customer_id ? "" : "Required",
      contact_id: props.form.contact_id ? "" : "Required",
      end_user_id: props.form.end_user_id ? "" : "Required",
    };
    setErrors(newErrors);
    props.onFormValidation(Object.values(newErrors).every((v) => v === ""));

  }, [props]);

  if (!props.form) return null;
  return (
    <div className="row">
      <div className="col-4 my-2">
        <CustomerSelect
          name="customer_id"
          label="Customer"
          value={props.form.customer_id}
          onChange={(value) => {
            props.onChange({
              ...props.form,
              customer_id: value,
            });
          }}
          error={!!errors.customer_id}
          helperText={errors.customer_id}
        />
      </div>
      <div className="col-4 my-2">
        <EndUserSelect
          name="end_user_id"
          label="End User"
          value={props.form.end_user_id}
          onChange={(value) => {
            props.onChange({
              ...props.form,
              end_user_id: value,
            });
          }}
          customer_id={props.form.customer_id}
          error={!!errors.end_user_id}
          helperText={errors.end_user_id}
        />
      </div>
      <div className="col-4 my-2">
        <ContactSelect
          name="contact_id"
          label="Contact"
          value={props.form.contact_id}
          onChange={(value) => {
            props.onChange({
              ...props.form,
              contact_id: value,
            });
          }}
          customer_id={props.form.customer_id}
          error={!!errors.contact_id}
          helperText={errors.contact_id}
        />
      </div>
      {props.prices && (
        <div className="col-4 my-2">
          <FormControl fullWidth error={!!errors.quote_type}>
            <InputLabel id="quote_type-label">Quote Type</InputLabel>
            <Select
              labelId="quote_type-label"
              label="Quote Type"
              id="quote_type"
              value={props.form.quote_type || ""}
              onChange={(e) => {
                props.onChange({
                  ...props.form,
                  quote_type: e.target.value,
                });
              }}
            >
              <MenuItem value="QUOTATION">Quote</MenuItem>
              <MenuItem value="COST_ESTIMATE">Cost Estimate</MenuItem>
              <MenuItem value="COMMUNICATION">Communication</MenuItem>
            </Select>
            {errors.quote_type && <FormHelperText>{errors.quote_type}</FormHelperText>}
          </FormControl>
        </div>
      )}
      <div className="col-4 my-2">
        <TextField
          id="order_num"
          fullWidth
          label="Quote Number"
          value={props.form.order_num || ""}
          onChange={(e) => {
            props.onChange({
              ...props.form,
              order_num: e.target.value,
            });
          }}
          error={!!errors.order_num}
          helperText={errors.order_num}
        />
      </div>
      <div className="col-4 my-2">
        <TextField
          id="job_num"
          fullWidth
          label="Job Number"
          value={props.form.job_num || ""}
          onChange={(e) => {
            props.onChange({
              ...props.form,
              job_num: e.target.value,
            });
          }}
          error={!!errors.job_num}
          helperText={errors.job_num}
        />
      </div>
      <div className="col-4 my-2">
        <TextField
          id="description"
          fullWidth
          label="Job Description"
          value={props.form.description || ""}
          onChange={(e) => {
            props.onChange({
              ...props.form,
              description: e.target.value,
            });
          }}
          error={!!errors.description}
          helperText={errors.description}
        />
      </div>
      <div className="col-4 my-2">
        <JobTypeSelect
          label="Job Type"
          value={props.form.type || ""}
          onChange={(value) => {
            props.onChange({
              ...props.form,
              type: value,
            });
          }}
          error={!!errors.type}
          helperText={errors.type}
        />
      </div>
      {props.prices && (
        <div className="col-4 my-2">
          <UserSelect
            name="attn"
            label="LTC P.O.C."
            value={props.form.attn || 0}
            onChange={(value) => {
              props.onChange({
                ...props.form,
                attn: value,
              });
            }}
          />
        </div>
      )}
      {props.prices && (
        <div className="col-4 my-2">
          <TextField
            id="rfq"
            fullWidth
            label="RFQ"
            value={props.form.rfq || ""}
            onChange={(e) => {
              props.onChange({
                ...props.form,
                rfq: e.target.value,
              });
            }}
          />
        </div>
      )}
      {props.prices && (
        <div className="col-4 my-2">
          <TextField
            id="award_date"
            fullWidth
            label="Award Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={props.form.award_date || ""}
            onChange={(e) => {
              props.onChange({
                ...props.form,
                award_date: e.target.value,
              });
            }}
          />
        </div>
      )}
      <div className="col-4 my-2">
        <TextField
          id="ship_date"
          fullWidth
          label="Ship Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={props.form.ship_date || ""}
          onChange={(e) => {
            props.onChange({
              ...props.form,
              ship_date: e.target.value,
            });
          }}
          error={!!errors.ship_date}
          helperText={errors.ship_date}
        />
      </div>
      {props.prices && (
        <div className="col-4 my-2">
          <FormControl fullWidth error={!!errors.shipping_option}>
            <InputLabel id="shipping_option-label">Shipping Option</InputLabel>
            <Select
              labelId="shipping_option-label"
              label="Shipping Option"
              id="shipping_option"
              value={props.form.shipping_option || "0"}
              onChange={(e) => {
                props.onChange({
                  ...props.form,
                  shipping_option: parseInt(e.target.value.toString()),
                  shipping: 0,
                });
              }}
            >
              <MenuItem value="0">Paid by Customer</MenuItem>
              <MenuItem value="1">Included</MenuItem>
              <MenuItem value="2">Prepay & Add</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {props.prices && parseInt(props.form.shipping_option?.toString()) === 1 && (
        <div className="col-4 my-2">
          <TextField
            id="shipping"
            fullWidth
            label="Shipping Cost"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={props.form.shipping || ""}
            onChange={(e) => {
              props.onChange({
                ...props.form,
                shipping: parseFloat(e.target.value),
              });
            }}
          />
        </div>
      )}
      {props.prices && (
        <div className="col-4 my-2">
          <TextField
            id="delivery_timeline"
            fullWidth
            label="Delivery (days after order)"
            value={props.form.delivery_timeline || ""}
            onChange={(e) => {
              props.onChange({
                ...props.form,
                delivery_timeline: e.target.value,
              });
            }}
          />
        </div>
      )}
      {props.prices && (
        <div className="col-4 my-2">
          <TextField
            id="quote_valid_days"
            fullWidth
            label="Quote Valid (Days)"
            type="number"
            InputProps={{
              inputProps: { min: 1 },
            }}
            value={props.form.quote_valid_days || ""}
            onChange={(e) => {
              props.onChange({
                ...props.form,
                quote_valid_days: parseInt(e.target.value),
              });
            }}
          />
        </div>
      )}
      {props.prices && (
        <div className="col-4 my-2">
          <FormControlLabel
            control={
              <Checkbox
                checked={parseInt(props.form?.credit_card_percent?.toString()) === 3}
                onChange={(e) => {
                  props.onChange({
                    ...props.form,
                    credit_card_percent: e.target.checked ? 3 : 0,
                  });
                }}
                name="price_hidden"
              />
            }
            label="Credit Card Payments"
          />
        </div>
      )}
      {props.prices && (
        <div className="col-12 my-2">
          <label htmlFor="payment_str">Payment: </label>
          <CustomEditor
            data={props.form.payment_str}
            onChange={(data) => {
              props.onChange({
                ...props.form,
                payment_str: data
              });
            }}
            placeholder="Payment"
          />
        </div>
      )}
      {props.prices && (
        <div className="col-12 my-2">
          <label htmlFor="delivery_str">Delivery: </label>
          <CustomEditor
            data={props.form.delivery_str}
            onChange={(data) => {
              props.onChange({
                ...props.form,
                delivery_str: data
              });
            }}
            placeholder="Leave blank for 'X days after reciept of order'"
          />
        </div>
      )}
    </div>
  );
};

export default OrderDetailsEditor;